import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  width: 1440px;
  margin: 0 auto;
  padding: 0px 30px 30px 230px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5rem 5.5rem 15rem;
  }
`;

// ===========
// PaymentList
// ===========
export const PaymentListWrapper = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  position: relative;
  @media (max-width: 1080px) {
    height: 50rem;
  }
`;

// ====================
// PaymentList - header
// ====================
export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid #dddddd;
  background: #f2f5f9;
  @media (max-width: 1080px) {
    padding: 0 1.5rem;
    height: 12rem;
  }
`;

export const Badge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 30px;
  border-radius: 5px;
  background: #254574;
  background: ${(props) =>
    (props.invoice.companyInvoice?.state === "REQUEST" && "#107C41") ||
    (props.invoice.companyInvoice?.state === "REJECT" && "#ff6600") ||
    (props.invoice.companyInvoice?.state === "REJECT_TAXINVOICE" &&
      "#ff6600") ||
    (props.invoice.companyInvoice?.state === "APPROVE" && "#005BAA") ||
    (props.invoice.companyInvoice?.state === "UPLOAD" && "#005BAA") ||
    (props.invoice.companyInvoice?.state === "PAYMENT" && "#005BAA") ||
    (props.invoice.companyInvoice?.state === "COMPLETE" && "#9C9EA1")};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
  @media (max-width: 1080px) {
    width: 23rem;
    height: 8rem;
    line-height: 8rem;
    font-size: 3rem;
  }
`;

export const InvoiceNo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 19%;
  font-weight: 500;
  font-size: 16px;
  color: #999;
  text-align: center;
  div {
    margin-right: 3px;
  }
  span {
    color: #000000;
  }
  @media (max-width: 1080px) {
    width: 30rem;
    font-size: 3.5rem;
  }
`;

export const PublishedDate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 23%;
  font-weight: 500;
  font-size: 16px;
  color: #999;
  text-align: center;
  div {
    margin-right: 3px;
  }
  span {
    color: #000000;
  }
  @media (max-width: 1080px) {
    width: 37rem;
    font-size: 3.5rem;
  }
`;

export const EstimateStep = styled.div`
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  div {
    position: relative;
  }
  div:not(:first-of-type)::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -30px;
    border-bottom: 30px solid #ffffff;
    border-left: 30px solid transparent;
    z-index: 10;
  }
  div:not(:last-of-type)::before {
    content: "";
    position: absolute;
    right: -30px;
    border-top: 30px solid #ffffff;
    border-right: 30px solid transparent;
    z-index: 10;
  }
  div:nth-child(1) {
    border-radius: 5px 0 0 5px;
    border-bottom: ${(props) =>
      (props.state === "REQUEST" || props.state === "REJECT") &&
      "30px solid #254574"};
    color: ${(props) =>
      (props.state === "REQUEST" || props.state === "REJECT") && "#fff"};
  }
  div:nth-child(1)::before {
    border-top: ${(props) =>
      (props.state === "REQUEST" || props.state === "REJECT") &&
      "30px solid #254574"};
    color: ${(props) =>
      (props.state === "REQUEST" || props.state === "REJECT") && "#fff"};
  }
  div:nth-child(2) {
    background: ${(props) =>
      (props.state === "REJECT_TAXINVOICE" ||
        props.state === "APPROVE" ||
        props.state === "PAYMENT" ||
        props.state === "UPLOAD") &&
      "#254574"};
    color: ${(props) =>
      (props.state === "REJECT_TAXINVOICE" ||
        props.state === "APPROVE" ||
        props.state === "PAYMENT" ||
        props.state === "UPLOAD") &&
      "#fff"};
  }
  div:nth-child(2)::before {
    border-top: ${(props) =>
      (props.state === "REJECT_TAXINVOICE" ||
        props.state === "APPROVE" ||
        props.state === "PAYMENT" ||
        props.state === "UPLOAD") &&
      "30px solid #254574"};
    color: ${(props) =>
      (props.state === "REJECT_TAXINVOICE" ||
        props.state === "APPROVE" ||
        props.state === "PAYMENT" ||
        props.state === "UPLOAD") &&
      "#fff"};
  }
  div:nth-child(2)::after {
    border-bottom: ${(props) =>
      (props.state === "REJECT_TAXINVOICE" ||
        props.state === "APPROVE" ||
        props.state === "PAYMENT" ||
        props.state === "UPLOAD") &&
      "30px solid #254574"};
    color: ${(props) =>
      (props.state === "REJECT_TAXINVOICE" ||
        props.state === "APPROVE" ||
        props.state === "PAYMENT" ||
        props.state === "UPLOAD") &&
      "#fff"};
  }
  div:nth-child(3) {
    border-radius: 0 5px 5px 0;
    background: ${(props) => props.state === "COMPLETE" && "#254574"};
    color: ${(props) => props.state === "COMPLETE" && "#ffffff"};
  }
  div:nth-child(3)::after {
    border-bottom: ${(props) =>
      props.state === "COMPLETE" && "30px solid #254574"};
    color: ${(props) => props.state === "COMPLETE" && "#ffffff"};
  }
`;

export const Step = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  font-size: 16px;
  margin-left: 35px;
  background: #ffffff;
  color: #333333;
  filter: drop-shadow(1.5px 1.5px 1.5px rgba(0, 0, 0, 0.25));
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  cursor: pointer;
  @media (max-width: 1080px) {
    flex-direction: column;
    align-items: flex-start;
    height: 15rem;
    margin-top: 5rem;
    padding: 0 6rem;
  }
`;

export const AlignWrap = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin-left: 20px;
  }
  @media (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 5rem;
    div {
      margin-left: 0;
    }
  }
`;

export const MobileAlignWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1080px) {
    width: 100%;
    margin-bottom: 3rem;
  }
`;

export const ToggleIcon = styled.img`
  display: block;
  margin: auto;
  width: 14px;
  height: 6px;
  @media (max-width: 1080px) {
    width: 4.5rem;
    height: 2.5rem;
  }
`;

export const SiteCount = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #999;
  }
  @media (max-width: 1080px) {
    font-size: 4.5rem;
    span {
      font-size: 4.5rem;
    }
  }
`;

export const OrderCount = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #999;
  }
  @media (max-width: 1080px) {
    font-size: 4.5rem;
    span {
      font-size: 4.5rem;
    }
  }
`;

export const TotalAmount = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #000;
  span {
    font-size: 18px;
    font-weight: 500;
    color: #999;
  }
  @media (max-width: 1080px) {
    font-size: 4.5rem;
    span {
      font-size: 4.5rem;
    }
  }
`;

export const BtnWrap = styled.div`
  button {
    width: 170px;
    height: 40px;
    background: #fff;
    border: 2px solid rgba(0, 40, 99, 0.8);
    box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
    border-radius: 4px;
    color: #002863;
    font-weight: 700;
    font-size: 16px;
    margin-left: 20px;
    transition: 0.2s all;
    &:hover {
      background: #002863;
      color: #fff;
    }
  }
  @media (max-width: 1080px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      width: 45%;
      height: 10rem;
      margin-left: 0;
      font-size: 4rem;
    }
  }
`;

export const MotionContainer = styled(motion.ul)``;

export const ListItem = styled(motion.div)`
  max-height: 300px !important;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const Title = styled(motion.div)`
  border: 1px solid #ddd;
  border-top: none;
  height: 34px;
  line-height: 34px;
  background: #f7f7f7;
  display: flex;
  flex-direction: row;
  div {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    border-right: 1px solid #ddd;
  }
  div:nth-of-type(1) {
    width: 13%;
  }
  div:nth-of-type(2) {
    width: 14%;
  }
  div:nth-of-type(3) {
    width: 10%;
  }
  div:nth-of-type(4) {
    width: 18%;
  }
  div:nth-of-type(5) {
    width: 13%;
  }
  div:nth-of-type(6) {
    width: 9%;
  }
  div:nth-of-type(7) {
    width: 13%;
  }
  div:nth-of-type(8) {
    width: 10%;
    border-right: none;
  }
  @media (max-width: 1080px) {
    width: 970.02px;
    height: 7rem;
    line-height: 7rem;
    div {
      font-size: 1.8rem;
    }
  }
`;

export const ToggleBtn = styled.button`
  width: 100%;
  height: 10rem;
  border: 1px solid #ddd;
  border-top: none;
`;
