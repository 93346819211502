import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
//library
import { useMediaQuery } from "react-responsive";

//component
import Mheader from "../../../component/common/Mheader";

//css
import "../../../asset/css/registration.css";

//api
import { getReqProduct_listApi } from "../../../api/auth";

//redux
import { useSelector } from "react-redux";

//comma
import { wonComma } from "../../../util/Util";

//menu
import { myInfoMenu } from "../../../asset/js/dummy.js";

export default function Registration() {
  const userData = useSelector((state) => state.user);

  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  const navigate = useNavigate();
  //menu
  const [nowUrl, setNowUrl] = useState("/my_page/registration");

  //물품등록리스트받아오기
  const [reqPDList, setReqPDList] = useState([]);

  const getReqProduct_list = async () => {
    const c_no = userData.c_no;
    if (c_no) {
      const res = await getReqProduct_listApi(c_no);

      if (res.status === "success") {
        setReqPDList(res.data);
      }
    }
  };

  useEffect(() => {
    getReqProduct_list();
  }, []);

  //퍼블스테이트
  // p_state
  // ALIVE
  // HOLD
  // BACK

  return (
    <div className="registration_wrap">
      <Mheader title="물품등록 요청" />
      <div className="sub">
        <div className="sub_title">
          {myInfoMenu.map((data, index) => (
            <Link
              to={data.to}
              onClick={() => setNowUrl(data.to)}
              className={nowUrl.includes(data.to) ? "sub_on" : "sub_off"}
            >
              {data.title}
            </Link>
          ))}
        </div>
      </div>
      <div className="registration_container wd">
        <div className="top_pagenation" style={{ paddingTop: "70px" }}>
          <h2>물품등록 요청</h2>
          <div className="register_top">
            <p>
              공새로 품목에 공급사에서 판매하고 계신 물품이 없으시다면 물품등록
              요청을 해보세요!
            </p>
            <div className="top_btn">
              <p onClick={() => navigate("/my_page/registration/request")}>
                물품등록 요청
              </p>
            </div>
          </div>
        </div>
        <div className="register_info">
          {reqPDList.length !== 0 ? (
            isResponsive ? (
              <ul className="register_list">
                {reqPDList.map((data, i) => {
                  return (
                    <li>
                      <h3 className={data.class}>{data.p_state}</h3>
                      <p>
                        {data.pro_name}&#62;
                        {data.lc_name}&#62;
                        {data.mc_name}&#62;
                        {data.sc_name}
                      </p>
                      <p>품목명 : {data.p_name}</p>
                      <p>브랜드 : {data.p_brand}</p>
                      <p>납품가격 : {wonComma(data.p_price)}원</p>
                      <div className="register_detail_btn">
                        <p
                          onClick={() => {
                            navigate(
                              `/my_page/registration/${data.cp_no}/detail`,
                              { state: { reqPDList: reqPDList[i] } }
                            );
                          }}
                        >
                          상세내역
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="table_container">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <p>상태</p>
                      </th>
                      <th>
                        <p>카테고리</p>
                      </th>
                      <th>
                        <p>품목명</p>
                      </th>
                      <th>
                        <p>브랜드</p>
                      </th>
                      <th>
                        <p>납품가격</p>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  {reqPDList.map((data, i) => {
                    return (
                      <tbody>
                        <tr>
                          <td>{data.num}</td>
                          <td>
                            {data.p_state === "HOLD" && "승인 대기중"}
                            {data.p_state === "ALIVE" && "승인 완료"}
                          </td>
                          <td>
                            {" "}
                            {data.pro_name}&#62;
                            {data.lc_name}&#62;
                            {data.mc_name}&#62;
                            {data.sc_name}
                          </td>
                          <td>
                            <p>{data.p_name}</p>
                          </td>
                          <td>{data.p_brand}</td>
                          <td>{wonComma(data.p_price)}원</td>
                          <td>
                            <div className="register_detail_btn">
                              <p
                                onClick={() => {
                                  navigate(
                                    `/my_page/registration/${data.cp_no}/detail`,
                                    { state: { reqPDList: reqPDList[i] } }
                                  );
                                }}
                              >
                                상세보기
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
            )
          ) : (
            <div className="register_none">
              <p>물품등록 요청을 하신 내역이 없습니다</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
