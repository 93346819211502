import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";

//component
import Mheader from "../../../component/common/Mheader";
import OrdersModifyHistoryTabs from "./OrdersModifyHistoryTabs";
// css
import "../../../asset/css/orderIssuance.css";
import * as S from "../../../asset/css/mobileOrderDetailItem.styles.js";

//api
import {
  putOrder_checkApi,
  generateCheckApi,
  showOrderSheetApi,
  ordersDetailApi,
  ordersModifyApi,
} from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

//popup
import GenerateCheckPop from "../../../component/common/Pop/generateCheckPop";

//redux
import { useSelector } from "react-redux";
import { wonComma } from "../../../util/Util";
import Loading from "../../../component/etc/Loading";
import OrderDonePop from "../../../component/common/Pop/OrderDonePop";
import default_invoice_preview from "../../../asset/images/default-invoice-preview.png";
import circle_plus from "../../../asset/images/circle-plus.png";
import ReactTooltip from "react-tooltip";
import { useMediaQuery } from "react-responsive";
import ModifyOrderDonePop from "../../../component/common/Pop/ModifyOrderDonePop";
import OrderDetailItem from "./OrderDetailItem";
import OrderAddRow from "./OrderAddRow";
import OrderFormCancelPop from "../../../component/common/Pop/OrderFormCancelPop";
import TableSummaryFooter from "../../../component/etc/TableFooter";

export default function OrderDetail(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const params = useParams();
  const or_no = params.id;

  //주문서 발행 내역 받아오기
  const [odList, setOdList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [or_state, setOr_state] = useState();
  const [bidType, setBidType] = useState();
  const [generateFile, setGenerateFile] = useState();
  const [generatePop, setGeneratePop] = useState(false);
  const [generatePop2, setGeneratePop2] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [generating2, setGenerating2] = useState(false);
  const [generating3, setGenerating3] = useState(false);
  const [orderDonePop, setOrderDonePop] = useState(false);
  const [orderDonePop2, setOrderDonePop2] = useState(false);
  const [filePath, setFilePath] = useState(null);
  const [detailInfo, setDetailInfo] = useState({});
  const [ordersGroupList, setOrdersGroupList] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [remarks, setRemarks] = useState(detailInfo.orders?.companyComment);
  const [orderData, setOrderData] = useState({
    ordersNo: or_no,
    type: "A",
    companyComment: "",
    productInfoList: [],
  });
  const fee = detailInfo?.orders?.fee;
  // 주문서 발행 취소를 요청하시겠습니까? 팝업
  const [orderFormPop, setOrderFormPop] = useState(false);
  const [clickState, setClickState] = useState(false);

  const branch = () => {
    if (
      or_state === "START" &&
      (detailInfo.bidType === "A" || detailInfo.bidType === "Q")
    )
      return "START";
    if (or_state === "CHECK") return "CHECK";
    if (or_state === "REJECT") return "REJECT";
    return "DEFAULT";
  };

  const generateCheck = async () => {
    // setGenerating(true); //loading ui on
    const response = await generateCheckApi(or_no);
    if (response.status === "success") {
      if (response.data === null) {
        //밀어넣은 데이터들 분기
        setGenerating(false);
        swal("검수확인서가 존재하지 않습니다.", "", "warning");
        return false;
      }

      const link = document.createElement("a");
      link.href = response.data.f_root;
      link.download = "파일이름.pdf"; // 다운로드 파일 이름 설정
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  // const generateCheck = async () => {
  //   // setGenerating(true); //loading ui on
  //   const response = await generateCheckApi(or_no);
  //   if (response.status === "success") {
  //     if (response.data === null) {
  //       //밀어넣은 데이터들 분기
  //       setGenerating(false);
  //       swal("검수확인서가 존재하지 않습니다.", "", "warning");
  //       return false;
  //     }

  //     const link = document.createElement("a");
  //     link.href = response.data.f_root;
  //     link.download = "파일이름.pdf"; // 다운로드 파일 이름 설정
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };

  const ordersDetailFnc = async () => {
    const response = await ordersDetailApi(or_no);
    if (response.status === "success") {
      console.log(response.data);
      setDetailInfo(response.data);
      setOr_state(response.data.orders?.state);
      setFileList(response.data?.bidImgInfoList);
      setOdList(response.data.productInfoList);
      setOrdersGroupList(response.data.ordersGroupList);
    }
  };

  const move2Success = async () => {
    ordersDetailFnc();
  };

  useEffect(() => {
    ordersDetailFnc();
  }, []);

  const onClick_putOrder_check = async () => {
    if (orderData?.productInfoList?.find((data) => data?.price < 1)) {
      swal("주문서 수정을 통해 1 이상의 단가를 입력해주세요.", "", "error");
      return;
    }
    if (
      orderData?.productInfoList?.find((data) => data?.bidProduct.count < 1)
    ) {
      swal("주문서 수정을 통해 1 이상의 수량을 입력해주세요.", "", "error");
      return;
    }
    setGenerating2(true);
    const res = await putOrder_checkApi(userData.uc_no, or_no);
    if (res.status === "success") {
      swal("주문을 확인하였습니다.", "", "success");
      ordersDetailFnc();
    } else {
      swal("주문확인에 실패하였습니다.", "", "warning");
    }
    setGenerating2(false);
  };

  const onClick_putOrder_Done = async () => {
    if (branch() === "CHECK") setOrderDonePop(true);
    if (branch() === "REJECT") setOrderDonePop2(true);
  };

  const onClickShowOrderSheet = async () => {
    const response = await showOrderSheetApi(or_no);
    if (response.status === "success") {
      // if (response.data === null) {
      //   //밀어넣은 데이터들 분기
      //   swal("주문서가 존재하지 않습니다.", "", "warning");
      //   return false;
      // }
      // setFilePath(response.data.fileRoot);
      // setGeneratePop2(true);
      const link = document.createElement("a");
      link.href = response.data.fileRoot;
      link.target = "_blank"; // 새 창에서 열기
      // link.download = "파일이름.pdf"; // 다운로드 파일 이름 설정
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    // setGenerating3(false);
  };

  const formatPhoneNumber = (phoneNumber) => {
    // 정규식 패턴
    var pattern = /^(\d{3})(\d{4})(\d{4})$/;

    // 하이픈을 추가하여 포맷팅된 전화번호 반환
    if (phoneNumber !== undefined || phoneNumber !== "") {
      return phoneNumber?.replace(pattern, "$1-$2-$3");
    }
  };

  useEffect(() => {
    setOrderData({
      ...orderData,
      // companyComment: remarks || "",
      productInfoList: Array(
        orderData?.productInfoList?.length || odList?.length
      )
        // odList?.length)
        .fill(null)
        .map((_, idx) => ({
          bidProduct: {
            productNo:
              orderData?.productInfoList[idx]?.bidProduct?.productNo ||
              odList[idx]?.bidProduct.productNo,
            name:
              orderData?.productInfoList[idx]?.bidProduct?.name ||
              odList[idx]?.bidProduct.name,
            standard:
              orderData?.productInfoList[idx]?.bidProduct?.standard ||
              odList[idx]?.bidProduct.standard,
            brand:
              orderData?.productInfoList[idx]?.bidProduct?.brand ||
              odList[idx]?.bidProduct.brand,
            unit:
              orderData?.productInfoList[idx]?.bidProduct?.unit ||
              odList[idx]?.bidProduct.unit,
            count:
              orderData?.productInfoList[idx]?.bidProduct?.count ||
              odList[idx]?.bidProduct.count,
            etc:
              orderData?.productInfoList[idx]?.bidProduct?.etc ||
              odList[idx]?.bidProduct.etc,
            productImage: odList[idx]?.bidProduct.productImage,
          },
          bidProductNo:
            orderData?.productInfoList[idx]?.bidProductNo ||
            odList[idx]?.bidProductNo,
          price: orderData?.productInfoList[idx]?.price || odList[idx]?.price,
          priceOptionNo:
            orderData?.productInfoList[idx]?.priceOptionNo ||
            odList[idx]?.priceOptionNo,
        })),
    });
  }, [odList, setOrderData, remarks]);

  useEffect(() => {
    setOrderData({
      ...orderData,
      companyComment: remarks || "",
    });
  }, [remarks]);

  const orderCheckMobile = async (endDate) => {
    if (orderData?.productInfoList?.length === 0) {
      swal("주문서 내 최소 1개 이상의 품목이 필요합니다.", "", "error");
      return;
    }
    if (orderData.productInfoList?.find((data) => !data?.bidProduct.name)) {
      swal("품목명을 입력해주세요.", "", "error");
      return;
    }
    if (orderData.productInfoList?.find((data) => !data?.price)) {
      swal("단가를 입력해주세요.", "", "error");
      return;
    }
    if (orderData.productInfoList?.find((data) => isNaN(data?.price))) {
      swal("올바른 단가(숫자)를 입력해주세요.", "", "error");
      return;
    }
    if (
      orderData.productInfoList?.find(
        (data) => !Number.isInteger(Number(data?.price))
      )
    ) {
      swal("올바른 단가(정수)를 입력해주세요.", "", "error");
      return;
    }
    if (orderData.productInfoList?.find((data) => !data?.bidProduct.count)) {
      swal("수량을 입력해주세요.", "", "error");
      return;
    }
    if (orderData.productInfoList?.find((data) => data?.bidProduct.count < 1)) {
      swal("1 이상의 수량을 입력해주세요.", "", "error");
      return;
    }
    if (orderData.productInfoList?.find((data) => data?.price < 2)) {
      swal("2 이상의 단가를 입력해주세요.", "", "error");
      return;
    }
    if (
      orderData.productInfoList?.find((data) => isNaN(data?.bidProduct.count))
    ) {
      swal("올바른 수량(숫자)를 입력해주세요.", "", "error");
      return;
    }
    if (
      orderData.productInfoList?.find(
        (data) => !Number.isInteger(Number(data?.bidProduct.count))
      )
    ) {
      swal("올바른 수량(정수)를 입력해주세요.", "", "error");
      return;
    }

    setGenerating2(true);

    async function modifyOrderAndNotify(apiParams, message) {
      const response = await ordersModifyApi(...apiParams);

      if (response.status === "success") {
        setOr_state(response.data?.or_state);
        swal({
          title: message,
          icon: "success",
          closeOnClickOutside: false,
        }).then((value) => {
          if (value) {
            // window.location.reload();
            setRows([]);
          }
        });
        setIsEdit(false);
        setGenerating2(false);
        setOrderDonePop2(false);
        ordersDetailFnc();
      }
    }

    const startParams = [orderData, "A", detailInfo.orders.delDate];
    const checkParams = [orderData, "CHECK", endDate];
    const rejectParams = [orderData, "REJECT", endDate];

    if (branch() === "START") {
      modifyOrderAndNotify(
        startParams,
        "주문서 수정 및 주문 확인이 완료되었습니다."
      );
    }

    if (branch() === "CHECK") {
      modifyOrderAndNotify(checkParams, "주문서 수정이 완료되었습니다.");
    }

    if (branch() === "REJECT") {
      modifyOrderAndNotify(
        rejectParams,
        "주문서 수정 및 배송이 완료되었습니다."
      );
    }
  };

  const openEditMode = () => {
    setIsEdit(true);
    setSelectedTab(0);
    ordersDetailFnc();
  };

  const closeEditMode = () => {
    setIsEdit(false);
    setRows([]);
    ordersDetailFnc();
  };

  const onClickCompleteEditStart = () => {
    swal({
      title: "주문서 수정 및 주문 확인을 완료하시겠습니까?",
      icon: "info",
      buttons: ["취소", "수정 및 주문확인"],
      closeOnClickOutside: false,
    }).then((value) => {
      if (value) orderCheckMobile();
    });
  };

  const onClickCompleteEditCheck = () => {
    swal({
      title: "주문서 수정을 완료하시겠습니까?",
      icon: "info",
      buttons: ["취소", "수정"],
      closeOnClickOutside: false,
    }).then((value) => {
      if (value) orderCheckMobile();
    });
  };

  const onClickCompleteEditModify = () => {
    swal({
      title: "주문서 수정 및 배송을 완료하시겠습니까?",
      icon: "info",
      buttons: ["취소", "수정 및 배송완료"],
      closeOnClickOutside: false,
    }).then((value) => {
      if (value) orderCheckMobile();
    });
  };

  const onChangeRemarks = (e) => {
    setRemarks(e.target.value);
  };

  const [rows, setRows] = useState([]);

  const handleAddRow = () => {
    const newRow = { id: uuidv4() };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleDeleteOriginRow = (no, index) => {
    setOdList((prev) => {
      const updatedRowList = prev?.filter((row) => row.bidProduct.no !== no);

      return updatedRowList;
    });

    setOrderData((prev) => {
      const updatedRowList = prev?.productInfoList?.filter(
        (_, i) => i !== index
      );

      return { ...prev, productInfoList: updatedRowList };
    });
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const [hoveredTab, setHoveredTab] = useState(null);

  const selectTabHandler = (index) => {
    setSelectedTab(index);
  };

  const hoverTabHandler = (index) => {
    setHoveredTab(index);
  };

  return (
    <div className="order_wrap">
      <Mheader title="주문서 상세 내역" />
      <div className="order_ok_container wd">
        <div className="top_pagenation">
          <h2>주문서 상세 내역</h2>
          <ul className="top_btn_box">
            {!isEdit && or_state === "END" && (
              <li
                onClick={() =>
                  navigate(`/management/order/return/document/${or_no}`)
                }
              >
                <p>반품 주문서 생성</p>
              </li>
            )}
            {!isEdit && branch() !== "DEFAULT" && (
              <li>
                <p onClick={openEditMode}>주문서 수정</p>
              </li>
            )}
            {isEdit && branch() === "START" && (
              <li>
                <p onClick={onClickCompleteEditStart}>주문확인</p>
              </li>
            )}
            {isEdit && branch() === "CHECK" && (
              <li>
                <p onClick={closeEditMode}>수정취소</p>
              </li>
            )}
            {isEdit && branch() === "CHECK" && (
              <li>
                <p onClick={onClickCompleteEditCheck}>수정완료</p>
              </li>
            )}
            {isEdit && branch() === "REJECT" && (
              <li>
                <p onClick={onClick_putOrder_Done}>배송완료</p>
              </li>
            )}
            {!isEdit && (
              <li>
                <p onClick={onClickShowOrderSheet}>거래명세서 다운로드</p>
              </li>
            )}
            {!isEdit && or_state === "START" && (
              <li onClick={onClick_putOrder_check}>
                <p>주문 확인</p>
              </li>
            )}
            {!isEdit && branch() === "CHECK" && (
              <li onClick={onClick_putOrder_Done}>
                <p>배송완료</p>
              </li>
            )}
            {!isEdit && (or_state === "END" || or_state === "TESTED") && (
              <li onClick={generateCheck}>
                <p>검수확인서 다운로드</p>
              </li>
            )}
          </ul>
        </div>
        <div className="order_ok_info">
          <div className="contract_content">
            <div className="content_top">
              <span>주문서 번호 :&nbsp;</span>
              <span className="public_code">{detailInfo.orders?.code}</span>
            </div>
            <div className="content_btm">
              <div className="content_box">
                {detailInfo.bidType === "Q" ? (
                  <img
                    className="company_img"
                    src={detailInfo.constructionLogo}
                    title={detailInfo.constructionName}
                    alt=""
                  />
                ) : (
                  <img
                    className="company_img"
                    src={detailInfo.constructionLogo}
                    title={detailInfo.constructionName}
                    alt=""
                  />
                )}
                <div className="contract_info">
                  <S.BadgeWrap>
                    <S.Badge state={or_state}>
                      {or_state === "START" && "발행완료"}
                      {or_state === "CHECK" && "주문확인"}
                      {or_state === "DONE" && "배송완료"}
                      {or_state === "TESTED" && "검수완료"}
                      {or_state === "CANCEL" && "주문취소"}
                      {or_state === "REJECT" && "반려"}
                      {or_state === "END" && "정산"}
                    </S.Badge>
                    {detailInfo.bidType === "A" && (
                      <S.TypeBadge type={detailInfo.bidType}>
                        주문전용 앱
                      </S.TypeBadge>
                    )}
                    {detailInfo.bidType === "S" && (
                      <S.TypeBadge type={detailInfo.bidType}>
                        일회성 입찰
                      </S.TypeBadge>
                    )}
                    {detailInfo.bidType === "T" && (
                      <S.TypeBadge type={detailInfo.bidType}>
                        단가 계약
                      </S.TypeBadge>
                    )}
                    {detailInfo.bidType === "P" && (
                      <S.TypeBadge type={detailInfo.bidType}>
                        주문서역발행
                      </S.TypeBadge>
                    )}
                    {detailInfo.bidType === "Q" && (
                      <S.TypeBadge type={detailInfo.bidType}>
                        업체지정
                      </S.TypeBadge>
                    )}
                    {(detailInfo.bidType === "B" ||
                      detailInfo.bidType === "D") && (
                      <S.TypeBadge type={detailInfo.bidType}>
                        PB품목
                      </S.TypeBadge>
                    )}
                    {detailInfo.bidType === "R" && (
                      <S.TypeBadge type={detailInfo.bidType}>반품</S.TypeBadge>
                    )}
                  </S.BadgeWrap>

                  <p className="contractor">
                    <span>{detailInfo?.orders?.title}</span>
                    <span>{detailInfo.orders?.createdDate} 발행</span>
                  </p>

                  <div className="contract_text">
                    <div>
                      <p>
                        납품주소 :&nbsp;
                        <span>
                          {detailInfo?.orders?.addr
                            ? `${detailInfo?.orders?.addr}, ${detailInfo?.orders?.detail}`
                            : "-"}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>납품기한 : {detailInfo.orders?.delDate || "-"}</p>
                      {detailInfo.bidType === "T" && (
                        <p>{`계약기간 : ${detailInfo?.bidTerm}개월` || "-"}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="quote_content">
            <h3 className="s_tit">
              <p>고객사</p>
            </h3>
            <div className="quote_list_box">
              <ul className="quote_list">
                <li>
                  <p>고객사명</p>
                  <span>{detailInfo?.constructionName || "-"}</span>
                </li>
                <li>
                  <p>담당자명</p>
                  <span>
                    {detailInfo?.siteUser?.name
                      ? `${detailInfo?.siteUser?.name} ${detailInfo?.siteUser?.rank}`
                      : "-"}
                  </span>
                </li>
              </ul>
              <ul className="quote_list">
                <li>
                  <p>현장명</p>
                  <span>{detailInfo?.siteName || "-"}</span>
                </li>
                <li>
                  <p>연락처</p>
                  <span>
                    {formatPhoneNumber(detailInfo?.siteUser?.phone) || "-"}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="quote_content" style={{ margin: "70px 0 40px 0" }}>
            <h3 className="s_tit">
              <p>공급사</p>
            </h3>
            <div className="quote_list_box">
              <ul className="quote_list">
                <li>
                  <p>공급사명</p>
                  <span>{detailInfo?.companyName || "-"}</span>
                </li>
                <li>
                  <p>연락처</p>
                  <span>
                    {formatPhoneNumber(detailInfo?.companyUser?.phone) || "-"}
                  </span>
                </li>
              </ul>
              <ul className="quote_list">
                <li>
                  <p>담당자명</p>
                  <span>
                    {detailInfo?.companyUser?.name
                      ? `${detailInfo?.companyUser?.name} ${detailInfo?.companyUser?.rank}`
                      : "-"}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <S.Wrap>
            {ordersGroupList?.map((_, index) => (
              <S.TabWrap key={index}>
                {selectedTab === index ? (
                  <a data-tip="React-tooltip">
                    <S.SelectedTabBtn
                      onClick={() => {
                        if (!isEdit) selectTabHandler(index);
                      }}
                      onMouseEnter={() => {
                        hoverTabHandler(index);
                      }}
                      style={{ cursor: isEdit && "not-allowed" }}
                    >
                      {index === 0
                        ? `${ordersGroupList.length}(최종)`
                        : ordersGroupList.length - index}
                    </S.SelectedTabBtn>
                    {isDesktop && (
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        backgroundColor="#002863"
                      >
                        <span>
                          수정일시 :{" "}
                          {ordersGroupList[hoveredTab]?.createdDate
                            .replace("T", " ")
                            .slice(0, 16)}
                        </span>
                      </ReactTooltip>
                    )}
                  </a>
                ) : (
                  <a data-tip="React-tooltip">
                    <S.TabBtn
                      onClick={() => {
                        if (!isEdit) selectTabHandler(index);
                      }}
                      onMouseEnter={() => {
                        hoverTabHandler(index);
                      }}
                      style={{ cursor: isEdit && "not-allowed" }}
                    >
                      {index === 0
                        ? `${ordersGroupList.length}(최종)`
                        : ordersGroupList.length - index}
                    </S.TabBtn>
                    {isDesktop && (
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        backgroundColor="#002863"
                      >
                        <span>
                          수정일시 :{" "}
                          {ordersGroupList[hoveredTab]?.createdDate
                            .replace("T", " ")
                            .slice(0, 16)}
                        </span>
                      </ReactTooltip>
                    )}
                  </a>
                )}
              </S.TabWrap>
            ))}
          </S.Wrap>

          {ordersGroupList?.map((ordersGroup, index) => (
            <OrdersModifyHistoryTabs
              ordersGroupNo={ordersGroup.no}
              key={ordersGroup.no}
              index={index}
              selectedTab={selectedTab}
              inOrderFee={detailInfo?.orders?.fee}
            />
          ))}

          {!selectedTab && (
            <div className="table_container">
              <table>
                <thead>
                  <tr>
                    <th className="table_index"></th>
                    <th className="table_name">품목명</th>
                    <th className="table_standard">규격</th>
                    <th className="table_brand">
                      제조국
                      <p className="table_sub_desc">(브랜드)</p>
                    </th>
                    <th className="table_etc">비고</th>
                    <th className="table_unit">단위</th>
                    <th className="table_count">수량</th>
                    <th className="table_total_amount">
                      고객사 납품단가
                      <p className="table_sub_desc">(수수료 포함 단가)</p>
                    </th>
                    <th className="table_total_amount">
                      최종 합계 금액
                      <p className="table_sub_desc">(고객사 납품 단가)</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {branch() !== "DEFAULT" &&
                    odList?.map((data, i) => (
                      <OrderDetailItem
                        key={data.bidProduct.no}
                        data={data}
                        i={i}
                        isEdit={isEdit}
                        orderData={orderData}
                        detailInfo={detailInfo}
                        setOrderData={setOrderData}
                        handleDeleteOriginRow={() =>
                          handleDeleteOriginRow(data.bidProduct.no, i)
                        }
                      />
                    ))}

                  {isEdit &&
                    rows.map((row, idx) => (
                      <OrderAddRow
                        setOrderData={setOrderData}
                        detailInfo={detailInfo}
                        idx={idx}
                        key={row.id}
                        odList={odList}
                        handleDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))}

                  {isEdit && (
                    <tr>
                      <th></th>
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddRow(rows.length + 1)}
                      >
                        <S.AddRowImg src={circle_plus} alt="" />
                        <S.AddRowText>행추가</S.AddRowText>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}

                  {branch() === "DEFAULT" &&
                    odList?.map((data, i) => (
                      <tr value={data} key={i}>
                        <th>{i + 1}</th>
                        <S.ProductNameWrap>
                          {/* {data.productImageUrl && (
                            <S.ProductImage
                              src={data.productImageUrl}
                              alt=""
                              onClick={() =>
                                window.open(data.productImageUrl, "_blank")
                              }
                            />
                          )} */}
                          {/* {!data.productImageUrl && (
                            <S.DefaultFileImgWrap
                              src={default_invoice_preview}
                              alt=""
                            />
                          )} */}
                          <span>{data?.bidProduct.name}</span>
                        </S.ProductNameWrap>
                        <td>
                          <p className="table_overflow_text">
                            {data?.bidProduct.standard.replace(/, /gi, "\n")}
                          </p>
                        </td>

                        <td>{data?.bidProduct.brand}</td>
                        <td>
                          <p className="table_overflow_text">
                            {data.bidProduct.etc}
                          </p>
                        </td>
                        <td>{data?.bidProduct.unit}</td>
                        <td>{wonComma(data?.bidProduct.count)}</td>
                        <td>{wonComma(data?.price)}</td>
                        <td>
                          {wonComma(data?.bidProduct.count * data?.price)}
                        </td>
                      </tr>
                    ))}
                </tbody>
                <TableSummaryFooter
                  list={isEdit ? orderData.productInfoList : odList}
                  priceKey="price"
                  countKey="bidProduct.count"
                  unitPriceKey={isEdit ? undefined : "unitPrice"}
                  rowSpan={3}
                  colSpan={7}
                  inOrderFee={detailInfo?.orders?.fee}
                />
              </table>
            </div>
          )}
          <div className="uniqueness_box">
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>특이사항</p>
              </div>
              <div className="uniqueness_content">
                <pre>{detailInfo.orders?.uniqueness}</pre>
              </div>
            </div>
          </div>
          <div className="uniqueness_box">
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>공급사 비고 발신란</p>
              </div>
              <div className="uniqueness_content_mobile">
                {!isEdit && <p>{detailInfo.orders?.companyComment}</p>}
                {isEdit && (
                  <textarea
                    defaultValue={detailInfo.orders?.companyComment}
                    onChange={onChangeRemarks}
                  />
                )}
              </div>
            </div>
          </div>
          {branch() === "REJECT" && (
            <div className="uniqueness_box">
              <div className="uniqueness">
                <div className="uniqueness_tit">
                  <p>반려 사유 수신란</p>
                </div>
                <div className="uniqueness_content">
                  <p>{detailInfo.orders?.rejectReason}</p>
                </div>
              </div>
            </div>
          )}
          {fileList?.length === 0 ? null : (
            <div className="look_info payment_account">
              <h3 className="s_tit">
                <p>첨부파일 정보</p>
              </h3>
              {fileList?.map((a, index) => {
                return (
                  <div>
                    <p
                      onClick={() => {
                        window.open(a.fileRoot);
                      }}
                    >
                      첨부파일 {index + 1} 보기
                    </p>
                    <span> {a.fileName} </span>
                  </div>
                );
              })}
            </div>
          )}
          {detailInfo.bidType === "R" && (
            <div className="uniqueness_box">
              <div className="uniqueness">
                <div className="uniqueness_tit">
                  <p>기존 주문서 번호</p>
                </div>
                <div className="uniqueness_content">
                  <a
                    className="origin_order_code"
                    href={`/management/order/document/${detailInfo?.orders?.originOrdersNo}`}
                  >
                    {detailInfo?.originOrdersCode}
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
        {(or_state === "START" ||
          or_state === "CHECK" ||
          or_state === "DONE" ||
          or_state === "TESTED") && (
          <p class="caution_txt">
            *고객사 및 공급사의 일방적인 취소에 따른 패널티가 발생할 수
            있습니다. 취소 시 상호 간 사전협의 후 진행해주세요.
            <span
              onClick={() => {
                setOrderFormPop(true);
                setClickState("CANCEL");
              }}
              className="contract_pause_request"
            >
              주문 취소
            </span>
          </p>
        )}
        {or_state === "TESTED" && (
          <div className="tested_box">
            <button
              //  onClick={Reject}
              onClick={() => {
                setOrderFormPop(true);
                setClickState("REJECT");
              }}
            >
              반려 및 수정
            </button>
          </div>
        )}
      </div>
      {generatePop2 && (
        <GenerateCheckPop
          setGeneratePop={setGeneratePop2}
          filePath={filePath}
        />
      )}
      {generatePop && (
        <GenerateCheckPop
          setGeneratePop={setGeneratePop}
          filePath={generateFile}
        />
      )}
      {orderDonePop && (
        <OrderDonePop
          orNo={or_no}
          setOrderDonePop={setOrderDonePop}
          move2Success={move2Success}
        />
      )}
      {orderDonePop2 && (
        <ModifyOrderDonePop
          onClickCompleteEdit={onClickCompleteEditModify}
          setOrderDonePop={setOrderDonePop2}
        />
      )}
      {orderFormPop && (
        <OrderFormCancelPop
          setOrderFormPop={setOrderFormPop}
          orderNum={detailInfo.orders?.no}
          move2Success={move2Success}
          clickState={clickState}
          bidType={detailInfo.bidType}
        />
      )}
      {generating && <Loading msg={"검수확인서 생성중입니다."} />}
      {generating2 && <Loading msg={"로딩중 입니다."} />}
      {generating3 && <Loading msg={"주문서 생성중입니다."} />}
    </div>
  );
}
