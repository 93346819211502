import { React, useState, useEffect } from 'react';
//css
import '../../../asset/css/look.css';
//etc
import i_close from '../../../asset/images/w-cancel-icon.png';
import i_cahat from '../../../asset/images/m-chat-icon.png';
import i_location from '../../../asset/images/w-map-pin-icon.png';
import { getSiteInfo } from '../../../api/auth';

//sweet alert
import swal from 'sweetalert';


export default function FindDetailPop({
  data,
  s_no,
  setSiteMapList,
  siteMapList,
  i,
}) {
  const [comDetail, setComDetail] = useState(false);
  const [cpList, setCpList] = useState([]);

  const detailDataFnc = async () => {
    const response = await getSiteInfo(s_no);

    if (response.status === 'success') {
      setComDetail(response.data);
    } else {
      swal('현장 정보에 오류가 있습니다.','','error');
      return;
    }
  };

  const hyphen = (phoneNum) => {
    if (phoneNum != undefined)
      return phoneNum.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    return phoneNum;
  };

  useEffect(() => {
    detailDataFnc();
  }, []);

  return (
    <>
      {comDetail !== false && (
        <div className='look_pop'>
          <div>
            <div
              className='look_close'
              onClick={() => {
                const CurrentData = siteMapList.map((item, index) =>
                  index === i && item.flag === false
                    ? { ...item, flag: true }
                    : { ...item, flag: false }
                );
                setSiteMapList(CurrentData);
              }}
            >
              <img src={i_close} alt='' />
            </div>
            <h3>현장 선택</h3>
            <div className='look_company'>
              <div className='look_company_img'>
                <img src={comDetail.f_root} alt='' />
              </div>
              <div className='look_company_info'>
                <div className='look_company_name'>
                  <p>
                    [{comDetail.con_name}] {comDetail.s_name}
                  </p>
                </div>
                <p className='look_company_address'>
                  <img src={i_location}></img>
                  <div>
                    <span>{comDetail.s_addr1}</span>
                    <span>{comDetail.s_addr2}</span>
                  </div>
                </p>
                <div className='look_company_details'>
                  <div>
                    <span>분류</span>
                    <span>{comDetail.s_type}</span>
                  </div>
                  <div>
                    <span>현장규모</span>
                    <span>{comDetail.s_scale}</span>
                  </div>
                  <div>
                    <span>공정</span>
                    <span>{comDetail.pro_name}</span>
                  </div>
                  <div>
                    <span>대표번호</span>
                    <span>{hyphen(comDetail.s_phone)}</span>
                  </div>
                  <div>
                    <span>공사시작일</span>
                    <span>{comDetail.s_sdate}~</span>
                  </div>
                  <div>
                    <span>준공예정일</span>
                    <span>~{comDetail.s_edate}</span>
                  </div>
                  {/* <p>현장규모 : {comDetail.s_scale}</p>
                  <p>공정 : {comDetail.pro_name}</p>
                  <p>공정 : {comDetail.s_phone}</p>
                  <p>공사시작일 : {comDetail.s_sdate}</p>
                  <p>준공예정일 : {comDetail.s_edate}</p> */}
                </div>
              </div>
            </div>
            {/* <div className='look_main_site'>
            <h4>주요 거래업체</h4>
            <p>B상사/C상사</p>
          </div> */}
          </div>
        </div>
      )}
    </>
  );
}
