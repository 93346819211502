export const userDefault = {
  u_no: false,
  u_name: false,
  u_rank: false,

  uc_no: false,
  c_no: false,
  c_name: false,
  c_bsname: false,
  c_fee: false,
};

export const pushDefault = {
  type: false,
  value: false,
};

export const estimateDefault = {
  excel_list: false,
  choice_list: false,

  bid_period: false,
  bid_single: false,

  bid_state: false,
};

export const activityDefault = {
  activity_list: [],
};
