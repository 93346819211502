//css
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../asset/css/requestChoice2.css";
import Mheader from "../../../component/common/Mheader";
import Loading from "../../../component/etc/Loading";
import { actions } from "../../../redux/action";
import { wonComma } from "../../../util/Util";
import question_mark from "../../../asset/images/question_icon_orange.png";
import ReactTooltip from "react-tooltip";

import swal from "sweetalert";

import { useMediaQuery } from "react-responsive";
import useFee from "../../main/customHooks/useFee";

export default function RequestChoice2() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const bidData = useSelector((state) => state.estimate);
  const dispatch = useDispatch();

  const { excel_list, choice_list, bid_period, bid_single, bid_state } =
    useSelector((state) => ({
      excel_list: state.estimate.excel_list,
      choice_list: state.estimate.choice_list,
      bid_period: state.estimate.bid_period,
      bid_single: state.estimate.bid_single,
      bid_state: state.estimate.bid_state,
    }));

  const { cart, bid_no } = location.state;

  const [completeData, setCompleteData] = useState({
    pList: [],
    bid_comment: bidData.bid_period.comment,
    bid_no: bid_no,
  });

  useEffect(() => {
    setCompleteData({ ...completeData, pList: cart });
  }, []);

  const [generating, setGenerating] = useState(false);

  const fee = useFee();

  const sendData = async (step) => {
    const comeData = completeData.pList;

    const price = await comeData.reduce(
      (sum, current) => sum * current.unit_price,
      1
    );

    let totalBid = {
      excel_list: excel_list,
      choice_list: comeData,
      bid_period: { ...bid_period, comment: completeData.bid_comment },
      bid_single: { ...bid_single, comment: completeData.bid_comment },
      bid_state: "choice",
    };

    if (
      comeData?.find(
        (data) =>
          Number(data?.cartAmount) === 0 ||
          Number(data?.cartAmount) === NaN ||
          String(data?.cartAmount).includes(".")
      )
    ) {
      swal("", "수량에 오류가 있습니다.\n숫자를 입력해주세요.", "warning");
      return false;
    }
    if (isNaN(price) || price <= 0) {
      swal("단가를 입력해주세요.", "", "error");
      return;
    } else if (
      completeData?.pList?.find(
        (el) => String(el?.unit_price)?.slice(-1) !== "0"
      )
    ) {
      swal("단가는 10원 단위 이상으로 입력해주세요.", "", "error");
      return;
    } else {
      dispatch(actions.setEstimate(totalBid));

      if (step === "go_step3") {
        navigate("/management/order/request_input_info");
      }

      if (step === "go_step1") {
        navigate("/management/order/request_type", {
          state: {
            againFirst: true,
          },
        });
      }
    }
  };

  const unitPriceCeil = (value) => {
    return Math.ceil((value * ((100 + fee) / 100)) / 10) * 10;
  };

  // 수수료포함 합
  const sumAll = () => {
    const a = completeData?.pList
      ?.map((data) => data?.cartAmount * unitPriceCeil(data?.unit_price || 0))
      .reduce((sum, current) => sum + current, 0);

    console.log("--", a);

    if (isNaN(a)) {
      return "수량과 단가에 숫자를 입력해주세요.";
    } else {
      return wonComma(a) + "원";
    }
  };
  // 공급사 정산 금액 합
  const sumPartnerAll = () => {
    const a = completeData?.pList
      ?.map((data) => data?.cartAmount * (data?.unit_price || 0))
      .reduce((sum, current) => sum + current, 0);

    if (isNaN(a)) {
      return "수량과 단가에 숫자를 입력해주세요.";
    } else {
      return wonComma(a) + "원";
    }
  };

  // 수수료 계산 합
  const feeAll = () => {
    const a = completeData?.pList
      ?.map((data) => data?.cartAmount * unitPriceCeil(data?.unit_price || 0))
      .reduce((sum, current) => sum + current, 0);
    const b = completeData?.pList
      ?.map((data) => data?.cartAmount * (data?.unit_price || 0))
      .reduce((sum, current) => sum + current, 0);

    if (isNaN(a - b)) {
      return "수량과 단가에 숫자를 입력해주세요.";
    } else {
      return wonComma(a - b) + "원";
    }
  };

  // 공급사 최종금액 개별
  const sumPartnerElem = (data) => {
    // is right 합계
    const a = data?.cartAmount * unitPriceCeil(data?.unit_price);

    if (isNaN(a)) {
      return "수량과 단가에 숫자를 입력해주세요.";
    } else {
      return wonComma(a) + "원";
    }
  };
  // 각 최종단가 개별
  const eachPartnerElem = (data) => {
    const a = unitPriceCeil(data?.unit_price);

    if (isNaN(a)) {
      return "수량과 단가에 숫자를 입력해주세요.";
    } else {
      return wonComma(a) + "원";
    }
  };

  return (
    <div className="request_self_choice_wrap">
      <Mheader title="입찰 / 계약 / 주문" />
      <div className="request_self_choice_container">
        {/* top progress bar */}
        <div className="top_progress_bar">
          <div
            className="menus"
            onClick={() => {
              sendData("go_step1");
            }}
          >
            주문방법선택
          </div>
          <div className="active_menus color2">품목 직접 선택(상세)</div>
          <div
            className="menus"
            onClick={() => {
              sendData("go_step3");
            }}
          >
            상세정보 입력
          </div>
          <div className="menus not_allowed">완료</div>
        </div>
        {/* input guide */}
        <div className="self_choice_top">
          <p>
            * 비고란에 추가 내용을 입력하여 정확한 품목을 요청해보세요
            <br />
            <br />* 각 항목의 수량 및 비고사항을 수정 가능합니다.
          </p>
        </div>
        {/* input table */}
        <div className="table_container">
          <table>
            <thead>
              <tr>
                <th className="table_index"></th>
                <th className="table_name">품목명</th>
                <th className="table_standard">규격</th>
                <th className="table_unit">단위</th>
                <th className="table_etc">비고</th>
                <th className="table_count">수량</th>
                <th className="table_amount">단가</th>
                <th data-tip data-for="tooltip" className="table_amount">
                  <div className="choice2_total_price">
                    <p>최종단가</p>
                    <img
                      src={question_mark}
                      className="choice2_total_price_img"
                    />
                    <ReactTooltip
                      id="tooltip"
                      place="top"
                      type="dark"
                      effect="solid"
                      backgroundColor="#002863"
                    >
                      * 최종단가는 중개 수수료 {fee}%가 포함된 가격입니다.
                      <br /> * 10원 단위로 올림 처리됩니다.
                    </ReactTooltip>
                  </div>
                </th>
                <th className="table_amount">합계</th>
              </tr>
            </thead>
            {/* input table body */}
            <tbody>
              {completeData?.pList?.map((el, idx) => (
                <tr key={el.name}>
                  <th>{idx + 1}</th>
                  <td>{el.name}</td>
                  <td>
                    <textarea
                      placeholder="규격을 입력해주세요"
                      defaultValue={el.standard.replace(/, /gi, "\n")}
                      className="no_arrow_input"
                      onChange={(e) => {
                        let tempList = completeData.pList;
                        tempList[idx].p_standard = e.target.value;
                        setCompleteData({ ...completeData, pList: tempList });
                      }}
                    />
                  </td>
                  <td>{el.unit}</td>
                  <td>
                    <div className="choice2_input_container">
                      <input
                        type="text"
                        defaultValue={bidData.choice_list[idx]?.etc}
                        placeholder="비고를 입력해주세요."
                        onChange={(e) => {
                          let tempList = completeData?.pList;
                          tempList[idx].etc = e.target.value;
                          setCompleteData({ ...completeData, pList: tempList });
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="choice2_input_container">
                      <input
                        type="number"
                        min={1}
                        placeholder="1"
                        defaultValue={completeData.pList[idx].cartAmount}
                        className="no_arrow_input"
                        onChange={(e) => {
                          let tempList = completeData.pList;
                          tempList[idx].cartAmount = e.target.value;
                          setCompleteData({ ...completeData, pList: tempList });
                        }}
                        // style={{ padding: "0", width: "50px" }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="choice2_input_container">
                      <input
                        type="number"
                        className="no_arrow_input"
                        // defaultValue={completeData.pList[idx]?.price}
                        placeholder="단가 입력"
                        // defaultValue={0}
                        onChange={(e) => {
                          let tempList = completeData.pList;
                          // 값 입력할 때 마다 최종단가 저장
                          tempList[idx].real_price = unitPriceCeil(
                            Number(e.target.value)
                          );
                          tempList[idx].unit_price = Number(e.target.value);
                          setCompleteData({ ...completeData, pList: tempList });
                        }}
                      />
                      {el.unit_price &&
                        String(el.unit_price)?.slice(-1) !== "0" && (
                          <div className="choice2_input_alert">
                            ※ 10원 단위 이상 입력
                          </div>
                        )}
                    </div>
                  </td>
                  <td>
                    {!el.unit_price
                      ? 0
                      : eachPartnerElem(completeData.pList[idx])}
                  </td>
                  <td>
                    {!el.unit_price
                      ? 0
                      : sumPartnerElem(completeData.pList[idx])}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <table className="table_container">
          <tfoot>
            <tr>
              {isDesktop && (
                <th rowSpan="3" colSpan="4">
                  <div>
                    * 최종 금액은 중개 수수료 {fee}%가 포함된 가격입니다.
                  </div>
                </th>
              )}
              {isMobile && (
                <td rowSpan="3" colSpan="4">
                  <div>
                    * 최종 금액은 중개 수수료 {fee}%가 포함된 가격입니다.
                  </div>
                </td>
              )}
              <th colSpan="2">A. 합계 금액</th>
              <td colSpan="2">{sumAll()}</td>
            </tr>
            <tr>
              <th colSpan="2">B. 중개 수수료 ({fee}%)</th>
              <td colSpan="2">{feeAll()}</td>
            </tr>
            <tr>
              <th colSpan="2">C. 최종 금액 (A - B)</th>
              <td colSpan="2">{sumPartnerAll()}</td>
            </tr>
          </tfoot>
        </table>

        <div className="add_write_wrap">
          <p>주문 관련 추가 설명</p>
          <textarea
            placeholder="주문 관련 추가 설명이 있으시다면 작성해주세요."
            spellCheck="false"
            onChange={(e) => {
              setCompleteData({ ...completeData, bid_comment: e.target.value });
            }}
            defaultValue={bidData.bid_period.comment}
          />
        </div>
        <div className="conclude_btn btn_box">
          <div
            className="prev"
            onClick={() => {
              navigate(-1);
            }}
          >
            <p>이전</p>
          </div>
          <div
            className="next"
            onClick={() => {
              sendData("go_step3");
            }}
          >
            <p>다음</p>
          </div>
        </div>
      </div>
      {generating && <Loading msg={"투찰 중입니다."} />}
    </div>
  );
}
