import { useMediaQuery } from "react-responsive";
import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//css
import "../../../asset/css/myInfo.css";
import "../../../asset/css/myInfoEditPwPop.css";

//component
import Mheader from "../../../component/common/Mheader";
import MyInfoEditLogo from "../../../component/common/Pop/MyInfoEditLogo";
import InviteMsgPop from "../../../component/common/Pop/InviteMsgPop";
import MyInfoEditInfo from "../../../component/common/Pop/MyInfoEditInfo";

//menu
import { myInfoMenu } from "../../../asset/js/dummy.js";

//api
import {
  getImg_pathApi,
  getMyPage_listApi,
  tagListApi,
} from "../../../api/auth";

//redux
import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";

export default function MyInfo() {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();

  const [inviteMsg, setInviteMsg] = useState(false);
  const [editLogo, setEditLogo] = useState(false);

  // maxwidth 구분
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  // 주요 공급 품목
  const [mainItem, setMainItem] = useState(false);

  // 사업자정보 상태 ("", 승인대기중, 승인거절) 3가지
  const [business, setBusiness] = useState("");
  //마이페이지 리스트 받아오기

  const [editMyInfoPop, setEditMyInfoPop] = useState(false);

  const [company, setCompany] = useState([]);
  const [mainProduct, setMainProduct] = useState([]);
  const [manager, setManager] = useState([]);
  const [user, setUser] = useState([]);
  const [tagList, setTagList] = useState([]);

  const getMyPage_list = async () => {
    const res = await getMyPage_listApi();

    if (res.status === "success") {
      setCompany(res.data.company);
      setMainProduct(res.data.mainProduct);
      setManager(res.data.manager);
      setUser(res.data.user);
    }

    const reslicense = await getImg_pathApi(res.data.company.c_f_license);
    const resbankbook = await getImg_pathApi(res.data.company.c_f_bankbook);
    const resintro = await getImg_pathApi(res.data.company.c_f_intro);
    const resstamp = await getImg_pathApi(res.data.company.c_f_stamp);

    if (reslicense.status === "success") {
      setC_f_license(reslicense.data);
    }
    if (resbankbook.status === "success") {
      setC_f_bankbook(resbankbook.data);
    }
    if (resintro.status === "success") {
      setC_f_intro(resintro.data);
    }
    if (resstamp.status === "success") {
      setC_f_stamp(resstamp.data);
    }
  };

  const _move2Success = () => {
    getMyPage_list();
    setEditMyInfoPop(false);
  };

  useEffect(() => {
    getMyPage_list();
    tagListFnc();
  }, []);

  //적용된 list 불러오는 api
  const tagListFnc = async () => {
    const response = await tagListApi();
    if (response.status === "success") {
      setTagList(response.data);
    } else {
      swal("리스트를 불러오지 못했습니다.", "", "warning");
    }
  };

  //이미지 받아오기
  const [c_f_license, setC_f_license] = useState([]);
  const [c_f_intro, setC_f_intro] = useState([]);
  const [c_f_bankbook, setC_f_bankbook] = useState([]);
  const [c_f_stamp, setC_f_stamp] = useState([]);

  const [nowUrl, setNowUrl] = useState("/my_page/my_info");

  //마이페이지 퍼블 참고 스테이트!!!
  // state : ALIVE(활동중), HOLD(승인대기중),REJECT(거절)

  const onClickCheckFee = () => {
    swal(
      `수수료율: ${company.c_fee}%`,
      `적용기간: 2023.06.01 ~

    ※ 최종정산금액 =
    [거래금액(공급가액) - (거래금액(공급가액) X 수수료율)]`,
      "info"
    );
  };

  const onClickCheckFeeMobile = () => {
    swal(
      `수수료율: ${company.c_fee}%`,
      `적용기간: 2023.06.01 ~

    ※ 최종정산금액 =
    [거래금액(공급가액) -
    (거래금액(공급가액) X 수수료율)]`,
      "info"
    );
  };

  return (
    <div className="myinfo_wrap">
      <Mheader title="내 정보 관리" />
      <div className="sub">
        <div className="sub_title">
          {myInfoMenu.map((data, index) => (
            <Link
              to={data.to}
              onClick={() => setNowUrl(data.to)}
              className={nowUrl.includes(data.to) ? "sub_on" : "sub_off"}
            >
              {data.title}
            </Link>
          ))}
        </div>
      </div>
      <div className="myinfo_container wd">
        <div className="myinfo_top">
          <div className="myinfo_title">
            <h2>내 정보 관리</h2>
            <div className="myinfo_menu">
              <p className="edit_btn" onClick={() => setEditMyInfoPop(true)}>
                내 정보 변경
                {editMyInfoPop && isResponsive === false && (
                  <MyInfoEditInfo
                    setEditMyInfoPop={setEditMyInfoPop}
                    move2Success={_move2Success}
                  />
                )}
              </p>
            </div>
          </div>
          <div className="myinfo_detail">
            <p className="myinfo_name">
              {user.u_name}
              <span className="line"></span> {user.u_rank}
            </p>
            {user.u_phone && (
              <p>
                {user.u_phone.substring(0, 3)}-{user.u_phone.substring(3, 7)}-
                {user.u_phone.substring(7, 11)}
              </p>
            )}
            <p>{user.u_id}</p>
          </div>
        </div>
        <div className="myinfo_site">
          <div
            className={
              business === ""
                ? "myinfo_site_title"
                : "myinfo_site_title approval_myinfo"
            }
          >
            <div className="site_title_top">
              <h3 className="s_tit flex">
                <p>
                  <span>사업자정보</span>
                  {company.c_state === "HOLD" && (
                    <span className="my_state cb">승인대기중</span>
                  )}
                  {company.c_state === "REJECT" &&
                    (isResponsive ? (
                      <span className="my_state co">
                        <strong>승인거절</strong>사업자등록증 식별이
                        불가능합니다.
                      </span>
                    ) : (
                      <span className="my_state co">
                        승인거절 : 사업자등록증 식별이 불가능합니다.
                      </span>
                    ))}
                </p>
                {business === "승인거절" && (
                  <div
                    className="my_re_btn"
                    onClick={() => {
                      navigate(`/my_page/my_info/edit`, {
                        state: { company: company },
                      });
                    }}
                  >
                    수정 및 재승인 요청
                  </div>
                )}
              </h3>
            </div>
            {company.c_state === "REJECT" ? (
              <p>
                *승인 후 활동이 가능합니다. 승인까지 1~2일정도 소요 예정입니다.
              </p>
            ) : null}
            {company.c_state === "HOLD" ? (
              <p>
                *승인 후 활동이 가능합니다. 승인까지 1~2일정도 소요 예정입니다.
              </p>
            ) : null}
          </div>
          <div className="myinfo_site_detail">
            <div className="site_detail_top">
              <div className="detail_top_left">
                <div className="detail_top_img">
                  <img src={company.f_root} alt="" />
                  <span onClick={() => setEditLogo(true)}>편집</span>
                </div>
                <div className="detail_top_text">
                  <p>{company.c_name}</p>
                  <p>
                    <span>
                      {company.c_div === "P" ? "개인사업자" : "법인사업자"}
                    </span>
                    <span className="line"></span>
                    <span>{company.c_num}</span>
                  </p>
                </div>
              </div>
              {!isResponsive && (
                <div className="detail_top_right">
                  {/* <p onClick={() => setEditLogo(true)}>
                    사업자 프로필 이미지 변경
                  </p> */}
                  <p onClick={onClickCheckFee}>수수료율 보기</p>
                  <p onClick={() => window.open(c_f_license.f_root, "_blank")}>
                    사업자등록증 보기
                  </p>
                  <p onClick={() => window.open(c_f_bankbook.f_root, "_blank")}>
                    통장사본 보기
                  </p>
                  <p onClick={() => window.open(c_f_intro.f_root, "_blank")}>
                    회사소개서 보기
                  </p>
                  {/* <p onClick={() => window.open(c_f_stamp.f_root, '_blank')}>
                    도장 보기
                  </p> */}
                </div>
              )}
            </div>
            <div className="site_detail_btm">
              <div className="btm_one">
                {company.c_state === "ALIVE" ? (
                  <ul>
                    <li>
                      <p>공급업체분류</p>
                      <p>{company.c_type}</p>
                    </li>
                    <li>
                      <p>주소</p>
                      <p>{company.c_addr1 + " " + company.c_addr2}</p>
                    </li>
                    <li>
                      <p>납품가능지역</p>
                      <p>
                        {company?.areaList?.map((item, idx) => (
                          <span>
                            {item.ca_addr}
                            {company?.areaList?.length - 1 === idx ? "" : ", "}
                            {/* 납품가능지역 사이에 컴마찍기 - 끝엔 안 찍고 */}
                          </span>
                        ))}
                      </p>
                    </li>
                  </ul>
                ) : (
                  <ul>
                    <li>
                      <p>대표자명</p>
                      <p>{company.c_name}</p>
                    </li>
                    <li>
                      <p>업종</p>
                      <p>{company.c_sec}</p>
                    </li>
                    <li>
                      <p>업태</p>
                      <p>{company.c_bc}</p>
                    </li>
                    <li>
                      <p>주소</p>
                      <p>{company.c_addr1}</p>
                    </li>
                    <li>
                      <p>납품 가능 지역</p>
                      {company?.areaList?.map((item) => (
                        <p key={item} value={item}>
                          {item.ca_addr}
                        </p>
                      ))}
                    </li>
                    <li>
                      <p>공급업체분류</p>
                      <p>{company.c_type}</p>
                    </li>
                  </ul>
                )}
              </div>
              {isResponsive && (
                <div className="detail_top_right">
                  {/* <p onClick={() => setEditLogo(true)}>
                    사업자 프로필 이미지 변경
                  </p> */}
                  <p onClick={onClickCheckFeeMobile}>수수료율 보기</p>
                  <p onClick={() => window.open(c_f_license.f_root, "_blank")}>
                    사업자등록증 보기
                  </p>
                  <p onClick={() => window.open(c_f_bankbook.f_root, "_blank")}>
                    통장사본 보기
                  </p>
                  <p onClick={() => window.open(c_f_intro.f_root, "_blank")}>
                    신용평가서 보기
                  </p>
                  {/* <p onClick={() => window.open(c_f_stamp.f_root, '_blank')}>
                    도장 보기
                  </p> */}
                </div>
              )}

              {company.c_state === "ALIVE" && (
                <div className="btm_two">
                  <div>
                    <p>공급사관계자 초대번호</p>
                    <p>{company.c_code}</p>
                  </div>
                  <p onClick={() => setInviteMsg(true)}>초대문자 발송</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {company.c_state === "ALIVE" && (
          <>
            <div className="my_business_info">
              <div className="site_title_top">
                <h3 className="s_tit">
                  <p>사업자 내 담당자 정보</p>
                </h3>
              </div>
              {manager.map((item, i) => (
                <ul className="my_bz_list" key={i} value={item}>
                  <li>
                    <p className="my_bz_name">
                      {item.u_name} <span className="line"></span>
                      {item.u_rank}
                    </p>
                    <p>
                      {item.u_id} / {item.u_rank}
                    </p>
                  </li>
                </ul>
              ))}
            </div>

            <div className="my_hashtag_info">
              <div className="site_title_top">
                <h3 className="s_tit flex">
                  <p>주요 키워드</p>
                  <div className="my_main_item_re_btn">
                    <p
                      onClick={() => {
                        navigate("/my_page/my_info/hashtag");
                      }}
                    >
                      {tagList.length !== 0 ? "수정" : "등록"}
                    </p>
                  </div>
                </h3>
              </div>

              <div className="look_hashtag">
                <div className="look_hashtag_inner">
                  {tagList.length !== 0 ? (
                    tagList.map((a, i) => (
                      <span className="look_hashtag_elem">
                        <span>#</span>
                        {a}
                      </span>
                    ))
                  ) : (
                    <div
                      style={{
                        margin: "30px 0",
                        textAlign: "center",
                        color: "#d2d2d2",
                      }}
                    >
                      적용된 주요 키워드가 없습니다.
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="my_main_item">
              <div className="site_title_top">
                <h3 className="s_tit flex">
                  <p>주요 공급 품목</p>
                  {mainProduct ? (
                    <div className="my_main_item_re_btn">
                      <p
                        onClick={() =>
                          navigate("/my_page/my_info/product_registration", {
                            state: { mainProduct: mainProduct },
                          })
                        }
                      >
                        수정
                      </p>
                    </div>
                  ) : (
                    <div className="my_main_item_btn">
                      <p
                        onClick={() =>
                          navigate("/my_page/my_info/product_registration")
                        }
                      >
                        물품등록 +
                      </p>
                    </div>
                  )}
                </h3>
              </div>
              {mainProduct ? (
                isResponsive ? (
                  <ul className="my_main_item_list">
                    {mainProduct.map((item) => (
                      <li>
                        <p>{item.p_name}</p>
                        <span>
                          규격 :
                          <p className="table_overflow_text">
                            {item.p_standard.replace(/, /gi, " / ")}
                          </p>
                        </span>
                        <span>단위 : {item.p_unit}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="table_container">
                    <table>
                      <thead>
                        <tr>
                          <th className="table_name">품목명</th>
                          <th className="table_standard">규격</th>
                          <th className="table_unit">단위</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mainProduct.map((item) => (
                          <tr>
                            <td>{item.p_name}</td>
                            <td>{item.p_standard}</td>
                            <td>{item.p_unit}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )
              ) : (
                <div className="my_main_item_none">
                  <p>주요 공급 품목이 없습니다.</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {/* {editPosition ? (
        <EditPositionPop
          editPosition={editPosition}
          setEditPosition={setEditPosition}
        />
      ) : null}
      {editPw ? (
        <MyInfoEditPwPop editPw={editPw} setEditPw={setEditPw} />
      ) : null} */}

      {inviteMsg ? (
        <InviteMsgPop
          inviteMsg={inviteMsg}
          setInviteMsg={setInviteMsg}
          company={company}
          user={user}
        />
      ) : null}
      {editLogo ? (
        <MyInfoEditLogo setEditLogo={setEditLogo} company={company} />
      ) : null}
      {editMyInfoPop && isResponsive === false && (
        <div
          className="pop_dim who_pop_dim"
          onClick={() => setEditMyInfoPop(false)}
        ></div>
      )}
      {editMyInfoPop && isResponsive && (
        <MyInfoEditInfo
          setEditMyInfoPop={setEditMyInfoPop}
          move2Success={_move2Success}
        />
      )}
    </div>
  );
}
