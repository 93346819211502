import styled from "@emotion/styled";

export const Wrapper = styled.div`
  @media (max-width: 1080px) {
    width: 970.02px;
  }
`;

export const PaymentList = styled.div`
  overflow: hidden;
  border: 1px solid #ddd;
  border-top: none;
  display: flex;
  flex-direction: row;
  height: 40px;
  line-height: 40px;
  &:hover {
    background: #f2f5f9;
  }
  div {
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    border-right: 1px solid #ddd;
  }
  div:nth-of-type(1) {
    width: 13%;
  }
  div:nth-of-type(2) {
    width: 14%;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div:nth-of-type(3) {
    width: 10%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
  div:nth-of-type(4) {
    width: 18%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
  div:nth-of-type(5) {
    width: 13%;
  }
  div:nth-of-type(6) {
    width: 9%;
  }
  div:nth-of-type(7) {
    width: 13%;
  }
  div:nth-of-type(8) {
    width: 10%;
    border-right: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    button {
      width: 80px;
      height: 30px;
      background: #dbe4f0;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 500;
      color: #002863;
      transition: all 0.2s;
      &:hover {
        background: #002863;
        color: #dbe4f0;
      }
    }
  }
  @media (max-width: 1080px) {
    height: 7rem;
    line-height: 7rem;
    div {
      font-size: 1.7rem;
      overflow-x: scroll;
      overflow-y: hidden;
    }
    div:nth-of-type(8) {
      button {
        font-size: 1.7rem;
        width: 13rem;
        height: 4.5rem;
        line-height: 4.5rem;
      }
    }
  }
`;
