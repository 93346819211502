import { useState } from "react";
import { invoiceListItemApi } from "../../../api/auth";

export default function useInvoiceListItem() {
  const [invoiceListItem, setInvoiceListItem] = useState();

  const fetchInvoiceListItem = async (invoiceNo) => {
    const response = await invoiceListItemApi(invoiceNo);
    if (response.status === "success") setInvoiceListItem(response.data);
  };

  return { invoiceListItem, fetchInvoiceListItem };
}
