import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//component
import Mheader from "../../../component/common/Mheader";

import { useHistory, useNavigate } from "react-router";
import swal from "sweetalert";

export default function PaymentRequestCompleted() {
  const navigate = useNavigate();
  const [isBlocking, setIsBlocking] = useState(false);

  //뒤로가기 확인
  // useEffect(() => {
  //   const unblock = history.block((loc, action) => {
  //     if (action === 'POP' && isBlocking) {
  //       swal('뒤로가기 할 수 없습니다. 확인버튼을 눌러주세요.','','warning');
  //       return false;
  //     }
  //     // return true;
  //   });

  //   return () => unblock();
  // }, [isBlocking]);

  // useEffect(() => {
  //   setIsBlocking(true);
  // }, [history]);

  return (
    <div className="request_completed_wrap completed_wrap">
      <Mheader title="지급 요청하기" />
      <div className="completed_container wd_s">
        <div className="top_pagenation">
          <h2>지급 요청하기</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p>2</p>
            <p className="active">3</p>
          </div>
        </div>
        <div className="completed_text">
          <h4>지급요청이 완료되었습니다.</h4>
          <p>정산관리에서 지급 내역 및 현황을 확인하실 수 있습니다.</p>
        </div>
        <div className="completed_btn">
          <Link to="/calculate/1">지급요청 상세내역</Link>
        </div>
      </div>
    </div>
  );
}
