import * as S from "../../asset/css/basket.styles.js";
import i_cancel_btn from "../../asset/images/cancel-btn.png";
import i_plus_btn from "../../asset/images/plus-square.png";
import i_minus_btn from "../../asset/images/minus-square.png";
import { useSelector } from "react-redux";
import swal from "sweetalert";

export default function Basket(props) {
  const { cartItemList, setCartItem, sendData, notifyWarn } = props;
  const siteNum = useSelector((state) => state.user.site_num);

  const onClickCountUp = (idx) => {
    let copy = [...cartItemList];
    copy[idx].cartAmount = Number(copy[idx].cartAmount) + 1;
    setCartItem(copy);
  };
  const onClickCountDown = (idx) => {
    let copy = [...cartItemList];
    if (copy[idx].cartAmount <= 1) {
      swal("0이하의 숫자는 입력할 수 없습니다.", "", "warning");
      return;
    }
    copy[idx].cartAmount = Number(copy[idx].cartAmount) - 1;
    setCartItem(copy);
  };

  return (
    <>
      <S.Wrapper>
        <S.Title>장바구니</S.Title>
        <S.CartItemListLength>
          <span>{`${cartItemList.length}개`}</span>의 품목이 담겨 있습니다.
        </S.CartItemListLength>
        <S.DivideLine />

        <S.BasketListContainer>
          {cartItemList?.map((cartItem, idx) => (
            <S.BasketListWrap key={cartItem.no}>
              <S.CancelBtnWrap>
                <S.CancelBtn
                  onClick={() => {
                    const deleteData = cartItemList.filter(
                      (cartItemDelete) => cartItemDelete.no !== cartItem.no
                    );
                    setCartItem(deleteData);
                    // cartItem.cartAmount = 0;
                    notifyWarn();
                  }}
                >
                  <img alt="" src={i_cancel_btn} />
                </S.CancelBtn>
              </S.CancelBtnWrap>

              <S.BasketList>
                <S.BasketImgWrap>
                  <img alt="" src={cartItem.file} />
                </S.BasketImgWrap>

                <S.BasketInfoWrap>
                  <S.Brand>{cartItem.brand}</S.Brand>
                  <S.Name>{cartItem.name}</S.Name>
                  <S.Standard>{cartItem.standard}</S.Standard>
                  <S.CountBtnWrap>
                    <S.MinusBtn onClick={() => onClickCountDown(idx)}>
                      <img alt="" src={i_minus_btn} />
                    </S.MinusBtn>
                    <S.Count>{cartItem.cartAmount || 0} 개</S.Count>
                    <S.PlusBtn onClick={() => onClickCountUp(idx)}>
                      <img alt="" src={i_plus_btn} />
                    </S.PlusBtn>
                  </S.CountBtnWrap>
                </S.BasketInfoWrap>
              </S.BasketList>
            </S.BasketListWrap>
          ))}
        </S.BasketListContainer>

        <S.NextBtn
          onClick={() => {
            if (
              cartItemList.some((cartItem) => Number(cartItem.cartAmount) === 0)
            ) {
              swal("수량이 0인 품목이 있습니다.", "", "warning");
              return;
            }
            sendData("go_step2-2");
          }}
        >
          다음
        </S.NextBtn>
      </S.Wrapper>
    </>
  );
}
