import React, { useState, useEffect } from "react";
//css
import "../../asset/css/itemInfo.css";
//etc
import i_cancel from "../../asset/images/w-cancel-icon.png";
import i_magnifier_gray from "../../asset/images/magnifier-icon-gray.png";
import i_magnifier_black from "../../asset/images/magnifier-icon-black.png";
import i_download from "../../asset/images/download_icon.png";
import { wonComma } from "../../util/Util";

export default function ItemInfo({ setOpenDetail, item, addCartItem }) {
  
  return (
    <div className="item_info_wrap pop">
      <div className="pop_dim" onClick={() => setOpenDetail(false)}></div>
      <div className="item_info_container edit_position_pop">
        <div className="item_info_top">
          <h3>상품 상세보기</h3>
          <div className="i_x" onClick={() => setOpenDetail(false)}>
            <img alt="" src={i_cancel} />
          </div>
        </div>
        <div className="item_info_detail">
          <div className="item_info_img_wrap">
            <div className="item_info_img">
              <img src={item.file} alt={item.product.name} />
              <img
                src={i_magnifier_black}
                className="item_plus_icon"
                onClick={() => {
                  window.open(item.file);
                }}
                alt=''
              />
            </div>
          </div>
          <div className="item_info_text_wrap1">
            <div className="wrap1">
              <p className="name">
                <p className="goods_name">{item.product.name}</p>
                <p className="goods_brand">{item.product.brand}</p>
              </p>
              {/* <div className="wrap1_desc">
                    <p className="model">모델명 :&nbsp;</p>
                    <p>{item.p_model_code}</p>
                  </div> */}
              <p className="desc_goods_title">상품 설명</p>
              <p className="desc_name2">
                {item.product.standard.replace(/, /gi, "\n")}
              </p>
              {/* <p className='price'>
                {wonComma(item.p_price)}원 | {item.p_unit}
              </p> */}
            </div>
          </div>
          <div className="item_info_text_wrap">
            <div className="wrap2">
              {item.product.comment && (
                <>
                  <p className="desc_char_title">특징</p>
                  <pre className="desc_char">{item.product.comment}</pre>
                </>
              )}
              {item.product.etc && (
                <>
                  <p className="desc_char_title">기타</p>
                  <p className="option">{item.product.etc}</p>
                </>
              )}
              {/* <p>원산지 : 한국　|　제조사 : 건창CD</p> */}
            </div>
          </div>
          <div
            className="add_this pc_on"
            onClick={() => {
              addCartItem(item);
              setOpenDetail(false);
            }}
          >
            담기
          </div>
          {item?.product.fileAttach && (
            <div className="item_pdf_download" onClick={() => {}}>
              <span>제품설명서 다운로드</span>
              <img src={i_download} className="pdf_download_icon" />
            </div>
          )}
        </div>

        {/* <div className="recom_package">
          <h3 className="recom_package_h3">패키지 추천</h3>
          <div className="recom_package_wrap">
            <div></div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check1" />
              <label for="package_check1" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check2" />
              <label for="package_check2" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check3" />
              <label for="package_check3" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check4" />
              <label for="package_check4" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check4" />
              <label for="package_check4" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check4" />
              <label for="package_check4" />
            </div>
          </div>
          <div className="btn_add_all">모두 담기</div>
        </div> */}
      </div>
    </div>
  );
}
