import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";

import { useNavigate } from "react-router";
import swal from "sweetalert";

import usePreventBack from "../main/customHooks/usePreventBack";

export default function RequestQuoteCompleted() {
  //뒤로가기 방지
  usePreventBack();
  const location = useLocation();
  const bidNo = location?.state?.no;

  // 추가 작업 Offerno작업

  return (
    <div className="request_completed_wrap completed_wrap">
      <Mheader title="입찰 참여하기" />
      <div className="completed_container wd_s">
        <div className="top_pagenation">
          <h2>입찰 참여하기</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p className="active">2</p>
          </div>
        </div>
        <div className="completed_text">
          <h4>입찰 참여가 완료되었습니다.</h4>
          <p>입찰관리에서 내역 및 현황을 확인하실 수 있습니다.</p>
        </div>
        <div className="completed_btn">
          <Link to={"/management/estimate/document/" + bidNo}>
            주문 정보 보기
          </Link>
        </div>
      </div>
    </div>
  );
}
