import React, { useState, useEffect } from "react";
//library
import { useLocation } from "react-router";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/orderIssuance.css";
import "../../../asset/css/contractChange.css";
//etc
import i_cancel from "../../../asset/images/w-cancle-bt.png";
import i_delete from "../../../asset/images/delete_icon_orange.png";

//popup
import OriginContractPop from "../../../component/common/Pop/OriginContractPop";

//loading
import Loading from "../../../component/etc/Loading";

import { wonComma } from "../../../util/Util";

import {
  changeContractApi,
  // getMy_ContractInfoApi
} from "../../../api/auth";

import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";
import { now } from "moment";
import ContractChangeTermsPop from "../../../component/common/Pop/ContractChangeTermsPop";

registerLocale("ko", ko);

export default function ContractChange() {
  const userData = useSelector((state) => state.user);
  const location = useLocation();
  const finalContract = location.state.finalContract;
  const firstConInfo = location.state.firstConInfo;

  const ctList = location.state.ctList; //되돌아가는 용도
  const [pList, setPlist] = useState(finalContract.productList);
  const [countList, setCountList] = useState([]);
  const [conInfo, setConInfo] = useState(finalContract);

  const [submitPlist, setSubmitPlist] = useState([]);
  const ct_no = location.state.contractNo;

  const [addList, setAddList] = useState([]);

  //기존계약보기 팝업
  const [originContractPop, setOriginContractPop] = useState(false);

  // date
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [termsPop, setTermsPop] = useState(false);

  const [filePath, setFilePath] = useState();
  const [changeCtNo, setChangeCtNo] = useState();

  const [generating, setGenerating] = useState(false);

  // const getMy_ContractInfo = async () => {
  //     const res = await getMy_ContractInfoApi(
  //       currentContract?.ct_no,
  //       currentContract?.ct_parent,
  //       currentContract?.ct_count,
  //     )
  //   // }
  //

  //   if (res.status === 'success') {
  //     // setConplist(res.data);
  //     setPlist(res.data.pList);
  //     setConInfo(res.data.conInfo);
  //     setCountList(res.data.countList);
  //   }
  // }

  // useEffect(()=>{
  //   getMy_ContractInfo();
  // },[])
  useEffect(() => {
    let temp = pList.map((now, idx, all) => {
      return {
        od_no: now.od_no,
        od_o_no: conInfo?.ct_o_no,
        od_bp_no: now.bp_no,
        od_price: now.od_price,
        od_count: now.bp_count,
      };
    });
    setSubmitPlist(temp);
  }, [pList]);
  useEffect(() => {
    setContractChangeData({
      ...contractChangeData,
      ct_sdate: conInfo?.ct_sdate,
      ct_edate: conInfo?.ct_edate,
      bid_no: firstConInfo?.bid_no,
      c_no: firstConInfo?.c_no,
      ct_code: conInfo?.ct_code,
    });
    setStartDate(conInfo?.ct_sdate);
    setEndDate(conInfo?.ct_edate);
  }, [conInfo]);

  const [contractChangeData, setContractChangeData] = useState({
    // ct_change_comment:'', //변경사항
    ct_sdate: conInfo?.ct_sdate,
    ct_edate: conInfo?.ct_edate, //어차피 나중엔 바뀔 예정
    bid_no: firstConInfo?.bid_no,
    ct_code: conInfo?.ct_code,
    c_no: firstConInfo?.c_no,
    u_no: userData?.u_no,
    ct_comment: "", //특이사항
    ct_parent: ct_no,
  });

  // 추가
  const addArray = () => {
    const obj = {
      bp_bid_no: firstConInfo?.bid_no,
      bp_name: "",
      bp_standard: "",
      bp_unit: "",
      bp_count: "",
      bp_price: "",
      // bp_etc:''
    };

    const plusLine = addList.concat(obj);
    setAddList(plusLine);
  };

  //계약기간날짜계산
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const CalChangeCompleteApi = async () => {
    if (contractChangeData.or_change_reason === "") {
      swal("변경 사유를 입력해주세요.", "", "warning");
      return;
    } else if (
      new Date(startDate).getTime() < new Date(formatDate(new Date())).getTime()
    ) {
      swal("계약기간을 확인해주세요!", "", "warning");
      return;
    } else if (
      new Date(endDate).getTime() < new Date(formatDate(new Date())).getTime()
    ) {
      swal("계약기간을 확인해주세요!", "", "warning");
      return;
    } else if (
      addList.find(
        (n) =>
          n.bp_name === "" ||
          n.bp_standard === "" ||
          n.bp_unit === "" ||
          n.bp_count === "" ||
          n.bp_count === "0" ||
          n.bp_price === "" ||
          n.bp_price === "0"
      )
    ) {
      swal("추가상품이 잘못 입력되었습니다.", "", "warning");
      return;
    } else if (
      submitPlist.find((data) => data.od_count === "0" && data.od_price !== "0")
    ) {
      swal("기존상품 수량이 잘못 입력되었습니다.", "", "warning");
      return;
    } else if (
      submitPlist.find((data) => data.od_count !== "0" && data.od_price === "0")
    ) {
      swal("기존상품 단가가 잘못 입력되었습니다", "", "warning");
      return;
    } else {
      const response = await changeContractApi(
        submitPlist,
        addList,
        contractChangeData
      );
      setGenerating(true);

      if (response.status === "success") {
        setGenerating(false);
        setFilePath(response.data.file_path);
        setChangeCtNo(response.data.ct_no);
        setTermsPop(true);
      } else {
        setGenerating(false);
        swal("계약 변경에 오류가 있습니다", "", "error");
      }
      setGenerating(false);
    }
  };

  return (
    <div className="order_issuance_wrap">
      <Mheader title="주문서 발행" />
      <div className="order_issuance_container wd">
        <div className="top_pagenation">
          <h2>공급계약 변경</h2>
          <div className="top_pagenation_num">
            <p className="active">1</p>
            <p>2</p>
          </div>
        </div>

        <div className="order_issuance_contents">
          <div className="issuance_subject">
            <h3 className="s_tit">
              <p>품목 작성</p>
            </h3>
            <div className="subject_box">
              <div className="subject_btn">
                {/* <p
                   onClick={() => {
                     history.push({
                       pathname: '/management/contract/product_choice',
                       state: {
                         bid_no: conInfo.bid_no,
                         o_no: conInfo.o_no,
                       },
                     });
                   }}
                 >
                   품목 검색 추가
                 </p> */}
                {/* 품목검색추가 잠깐 삭제 [대표님 지시] */}
              </div>
            </div>

            <div className="subject_table_sub">
              <div className="subject_table_btn" onClick={addArray}>
                물품 추가
              </div>
              <div
                className="subject_table_btn"
                onClick={() => {
                  setOriginContractPop(true);
                }}
              >
                기존 계약 보기
              </div>
            </div>
            <div className="subject_table">
              <table className="table_container">
                <thead>
                  <tr>
                    <th className="table_name">품목명</th>
                    <th className="table_standard">규격</th>
                    <th className="table_unit">단위</th>
                    <th className="table_count">수량</th>
                    <th className="table_amount">단가</th>
                    <th className="table_amount">금액</th>
                    <th className="table_etc"></th>
                  </tr>
                </thead>
                <tbody>
                  {submitPlist?.map((data, i) => {
                    return (
                      <tr
                        className={
                          data?.od_count * data?.od_price !== 0
                            ? "contract_change_p_list"
                            : "contract_change_delete_p_list"
                        }
                      >
                        <td>{pList[i]?.bp_name}</td>
                        <td>
                          <p className="table_overflow_text">
                            {pList[i].bp_standard.replace(/, /gi, "\n")}
                          </p>
                        </td>
                        <td>{pList[i]?.bp_unit}</td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            defaultValue={Number(data?.od_count)}
                            value={Number(data?.od_count)}
                            onChange={(e) => {
                              if (e.target.value === "") e.target.value = 0;

                              let submitList = [...submitPlist];
                              submitList[i].od_count = e.target.value;
                              setSubmitPlist(submitList);
                            }}
                            placeholder="수량"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            defaultValue={Number(data?.od_price)}
                            value={Number(data?.od_price)}
                            onChange={(e) => {
                              if (e.target.value === "") e.target.value = 0;

                              let submitList = [...submitPlist];
                              submitList[i].od_price = e.target.value;
                              setSubmitPlist(submitList);
                            }}
                            placeholder="단가"
                          />
                        </td>
                        <td>{wonComma(data?.od_count * data?.od_price)}</td>

                        <td>
                          <img
                            onClick={() => {
                              let tempList = [...submitPlist];
                              tempList[i].od_count = 0;
                              tempList[i].od_price = 0;
                              setSubmitPlist(tempList);
                            }}
                            src={i_delete}
                            className="delete_icon"
                          />
                        </td>
                      </tr>
                    );
                  })}
                  {addList?.map((data, i) => {
                    return (
                      <tr className="contract_change_add_list">
                        <td>
                          <input
                            type="text"
                            value={data.bp_name}
                            onChange={(e) => {
                              let tempList = [...addList];
                              tempList[i].bp_name = e.target.value;
                              setAddList(tempList);
                            }}
                            placeholder="품목명"
                          />
                        </td>
                        <td>
                          <textarea
                            className="textarea_standard"
                            type="text"
                            value={data.bp_standard}
                            onChange={(e) => {
                              let tempList = [...addList];
                              tempList[i].bp_standard = e.target.value;
                              setAddList(tempList);
                            }}
                            placeholder="규격"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={data.bp_unit}
                            onChange={(e) => {
                              let tempList = [...addList];
                              tempList[i].bp_unit = e.target.value;
                              setAddList(tempList);
                            }}
                            placeholder="단위"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={data.bp_count}
                            min={0}
                            onChange={(e) => {
                              if (e.target.value === "") e.target.value = 0;

                              let tempList = [...addList];
                              tempList[i].bp_count = e.target.value;
                              setAddList(tempList);
                            }}
                            placeholder="수량"
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            value={data.bp_price}
                            min={0}
                            onChange={(e) => {
                              let tempList = [...addList];
                              tempList[i].bp_price = e.target.value;
                              if (e.target.value === "") {
                                tempList[i].bp_price = 0;
                              }
                              setAddList(tempList);
                            }}
                            placeholder="단가"
                          />
                        </td>
                        <td>
                          {data.bp_count !== 0 &&
                            wonComma(data.bp_count * data.bp_price)}
                        </td>

                        <td>
                          <img
                            onClick={() => {
                              let tempList = addList.filter(
                                (now, idx, all) => addList.indexOf(data) !== idx
                              );
                              setAddList(tempList);
                              // setBlink(!blink);
                            }}
                            src={i_delete}
                            className="delete_icon"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={5}>합계</th>
                    <td colSpan={3}>
                      <p className="contract_change_sum">
                        {wonComma(
                          submitPlist
                            ?.map((data) => data.od_count * data.od_price)
                            .reduce((sum, current) => sum + current, 0) +
                            addList
                              .map((data) => data.bp_count * data.bp_price)
                              .reduce((sum, current) => sum + current, 0)
                        )}
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* <div className='uniqueness_box'>
                    <div className='uniqueness'>
                      <div className='uniqueness_tit'>
                        <p>변경 사유</p>
                      </div>
                      <div className='uniqueness_content'>
                        <p>
                          <textarea
                            onChange={(e) => {
                              setContractChangeData({
                                ...contractChangeData,
                                or_change_reason: e.target.value,
                              });
                            }}
                            placeholder='변경사유'
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div> */}

            <div className="uniqueness_box">
              <div className="uniqueness">
                <div className="uniqueness_tit">
                  <p>특이사항</p>
                </div>
                <div className="uniqueness_content">
                  <p>
                    <textarea
                      onChange={(e) => {
                        setContractChangeData({
                          ...contractChangeData,
                          ct_comment: e.target.value,
                        });
                      }}
                      placeholder="특이사항"
                    ></textarea>
                  </p>
                </div>
              </div>
            </div>

            <div className="contract_change_date">
              <h3 className="s_tit">
                <p>계약 기간</p>
              </h3>
              <ul className="contract_change_date_ul">
                <li>
                  <DatePicker
                    locale="ko"
                    minDate={new Date()}
                    selected={endDate && new Date(startDate)}
                    dateFormat="yyyy-MM-dd"
                    className="bidding_time_select"
                    onChange={(date) => {
                      setStartDate(date);
                      setContractChangeData({
                        ...contractChangeData,
                        ct_sdate: formatDate(date),
                      });
                      // minValueTime(formatDate(date), deliveryTime);
                    }}
                  />
                </li>
                <li>~</li>
                <li>
                  <DatePicker
                    locale="ko"
                    minDate={new Date()}
                    // selected={
                    //   endDate ? endDate :
                    //   endDateFnc2(new Date(conInfo.ct_sdate), conInfo.bid_term)
                    // }
                    selected={endDate && new Date(endDate)}
                    dateFormat="yyyy-MM-dd"
                    className="bidding_time_select"
                    onChange={(date) => {
                      setEndDate(date);
                      setContractChangeData({
                        ...contractChangeData,
                        ct_edate: formatDate(date),
                      });
                    }}
                  />
                </li>
              </ul>
              <div className="contract_change_desc">
                기존 계약 기간은{" "}
                <p>
                  {conInfo?.ct_sdate} ~{conInfo?.ct_edate}
                </p>
                입니다.
              </div>
            </div>

            <p className="caution_txt">
              *변경계약은 계약종료 후 한달까지 가능합니다.
            </p>
            <div className="order_btn_box btn_box">
              <div className="prev">
                <p>이전</p>
              </div>
              <div className="ok">
                <p
                  onClick={() => {
                    CalChangeCompleteApi();
                  }}
                >
                  작성
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {originContractPop && (
        <OriginContractPop
          setOriginContractPop={setOriginContractPop}
          pList={pList}
        />
      )}
      {termsPop && (
        <ContractChangeTermsPop
          setTermsPop={setTermsPop}
          filePath={filePath}
          ct_no={changeCtNo}
          ctList={ctList}
        />
      )}
      {generating && <Loading msg={"계약 날인중입니다."} />}
    </div>
  );
}
