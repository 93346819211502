import React, { useState } from 'react';

import i_close from '../../../asset/images/w-cancel-icon.png';
import i_download from '../../../asset/images/download_icon.png';

import { contract_aliveApi } from '../../../api/auth';
import Loading from '../../etc/Loading';

import { isMobile, isIOS } from 'react-device-detect';

//redux
import { useSelector } from 'react-redux';

//sweet alert
import swal from 'sweetalert';


export default function ContractTermsPop({
  setTermsPop,
  formCt_no,
  move2Success,
  contract_file,
}) {
  const userData = useSelector((state) => state.user);
  const PageName = '공급계약 약관';

  const [generating, setGenerating] = useState(false);

  const onClickcontract_alive = async () => {
    setGenerating(true);
    const uc_no = userData.uc_no;
    const res = await contract_aliveApi(formCt_no, uc_no);

    if (res.status === 'success') {
      move2Success();
      setGenerating(false);
      swal('공급계약이 체결되었습니다.','','success');
    } else {
      swal('동의하기에 실패하였습니다.','','warning');
    }
  };

  const downloadPdf = async () => {
    const link = document.createElement('a');
    link.href = contract_file;
    link.target = "_blank"; // Set the target attribute to "_blank"
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className='pop'>
        <div className='pop_dim' onClick={() => setTermsPop(false)}></div>
        <div className='contract_terms_pop'>
          <div className='close' onClick={() => setTermsPop(false)}>
            <img src={i_close} alt='' />
          </div>
          <h3 className='pop_tit'>{PageName}</h3>
          <div>
            {!isMobile?
            <>
            <iframe
              style={{ width: '100%', height: '500px' }}
              src={contract_file}
            ></iframe>
            <div className="pop_download_container">
              <span>보안 프로그램의 문제로 인해 미리보기가 표시되지 않는 경우, 다운로드하여 {PageName}를 확인해주세요.</span>
              <div className="pop_download_box" onClick={()=>{downloadPdf()}}>
                  <span>다운로드</span>
                  <img src={i_download} alt=""/>
              </div>
            </div>              
            </>
            :
            isMobile && isIOS ? //아이폰 웹에서 계약서보기로 띄워줌
            <div>
              <span className="contract_terms_info">
                모바일 환경에선 미리보기가 불가합니다.<br/>
                아래 계약서를 확인한 후 동의를 눌러주세요.
              </span>
              <a className="go_contract_terms" 
              href={contract_file}
              >계약서 보기</a>
            </div>
            :
            <div>
              <iframe
              style={{ width: '100%', height: '200px' }}
              src={contract_file}
              ></iframe>
              <div className="pop_download_container">
              <span>보안 프로그램의 문제로 인해 미리보기가 표시되지 않는 경우, 다운로드하여 {PageName}를 확인해주세요.</span>
              <div className="pop_download_box" onClick={()=>{downloadPdf()}}>
                  <span>다운로드</span>
                  <img src={i_download} alt=""/>
              </div>
            </div>
              <span className="contract_terms_info">
                모바일 환경에선 미리보기가 불가합니다.<br/>
                다운로드 받은 계약서를 보고 계약해주세요!
              </span>
            </div>
            }
          </div>
          <div className='terms_pop_btn btn_box'>
            <div className='cancel'>
              {/* 계약 로딩 Ui가 안나와서 여기로 옮김 */}
            {generating && <Loading msg={'계약 날인중입니다.'} />}
              <p onClick={() => setTermsPop(false)}>취소</p>
            </div>
            <div
              className='agree'
              onClick={() => {
                onClickcontract_alive();
              }}
            >
              동의
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
