import Mheader from "../../../component/common/Mheader";

import "../../../asset/css/requestInputInfo.css";
import {
  fetchUserListApi,
  bidCreateApi,
  bidPreviewApi,
  fetchQuickSiteListApi,
  fetchConstructionListApi,
} from "../../../api/auth";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/action";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { estimateDefault } from "../../../redux/reducer/default";
import GenerateCheckPop from "../../../component/common/Pop/generateCheckPop";
import Loading from "../../../component/etc/Loading";
import moment from "moment";
import "moment/locale/ko";

export default function RequestInputInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [generatePop, setGeneratePop] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [filePath, setFilePath] = useState();

  const [constructionList, setConstructionList] = useState([]);
  const [constructionNo, setConstructionNo] = useState(null);
  const [selectedConstructionNo, setSelectedConstructionNo] = useState(null);

  const [siteList, setSiteList] = useState([]);
  const [siteNo, setSiteNo] = useState(null);
  const [selectedSiteNo, setSelectedSiteNo] = useState(null);

  const [userList, setUserList] = useState([]);
  const [userName, setUserName] = useState("");
  const [userNo, setUserNo] = useState(null);
  const [selectedUserNo, setSelectedUserNo] = useState(null);

  const [deliveryState, setDeliveryState] = useState("");
  const [selectedDeliveryState, setSelectedDeliveryState] = useState("");

  const [deliveryStartDate, setDeliveryStartDate] = useState(null);
  const [deliveryCompleteDate, setDeliveryCompleteDate] = useState(null);

  const [estimateInfo, setEstimateInfo] = useState({
    siteNo: "",
    siteUserNo: "",
    ordersState: "",
    ordersDate: "",
    endDate: "",
  });
  const [onlyConfirmInfo, setOnlyConfirmInfo] = useState({
    constructionName: "",
    siteName: "",
    siteUserName: "",
  });

  const bidData = useSelector((state) => state.estimate);
  const { excel_list, choice_list, bid_period, bid_single, bid_state } =
    useSelector((state) => ({
      excel_list: state.estimate.excel_list,
      choice_list: state.estimate.choice_list,
      bid_period: state.estimate.bid_period,
      bid_single: state.estimate.bid_single,
      bid_state: state.estimate.bid_state,
    }));

  const fetchQuickConstructionList = async () => {
    const response = await fetchConstructionListApi();
    if (response.status === "success") setConstructionList(response.data);
  };

  const fetchQuickSiteList = async () => {
    const response = await fetchQuickSiteListApi(constructionNo);
    if (response.status === "success") setSiteList(response.data);
  };

  const fetchUserList = async () => {
    const response = await fetchUserListApi(siteNo);
    if (response.status === "success") setUserList(response.data);
  };

  const sendData = (step) => {
    let totalBid = {
      excel_list: excel_list,
      choice_list: choice_list,
      bid_period: bid_period,
      bid_single: bid_single,
      bid_state: bid_state,
    };

    dispatch(actions.setEstimate(totalBid));

    if (step === "go_step2" && bidData.bid_state === "excel") {
      navigate("/management/order/request_excel");
    } else if (step === "go_step2" && bidData.bid_state === "choice") {
      navigate("/management/order/request_choice");
    }

    if (step === "go_step1") {
      navigate({
        pathname: "/management/order/request_type",
        state: {
          againFirst: true,
        },
      });
    }

    if (step === "go_last") {
      swal("success");
    }
  };

  const onClickCreateBid = async () => {
    updateConfirmInfo();
    if (!constructionNo) {
      swal("고객사명을 입력해주세요.", "", "warning");
      return;
    }
    if (!selectedSiteNo) {
      swal("현장명을 입력해주세요.", "", "warning");
      return;
    }
    if (!siteNo) {
      swal("현장명을 입력해주세요.", "", "warning");
      return;
    }
    if (!userNo) {
      swal("담당자명을 입력해주세요.", "", "warning");
      return;
    }
    if (!deliveryState) {
      swal("배송상태를 입력해주세요.", "", "warning");
      return;
    }
    if (!deliveryStartDate) {
      swal("주문시간을 입력해주세요.", "", "warning");
      return;
    }
    if (deliveryState === "배송완료 - 납품완료" && !deliveryCompleteDate) {
      swal("납품완료시간을 입력해주세요", "", "warning");
      return;
    }

    const allData = {
      siteNo: estimateInfo.siteNo,
      siteUserNo: estimateInfo.siteUserNo,
      ordersState: estimateInfo.ordersState,
      ordersDate: estimateInfo.ordersDate,
      endDate: estimateInfo.endDate,
      comment: bid_period?.comment,
      productInfoList: excel_list?.productInfoList,
      bidImgList: excel_list.bidImgList?.bidImgNo, //img equal excel
    };

    navigateFnc(allData);

    // const perform = async () => {
    //   setGenerating(true);
    //   const res = await bidCreateApi(allData);

    //   if (res.status === "success") {
    //     dispatch(actions.setEstimate(estimateDefault));
    //     navigate("/management/order/request_complete");
    //   }
    //   setGenerating(false);
    // };
    // perform();
  };

  const navigateFnc = async (allData) => {
    navigate("/management/order/request_input_info/request_confirm", {
      state: { data: allData, onlyViewData: onlyConfirmInfo },
    });
  };

  const onClickPreviewBid = async () => {
    if (!constructionNo) {
      swal("고객사명을 입력해주세요.", "", "warning");
      return;
    }
    if (!selectedSiteNo) {
      swal("현장명을 입력해주세요.", "", "warning");
      return;
    }
    if (!siteNo) {
      swal("현장명을 입력해주세요.", "", "warning");
      return;
    }
    if (!userNo) {
      swal("담당자명을 입력해주세요.", "", "warning");
      return;
    }
    if (!deliveryState) {
      swal("배송상태를 입력해주세요.", "", "warning");
      return;
    }
    if (!deliveryStartDate) {
      swal("주문일자를 입력해주세요.", "", "warning");
      return;
    }
    if (deliveryState === "배송완료 - 납품완료" && !deliveryCompleteDate) {
      swal("납품완료일자를 입력해주세요", "", "warning");
      return;
    }

    const allData = {
      siteNo: estimateInfo.siteNo,
      siteUserNo: estimateInfo.siteUserNo,
      ordersState: estimateInfo.ordersState,
      ordersDate: estimateInfo.ordersDate,
      endDate: estimateInfo.endDate,
      comment: bid_period?.comment,
      productInfoList: excel_list.productInfoList,
      bidImgList: excel_list.bidImgList.bidImgNo, //img equal excel
    };

    const perform = async () => {
      setGenerating(true);

      const res = await bidPreviewApi(allData);

      if (res.status === "success") {
        setFilePath(res.data.fileRoot);
        setGeneratePop(true);
      }
      setGenerating(false);
    };
    perform();
  };

  useEffect(() => {
    fetchQuickConstructionList();
  }, []);

  useEffect(() => {
    if (constructionNo) fetchQuickSiteList();
  }, [constructionNo]);

  useEffect(() => {
    if (siteNo) fetchUserList();
  }, [siteNo]);

  useEffect(() => {
    setUserNo(null);
    setSelectedUserNo(null);
    setDeliveryState(null);
    setSelectedDeliveryState(null);
    setDeliveryStartDate(null);
    setDeliveryCompleteDate(null);
  }, [constructionNo, siteNo]);

  // 건설사 바꿀 때마다만 변경
  useEffect(() => {
    setSiteNo(null);
    setSelectedSiteNo(null);
  }, [constructionNo]);

  useEffect(() => {
    updateConfirmInfo();
  }, [selectedUserNo, selectedSiteNo, selectedConstructionNo]);

  const updateConfirmInfo = () => {
    const { siteUserNo, siteNo } = estimateInfo;

    const construction = constructionList.find(
      (el) => el.no == selectedConstructionNo
    );
    const site = siteList.find((el) => el.site.no == siteNo);
    const siteUser = userList.find((el) => el.no == siteUserNo);

    if (construction && site && siteUser) {
      setOnlyConfirmInfo({
        ...onlyConfirmInfo,
        constructionName: construction.name,
        siteName: site.site.name,
        siteUserName: siteUser.name,
      });
    }
  };

  // const location = useLocation();
  const currentPath = location.pathname; // 현재 경로
  const keyword = "request_confirm"; // 확인할 단어

  // URL에 특정 단어가 포함되어 있는지 확인
  const isContainsKeyword = currentPath.includes(keyword);

  return (
    <>
      {!isContainsKeyword ? (
        <div className="request_short_wrap">
          <Mheader title="상세정보 입력" />
          <div className="request_short_container">
            <div className="top_progress_bar">
              <div className="menus">주문서 작성하기</div>
              <div className="active_menus">상세정보 입력</div>
              <div className="menus">상세정보 확인</div>
              <div className="menus" onClick={updateConfirmInfo}>
                완료
              </div>
            </div>

            <ul className="many_input_list">
              <li>
                <p>고객사명</p>
                <div>
                  <select
                    className="request_select"
                    value={constructionNo || "고객사명"}
                    onChange={(e) => {
                      setConstructionNo(Number(e.target.value));
                      setSelectedConstructionNo(Number(e.target.value));
                    }}
                  >
                    <option value="">고객사명</option>
                    {constructionList?.map((el, idx) => (
                      <option value={el.no} key={idx}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>
              </li>
              <li>
                <p></p>
                <div className="site_name_btn_wrap">
                  {constructionList?.slice(0, 6).map((el, idx) => (
                    <button
                      className={`site_name_btn ${
                        selectedConstructionNo === el.no ? "selected" : ""
                      }`}
                      title={el.name}
                      key={idx}
                      onClick={() => {
                        setConstructionNo(el.no);
                        setSelectedConstructionNo(el.no);
                        // setEstimateInfo({
                        //   ...estimateInfo,
                        //   siteNo: el.site.no,
                        // });
                      }}
                    >
                      {el.name}
                    </button>
                  ))}
                </div>
              </li>
              <li>
                <p>현장명</p>
                <div>
                  <select
                    className="request_select"
                    value={siteNo || "현장명"}
                    disabled={!constructionNo ? true : false}
                    onChange={(e) => {
                      setSiteNo(Number(e.target.value));
                      setSelectedSiteNo(Number(e.target.value));
                      setEstimateInfo({
                        ...estimateInfo,
                        siteNo: e.target.value,
                      });
                    }}
                  >
                    <option value="">현장명</option>
                    {siteList?.map((el, idx) => (
                      <option value={el.site.no} key={idx}>
                        {el.site.name}
                      </option>
                    ))}
                  </select>
                </div>
              </li>

              <li>
                <p></p>
                <div className="site_name_btn_wrap">
                  {siteList?.slice(0, 6).map((el, idx) => (
                    <button
                      className={`site_name_btn ${
                        selectedSiteNo === el.site.no ? "selected" : ""
                      }`}
                      title={el.site.name}
                      key={idx}
                      onClick={() => {
                        setSiteNo(el.site.no);
                        setSelectedSiteNo(el.site.no);
                        setEstimateInfo({
                          ...estimateInfo,
                          siteNo: el.site.no,
                        });
                      }}
                    >
                      {el.site.name}
                    </button>
                  ))}
                </div>
              </li>

              <li>
                <p>담당자명</p>
                <div className="user_name_btn_wrap">
                  {userList?.map((el, idx) => (
                    <button
                      className={`user_name_btn ${
                        selectedUserNo === el.no ? "selected" : ""
                      }`}
                      title={el.name}
                      key={idx}
                      onClick={() => {
                        setUserName(el.no);
                        setUserNo(el.no);
                        setSelectedUserNo(el.no);
                        setEstimateInfo({
                          ...estimateInfo,
                          siteUserNo: el.no,
                        });
                      }}
                    >
                      {el.name}
                    </button>
                  ))}
                </div>
              </li>

              <li>
                <p>배송상태</p>
                <div className="delivery_state_btn_wrap">
                  <button
                    onClick={() => {
                      setDeliveryState("배송중 - 납품 미완료");
                      setSelectedDeliveryState("배송중 - 납품 미완료");
                      setEstimateInfo({
                        ...estimateInfo,
                        ordersState: "배송중 - 납품 미완료",
                        endDate: "",
                      });
                      setDeliveryCompleteDate(null);
                    }}
                    className={`delivery_state_btn ${
                      selectedDeliveryState === "배송중 - 납품 미완료"
                        ? "selected"
                        : ""
                    }`}
                  >
                    배송중 - 납품 미완료
                  </button>
                  <button
                    onClick={() => {
                      setDeliveryState("배송완료 - 납품완료");
                      setSelectedDeliveryState("배송완료 - 납품완료");
                      setEstimateInfo({
                        ...estimateInfo,
                        ordersState: "배송완료 - 납품완료",
                      });
                    }}
                    className={`delivery_state_btn ${
                      selectedDeliveryState === "배송완료 - 납품완료"
                        ? "selected"
                        : ""
                    }`}
                  >
                    배송완료 - 납품완료
                  </button>
                </div>
              </li>

              <li>
                <p></p>
                <div className="guide_line_text">
                  <div>
                    * 배송중 선택시 차후 검수 확인 요청을 위한 배송완료
                    처리(주문서 수신 내역)가 필요합니다.
                  </div>
                  <div>* 배송완료 선택시 검수 확인이 요청됩니다.</div>
                </div>
              </li>

              <li>
                <p>주문일자</p>
                <div className="date_picker_wrap">
                  <DatePicker
                    locale="ko"
                    selected={deliveryStartDate}
                    maxDate={new Date()}
                    onChange={(date) => {
                      setDeliveryStartDate(date);
                      setEstimateInfo({
                        ...estimateInfo,
                        ordersDate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                      });
                    }}
                    showDateSelect
                    dateFormat="yyyy/MM/dd 주문"
                    withPortal
                  />
                </div>
              </li>

              <li>
                <p>납품완료일자</p>
                <div
                  className={
                    deliveryState === "배송중 - 납품 미완료"
                      ? "date_picker_wrap_readOnly"
                      : "date_picker_wrap"
                  }
                >
                  <DatePicker
                    locale="ko"
                    selected={deliveryCompleteDate}
                    minDate={deliveryStartDate}
                    onChange={(date) => {
                      setDeliveryCompleteDate(date);
                      setEstimateInfo({
                        ...estimateInfo,
                        endDate: moment(date).format("yyyy-MM-DD HH:mm:ss"),
                      });
                    }}
                    showDateSelect
                    dateFormat="yyyy/MM/dd 납품"
                    withPortal
                    readOnly={
                      deliveryState === "배송중 - 납품 미완료" ? true : false
                    }
                  />
                </div>
              </li>

              <li>
                <p></p>
                <div>
                  <div className="conclude_btn btn_box">
                    <div
                      className="estimate_preview_btn"
                      onClick={onClickPreviewBid}
                    >
                      주문서 미리보기
                    </div>
                    <div
                      className="estimate_complete_btn"
                      onClick={onClickCreateBid}
                    >
                      주문서 발송
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <>
            {generatePop && (
              <GenerateCheckPop
                setGeneratePop={setGeneratePop}
                filePath={filePath}
              />
            )}
          </>
          <>
            {generating && (
              <Loading msg={"주문 확인서 생성중입니다."} color="#000" />
            )}
          </>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
}
