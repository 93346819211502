import React from "react";
//library
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function Footer() {
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  return (
    <div className="footer_wrap">
      <div className="footer_container">
        <div className="footer_top">
          <p>
            <span>고객센터</span>032-205-0479
          </p>
          <div className="top_link">
            <Link to="/release_note">제품정보</Link>
            <Link to="/terms">이용약관</Link>
            <Link to="/privacy">개인정보처리방침</Link>
          </div>
        </div>
        {isResponsive ? (
          <div className="footer_mid">
            <p>상호명 : 주식회사 공새로 | 사업자등록번호 : 670-87-02114</p>
            <p>
              통신판매신고 : 제2021-인천연수구-2330호 | 전화번호 : 032-205-0479
            </p>
            <p>주소 : 인천광역시 연수구 컨벤시아대로 204, 522호 (송도동)</p>
            <p>문의메일 : contact@gongsaero.com | 대표자 : 남가람</p>
          </div>
        ) : (
          <div className="footer_mid">
            <p>
              상호명 : 주식회사 공새로 | 사업자등록번호 : 670-87-02114 |
              통신판매신고 : 제2021-인천연수구-2330호 | 전화번호 : 032-205-0479
            </p>
            <p>
              주소 : 인천광역시 연수구 컨벤시아대로 204, 522호 (송도동) |
              문의메일 : contact@gongsaero.com | 대표자 : 남가람
            </p>
          </div>
        )}
        <div className="footer_btm">
          <p>Copyright &copy; by 공새로. All Right Reserved.</p>
        </div>
      </div>
    </div>
  );
}
