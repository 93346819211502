import { ReactComponent as EstimateIcon } from "../images/side_estimate_icon.svg";
import { ReactComponent as ContractIcon } from "../images/side_contract_icon.svg";
import { ReactComponent as OrderIcon } from "../images/side_order_icon.svg";
import { ReactComponent as CalculateIcon } from "../images/side_calculate_icon.svg";
import { ReactComponent as ChatIcon } from "../images/side_chat_icon.svg";
import { ReactComponent as PriceIcon } from "../images/side_price_icon.svg";
import { ReactComponent as GraphIcon } from "../images/side_graph_icon.svg";

//메뉴리스트

export const menuList = [
  {
    id: 1,
    mainNav: "인근현장찾기",
    uri: "/look",
    active: false,
    css_on: false,
  },
  {
    id: 2,
    mainNav: "입찰공고 보기",
    uri: "/request/1",
    active: false,
    css_on: false,
    cnt: true,
  },
  {
    id: 3,
    mainNav: "입찰/계약/주문",
    uri: "/management/estimate",
    active: false,
    css_on: false,
    subNav: [
      {
        to: "/management/estimate/1",
        title: "입찰관리",
      },
      {
        to: "/management/contract/1",
        title: "계약관리",
      },
      {
        to: "/management/order/1",
        title: "주문서 수신관리",
      },
    ],
  },
  {
    id: 4,
    mainNav: "정산관리",
    uri: "/invoice",
    active: false,
    css_on: false,
  },
  {
    id: 5,
    mainNav: "질의응답",
    uri: "/qna",
    active: false,
    css_on: false,
  },
  {
    id: 6,
    mainNav: "거래이력",
    uri: "/all_history",
    active: false,
    css_on: false,
  },
  // {
  //   id: 6,
  //   mainNav: "앱 품목 관리",
  //   uri: "/management/price",
  //   active: false,
  //   css_on: false,
  // },
  {
    id: 7,
    mainNav: "마이페이지",
    uri: "/my_page/my_info",
    active: false,
    css_on: false,
    subNav: [
      {
        to: "/my_page/my_info",
        title: "내 정보 관리",
      },
      {
        to: "/my_page/notice",
        title: "공지사항",
      },
      // {
      //   to: "/my_page/service_history",
      //   title: "유료 서비스 이용내역",
      // },
      {
        to: "/my_page/registration",
        title: "물품등록 요청",
      },
    ],
  },
];

export const sideList = [
  {
    id: 1,
    mainNav: "입찰관리",
    uri: "/management/estimate/1",
    Icon: EstimateIcon,
    active: false,
    subNav: [],
  },
  {
    id: 2,
    mainNav: "계약관리",
    uri: "/management/contract/1",
    Icon: ContractIcon,
    active: false,
  },
  {
    id: 3,
    mainNav: "주문관리",
    uri: "/management/order/1",
    Icon: OrderIcon,
    active: false,
  },
  {
    id: 4,
    mainNav: "정산관리",
    uri: "/invoice",
    Icon: CalculateIcon,
    active: false,
  },
  {
    id: 5,
    mainNav: "질의응답",
    uri: "/qna",
    Icon: ChatIcon,
    active: false,
  },
  {
    id: 6,
    mainNav: "거래이력",
    uri: "/all_history",
    Icon: GraphIcon,
    active: false,
  },
];

export const myInfoMenu = [
  {
    to: "/my_page/my_info",
    title: "내 정보 관리",
  },
  {
    to: "/my_page/notice",
    title: "공지사항",
  },
  // {
  //   to: "/my_page/service_history",
  //   title: "유료 서비스 이용내역",
  // },
  {
    to: "/my_page/registration",
    title: "물품등록 요청",
  },
];
