import * as S from "../../asset/css/invoiceList.styles";
import arrow_open from "../../asset/images/arrow-open.png";
import arrow_close from "../../asset/images/arrow-close.png";
import {
  invoiceCompleteApi,
  invoiceRejectApi,
  paymentRequestDownloadApi,
} from "../../api/auth";
import InvoiceListItem from "./InvoiceListItem";
import swal from "sweetalert";
import InvoiceListItemBtn from "./InvoiceListItemBtn";
import useOpenInvoiceList from "./customHooks/useOpenInvoiceList";
import useInvoiceItemList from "./customHooks/useInvoiceListItem";
import InvoiceUpload from "./InvoiceUpload";
import useOpenUploadModal from "./customHooks/useOpenUploadModal";
import { useMediaQuery } from "react-responsive";
import { useRef } from "react";
import useGenerateLoading from "./customHooks/useGenerateLoading";
import Loading from "../../component/etc/Loading";

export default function InvoiceList(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });

  const { invoice, state, fetchInvoiceList, fetchInvoiceCountByState } = props;

  const { openInvoiceList, setOpenInvoiceList } = useOpenInvoiceList();
  const { invoiceListItem, fetchInvoiceListItem } = useInvoiceItemList();
  const { openUploadModal, setOpenUploadModal } = useOpenUploadModal();
  const { generate, setGenerate } = useGenerateLoading();

  const onClickOpenUploadModal = () => {
    setOpenUploadModal(true);
  };

  const onClickCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const onClickInvoiceList = () => {
    fetchInvoiceListItem(invoice.companyInvoice.no);
    setOpenInvoiceList((prev) => !prev);
  };

  const onClickRejectComment = (e) => {
    e.stopPropagation();
    swal({
      title: "반려 사유",
      text: invoice.companyInvoice.rejectComment,
      icon: "info",
    });
  };

  const onClickTaxInvoice = (e) => {
    e.stopPropagation();
    window.open(invoice.texInvoiceFile);
  };

  const onClickReject = async (invoiceNo, rejectComment) => {
    await invoiceRejectApi(invoiceNo, rejectComment);
  };

  const handleSendClick = () => {
    const value = document.querySelector(".swal-content__textarea").value;

    if (!value) {
      swal("반려사유를 입력해주세요.", "", "warning");
    } else {
      onClickReject(invoice.companyInvoice.no, value);
      swal({
        title: "정산이 반려되었습니다.",
        text: `${value}`,
        icon: "success",
      }).then((value) => {
        if (value) fetchInvoiceList("request");
      });
    }
  };

  const handleCancelClick = () => {
    swal("입력이 취소되었습니다.", "", "info");
  };

  const Reject = () => {
    swal({
      title: "반려사유 입력",
      icon: "info",
      buttons: {
        cancel: {
          text: "취소",
          value: null,
          visible: true,
        },
        confirm: {
          text: "전송",
          value: true,
          visible: true,
        },
      },
      closeOnClickOutside: false,
      content: {
        element: "textarea",
        attributes: {
          placeholder: "사유를 입력해주세요.",
          rows: 4,
          style: "resize:none",
        },
      },
    }).then((value) => {
      if (value) handleSendClick();
      if (!value) handleCancelClick();
    });
  };

  const downloadLinkRef = useRef(null);

  const onClickPaymentRequestDownload = async (invoiceNo) => {
    setGenerate(true);

    try {
      const response = await paymentRequestDownloadApi(invoiceNo);

      const url = URL.createObjectURL(response.data);
      let filename = "filename.xlsx";
      const contentDisposition = response.headers["content-disposition"];

      if (contentDisposition) {
        const match = contentDisposition.match(/fileName="([^"]+)"/);
        if (match && match[1]) {
          filename = decodeURIComponent(match[1]).replace(/\+/g, " ");
        }
      }

      downloadLinkRef.current.href = url;
      downloadLinkRef.current.download = filename;
      downloadLinkRef.current.click();
      setGenerate(false);
    } catch (error) {
      swal("다운로드 오류", "", "error");
      setGenerate(false);
    }
  };

  const onClickComplete = async (invoiceNo) => {
    await invoiceCompleteApi(invoiceNo);
  };

  const Complete = async () => {
    swal({
      title: "대금지급 확인 처리 하시겠습니까?",
      icon: "info",
      buttons: ["취소", "확인"],
      closeOnClickOutside: false,
    }).then((value) => {
      if (value) {
        onClickComplete(invoice.companyInvoice.no);
        swal({
          title: "대금지급 확인이 완료되었습니다.",
          text: "해당 건은 '지급완료' 상태로 이동됩니다.",
          icon: "success",
          closeOnClickOutside: false,
        }).then((value) => {
          if (value) {
            fetchInvoiceList("complete");
            fetchInvoiceCountByState();
          }
        });
      }
      if (!value) swal("취소되었습니다.", "", "info");
    });
  };

  return (
    <>
      <S.Wrapper>
        {openUploadModal && (
          <InvoiceUpload
            onClickCloseUploadModal={onClickCloseUploadModal}
            invoice={invoice}
            fetchInvoiceList={fetchInvoiceList}
            fetchInvoiceCountByState={fetchInvoiceCountByState}
          />
        )}
        <S.PaymentListWrapper>
          <S.ListHeader>
            <S.Badge invoice={invoice}>
              {invoice.companyInvoice.state === "REQUEST" && "세금계산서 요청"}
              {invoice.companyInvoice.state === "REJECT" && "정산 반려"}
              {invoice.companyInvoice.state === "REJECT_TAXINVOICE" &&
                "세금계산서 반려"}
              {invoice.companyInvoice.state === "APPROVE" && "세금계산서 승인"}
              {invoice.companyInvoice.state === "UPLOAD" && "세금계산서 전송"}
              {invoice.companyInvoice.state === "PAYMENT" && "입금완료"}
              {invoice.companyInvoice.state === "COMPLETE" && "정산완료"}
            </S.Badge>
            <S.InvoiceNo>
              {isDesktop && <div>정산번호 : </div>}
              <span>{invoice.companyInvoice.code}</span>
            </S.InvoiceNo>
            <S.PublishedDate>
              {isDesktop && <div>발행날짜 : </div>}
              <span>
                {invoice.companyInvoice.createdDate
                  ?.replace("T", " ")
                  .replaceAll("-", ".")
                  .slice(0, 16)}
              </span>
            </S.PublishedDate>

            {isDesktop && (
              <S.EstimateStep state={invoice.companyInvoice.state}>
                <S.Step>
                  <p>지급요청 확인</p>
                </S.Step>
                <S.Step>
                  <p>지급처리중</p>
                </S.Step>
                <S.Step>
                  <p>지급완료</p>
                </S.Step>
              </S.EstimateStep>
            )}
          </S.ListHeader>

          <S.ListBody onClick={isDesktop && onClickInvoiceList}>
            <S.AlignWrap>
              {isDesktop && (
                <S.ToggleIcon
                  src={openInvoiceList ? arrow_close : arrow_open}
                />
              )}
              <S.MobileAlignWrap>
                <S.SiteCount>
                  현장 : <span>{invoice?.siteCount}곳</span>
                </S.SiteCount>
                {isDesktop && <div>|</div>}
                <S.OrderCount>
                  주문서 개수 : <span>{invoice?.ordersCount}개</span>
                </S.OrderCount>
              </S.MobileAlignWrap>
              {isDesktop && <div>|</div>}
              <S.TotalAmount>
                합계 금액 :{" "}
                <span>
                  {invoice?.companyInvoice.realAmount?.toLocaleString("ko")}원
                  (VAT 제외)
                </span>
              </S.TotalAmount>
            </S.AlignWrap>

            <S.BtnWrap>
              <button
                onClick={() => {
                  setOpenInvoiceList(true);
                  onClickPaymentRequestDownload(invoice.companyInvoice.no);
                }}
              >
                정산내역서 다운
              </button>
              <a ref={downloadLinkRef} style={{ display: "none" }} />
              {(invoice.companyInvoice.state === "REJECT_TAXINVOICE" ||
                invoice.companyInvoice.state === "APPROVE" ||
                invoice.companyInvoice.state === "PAYMENT" ||
                invoice.companyInvoice.state === "UPLOAD" ||
                invoice.companyInvoice.state === "COMPLETE") && (
                <button onClick={(e) => onClickTaxInvoice(e)}>
                  세금계산서 보기
                </button>
              )}
            </S.BtnWrap>
          </S.ListBody>
        </S.PaymentListWrapper>
        {isMobile && (
          <S.ToggleBtn onClick={onClickInvoiceList}>
            <S.ToggleIcon src={openInvoiceList ? arrow_close : arrow_open} />
          </S.ToggleBtn>
        )}

        {openInvoiceList && (
          <>
            <S.ListItem>
              <S.Title>
                <div>시간</div>
                <div>주문번호</div>
                <div>현장명</div>
                <div>품목명</div>
                <div>납품금액</div>
                <div>수수료</div>
                <div>정산금액</div>
                <div></div>
              </S.Title>

              {invoiceListItem?.map((invoiceItem) => (
                <InvoiceListItem
                  key={invoiceItem.ordersNo}
                  invoiceItem={invoiceItem}
                />
              ))}
            </S.ListItem>

            <InvoiceListItemBtn
              state={state}
              invoice={invoice}
              Reject={() => Reject(invoice.companyInvoice.no)}
              Complete={() => Complete(invoice.companyInvoice.no)}
              onClickRejectComment={onClickRejectComment}
              onClickOpenUploadModal={onClickOpenUploadModal}
            />
          </>
        )}
      </S.Wrapper>
      {generate && <Loading />}
    </>
  );
}
