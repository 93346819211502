import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import i_close from '../../../asset/images/w-cancel-icon.png';
//css
import '../../../asset/css/pop.css';
// import '../../../asset/css/myInfoEditPwPop.css';

import { mypage_UpdatePwApi } from '../../../api/auth';
import { useDispatch } from 'react-redux';
import { actions } from '../../../redux/action';
import { userDefault } from '../../../redux/reducer/default.js';

//sweet alert
import swal from 'sweetalert';


export default function MyInfoEditPwPop({ editPw, setEditPw }) {
  //비밀번호스테이트
  const [pwd, setPwd] = useState({
    u_pw: '',
    newPw: '',
    u_pwc: '',
  });
  const dispatch = useDispatch();

  //비밀번호 영문+숫자+특수문자 8자리 이상
  const patternPwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

  const onClick_mypage_UpdatePw = async () => {
    if (!patternPwd.test(pwd.u_pw)) {
      swal('기존비밀번호의 비밀번호 형식을 맞춰주세요','','warning');
      return;
    }
    if (!patternPwd.test(pwd.newPw)) {
      swal('신규비밀번호의 비밀번호 형식을 맞춰주세요','','warning');
      return;
    }
    if (pwd.newPw !== pwd.u_pwc) {
      swal('신규비밀번호와 확인이 같지 않습니다.','','warning');
      return;
    }

    const res = await mypage_UpdatePwApi(pwd.u_pw, pwd.newPw);

    if (res.message === 'pw-error') {
      swal('기존비밀번호와 같지 않습니다.','','warning');
      return;
    }
    if (res.status === 'success') {
      //dispatch
      dispatch(actions.setToken(false));
      dispatch(actions.setUser(userDefault));
      swal('비밀번호변경이 완료되었습니다.','','success');
      setEditPw(false);
      return;
    }
  };

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setEditPw(false)}></div>
      <div className='contract_terms_pop my_info_edit_pw_pop'>
        <div className='close' onClick={() => setEditPw(false)}>
          <img src={i_close} alt='' />
        </div>
        <h3 className='pop_tit'>비밀번호 변경</h3>
        <form className='edit_pw_input_form'>
          <div>
            <h4>기존 비밀번호</h4>
            <input
              type='password'
              placeholder='기존 비밀번호를 입력해주세요.'
              onChange={(e) => {
                setPwd({ ...pwd, u_pw: e.target.value });
              }}
            />
          </div>
          <div>
            <h4>신규 비밀번호</h4>
            <input
              type='password'
              placeholder='신규 비밀번호를 입력해주세요.'
              onChange={(e) => {
                setPwd({ ...pwd, newPw: e.target.value });
              }}
            />
            <p className='small_text'>
              영문, 숫자, 특수문자를 혼합한 8~15자리의 비밀번호를 입력
              <span>해주세요.</span>
            </p>
          </div>
          <div
            className={
              pwd.newPw !== pwd.u_pwc ? 'check_pw not_match' : 'check_pw'
            }
          >
            <h4>비밀번호 확인</h4>
            <input
              type='password'
              placeholder='신규 비밀번호를 입력해주세요.'
              onChange={(e) => {
                setPwd({ ...pwd, u_pwc: e.target.value });
              }}
            />
            {pwd.newPw !== pwd.u_pwc ? (
              <p className='small_text'>비밀번호가 일치하지 않습니다.</p>
            ) : null}
          </div>
        </form>
        <div className='terms_pop_btn btn_box'>
          <div className='cancel'>
            <p onClick={() => setEditPw(false)}>취소</p>
          </div>
          <div className='ok' onClick={onClick_mypage_UpdatePw}>
            <p>확인</p>
          </div>
        </div>
      </div>
    </div>
  );
}
