import React from "react";
import useFee from "../../page/main/customHooks/useFee";

const TableSummaryFooter = ({
  list,
  priceKey,
  unitPriceKey,
  countKey,
  rowSpan,
  colSpan,
  inOrderFee,
}) => {
  const commonFee = useFee();
  const fee = inOrderFee ?? commonFee;

  // 중첩된 속성에 접근하는 헬퍼 함수
  const getNestedProperty = (obj, keyPath) => {
    return keyPath?.split(".").reduce((acc, key) => acc && acc[key], obj);
  };

  // 총 합계 금액을 계산하는 함수
  const calculateTotalInvoiceAmount = (list, priceKey, countKey) => {
    return list
      .map(
        (item) =>
          Number(getNestedProperty(item, priceKey)) *
          Number(getNestedProperty(item, countKey))
      )
      .reduce((sum, current) => sum + current, 0);
  };

  const calculateFeeTotalPrice = (list, priceKey, countKey) => {
    const feePercent = (100 + fee) / 100;

    return list
      .map((item) => {
        const price = getNestedProperty(item, priceKey);
        const count = getNestedProperty(item, countKey);

        if (price <= 0 || fee === 0) return 0; // fee가 0일 때도 0을 반환

        const feeValue = price - Math.ceil(price / feePercent);
        const adjustedFeeValue = feeValue <= 1 ? 1 : feeValue;
        const finalPrice = adjustedFeeValue * count;

        return finalPrice;
      })
      .reduce((sum, current) => sum + current, 0);
  };

  // 총 합계 금액을 계산하는 함수
  const calculateTotalUnitAmount = (list, unitPriceKey, countKey) => {
    return list
      .map(
        (item) =>
          Number(getNestedProperty(item, unitPriceKey)) *
          Number(getNestedProperty(item, countKey))
      )
      .reduce((sum, current) => sum + current, 0);
  };

  const totalInvoiceAmount = calculateTotalInvoiceAmount(
    list,
    priceKey,
    countKey
  );
  const feeTotalPrice = calculateFeeTotalPrice(list, priceKey, countKey);
  const totalUnitAmount = calculateTotalUnitAmount(
    list,
    unitPriceKey,
    countKey
  );
  console.log(unitPriceKey);

  return (
    <tfoot>
      <tr>
        <th rowSpan={rowSpan} colSpan={colSpan}>
          <div className="tfoot_sum_desc">
            * 최종 금액은 중개 수수료 {fee}%가 포함된 가격입니다.
            <br />* 본 주문서의 중개수수료는{" "}
            {/* 주문상세페이지에서는 업데이트 이전의 방식대로 띄워줘야하는 이슈 발생 🚨 */}
            {unitPriceKey
              ? (totalInvoiceAmount - totalUnitAmount).toLocaleString("ko")
              : feeTotalPrice.toLocaleString("ko")}
            원입니다.
            <br />* 나의 정산금액(공새로 세금계산서 발행)은 (최종 합계 금액 -
            중개수수료)인{" "}
            {/* 주문상세페이지에서는 업데이트 이전의 방식대로 띄워줘야하는 이슈 발생 🚨 */}
            {unitPriceKey
              ? totalUnitAmount.toLocaleString("ko")
              : (totalInvoiceAmount - feeTotalPrice).toLocaleString("ko")}
            원입니다.
            <br />
          </div>
        </th>
      </tr>

      <tr>
        <th colSpan="1">
          <div className="tfoot_total_price">
            최종 합계 금액
            <br />
            (고객사 납품 단가)
          </div>
        </th>
        <td colSpan="1">
          <strong>{totalInvoiceAmount.toLocaleString("ko")}원</strong>
        </td>
      </tr>
    </tfoot>
  );
};

export default TableSummaryFooter;
