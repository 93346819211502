import styled from "@emotion/styled";

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  bottom: 100px;
  right: 45px;
  width: 220px;
  height: 500px;
  background: #ffffff;
  border: 0.8px solid #dddddd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10000;
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  color: #000;
`;

export const CartItemListLength = styled.div`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  > span {
    color: #ff6600 !important;
  }
`

export const DivideLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 20px;
`;

export const BasketListContainer = styled.div`
  height: 350px;
  overflow: auto;
`;

export const BasketListWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 205px;
  height: 85px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 0 5px 5px 5px;
`;

export const CancelBtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CancelBtn = styled.button`
  width: 8px;
  /* height: 8px; */
`;
export const BasketList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const BasketImgWrap = styled.div`
  > img {
    width: 50px;
    height: 50px;
  }
`;
export const BasketInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Brand = styled.div`
  width: 140px;
  font-weight: 400;
  font-size: 11px;
  color: rgba(34, 34, 34, 0.81);
  padding-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Name = styled.div`
  width: 140px;
  font-weight: 700;
  font-size: 15px;
  color: rgba(34, 34, 34, 0.81);
  padding-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Standard = styled.div`
  width: 140px;
  font-weight: 400;
  font-size: 11px;
  color: rgba(34, 34, 34, 0.81);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CountBtnWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MinusBtn = styled.button`
  margin-right: 5px;
  cursor: pointer;
  > img {
    width: 14px;
    height: 14px;
  }
`;

export const Count = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #000;
`;

export const PlusBtn = styled.button`
  margin-left: 5px;
  cursor: pointer;
  > img {
    width: 14px;
    height: 14px;
  }
`;

export const BasketSaveBtn = styled.button`
  width: 205px;
  height: 35px;
  background: #fff;
  border-radius: 1px;
  border: 1px solid #ccc;
  font-weight: 700;
  font-size: 16px;
  color: #222;
  transition: all 0.2s;
  &:hover {
    background: #aaa;
    color: #fff;
    border: 1px solid #aaa;
  }
`;

export const NextBtn = styled.button`
  width: 205px;
  height: 60px;
  background: #002863;
  border-radius: 1px;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  margin-bottom: 10px;
  transition: all 0.2s;
  &:hover {
    background: #fff;
    color: #002863;
    border: 1px solid #002863;
  }
`;
