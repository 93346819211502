import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Mheader from "../../component/common/Mheader";
import "../../asset/css/estimate.css";
import "../../asset/css/estimateDetail.css";
import "../../asset/css/concludeContract.css";
import { useSelector } from "react-redux";
import { req_listApi_new } from "../../api/auth";
import Pagination from "../../component/ui/pagination/Pagination";

function RequestQuote() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const [maxNum, setMaxNum] = useState(0);
  const [req_list, setReq_list] = useState([]);
  const [sortPageList, setSortPageList] = useState({ nowPage: 1 });
  const getReq_list = async () => {
    const response = await req_listApi_new(
      userData.uc_no,
      sortPageList.nowPage
    );

    if (response.status === "success") {
      setReq_list(response.data.rList);
      setMaxNum(response.data.maxPage);
    }
  };

  useEffect(() => {
    getReq_list();
  }, [sortPageList.nowPage]);

  const handlePageChange = (page) => {
    setSortPageList((prev) => ({ ...prev, nowPage: page }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div class="request_quote_wrap">
      <Mheader title="입찰공고 관리" />
      <div class="request_quote_container wd_s">
        <div className="contract_list">
          <div className="request_quote_title">| 입찰 공고 보기</div>

          <div className="contract_list_btm">
            {req_list.map((data, i) => {
              return (
                <div className="contract_content" key={i}>
                  <div className="content_top">
                    <span>입찰번호 :&nbsp;</span>
                    <span className="public_code">{data.bid_code}</span>
                    <span className="num_line">|</span>
                    <span>
                      {data.bid_regdate.substring(0, 4)}.
                      {data.bid_regdate.substring(5, 7)}.
                      {data.bid_regdate.substring(8, 10)}
                    </span>
                  </div>
                  <div className="content_btm">
                    <div className="content_box">
                      <div className="company_img">
                        <img src={data.f_root} alt="" />
                      </div>
                      <div className="contract_info">
                        <div className="icon_box">
                          <p className={data.bid_state + " icon"}>
                            {data.bid_state === "ALIVE"
                              ? "진행중"
                              : data.bid_state === "CHOOSE"
                              ? "선정대기"
                              : data.bid_state === "RE"
                              ? "재요청/재참여"
                              : data.bid_state === "FAIL"
                              ? "마감"
                              : data.bid_state === "CANCEL"
                              ? "취소"
                              : data.bid_state === "END"
                              ? "완료"
                              : null}
                          </p>
                          {data.extraType && (
                            <p
                              className={data.bid_state + " icon"}
                              style={{ background: "#9c9ea1" }}
                            >
                              {data.extraType === "REBID" && "재입찰"}
                              {data.extraType === "REESTIMATE" && "재공고입찰"}
                            </p>
                          )}
                          {data.bid_state === "RE" ? (
                            <span class="re_text">
                              입찰 공고가 수정되었습니다. 재참여 해주세요.
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <p className="contractor">
                          <span>
                            [{data.con_name}]&nbsp;{data.s_name}
                          </span>
                          <span>
                            {data.bid_type === "T" || data.bid_type === "C"
                              ? `단가계약(${data.bid_term}개월)`
                              : data.bid_type === "S"
                              ? "일회성 입찰"
                              : null}
                          </span>
                        </p>
                        <div className="contract_text">
                          {data.bid_state === "ALIVE" && (
                            <p className="bidding_time">
                              남은 투찰시간 : {data.bidTime}{" "}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="contract_more">
                      {data.isOffer === "N" && data.bid_state === "ALIVE" ? (
                        <p
                          onClick={() => {
                            navigate(`/request/document/${data.bid_no}`, {
                              state: { req_list: req_list[i] },
                            });
                          }}
                        >
                          참여하기
                        </p>
                      ) : null}
                      {data.bid_state === "RE" && (
                        <p
                          onClick={() => {
                            navigate(`/request/document/${data.bid_no}`, {
                              state: { req_list: req_list[i] },
                            });
                          }}
                        >
                          재참여하기
                        </p>
                      )}
                      {data.isOffer === "Y" && (
                        <>
                          <p className="contract_more_ok">참여완료</p>
                          <p
                            onClick={() => {
                              navigate(
                                `/management/estimate/document/${data.bid_no}`,
                                { state: { bList: req_list[i] } }
                              );
                            }}
                          >
                            내 투찰 내역 보기
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="request_quote_pagination_container">
            <Pagination
              current={sortPageList.nowPage}
              total={maxNum}
              pageSize={10}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestQuote;
