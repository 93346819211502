import React, { useCallback, useEffect, useState } from "react";

//component
import Mheader from "../../component/common/Mheader";

//css
import s from "../../asset/css/requestQuoteDetail.module.css";

// img
import calculator from "../../asset/images/icon_calculator.png";

//api
import EstimateSubmitPop from "../../component/common/Pop/EstimateSubmitPop";
import TemporaryStoragePop from "../../component/common/Pop/TemporaryStoragePop";
import {
  // req_attendInfoApi,
  attendChat,
  imgUpApi,
  bidDetailCompanyApi,
  bidDetailApi,
  createOrTemporaryApi,
} from "../../api/auth";

//lib
import { useLocation, useNavigate } from "react-router";

//redux
import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";
import { useMediaQuery } from "react-responsive";
import CalculatePricePop from "../../component/common/Pop/CalculatePricePop";
import TableSummaryFooter from "../../component/etc/TableFooter";

export default function RequestQuoteDetail(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const userData = useSelector((state) => state.user);
  const location = useLocation();

  const data = location.state.req_list;
  const navigate = useNavigate();

  const [submitPop, setSubmitPop] = useState(false);
  const [pList, setPlist] = useState([]);
  const [imgList, setImgList] = useState([]);

  const [temporaryStoragePop, setTemporaryStoragePop] = useState(false);
  const [comment, setComment] = useState("");
  const [detailInfo, setDetailInfo] = useState({
    bid: {},
    bidProductList: [],
    offerDetailInfoList: [],
  });
  //견적참여하기 상세보기
  const bidDetailCompanyFnc = async () => {
    const response = await bidDetailCompanyApi(data.bid_no);
    console.log(response);

    if (response.status === "success") {
      setPlist(
        response.data.productInfoList.map((item) => ({
          ...item,
          offerDetail: {
            price: 0,
            displayPrice: 0,
            no: 0,
            offerNo: 0,
          },
        }))
      );
      setDetailInfo(response.data);
      setImgList(response.data.fileInfoList);
    }
  };

  console.log(pList);

  const _attendChat = async () => {
    const reqData = {
      bidNo: data.bid_no,
      // uc_no: userData.uc_no,
    };

    const response = await attendChat(reqData);

    if (response.status === "success") {
      navigate("/qna", {
        state: {
          bidRoomNo: response.data.chatRoom.no,
          selectedChatItem: response.data,
        },
      });
    }
    // }
  };

  useEffect(() => {
    bidDetailCompanyFnc();
    // bidDetailFnc();
  }, []);

  //od usestate
  const [od, setOd] = useState([]);

  const setOdState = () => {
    const odData = pList.map((item, index) => {
      const bidProductNo = item.bidProduct?.no;
      const count = item.bidProduct?.count;
      const price = item.offerDetail?.price;
      return {
        bidProductNo: bidProductNo,
        price: price,
        count: count,
      };
    });
    setOd(odData);
  };

  useEffect(() => {
    setOdState();
  }, [pList]);

  const [isBlocking, setIsBlocking] = useState(false);

  useEffect(() => {
    data && setIsBlocking(true);
  }, [data]);

  const [formData, setFormData] = useState({
    saveAsDraft: true,
    bidNo: data.bid_no,
    detailList: od,
    comment: comment,
    fileNo: fileNo,
  });

  useEffect(() => {
    setFormData({
      saveAsDraft: true,
      bidNo: data.bid_no,
      detailList: od,
      comment: comment,
      fileNo: fileNo,
    });
  }, [fileNo, data, od, comment, handleUploadFile]);

  // 임시저장 단가 validation
  const isNullPrice = pList?.some(
    (product) =>
      product.offerDetail?.price === null ||
      product.offerDetail?.price === undefined ||
      product.offerDetail?.price === ""
  );

  const isOnePrice = pList?.some(
    (product) => Number(product.offerDetail?.price) < 1
  );
  const isPositiveNumberPrice = pList?.some(
    (product) =>
      isNaN(product.offerDetail?.price) ||
      parseFloat(product.offerDetail?.price) <= 1
  );

  // 임시저장하기
  const temporaryBidFnc = async () => {
    console.log(pList);
    if (isNullPrice) {
      swal("단가를 입력해주세요", "", "info");
      return;
    }
    if (isPositiveNumberPrice) {
      swal("올바른 단가를 입력해주세요", "", "info");
      return;
    }
    try {
      const response = await createOrTemporaryApi(formData);
      if (response.status === "success") {
        swal("임시저장 완료되었습니다.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 금액
  const finalTotalPrice = (price, count) => {
    return (price * count).toLocaleString("ko") + "원";
  };

  // 파일 업로드
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileImg, setUploadedFileImg] = useState(null);
  const [fileNo, setFileNo] = useState(0);

  const onChangeUploadFile = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadedFile(e.target.files[0]?.name);
    setUploadedFileImg(e.target.files[0]);
  };

  const handleUploadFile = useCallback(async () => {
    let form = new FormData();
    form.append("file", uploadedFileImg);
    const response = await imgUpApi(form);

    if (response.status === "success") {
      setFileNo(response.data.f_no);
      setFormData({ ...formData, fileNo: response.data.f_no });
    }
  }, [uploadedFileImg]);

  useEffect(() => {
    if (uploadedFileImg) handleUploadFile();
  }, [uploadedFileImg, handleUploadFile]);

  // 계산기 모달 관련
  const [openModalId, setOpenModalId] = useState(null);

  const openModal = (id) => {
    setOpenModalId(id);
  };

  const closeModal = () => {
    setOpenModalId(null);
  };

  const handleConfirm = (i, taxPrice) => {
    const convertTaxPrice = String(taxPrice);
    const formattedValue = convertTaxPrice.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    const updatedPList = [...pList]; // pList 배열을 복사
    updatedPList[i] = {
      ...updatedPList[i],
      offerDetail: {
        ...updatedPList[i].offerDetail,
        price: Number(convertTaxPrice),
        displayPrice: formattedValue,
      },
    }; // id번째 요소 업데이트
    setPlist(updatedPList); // 상태 업데이트
    closeModal();
  };

  const handleChange = (e, i, data) => {
    let value = e.target.value;

    // 소수점 제거
    value = value.split(".")[0];

    // 숫자만 입력 가능하게 필터링
    value = value.replace(/[^0-9]/g, "").replace(/^0+/, "");
    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    // 세 자리마다 콤마 추가
    const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const updatedPList = [...pList]; // pList 배열을 복사
    updatedPList[i] = {
      ...updatedPList[i],
      offerDetail: {
        ...updatedPList[i].offerDetail,
        price: value,
        displayPrice: formattedValue,
      },
    }; // id번째 요소 업데이트
    setPlist(updatedPList); // 상태 업데이트
  };

  const handleBlur = (e, i, data) => {
    let value = e.target.value;

    // 숫자만 입력 가능하게 필터링 후 앞에 0 제거
    value = value.replace(/[^0-9]/g, "").replace(/^0+/, "");

    // 세 자리마다 콤마 추가
    const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const updatedPList = [...pList]; // pList 배열을 복사
    updatedPList[i] = {
      ...updatedPList[i],
      offerDetail: {
        ...updatedPList[i].offerDetail,
        price: value,
        displayPrice: formattedValue,
      },
    }; // id번째 요소 업데이트
    setPlist(updatedPList); // 상태 업데이트
  };

  return (
    <div class="request_quote_ok_wrap">
      <Mheader title="입찰 참여하기" />
      <div class="request_quote_container wd_s">
        <div className="top_pagenation">
          <h2>입찰 참여하기</h2>
          <div className="top_pagenation_num">
            <p className="active">1</p>
            <p>2</p>
          </div>
        </div>
        <div class="quote_info">
          <div className="contract_content">
            <div className="content_btm">
              <div className="content_box">
                <img className="company_img" src={data.f_root} alt="" />
                <div className="contract_info">
                  <div className="icon_box">
                    <p className={data.bid_state + " icon"}>
                      {data.bid_state === "ALIVE"
                        ? "진행중"
                        : data.bid_state === "RE"
                        ? "재요청/재참여"
                        : data.bid_state === "FAIL"
                        ? "마감"
                        : data.bid_state === "CANCEL"
                        ? "취소"
                        : data.bid_state === "END"
                        ? "마감"
                        : null}
                    </p>
                    {data.extraType && (
                      <p
                        className={data.bid_state + " icon"}
                        style={{ background: "#9c9ea1" }}
                      >
                        {data.extraType === "REBID" && "재입찰"}
                        {data.extraType === "REESTIMATE" && "재공고입찰"}
                      </p>
                    )}
                  </div>
                  <p className="contractor">
                    <span>
                      [{data.con_name}]&nbsp;{data.s_name}
                    </span>
                    <span>
                      {data.bid_type === "T"
                        ? `단가계약(${data.bid_term}개월)`
                        : data.bid_type === "S"
                        ? "일회성 입찰"
                        : null}
                    </span>
                  </p>
                  <div className="contract_text">
                    {data.bid_state === "ALIVE" && (
                      <>
                        <p className="bidding_time">
                          남은 투찰시간 : {data.bidTime}{" "}
                        </p>
                        <p className="bidding_time">
                          투찰 마감일시 : {data.bid_endate}{" "}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quote_content">
            <div className="quote_list_box">
              <ul className="quote_list">
                {/* <li>
                  <p>진행공정</p>
                  <span>{detailInfo.processName}</span>
                </li> */}

                {detailInfo.bid?.type === "T" ||
                detailInfo.bid?.type === "C" ? (
                  <li>
                    <p>계약기간</p>
                    <span>{detailInfo.bid?.term}개월</span>
                  </li>
                ) : null}
                {detailInfo.bid?.type === "S" ? (
                  <li>
                    <p>납품기한</p>
                    <span>{detailInfo.bid?.deldate}</span>
                  </li>
                ) : null}

                <li>
                  <p>비용정산 방법</p>
                  <span>{detailInfo.bid?.refund}</span>
                </li>
                <li>
                  <p>납품주소</p>
                  <span>
                    {detailInfo.bid?.addr1}, {detailInfo.bid?.addr2}
                  </span>
                </li>
                <li>
                  <p>현장 대표 번호</p>
                  <span>
                    {detailInfo?.site?.phone?.replace(
                      /^(\d{2,3})(\d{3,4})(\d{4})$/,
                      `$1-$2-$3`
                    )}
                  </span>
                </li>
              </ul>
              <ul className="quote_list">
                <li>
                  <p>배송비 포함여부</p>
                  <span>
                    {detailInfo.bid?.fee === "Y"
                      ? "포함"
                      : detailInfo.bid?.fee === "N"
                      ? "미포함"
                      : null}
                  </span>
                </li>
                <li>
                  <p>하차역무 포함여부</p>
                  <span>
                    {detailInfo.bid?.drop === "L"
                      ? "하차 역무 있음 ⭕️"
                      : detailInfo.bid?.drop === "U"
                      ? "하차 역무 없음 ❌"
                      : null}
                  </span>
                </li>
                <li>
                  <p>우선사항</p>
                  <span>{detailInfo.bid?.priority}</span>
                </li>
                <li>
                  <p>담당자</p>
                  <span>
                    {detailInfo?.user?.name} {detailInfo?.user?.rank}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="conclude_est">
            <h3 className="s_tit">
              <p>투찰서 작성</p>
            </h3>
            <div className="comparison_box temporary_storage_box bid_zero_dn">
              <p
                onClick={() => {
                  setTemporaryStoragePop(true);
                }}
              >
                임시저장 불러오기
              </p>
              <p
                onClick={() => {
                  temporaryBidFnc();
                }}
              >
                임시저장
              </p>
            </div>
            <span className="temporary_desc">
              ※ 임시저장은 최근 1회만 저장됩니다.
            </span>
            <div className="table_container">
              <table className="table_container">
                <thead>
                  <tr>
                    <th className="table_index"></th>
                    <th className="table_name">품목명</th>
                    <th className="table_standard">규격</th>
                    <th className="table_brand">
                      제조국
                      <p className="table_sub_desc">(브랜드)</p>
                    </th>
                    <th className="table_etc">비고</th>
                    <th className="table_unit">단위</th>
                    <th className="table_count">수량</th>
                    <th className="table_total_amount">
                      고객사 납품단가
                      <p className="table_sub_desc">(수수료 포함 단가)</p>
                    </th>
                    <th className="table_total_amount">
                      최종 합계 금액
                      <p className="table_sub_desc">(수수료 포함 단가)</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pList.map((data, i) => {
                    return (
                      <tr value={data} key={i}>
                        <th>{i + 1}</th>
                        <td>{data.bidProduct.name}</td>
                        <td>
                          <p className="table_overflow_text">
                            {data.bidProduct.standard.replace(/, /gi, "\n")}
                          </p>
                        </td>
                        <td>{data.bidProduct.brand}</td>
                        <td>
                          <p className="table_overflow_text">
                            {data.bidProduct.etc}
                          </p>
                        </td>
                        <td>{data.bidProduct.unit}</td>
                        <td>{data.bidProduct.count}</td>
                        <td>
                          <div className="table_calculate_box">
                            <input
                              onChange={(e) => handleChange(e, i, data)}
                              onBlur={(e) => handleBlur(e, i, data)}
                              placeholder="단가를 입력해주세요"
                              value={
                                data.offerDetail?.displayPrice ||
                                data.offerDetail?.price
                              } // 콤마가 포함된 값 표시
                              className="unit_price"
                            />
                            <div
                              onClick={() => openModal(i)}
                              className="table_calculate_img_box"
                            >
                              <img src={calculator} />
                            </div>
                          </div>
                          {openModalId === i && (
                            <CalculatePricePop
                              closeCalcModal={closeModal}
                              name={data.bidProduct.name}
                              onConfirm={(taxPrice) =>
                                handleConfirm(i, taxPrice)
                              }
                            />
                          )}
                        </td>
                        <td>
                          {finalTotalPrice(
                            data.offerDetail?.price,
                            data?.bidProduct.count
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <TableSummaryFooter
                  list={pList}
                  priceKey="offerDetail.price"
                  countKey="bidProduct.count"
                  rowSpan="3"
                  colSpan="7"
                />
              </table>
            </div>
          </div>
          <div className="uniqueness_box">
            <h3 className={s.constructor}>
              <p>고객사</p>
            </h3>
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>
                  <span>입찰 관련</span>
                  <span>추가 내용</span>
                </p>
              </div>
              <div className="uniqueness_content">
                <p>{detailInfo?.bid.comment}</p>
              </div>
            </div>
            {imgList?.length === 0 ? null : (
              <div className="look_info payment_account">
                <h3 className="s_tit">
                  <p>첨부파일 정보</p>
                </h3>
                {imgList?.map((a, index) => {
                  return (
                    <div>
                      <p
                        onClick={() => {
                          window.open(a.root);
                        }}
                      >
                        첨부파일 {index + 1} 보기
                      </p>
                      <span> {a.origname} </span>
                    </div>
                  );
                })}
              </div>
            )}

            <h3 className={s.company}>
              <p>공급사</p>
            </h3>
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>
                  <span>특이사항</span>
                  <span>작성</span>
                </p>
              </div>
              <div className="uniqueness_content contract_additions">
                <textarea
                  placeholder="특이사항이 있으면 작성해주세요"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className={s.image_upload_container}>
              <input
                type="file"
                id="upload"
                onChange={onChangeUploadFile}
                placeholder="123"
              />
              <input
                type="text"
                value={uploadedFile}
                placeholder="필요 시 파일을 첨부해주세요."
                disabled
              />
              <label htmlFor="upload">업로드</label>
            </div>
          </div>
        </div>

        <div class="btn_box">
          <div class="ok">
            <p onClick={() => setSubmitPop(true)}>입찰 참여</p>
          </div>
          <div class="third">
            <p onClick={_attendChat}>질의응답 참여</p>
          </div>
        </div>
        <div class="cancel">
          <p
            onClick={() => {
              navigate(-1);
            }}
          >
            뒤로가기
          </p>
        </div>
      </div>
      {submitPop && (
        <EstimateSubmitPop
          setSubmitPop={setSubmitPop}
          formData={formData}
          comment={comment}
          od={od}
          pList={pList}
        />
      )}

      {temporaryStoragePop && (
        <TemporaryStoragePop
          // setWinningBidPop={setWinningBidPop}
          // formbidData={formbidData}
          setTemporaryStoragePop={setTemporaryStoragePop}
          // req_data={req_data}
          pList={pList}
          setPlist={setPlist}
        />
      )}
    </div>
  );
}
