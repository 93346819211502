import React, { useCallback, useEffect, useRef } from "react";
//library
import { useLocation, Routes, Route } from "react-router-dom";
import { isIE } from "react-device-detect";

//css
import "./asset/css/reset.css";
import "./component/styles/palette.module.css";
//etc
//component
import Header from "./component/common/Header";
import Footer from "./component/common/Footer";
//page
import Main from "./page/main/Main";
import Login from "./page/user/Login";
import Join from "./page/user/Join";
import NewInfo from "./page/user/NewInfo";
import NewInfo2 from "./page/user/NewInfo2";
import NewInfo3 from "./page/user/NewInfo3";
import NaverLogin from "./page/user/Naver";
import SideBar from "./component/common/SideBar";

// find
import Find from "./page/find/Find";
import FindId from "./page/find/FindId";
import FindPw from "./page/find/FindPw";
import FindPw2 from "./page/find/FindPw2";
// look
import Look from "./page/look/Look";
import Payment from "./page/look/Payment";
import Payment2 from "./page/look/Payment2";
//request_quote
import RequestQuote from "./page/RequestQuote/RequestQuote";
import RequestQuoteDetail from "./page/RequestQuote/RequestQuoteDetail";
import RequestQuoteCompleted from "./page/RequestQuote/RequestQuoteCompleted";
// management - estimate
import Estimate from "./page/management/estimate/Estimate";
import EstimateDetail from "./page/management/estimate/EstimateDetail";
// management - contract
import Contract from "./page/management/contract/Contract";
import ContractDetail from "./page/management/contract/ContractDetail";
import ContractChange from "./page/management/contract/ContractChange";
// management - order
import Order from "./page/management/order/Order";
import OrderDetail from "./page/management/order/OrderDetail";
import PaymentRequest2 from "./page/management/order/PaymentRequest2";
import PaymentRequestCompleted from "./page/management/order/PaymentRequestCompleted";
import RequestType from "./page/management/order/RequestType";
import RequestExcel from "./page/management/order/RequestExcel";
import RequestInputInfo from "./page/management/order/RequestInputInfo";
import RequestChoice from "./page/management/order/RequestChoice";
import RequestChoice2 from "./page/management/order/RequestChoice2";
import RequestComplete from "./page/management/order/RequestComplete";
// qna
import QnA from "./page/qna/QnA";
// mypage
import MyInfo from "./page/myPage/myInfo/MyInfo";
import ProductRegistration from "./page/myPage/myInfo/ProductRegistration";
import ProductRegistrationCompleted from "./page/myPage/myInfo/ProdcutRegistrationCompleted";
import MyInfoEdit from "./page/myPage/myInfo/MyInfoEdit";
import MyInfoEditCompleted from "./page/myPage/myInfo/MyInfoEditCompleted";
import MyInfoHashTag from "./page/myPage/myInfo/MyInfoHashTag";
import AllHistory from "./page/myPage/allHistory/AllHistory";

import Notice from "./page/myPage/notice/Notice";
import NoticeDetail from "./page/myPage/notice/NoticeDetail";

import History from "./page/myPage/history/History";

import Registration from "./page/myPage/registration/Registration";

import RegistrationDetail from "./page/myPage/registration/RegistrationDetail";
import RegistrationInput from "./page/myPage/registration/RegistrationInput";
import RegistrationCompleted from "./page/myPage/registration/RegistrationCompleted";
import RegistrationDetailEdit from "./page/myPage/registration/RegistrationDetailEdit";

// policy
import Privacy from "./page/policy/Privacy";
import PrivacyAgree from "./page/policy/PrivacyAgree";
import Terms from "./page/policy/Terms";
import Terms0811 from "./page/policy/Terms~0811";
import Terms1211 from "./page/policy/Terms~1211";

//redux
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./redux/action";
import PrivateRoute from "./component/etc/PrivateRoute";
import Invoice from "./page/calculate/Invoice";
import Test from "./page/test/Test";
import ReactGA4 from "react-ga4";
import RouteChangeTracker from "./util/RouteChangeTracker";
import OrderReturnDetail from "./page/management/order/OrderReturnDetail";
import CustomFloatButton from "./component/etc/CustomFloatButton";
import ReleaseNote from "./page/policy/ReleaseNote";

import { version } from "../package.json";
import { notification } from "antd";
import axios from "axios";
import RequestConfirm from "./page/management/order/RequestConfirm";
import NotionTest from "./page/myPage/notice/NotionTest";

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const signToken = useSelector((state) => state.token);

  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const _savePushToken = (e) => {
      if (e.data) {
        // const pushTokenData = JSON.parse(e.data);
        let pushTokenData = "";
        if (typeof e.data === "object") {
          pushTokenData = e.data;
        } else {
          pushTokenData = JSON.parse(e.data);
        }
        const pushToken = pushTokenData.value;
        const pushType = pushTokenData.type;

        const data = {
          value: pushToken,
          type: pushType,
        };
        if (pushToken && pushType) {
          dispatch(actions.setPushToken(data));
        }
      }
    };

    /** android */
    document.addEventListener("message", _savePushToken);
    /** ios */
    window.addEventListener("message", _savePushToken);
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      if (signToken) {
        window.ReactNativeWebView.postMessage("setLoginState_true");
      } else {
        window.ReactNativeWebView.postMessage("setLoginState_false");
      }
    }
  }, [signToken]);

  const [api, contextHolder] = notification.useNotification();
  const notificationOpenRef = useRef(false);

  const openNotificationWith = useCallback(
    (type) => {
      if (notificationOpenRef.current) return;

      api.destroy();

      api[type]({
        message: "신규 업데이트 알림",
        description: (
          <div>
            <div>공새로 플랫폼의 업데이트가 존재합니다.</div>
            <div>
              F5를 눌러 새로고침하거나 이 알림을 클릭하여 새로고침 하세요.
            </div>
          </div>
        ),
        duration: 0,
        onClick: () => {
          window.location.reload(true);
          notificationOpenRef.current = false;
        },
        style: { width: 450, cursor: "pointer" },
        onClose: () => {
          notificationOpenRef.current = false;
        },
      });

      notificationOpenRef.current = true;
    },
    [api]
  );

  const checkVersion = useCallback(async () => {
    try {
      const frontVersion = version?.slice(0, 5);

      axios.interceptors.response.use((response) => {
        const backVersion = response.headers["version-company"]?.slice(0, 5);

        if (backVersion && frontVersion !== backVersion) {
          openNotificationWith("info");
        }

        return response;
      });
    } catch (error) {
      console.error("Error checking version:", error);
    }
  }, [openNotificationWith]);

  useEffect(() => {
    checkVersion();
  }, [checkVersion]);

  return (
    <>
      {isIE ? (
        <>
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontSize: "3rem",
                marginBottom: "4rem",
              }}
            >
              <span
                style={{
                  fontWeight: "bold",
                  color: "#002863",
                }}
              >
                공새로 서비스
              </span>
              는 크롬 브라우저에 최적화되어 있습니다.
            </h2>
            <p
              style={{
                marginBottom: "1rem",
              }}
            >
              크롬 브라우저를 이용하여 서비스 이용을 부탁드립니다.
            </p>
            <a
              style={{ color: "#002863" }}
              href="https://www.google.com/chrome/"
            >
              크롬 다운로드 바로가기
            </a>
          </div>
        </>
      ) : (
        <div className="wrap">
          <Header />
          <SideBar role={true} />
          <div className="container">
            <Routes>
              <Route path="/" element={<Main />} />
              {/* login & join */}
              <Route path="/login" element={<Login />} />
              <Route path="/naver-login" element={<NaverLogin />} />
              <Route path="/join" element={<Join />} />
              <Route path="/join/newinfo" element={<NewInfo />} />
              <Route path="/join/newinfo2" element={<NewInfo2 />} />
              <Route path="/join/newinfo3" element={<NewInfo3 />} />
              {/* find */}
              <Route path="/find" element={<Find />} />
              <Route path="/find-id" element={<FindId />} />
              <Route path="/find-pw" element={<FindPw />} />
              <Route path="/find-pw2" element={<FindPw2 />} />
              {/* look */}
              <Route
                path="/look"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Look />}
                  />
                }
              />
              <Route
                path="/look/payment"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Payment />}
                  />
                }
              />
              <Route
                path="/look/payment2"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Payment2 />}
                  />
                }
              />
              {/* request_quote */}
              <Route
                path="/request/:id"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestQuote />}
                  />
                }
              />
              <Route
                path="/request/document/:id"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestQuoteDetail />}
                  />
                }
              />
              <Route
                path="/request/:id/completed"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestQuoteCompleted />}
                  />
                }
              />
              {/* management - estimate */}
              <Route
                path="/management/estimate/:page"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Estimate />}
                  />
                }
              />
              <Route
                //path='/management/estimate/:id'
                path="/management/estimate/document/:id"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<EstimateDetail />}
                  />
                }
              />
              {/* management - contract */}
              <Route
                path="/management/contract/:page"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Contract />}
                  />
                }
              />
              <Route
                path="/management/contract/document/:id"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<ContractDetail />}
                  />
                }
              />
              <Route
                path="/management/contract/contract_change/:id"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<ContractChange />}
                  />
                }
              />
              {/* management - order */}
              <Route
                path="/management/order/:page"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Order />}
                  />
                }
              />
              <Route
                path="/management/order/document/:id"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<OrderDetail />}
                  />
                }
              />
              <Route
                path="/management/order/return/document/:id"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<OrderReturnDetail />}
                  />
                }
              />
              <Route
                path="/management/order/request2"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<PaymentRequest2 />}
                  />
                }
              />
              <Route
                path="/management/order/completed"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<PaymentRequestCompleted />}
                  />
                }
              />
              {/* <Route
                path="/management/order/request_type"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestType />}
                  />
                }
              /> */}
              <Route
                path="/management/order/request_excel"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestExcel />}
                  />
                }
              />
              <Route
                path="/management/order/request_input_info"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestInputInfo />}
                  />
                }
              >
                <Route
                  path="request_confirm"
                  element={
                    <PrivateRoute
                      authenticated={signToken}
                      component={<RequestConfirm />}
                    />
                  }
                />
              </Route>
              <Route
                path="/management/order/request_choice"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestChoice />}
                  />
                }
              >
                <Route
                  path="detail/:id"
                  element={
                    <PrivateRoute
                      authenticated={signToken}
                      component={<Test />}
                    />
                  }
                />
              </Route>
              <Route
                path="/management/order/request_choice2"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestChoice2 />}
                  />
                }
              />
              <Route
                path="/management/order/request_complete"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RequestComplete />}
                  />
                }
              />
              <Route
                path="/all_history"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<AllHistory />}
                  />
                }
              />
              {/* qna */}
              <Route
                path="/qna"
                element={
                  <PrivateRoute authenticated={signToken} component={<QnA />} />
                }
              />
              {/* invoice */}
              <Route
                path="/invoice"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Invoice />}
                  />
                }
              />
              {/* myPage */}
              <Route
                path="/my_page/my_info"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<MyInfo />}
                  />
                }
              />
              <Route
                path="/my_page/my_info/product_registration"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<ProductRegistration />}
                  />
                }
              />
              <Route
                path="/my_page/my_info/product_registration/completed"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<ProductRegistrationCompleted />}
                  />
                }
              />
              <Route
                path="/my_page/my_info/edit"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<MyInfoEdit />}
                  />
                }
              />
              <Route
                path="/my_page/my_info/edit_completed"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<MyInfoEditCompleted />}
                  />
                }
              />
              <Route
                path="/my_page/my_info/hashtag"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<MyInfoHashTag />}
                  />
                }
              />
              <Route
                path="/my_page/notice"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Notice />}
                  />
                }
              />
              <Route path="/my_page/notice/:id" element={<NoticeDetail />} />
              {/* <Route
                path="/my_page/service_history"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<PaidService />}
                  />
                }
              /> */}
              <Route
                path="/my_page/registration"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<Registration />}
                  />
                }
              />
              <Route
                path="/my_page/registration/:id/detail"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RegistrationDetail />}
                  />
                }
              />
              <Route
                path="/my_page/registration/requestEdit"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RegistrationDetailEdit />}
                  />
                }
              />
              <Route
                path="/my_page/registration/request"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RegistrationInput />}
                  />
                }
              />
              <Route
                path="/my_page/registration/completed"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<RegistrationCompleted />}
                  />
                }
              />
              <Route
                path="/my_page/history"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<History />}
                  />
                }
              />
              <Route
                path="/notion/test"
                element={
                  <PrivateRoute
                    authenticated={signToken}
                    component={<NotionTest />}
                  />
                }
              />
              {/* policy */}
              <Route path="/release_note" element={<ReleaseNote />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms0811" element={<Terms0811 />} />
              <Route path="/terms1211" element={<Terms1211 />} />
              <Route path="/privacyAgree" element={<PrivacyAgree />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </div>
          <Footer />
          <CustomFloatButton />
        </div>
      )}
      {contextHolder}
      <RouteChangeTracker />
    </>
  );
}

export default App;
