import React, { useEffect, useState } from "react";
import { useHistory, useNavigate } from "react-router";

//library
import { useMediaQuery } from "react-responsive";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/registrationDetail.css";
//api
import { getreqProduct_infoDetailApi } from "../../../api/auth";

//comma
import { wonComma } from "../../../util/Util";

//sweet alert
import swal from "sweetalert";

export default function RegistrationDetail(props) {
  const navigate = useNavigate();

  const { id } = props?.match?.params;
  if (id === undefined || id === "undefined") {
    swal("잘못된 접근입니다.", "", "warning");
    navigate("/my_page/registration", { replace: true });
  }
  const data = props.location.state.reqPDList;
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  //물품상세내역받아오기
  const [reqPDList, setReqPDList] = useState([]);
  const getreqProduct_infoDetail = async () => {
    const res = await getreqProduct_infoDetailApi(id);

    if (res.status === "success") {
      setReqPDList(res.data);
    }
  };

  useEffect(() => {
    getreqProduct_infoDetail();
  }, []);

  return (
    <div className="registration_ok_wrap">
      <Mheader title="물품등록 내역 상세" />
      <div className="registration_ok_container wd">
        <div className="tit_box">
          <div className="top_tit">
            <h2>물품등록 요청</h2>
            <span className={reqPDList.p_state}>
              {reqPDList.p_state === "HOLD" ? "승인 대기중" : ""}
              {reqPDList.p_reason && reqPDList.p_reason}
            </span>
          </div>
          <div
            className="re_btn"
            onClick={() => {
              navigate("/my_page/registration/requestEdit", {
                state: { reqPDList: reqPDList, data: data },
              });
            }}
          >
            <p>수정 및 재승인 요청</p>
          </div>
        </div>
        <div className="registration_ok_info">
          <div className="register_img">
            <img className="register_img" src={reqPDList.f_root} alt="" />
          </div>
          <div className="register_box">
            {isResponsive ? (
              <p className={reqPDList.class}>
                {reqPDList.p_state}
                <span>
                  {reqPDList.p_state === "승인거절" &&
                    "품목 이미지 식별이 불가능합니다."}
                </span>
              </p>
            ) : (
              ""
            )}
            <ul className="register_text">
              <li>
                <p>카테고리</p>
                <span>
                  {" "}
                  {reqPDList.pro_name}&#62;
                  {reqPDList.lc_name}&#62;
                  {reqPDList.mc_name}&#62;
                  {reqPDList.sc_name}
                </span>
              </li>
              <li>
                <p>품목명</p>
                <span>{reqPDList.p_name}</span>
              </li>
              <li>
                <p>브랜드명</p>
                <span>{reqPDList.p_brand}</span>
              </li>
              <li>
                <p>설명</p>
                <span>{reqPDList.p_comment}</span>
              </li>
              <li>
                <p>규격</p>
                <span>{reqPDList.p_standard}</span>
              </li>
              <li>
                <p>단위</p>
                <span>{reqPDList.p_unit}</span>
              </li>
              <li>
                <p>등록가격</p>
                <span>{wonComma(reqPDList.p_price) + "원"}</span>
              </li>
              <li>
                <p>비고</p>
                <span>{reqPDList.p_etc}</span>
              </li>
              <li>
                <p>제품설명서</p>
                <span>
                  {reqPDList?.attachFileBeanList?.map((data, i) => {
                    return (
                      <a
                        href={data.f_root}
                        target="_blank"
                        className="register_goods_download"
                      >
                        {data.f_origname}
                      </a>
                    );
                  })}
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="register_goods_container">
            <h2>제품 설명서 다운로드</h2>
            
          </div> */}
      </div>
    </div>
  );
}
