import types from '../action/types';
import { estimateDefault } from './default';

export default (state = estimateDefault, action) => {
  switch (action.type) {
    case types.SET_ESTIMATE:
      return action.payload;

    default:
      return state;
  }
};
