import React from 'react';
import i_close from '../../../asset/images/w-cancel-icon.png';
import { contract_stopApi } from '../../../api/auth';

//sweet alert
import swal from 'sweetalert';


export default function ContractTermsStopPop({
  setTermsStopPop,
  formCt_no,
  move2Success,
}) {
  const ct_no = formCt_no;

  //계약중지동의버튼
  const contract_stop = async () => {
    const res = await contract_stopApi(ct_no);
    if (res.status === 'success') {
      move2Success();
      setTermsStopPop(false);
    } else {
      swal('계약중지동의에 실패하였습니다.','','warning');
    }
  };

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setTermsStopPop(false)}></div>
      <div className='contract_terms_pop'>
        <div className='close' onClick={() => setTermsStopPop(false)}>
          <img src={i_close} alt='' />
        </div>
        <div className='contract_pop_tit' style={{ textAlign: 'center' }}>
          <h3>계약중지에 동의하시겠습니까?</h3>
          <p>동의 완료 시 계약이 중지됩니다.</p>
        </div>

        <div className='terms_pop_btn btn_box'>
          <div className='cancel'>
            <p onClick={() => setTermsStopPop(false)}>취소</p>
          </div>
          <div className='agree' onClick={contract_stop}>
            동의
          </div>
        </div>
      </div>
    </div>
  );
}
