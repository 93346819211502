import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; //local storage 활용 => Cookie 대체하기 위해서
/**
 * @DevMoon
 * session storage VS local storage VS Cookie
 * https://velog.io/@ejchaid/localstorage-sessionstorage-cookie%EC%9D%98-%EC%B0%A8%EC%9D%B4%EC%A0%90
 */

//token
import tokenReducer from './tokenReducer';

//push
import chatReducer from './chatReducer';

//user
import userReducer from './userReducer';

//user
import pushReducer from './pushReducer';

//estimate
import estimateReducer from './estimateReducer';

import activityReducer from './activityReducer';

const persistConfig = {
  key: 'root',
  storage,
  blackList: ['chat', 'push'], //채팅 관련 소켓서버, 푸쉬 관련 리듀서만 제외하고 나머지는 모두 저장
};

const rootReducer = combineReducers({
  chat: chatReducer,
  token: tokenReducer,
  user: userReducer,
  push: pushReducer,
  estimate : estimateReducer,
  activity: activityReducer,
});

export default persistReducer(persistConfig, rootReducer);
