import React, { useState } from "react";
import i_close from "../../../asset/images/w-cancel-icon.png";
import "../../../asset/css/pop.css";
import { createOrTemporaryApi } from "../../../api/auth";
import { useNavigate } from "react-router";

//redux
import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";

import Loading from "../../etc/Loading";

export default function EstimateSubmitPop({
  setSubmitPop,
  formData,
  od,
  pList,
}) {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  console.log(formData);
  console.log(pList);

  const [generating, setGenerating] = useState(false);

  const onClick_req_attend = async () => {
    setGenerating(true);
    for (let i = 0; i < od.length; i++) {
      if (od[i].price === "" || od[i].price === 0 || od[i].price === "0") {
        swal("단가를 입력해주세요.", "", "warning");
        return setSubmitPop(false);
      }
      if (od[i].price <= 1) {
        swal("2원 이상의 고객사 납품 단가를 입력해주세요.", "", "warning");
        return setSubmitPop(false);
      }
    }
    const newFormData = { ...formData, saveAsDraft: false };

    const res = await createOrTemporaryApi(newFormData);
    if (res.status === "success") {
      navigate("/request/1/completed", {
        state: {
          no: res.data.bidNo,
        },
      });
    } else if (res.status === "fail" && res.message === "already biding") {
      swal("이미 투찰에 참여하셨습니다", "", "warning");
    } else if (res.status === "fail" && res.message === "No Alive") {
      swal("이미 마감된 공고입니다", "", "warning");
    } else {
      swal("입찰참여에 오류가 발생하였습니다.", "", "error");
    }

    setGenerating(false);
  };

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setSubmitPop(false)}></div>
      <div className="est_submit_pop">
        <div className="close" onClick={() => setSubmitPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <div className="contract_pop_tit">
          <h3>최종 제출하시겠습니까?</h3>
          <p>입력한 입찰서를 최종 제출하시겠습니까?</p>
        </div>
        <div className="est_submit_pop_info">
          <div className="btn_box">
            <div className="cancel">
              <p onClick={() => setSubmitPop(false)}>취소</p>
            </div>
            <div className="ok" onClick={onClick_req_attend}>
              확인
            </div>
          </div>
        </div>
      </div>
      {generating && <Loading msg={"로딩 중입니다."} />}
    </div>
  );
}
