import { useMediaQuery } from "react-responsive";
import * as S from "../../asset/css/invoice.styles";
import Mheader from "../../component/common/Mheader";
import DashBoardTabs from "../../component/etc/DashBoardTabs";
import useInvoiceCount from "./customHooks/useInvoiceCount";
import useInvoiceList from "./customHooks/useInvoiceList";
import InvoiceList from "./InvoiceList";

export default function Invoice() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });

  const { countByState, fetchInvoiceCountByState } = useInvoiceCount();
  const { invoiceList, state, fetchInvoiceList } = useInvoiceList();

  const calculateEntireTotalAmount = () => {
    const totalAmounts =
      invoiceList?.map((invoice) => invoice?.companyInvoice?.realAmount) || [];
    const entireTotalAmount = totalAmounts.reduce((acc, cur) => acc + cur, 0);

    return entireTotalAmount ? entireTotalAmount.toLocaleString("ko") : "-";
  };

  return (
    <S.Wrapper>
      <Mheader title="정산관리" />
      <S.DashboardContainer>
        {isDesktop && <DashBoardTabs />}
        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>나의 정산관리 현황</S.Title>
              {/* <div>|</div> */}
              {/* <S.SelectedDate>{firstData?.startDate} ~ {firstData?.endDate}</S.SelectedDate> */}
            </S.TitleWrap>
          </S.ContentHeader>

          <S.ContentBody>
            <S.PaymentStateWrap>
              <S.RequestState state={state}>지급요청</S.RequestState>
              <S.CheckPaymentRequestListBtn
                onClick={() => fetchInvoiceList("request")}
                state={state}
              >
                {countByState?.request}건
              </S.CheckPaymentRequestListBtn>
            </S.PaymentStateWrap>

            <S.PaymentStateWrap>
              <S.ProcessState state={state}>지급처리중</S.ProcessState>
              <S.PaymentProcessingBtn
                onClick={() => fetchInvoiceList("process")}
                state={state}
              >
                {countByState?.process}건
              </S.PaymentProcessingBtn>
            </S.PaymentStateWrap>

            <S.PaymentStateWrap>
              <S.CompleteState state={state}>지급완료</S.CompleteState>
              <S.PaymentCompletedBtn
                onClick={() => fetchInvoiceList("complete")}
                state={state}
              >
                {countByState?.complete}건
              </S.PaymentCompletedBtn>
            </S.PaymentStateWrap>

            {isDesktop && <S.DivideLine></S.DivideLine>}

            <S.PaymentStateWrap>
              <S.AllState state={state}>전체보기</S.AllState>
              <S.PaymentAllBtn
                onClick={() => fetchInvoiceList("all")}
                state={state}
              >
                {countByState?.all}건
              </S.PaymentAllBtn>
            </S.PaymentStateWrap>
          </S.ContentBody>

          <S.ContentFooter>
            <S.EntireTotalAmount>
              당월 총 금액 : {calculateEntireTotalAmount()} 원 (VAT 제외)
            </S.EntireTotalAmount>
          </S.ContentFooter>
        </S.DashBoardContent>
      </S.DashboardContainer>

      {invoiceList?.length !== 0 ? (
        invoiceList?.map((invoice) => (
          <InvoiceList
            invoice={invoice}
            state={state}
            key={invoice.companyInvoice.no}
            fetchInvoiceList={fetchInvoiceList}
            fetchInvoiceCountByState={fetchInvoiceCountByState}
          />
        ))
      ) : (
        <S.NoInvoiceAlert>
          <S.NoInvoiceAlertText>정산 내역이 없습니다.</S.NoInvoiceAlertText>
        </S.NoInvoiceAlert>
      )}
    </S.Wrapper>
  );
}
