import React from 'react';
import { Link } from 'react-router-dom';
//component
import Mheader from '../../../component/common/Mheader';

export default function ProdcutRegistrationCompleted() {
  return (
    <div className="registration_completed_wrap completed_wrap">
      <Mheader title="주요 공급품목 등록" />
      <div className="completed_container wd">
        <div className="top_pagenation">
          <h2>주요 공급품목 등록</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p className="active">2</p>
          </div>
        </div>
        <div className="completed_text">
          <h4>주요 공급품목 등록이 완료되었습니다.</h4>
        </div>
        <div className="completed_btn">
          <Link to="/my_page/my_info">마이페이지로 이동</Link>
        </div>
      </div>
    </div>
  );
}
