import React, { useState } from "react";
//library
import { useNavigate } from "react-router-dom";

//css
import "../../../asset/css/pop.css";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import { orderCancelApi, orderRejectApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function OrderFormCancelPop({
  setOrderFormPop,
  orderNum,
  move2Success,
  clickState,
  bidType,
}) {
  const [cancelData, setCancelData] = useState({
    or_no: orderNum,
    or_cancel: "",
  });
  const [rejectData, setRejectData] = useState({
    or_no: orderNum,
    or_reject: "",
  });

  const orderCancelFnc = async () => {
    if (cancelData.or_no === 0) {
      swal("다시 시도해주세요.", "", "warning");
    } else if (cancelData.or_cancel === "") {
      swal("취소사유를 입력해주세요.", "", "warning");
    } else if (bidType === "B" || bidType === "D") {
      swal(
        "PB주문서 취소는 고객센터를 통해서 가능합니다.",
        "고객센터: 032-205-0479",
        "warning"
      );
    } else {
      const response = await orderCancelApi(
        cancelData.or_no,
        cancelData.or_cancel
      );
      if (response.status === "success") {
        swal("주문이 취소되었습니다.", "", "success");
        move2Success();
        // navigate("/management/order/1");
        setOrderFormPop(false);
      } else if (response.status === "fail") {
        swal("다시 시도해주세요.", "", "warning");
      }
    }
  };

  const orderRejectFnc = async () => {
    if (rejectData.or_no === 0) {
      swal("다시 시도해주세요.", "", "warning");
    } else if (rejectData.or_reject === "") {
      swal("반려사유를 입력해주세요.", "", "warning");
    } else {
      const response = await orderRejectApi(
        rejectData.or_no,
        rejectData.or_reject
      );
      if (response.status === "success") {
        move2Success();
        setOrderFormPop(false);
        swal("주문이 반려되었습니다.", "", "success");
      } else {
        swal("다시 시도해주세요.", "", "warning");
      }
    }
  };

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setOrderFormPop(false)}></div>
      <div className="contract_pop">
        <div className="close" onClick={() => setOrderFormPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <div className="contract_pop_tit">
          <h3>
            주문 {clickState === "CANCEL" && "취소"}
            {clickState === "REJECT" && "반려"}를 요청하시겠습니까?
          </h3>
          <p>
            주문 {clickState === "CANCEL" && "취소"}
            {clickState === "REJECT" && "반려"} 사유를 입력해주세요
          </p>
        </div>
        <input
          placeholder={
            clickState === "CANCEL"
              ? "주문 취소 사유를 입력해주세요."
              : "주문 반려 사유를 입력해주세요."
          }
          maxLength={255}
          onChange={(e) => {
            {
              clickState === "CANCEL" &&
                setCancelData({ ...cancelData, or_cancel: e.target.value });
            }
            {
              clickState === "REJECT" &&
                setRejectData({ ...rejectData, or_reject: e.target.value });
            }
          }}
        />
        <div className="confirm_btn">
          <p
            onClick={() => {
              clickState === "CANCEL" && orderCancelFnc();
              clickState === "REJECT" && orderRejectFnc();
            }}
          >
            확인
          </p>
        </div>
      </div>
    </div>
  );
}
