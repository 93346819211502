import React from 'react';
import { useMediaQuery } from 'react-responsive';
import i_close from '../../../asset/images/w-cancel-icon.png';
import '../../../asset/css/chatPop.css';

export default function ChatWhoPop({ setWhoPop, partInfo }) {
  // maxwidth 구분
  const isResponsive = useMediaQuery({
    query: '(max-width : 1080px)',
  });
  

  return (
    <>
      {isResponsive ? (
        <div className='pop'>
          <div className='pop_dim' onClick={() => setWhoPop(false)}></div>
          <div className='who_pop'>
            <div className='close' onClick={() => setWhoPop(false)}>
              <img src={i_close} alt='' />
            </div>
            <h3 className='pop_tit'>참여인원 목록</h3>
            <div className='who_pop_info'>
              <ul className='chat_who_list'>
                {partInfo &&
                  partInfo.map((item) => {
                    return (
                      <li>
                        <div className='chat_box'>
                          <div className='chat_img'>
                            {item.type !== "COMPANY" &&
                              <img src={item.typeLogo} />
                            }
                          </div>
                          <div className='chat_right'>
                            <div className='chat_text'>
                              <h4>
                                {
                                item.type === "COMPANY" ? 
                                `공급사${item.typeNo}` : 
                                item.typeName
                                }
                              </h4>
                              <p>
                                {
                                  item.type === "COMPANY" ?
                                  `${item.name.charAt(0)}**` :
                                  `${item.name} | ${item.rank}`
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className='chat_who_pop'>
          <h3>참여인원 목록</h3>
          <ul className='chat_who_list'>
            {partInfo &&
              partInfo.map((item) => {
                return (
                  <li>
                    <div className='chat_box'>
                      <div className='chat_img'>
                        {item.type !== "COMPANY" &&
                           <img src={item.typeLogo} />
                        }
                      </div>
                      <div className='chat_right'>
                        <div className='chat_text'>
                          <h4>
                            {
                              item.type === "COMPANY" ? 
                              `공급사${item.typeNo}` : 
                              item.typeName
                            }
                          </h4>
                          <p>
                            {
                              item.type === "COMPANY" ?
                              `${item.name.charAt(0)}**` :
                              `${item.name} | ${item.rank}`
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </>
  );
}
