import React, { useEffect } from "react";
//etc
import i_close from "../../../asset/images/w-cancel-icon.png";
import i_logo from "../../../asset/images/gong_logo.png";
import i_circle from "../../../asset/images/circle-icon.png";
import i_arrow from "../../../asset/images/triangle-arrow-icon.png";
import channel_talk_web_icon from "../../../asset/images/channel_talk_web_icon.png";
import ChannelService from "../../../component/etc/ChannelService";

export default function JoinDescPop({ setJoinDescPop }) {
  useEffect(() => {
    ChannelService.boot({
      pluginKey: "20a812ff-1572-4c34-8c82-814edbd61f81", //please fill with your plugin key
      customLauncherSelector: ".custom_alarm",
      hideChannelButtonOnBoot: true,
    });
  }, []);

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setJoinDescPop(false)}></div>
      <div className="join_desc_pop">
        <div className="close" onClick={() => setJoinDescPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3 className="pop_tit">
          <img src={i_logo} alt="logo" />
          <span>공급사 신청</span>
        </h3>

        <div className="join_sub_title">
          공새로는 B2B기반 건자재 조달 솔루션 서비스를 제공합니다.
        </div>

        <div className="join_info">
          <div className="join_progress">
            <div className="circle">
              <img src={i_circle} className="circle_bg" alt="circle" />
              <span className="step">STEP1</span>
              <span>
                회원가입
                <br />
                신청완료
              </span>
            </div>

            <img src={i_arrow} className="arrow_icon" alt="arrow" />

            <div className="circle">
              <img src={i_circle} className="circle_bg" alt="circle" />
              <span className="step">STEP2</span>
              <span>공새로 검토</span>
              <span className="circle_desc">(검토기준 참고)</span>
            </div>

            <img src={i_arrow} className="arrow_icon" alt="arrow" />
            <div className="circle">
              <img src={i_circle} className="circle_bg" alt="circle" />
              <span className="step">STEP3</span>
              <span>가입완료</span>
              <span className="circle_desc">카카오알림톡 공지</span>
            </div>
          </div>

          <span className="join_standard_title">※ 검토기준</span>
          <div className="join_standard">
            <span>
              ✔ 사업자등록증, 사업자 및 창고/대지 사진, 회사소개서를 기준으로
              검토합니다.
            </span>
            <span>
              ✔ 가입절차 완료시 영업일 기준 1일 이내에 카카오알림톡을 통해
              공지드립니다.
            </span>
          </div>

          <div className="join_question">
            <span>궁금하신 사항은❓</span>
            <div className="custom_alarm custom_alarm_in_popup">
              <img src={channel_talk_web_icon} alt="channel_talk" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
