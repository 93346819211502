import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import i_close from '../../../asset/images/w-cancel-icon.png';
import i_plus from '../../../asset/images/c-plus-icon.png';
import no_image from '../../../asset/images/no_image.png';
import '../../../asset/css/pop.css';
import '../../../asset/css/myInfoEditPwPop.css';

//sweet alert
import swal from 'sweetalert';


export default function MyInfoPreviewPop({ setPreviewPop, popImgPath }) {
  // 기타 입력란
  const [imgName, setImgName] = useState(popImgPath?.f_origname);
  
  // img 확장자 : .png, .jpeg, .jpg, .bmp, gif

  

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setPreviewPop(false)}></div>
      <div className="contract_terms_pop my_info_edit_pw_pop">
        <div className="close" onClick={() => setPreviewPop(false)}>
          <img src={i_close} alt="" />
        </div>

        <h3 className='pop_tit'>이미지 미리보기</h3>
        {imgName?.includes(".png") || imgName?.includes(".jp") || imgName?.includes(".gif") || imgName?.includes(".bmp") ?
        <div className='img_preview_box'>
          <img src={popImgPath?.f_root ? popImgPath?.f_root : no_image } className="pop_preview_img" alt="이미지 불러오기 실패"/>
          <img
            src={i_plus}
            className="pop_plus_icon"
            onClick={()=>{window.open(popImgPath?.f_root)}}
          ></img>
        </div>
        :
        popImgPath?.f_origname ? 
        <div className="file_preview_box">
            <span
            className="file_download"
            onClick={()=>{
              window.open(popImgPath?.f_root)
            }}>파일 다운로드</span>
        </div>
        : null
        }
        
        <span className="preview_name">
          {popImgPath?.f_origname ? 
          `파일이름 : ${popImgPath?.f_origname}` : 
          "파일을 업로드해주세요."}
        </span>
        
        <div className="confirm_btn"  onClick={() => setPreviewPop(false)}>
          {/* <Link to="/management/contract">확인</Link> */}
          확인
        </div>
      </div>
    </div>
  );
}
