import React from "react";
//library
import { useNavigate } from "react-router";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/newinfo4.css";

export default function Newinfo3() {
  const navigate = useNavigate();

  return (
    <div className="completed_wrap joincomplete_wrap">
      <Mheader title="회원가입" />
      <div className="completed_container joincomplete_container">
        <div className="completed_info">
          <div className="join_top">
            <h2>회원가입 완료</h2>
            <div className="join_pagenation">
              <p>1</p>
              <p>2</p>
              <p>3</p>
              <p className="active">4</p>
            </div>
          </div>
          <p className="complete_text">
            회원가입이 완료되었습니다.
            <br />
            지금 바로 로그인하세요.
          </p>

          <p className="ready-for-approve">
            * 신규 공급사 등록의 경우, 승인 후 이용이 가능합니다.
          </p>
        </div>
        <div
          className="completed_btn"
          onClick={() => {
            navigate("/login");
          }}
        >
          <p>로그인</p>
        </div>
      </div>
    </div>
  );
}
