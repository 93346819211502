import React from 'react';
import DaumPostcode from 'react-daum-postcode';

import i_close from '../../asset/images/w-cancel-icon.png';

import { errorLog } from '../../util/Util';

const DaumPostApi = (props) => {
  const conInfo = props.conInfo;
  const setConInfo = props.setConInfo;
  const setAddrPopup = props.setAddrPopup;
  const addrPopup = props.addrPopup;
  const { kakao } = window;

  const handleComplete = (data) => {
    errorLog('DaumPostApi.js_handleComplete', JSON.stringify(data));
    let allAddress = data.address;
    let extraAddress = '';
    let zoneCodes = data.zonecode;

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      allAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    const geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(allAddress, function (result, status) {
      if (status === kakao.maps.services.Status.OK) {
        errorLog(
          'DaumPostApi.js_handleComplete_addressSearch',
          JSON.stringify(result)
        );
        const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
        setConInfo({
          ...conInfo,
          c_addr1: allAddress,
          c_zip: zoneCodes,
          c_lat: coords.Ma,
          c_lng: coords.La,
        });
      } else {
        errorLog('DaumPostApi.js_handleComplete_addressSearch', 'failed');
      }
    });

    setAddrPopup(false);
  };

  const postCodeStyle = {
    padding: '10px',
    background: '#ececec',
    display: 'block',
    position: 'absolute',
    top: '25%',
    width: '85vw',
    maxWidth: '500px',
    height: 'fit-content',
    zIndex: 999,
  };

  return (
    <>
      {addrPopup && (
        <div style={postCodeStyle}>
          <div
            className='close'
            onClick={() => {
              setAddrPopup(false);
            }}
          >
            <img src={i_close} alt='' />
          </div>
          <DaumPostcode
            onComplete={handleComplete}
            autoClose
            style={{
              marginTop: '50px',
            }}
          />
        </div>
      )}
    </>
  );
};

export default DaumPostApi;
