import React, { useState, useEffect } from "react";

import "../../asset/css/common.css";

export default function HistoryPagenation({
  maxNum,
  nowNum,
  setFirstData,
  firstData,
  onClickSetTempVar,
}) {
  const [pageNum, setPageNum] = useState([]);

  // const pagenationFnc = () => {
  //   if (maxNum === 0) {
  //     setPageNum([1]);
  //   } else if (maxNum !== 0) {
  //     const page = [];
  //     for (var i = 1; i < maxNum / 10 + 1; i++) {
  //       page.push(i);
  //     }
  //     setPageNum(page);
  //   }
  // };


  const pagenationFnc = () => {
    if (maxNum === 0) {
      setPageNum([1]);
    } else if (maxNum !== 0) {
      const page = [];
      if (maxNum <= 9) {
        for (let i = 1; i <= maxNum; i++) {
          page.push(i);
        }
      } else if (nowNum <= 5 && maxNum > 9) {
        for (let i = 1; i <= 9; i++) {
          page.push(i);
        }
      } else if (nowNum > 5 && nowNum < maxNum - 8) {
        page.push(nowNum - 4);
        page.push(nowNum - 3);
        page.push(nowNum - 2);
        page.push(nowNum - 1);
        page.push(nowNum);
        page.push(nowNum + 1);
        page.push(nowNum + 2);
        page.push(nowNum + 3);
        page.push(nowNum + 4);
      } else if (nowNum >= maxNum - 8) {
        for (let i = 8; i > -1; i--) {
          page.push(maxNum - i);
        }
      }

      setPageNum(page);
    }
  };

  useEffect(() => {
    pagenationFnc();
  }, [maxNum, nowNum]);

  return (
    <div className="pagination_wrap">
      <span
        className="prev_btn btn"
        onClick={() => {
          if (nowNum > 1) {
            setFirstData({ ...firstData, nowPage: nowNum - 1 });
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
          onClickSetTempVar();
        }}
      >
        이전
      </span>
      {pageNum.map((data, i) => {
        return (
          <div
            className={nowNum === data ? "page now_page" : "page"}
            key={i}
            onClick={() => {
              setFirstData({ ...firstData, nowPage: data });
              window.scrollTo({ top: 0, behavior: "smooth" });
              onClickSetTempVar();
            }}
          >
            {data}
          </div>
        );
      })}
      <span
        className="next_btn btn"
        onClick={() => {
          onClickSetTempVar();
          if (nowNum < pageNum.length) {
            setFirstData({ ...firstData, nowPage: nowNum + 1 });
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        }}
      >
        다음
      </span>
    </div>
  );
}
