import React, { useState } from "react";
import { Link } from "react-router-dom";
import i_close from "../../../asset/images/w-cancel-icon.png";
import "../../../asset/css/pop.css";
import { putOrder_DoneApi } from "../../../api/auth";
import ko from "date-fns/locale/ko";

import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";

import Loading from "../../etc/Loading";

//sweet alert
import swal from "sweetalert";

export default function ModifyOrderDonePop({
  onClickCompleteEdit,
  setOrderDonePop,
}) {
  //직급보내기
  const [generating, setGenerating] = useState(false);
  const [selectDateSingle2, setSelectDateSingle2] = useState(false);

  registerLocale("ko", ko);

  //배송완료버튼

  const onClick_putOrder_Done = async () => {
    if (selectDateSingle2 === false || selectDateSingle2 === null) {
      swal("배송완료 시간을 입력해주세요.", "", "error");
      return;
    } else {
      const formatDate = moment(selectDateSingle2).format(
        "yyyy-MM-DD HH:mm:ss"
      );
      onClickCompleteEdit(formatDate);
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getDiffDate = (selectDate) => {
    let diff = moment(selectDate).fromNow();
    // if(diff.includes('전')){
    //     return '날짜와 시간을 확인해주세요.';
    // } else{
    return "약 " + diff;

    // }
  };

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setOrderDonePop(false)}></div>
      <div className="order_done_pop">
        <div className="close" onClick={() => setOrderDonePop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3 className="pop_tit">배송완료</h3>
        <div className="order_done_box">
          <h3>배송완료</h3>
          <h4>배송 완료 시간을 입력해주세요.</h4>
          <DatePicker
            locale="ko"
            selected={selectDateSingle2 ? selectDateSingle2 : null}
            minDate=""
            maxDate={new Date()}
            showTimeSelect // 시간 나오게 하기
            timeFormat="HH:mm" //시간 포맷
            timeIntervals={30} // 10분 단위로 선택 가능한 box가 나옴
            timeCaption="time"
            dateFormat="yyyy/MM/dd HH:mm 완료"
            isClearable={true}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            onChange={(date) => {
              setSelectDateSingle2(date);
            }}
            popperClassName="some-custom-class"
            popperPlacement="top-start"
            popperModifiers={[
              {
                name: "offset",
                options: {
                  offset: [5, 10],
                },
              },
              {
                name: "preventOverflow",
                options: {
                  rootBoundary: "viewport",
                  tether: false,
                  altAxis: true,
                },
              },
            ]}
          />
          {selectDateSingle2 && (
            <span className="done_time_info">
              {getDiffDate(selectDateSingle2)}
            </span>
          )}
          <div className="edit_position_btn btn_box">
            <div className="cancel" onClick={() => setOrderDonePop(false)}>
              <p>취소</p>
            </div>
            <div className="ok" onClick={onClick_putOrder_Done}>
              <p>확인</p>
            </div>
          </div>
        </div>
      </div>
      {generating && <Loading msg={"로딩중 입니다."} />}
    </div>
  );
}
