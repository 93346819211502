import request from "./request";
import Cookies from "js-cookie";

//redux
import { store } from "../redux/store/store";
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

let signToken = false;
const selectSignToken = (state) => {
  return state.token;
};
const signTokenListener = () => {
  const token = selectSignToken(store.getState());
  signToken = token;
};
store.subscribe(signTokenListener);

//토큰값 얻기

export const getToken = () => {
  return Cookies.get("user");
};

//3. 로그인

export const loginApi = async (id, password, pushToken) => {
  return request(
    "api/v1/sign-in",
    "post",
    {},
    {
      u_id: id,
      u_pw: password,
      pushType: pushToken.type ? pushToken.type : "",
      pushValue: pushToken.value ? pushToken.value : "",
    }
  );
};

//7. 로그인_유저정보

export const headerInfoApi = async () => {
  return request(
    "api/v1/company/user-info",
    "get",
    { Authorization: signToken },
    null
  );
};

//4. 소셜로그인 로그인
export const socialSignIn = async (social_type, social_key) => {
  return request(
    "api/v1/user/socialSignIn",
    "post",
    {},
    {
      social_type: social_type,
      social_key: social_key,
    }
  );
};

//6. 소셜 회원가입

export const socialSignUp = async (formData) => {
  return request("api/v1/user/socialSignUp", "post", {}, formData);
};

//7. 회원가입 본인인증
export const impApi = async (impId) => {
  return request("api/v1/sign-uid", "post", {}, { imp_uid: impId });
};

//8. 회원가입

export const joinApiStep1 = async (no, id, pw, dept, rank, root) => {
  return request(
    "api/v1/sign-user",
    "post",
    {},
    {
      u_no: no,
      u_id: id,
      u_pw: pw,
      department: dept,
      u_rank: rank,
      u_root: root,
    }
  );
};

//9. 회원가입 이메일 체크

export const emailCheckApi = async (u_id) => {
  return request("api/v1/overlap-id/" + u_id, "get", {}, null);
};

//10. 회원가입 step2
export const joinApiStep2 = async (formData) => {
  return request("api/v1/sign-company", "post", {}, formData);
};

//11. 회원가입step3이미지 폼데이터

export const imgUpApi = async (formData) => {
  return request("api/v1/img-up", "post", {}, formData);
};

//16, 회원가입 step3 기존 공급사 검색

export const getConInfoApi = async (lat, lng) => {
  return request("api/v1/existing-companys?lat=" + lat + "&lng=" + lng);
};

//11. 회원가입step3이미지 폼데이터

export const existingConSignUpApi = async (u_no, code) => {
  return request(
    "api/v1/sign-existing-company",
    "post",
    {},
    {
      userNo: u_no,
      companyCode: code,
    }
  );
};

//29.요청관리 리스트

export const req_listApi_new = async (uc_no, page) => {
  return request(
    `api/v1/company/bid/req-list-new/${uc_no}/${page}`,
    "get",
    { Authorization: signToken },
    null
  );
};

//30.요청관리 리스트

export const req_attendInfoApi = async (bid_no) => {
  return request(
    // "api/v1/company/bid/req-attend-info/" + bid_no,
    "v2/bid/detail/company" + bid_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//31.견적제출

//33 .요청관리 리스트

export const getMy_Bid_ListApi = async (
  uc_no,
  sort,
  page,
  startDate,
  endDate
) => {
  return request(
    "api/v1/company/bid-tab/my-bid-list/" +
      uc_no +
      "/" +
      sort +
      "/" +
      page +
      "/" +
      startDate +
      "/" +
      endDate,
    "get",
    { Authorization: signToken },
    null
  );
};

//34. 낙찰견적확인

export const getWinning_bidApi = async (bid_no, bid_order) => {
  return request(
    "api/v1/company/bid-tab/winning-bid/" + bid_no + "/" + bid_order,
    "get",
    { Authorization: signToken },
    null
  );
};

export const fetchWinningBidInfoApi = async (bid_no) => {
  return request(
    `v2/bid/winning/${bid_no}`,
    "get",
    { Authorization: signToken },
    null
  );
};

//36. 내견적내역

export const bidDetailCompanyApi = async (bid_no) => {
  return request(
    "v2/bid/detail/company/" + bid_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//37. 첨부파일다운로드

export const getFileDownloadApi = async (f_no) => {
  return request(
    "api/v1/download/" + f_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//37.견적수정제출

export const offerUpdateApi = async (formData) => {
  return request(
    "v2/offer/update",
    "post",
    { Authorization: signToken },
    formData
  );
};

//39. 내견적내역

export const getMy_ContractInfoListApi = async (
  uc_no,
  sort,
  page,
  startDate,
  endDate
) => {
  return request(
    "api/v1/company/contract-tab/my-contract-list/" +
      uc_no +
      "/" +
      sort +
      "/" +
      page +
      "/" +
      startDate +
      "/" +
      endDate,
    "get",
    { Authorization: signToken },
    null
  );
};

//41. 공급계약확인

export const getMy_ContractInfoApi = async (ct_no, ct_parent, ct_count) => {
  if (ct_parent === undefined || ct_parent === "") {
    return request(
      "api/v1/company/contract-tab/contract-info/" + ct_no,
      "get",
      { Authorization: signToken },
      null
    );
  } else {
    return request(
      "api/v1/company/contract-tab/contract-info/" +
        ct_no +
        "/" +
        ct_parent +
        "/" +
        ct_count,
      "get",
      { Authorization: signToken },
      null
    );
  }
};

// export const getMy_ContractInfoApi = async (ct_no) => {
//   return request(
//     'api/v1/company/contract-tab/contract-info/' + ct_no,
// "contract-info/{ct_no}/{ct_parent}/{ct_count}",
//     'get',
//     { Authorization: signToken },
//     null
//   );
// };

//42. 공급계약받아오기

export const getTermsApi = async () => {
  return request(
    "api/v1/company/contract-tab/terms/",
    "get",
    { Authorization: signToken },
    null
  );
};

//43. 계약 동의 버튼

export const contract_aliveApi = async (ct_no, uc_no) => {
  return request(
    "api/v1/company/contract-tab/contract-alive/" + ct_no + "/" + uc_no,
    "PUT",
    { Authorization: signToken },
    null
  );
};

//44. 계약 중지 버튼

export const contract_stopApi = async (ct_no) => {
  return request(
    "api/v1/company/contract-tab/contract-stop/" + ct_no,
    "PUT",
    { Authorization: signToken },
    null
  );
};

//46. 공급계약내역확인

export const listCheck = async (ct_no) => {
  return request(
    "api/v1/company/contract-tab/contract-stop/" + ct_no,
    "PUT",
    { Authorization: signToken },
    null
  );
};

//47. 지급계좌정보(통장사본)

export const getCopyBankImgApi = async (f_no) => {
  return request(
    "api/v1/img-path/" + f_no,
    "GET",
    { Authorization: signToken },
    null
  );
};

//48. 세금계산서 발행정보

export const getConstruction_infoApi = async (con_no) => {
  return request(
    "api/v1/company/contract-tab/construction-info/" + con_no,
    "GET",
    { Authorization: signToken },
    null
  );
};

//50. 주문서수신관리

export const getMy_Order_ListApi = async (
  uc_no,
  sort,
  page,
  startDate,
  endDate,
  siteNo,
  keyword
) => {
  return request(
    "api/v1/company/order-tab/my-order-list/" +
      uc_no +
      "/" +
      sort +
      "/" +
      page +
      "/" +
      startDate +
      "/" +
      endDate +
      "/" +
      siteNo +
      "/" +
      keyword,
    "get",
    { Authorization: signToken },
    null
  );
};

//52. 주문서 수신 내역 클릭

export const getOrder_tab_infoApi = async (or_no) => {
  return request(
    "api/v1/company/order-tab/order-info/" + or_no,
    "GET",
    { Authorization: signToken },
    null
  );
};

//52. 주문서 확인 버튼 클릭

export const putOrder_checkApi = async (uc_no, or_no) => {
  return request(
    "api/v1/company/order-tab/order-check/" + uc_no + "/" + or_no,
    "PUT",
    { Authorization: signToken },
    null
  );
};

//53. 주문서 배송시작버튼 클릭

export const putOrder_ingApi = async (or_no) => {
  return request(
    "api/v1/company/order-tab/order-ing/" + or_no,
    "PUT",
    { Authorization: signToken },
    null
  );
};

//53. 주문서 배송완료 상태값

export const putOrder_DoneApi = async (or_no, date) => {
  return request(
    "api/v1/company/order-tab/order-done/",
    "POST",
    { Authorization: signToken },
    {
      ordersNo: or_no,
      endDate: date,
      userType: "C",
    }
  );
};

//55. 지급요청하기 페이지 랜딩

export const getReqPayment_listApi = async (or_no, page, sDate, eDate) => {
  return request(
    "api/v1/company/order-tab/reqPayment-list/" +
      or_no +
      "/" +
      page +
      "/" +
      sDate +
      "/" +
      eDate,
    "get",
    { Authorization: signToken },
    null
  );
};

//57. 공급계약신청버튼요청하기 이벤트

export const postReqPayment_correctionApi = async (orMap) => {
  return request(
    "api/v1/company/order-tab/reqPayment-correction",
    "put",
    { Authorization: signToken },
    {
      orMap: orMap,
    }
  );
};

//55. 정산관리 리스트 불러오기

export const getSettlement_listApi = async (uc_no, sort, page) => {
  return request(
    "api/v1/company/settlement/list/" + uc_no + "/" + sort + "/" + page,
    "get",
    { Authorization: signToken },
    {
      uc_no: uc_no,
      sort: sort,
      page: page,
    }
  );
};

//. 정산관리 공급사 입금확인처리
export const checkDepositApi = async (calc_no) => {
  return request(
    "api/v1/company/settlement/check-deposit",
    "post",
    { Authorization: signToken },
    {
      calc_no: calc_no,
    }
  );
};

//60 세금계산서 업로드

export const formTaxBillApi = async (calc_no, f_no) => {
  return request(
    "api/v1/company/order-tab/tax-bill/" + calc_no + "/" + f_no,
    "put",
    { Authorization: signToken },
    null
  );
};

//61. 정산관리 상세내역 불러오기

export const getSettlement_infoApi = async (calc_no) => {
  return request(
    "api/v1/company/settlement/info?calc_no=" + calc_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//71. 마이페이지 - 내정보관리

export const getMyPage_listApi = async () => {
  return request(
    "api/v1/company/mypage/my-list",
    "GET",
    { Authorization: signToken },
    null
  );
};

//72. 마이페이지 - 내정보관리

export const getCompany_InfoApi = async (c_no) => {
  return request(
    "api/v1/company/mypage/company-info/" + c_no,
    "GET",
    { Authorization: signToken },
    null
  );
};

//73. 공급사 정보 수정

export const Update_CompanyInfoApi = async (formData) => {
  return request(
    "api/v1/company/mypage/update-company",
    "PUT",
    { Authorization: signToken },
    formData
  );
};

//74. 내정보 관리 파일 번호 보내기

export const getImg_pathApi = async (f_no) => {
  return request(
    "api/v1/img-path/" + f_no,
    "GET",
    { Authorization: signToken },
    null
  );
};

//75. 공정 리스트 가져오기

export const getProcess_listAPi = async () => {
  return request(
    "api/v1/company/category/process-list",
    "GET",
    { Authorization: signToken },
    null
  );
};

//76. 세부공정 리스트 가져오기

export const getCategory_listApi = async (lc_pro_no) => {
  return request(
    "api/v1/company/category/lCategory-list/" + lc_pro_no,
    "GET",
    { Authorization: signToken },
    null
  );
};

//77. 대분류 리스트 가져오기

export const getCategory_BiglistApi = async (mc_lc_no) => {
  return request(
    "api/v1/company/category/mCategory-list/" + mc_lc_no,
    "GET",
    { Authorization: signToken },
    null
  );
};

//78. 소분류 리스트 가져오기

export const getCategory_SlistApi = async (sc_mc_no) => {
  return request(
    "api/v1/company/category/sCategory-list/" + sc_mc_no,
    "GET",
    { Authorization: signToken },
    null
  );
};

//79. 물품등록 요청 버튼 클릭 후 랜딩 화면검색

export const search_productApi = async (
  pro_no,
  lc_no,
  mc_no,
  sc_no,
  pNum,
  s_key
) => {
  return request(
    "api/v1/company/main-product/search-product?pro_no=" +
      pro_no +
      "&lc_no=" +
      lc_no +
      "&mc_no=" +
      mc_no +
      "&sc_no=" +
      sc_no +
      "&pNum=" +
      pNum +
      "&s_key=" +
      s_key,
    "GET",
    { Authorization: signToken },
    null
  );
};

//80. 상품상세보기

export const getReqProduct_infoApi = async (p_no) => {
  return request(
    "api/v1/company/main-product/product-info/" + p_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//81. 공급품목 보내기

export const main_ProductAddApi = async (c_no, pList) => {
  return request(
    "api/v1/company/main-product/add",
    "post",
    { Authorization: signToken },
    { c_no: c_no, pList: pList }
  );
};

//83. 초대문자 발송 버튼

export const mypage_send_smsApi = async (c_code, receiver, u_phone) => {
  return request(
    "api/v1/company/mypage/send-sms/",
    "post",
    { Authorization: signToken },
    { c_code: c_code, receiver: receiver, u_phone: u_phone }
  );
};

//84. 공지사항받아오기

export const getBoard_ListApi = async (pNum) => {
  return request(
    "api/v1/company/board/list?pNum=" + pNum,
    "get",
    { Authorization: signToken },
    null
  );
};

//85. 공지사항 상세받아오기

export const getBoard_InfoApi = async (bd_no) => {
  return request("api/v1/company/board/info/" + bd_no, "get", {
    Authorization: signToken,
  });
};

//86. 마이페이지 물품등록 리스트받아오기

export const getReqProduct_listApi = async (c_no) => {
  return request("api/v1/company/reqProduct-list/" + c_no, "get", {
    Authorization: signToken,
  });
};

//87. 마이페이지 물품등록 요청

export const postCompany_ProductApi = async (formData) => {
  return request(
    "api/v1/company/reqProduct-add/",
    "post",
    { Authorization: signToken },
    formData
  );
};

//88. 물품등록 상세받아오기

export const getreqProduct_infoDetailApi = async (cp_no) => {
  return request("api/v1/company/reqProduct-info/" + cp_no, "get", {
    Authorization: signToken,
  });
};

//89. 물품등록 상세내역수정

export const reqProduct_updateApi = async (formData) => {
  return request(
    "api/v1/company/reqProduct-update/",
    "put",
    { Authorization: signToken },
    formData
  );
};

//91. 마이페이지 내 정보 변경

export const mypage_UpdateRankApi = async (u_rank) => {
  return request(
    "api/v1/company/mypage/rank/",
    "put",
    { Authorization: signToken },
    { u_rank: u_rank }
  );
};

//92. 비밀번호변경

export const mypage_UpdatePwApi = async (u_pw, newPw) => {
  return request(
    "api/v1/company/mypage/update-pw/",
    "put",
    { Authorization: signToken },
    {
      u_pw: u_pw,
      newPw: newPw,
    }
  );
};

//93. 회원탈퇴

export const mypage_WithdrawalApi = async (u_pw) => {
  return request(
    "api/v1/company/mypage/withdrawal/",
    "put",
    { Authorization: signToken },
    { u_pw: u_pw }
  );
};

//인근현장찾기 - look
export const silverFindApi = async (siteNum, km) => {
  return request(
    "api/v1/gold/nearby/sites/" + siteNum + "/" + km,
    "get",
    { Authorization: signToken },
    null
  );
};

//인근현장찾기 - 유저정보 가져오기

export const findUserRoleApi = async () => {
  return request(
    "api/v1/company/pay/role",
    "get",
    { Authorization: signToken },
    null
  );
};

//인근현장찾기 - finddetailpop

export const companyDetailApi = async (comNum) => {
  return request(
    "api/v1/gold/nearby/company-product/" + comNum,
    "get",
    { Authorization: signToken },
    null
  );
};

//인근현장찾기 - companyinfoPop

export const companyInfoPopApi = async (comNum) => {
  return request(
    "api/v1/gold/nearby/company-review/" + comNum,
    "get",
    { Authorization: signToken },
    null
  );
};

//인근현장찾기 - 결제 정보 검증
export const paySendingApi = async (merchant_uid, amount, service_no) => {
  return request(
    "api/v1/company/pay/before-prepare",
    "post",
    { Authorization: signToken },
    {
      merchant_uid: merchant_uid,
      amount: amount,
      service_no: service_no,
    }
  );
};

//인근현장찾기 - 결제 완료

export const peyCompleteApi = async (data) => {
  return request(
    "api/v1/company/pay/payment-complete",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//사업자 이미지 변경
export const mypage_UpdateLogoApi = async (comNum) => {
  return request(
    "api/v1/company/mypage/update-logo",
    "put",
    { Authorization: signToken },
    { file_index: comNum }
  );
};

//메인 대시보드 정보 띄우기
export const getMainApi = async (uc_no) => {
  return request(
    "api/v1/company/main/" + uc_no,
    "get",
    { Authorization: signToken },
    null
  );
};

///-----------

export const getNaverInfoByToken = async (data) => {
  return request("api/v1/util/naver-info", "post", {}, { ...data });
};

export const checkSocialRegistered = async (data) => {
  return request("api/v1/social/checkRegistered", "post", {}, { ...data });
};

export const socialLogin = async (type, value, pushToken) => {
  return request(
    "api/v1/social-sign-in",
    "post",
    {},
    {
      type: type,
      value: value,
      pushType: pushToken.type ? pushToken.type : "",
      pushValue: pushToken.value ? pushToken.value : "",
    }
  );
};

//token verification
export const tokenVerification = async (token) => {
  return request(
    "api/v1/util/token-verification",
    "get",
    { Authorization: signToken },
    null
  );
};

export const socialJoin = async (
  no,
  id,
  pw,
  dept,
  rank,
  root,
  socialType,
  socialValue
) => {
  return request(
    "api/v1/socialUser",
    "post",
    {},
    {
      u_no: no,
      u_id: id,
      u_pw: pw,
      department: dept,
      u_rank: rank,
      u_root: root,
      socialType: socialType,
      socialValue: socialValue,
    }
  );
};

export const checkCompanyCode = async (data) => {
  return request(
    "api/v1/check-company-code",
    "post",
    {},
    {
      ...data,
    }
  );
};

export const getServiceData = async (data) => {
  return request(
    "api/v1/company/pay/service-info",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const paymentFailed = async (data) => {
  return request(
    "api/v1/company/pay/payment-fail",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const getSiteInfo = async (s_no) => {
  return request(
    "api/v1/gold/nearby/site-info/" + s_no,
    "get",
    { Authorization: signToken },
    null
  );
};

/**
 *  chatting api
 * */

export const chatRoomList = async (data) => {
  return request(
    "v2/chat/room/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const chatMessageList = async (data) => {
  return request(
    "v2/chat/message/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const chatRoomInfo = async (roomNo) => {
  return request(
    "v2/chat/room/info/" + roomNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const chatUploadFile = async (formData) => {
  return request(
    "api/v1/company/chat/upload-file",
    "post",
    { Authorization: signToken },
    formData
  );
};

export const chatInvite = async (data) => {
  return request(
    "api/v1/company/chat/invite",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const chatInviteList = async (data) => {
  return request(
    "api/v1/company/chat/invite-list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//아이디찾기
export const findAccount = async (data) => {
  return request("api/v1/find/company", "post", {}, { ...data });
};

//번호 변경
export const changePhone = async (data) => {
  return request(
    "api/v1/company/mypage/change-phone",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//공급사 시작 날짜 가져오기
export const getRegDateApi = async (uc_no) => {
  return request(
    "api/v1/company/contract-tab/my-contract-list-company-regdate/" + uc_no,
    "get",
    { Authorization: signToken },
    null
  );
};

//견적참여 내 질의응답 참여
export const attendChat = async (data) => {
  return request(
    "v2/chat/room/attend",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//에러 로깅
export const logError = async (data) => {
  return request("api/v1/util/error-log", "post", {}, { ...data });
};

//공급사 검수확인서 보기
export const generateCheckApi = async (orNo) => {
  return request(
    "api/v1/company/order-tab/checkFile/" + orNo,
    "get",
    { Authorization: signToken },
    null
  );
};

//투찰 임시저장 및 비드 발행 - 07/11 변경 - 아래 url 사용, flag값으로 비드생성/임시저장 구분
export const createOrTemporaryApi = async (formdata) => {
  return request(
    "v2/offer/create",
    "post",
    { Authorization: signToken },
    formdata
  );
};

//투찰 임시저장 가져오기
export const getTemporaryBidApi = async (bid_no) => {
  return request(
    "v2/offer/draft/" + bid_no,
    "",
    { Authorization: signToken },
    null
  );
};

//변경계약
export const changeContractApi = async (pList, addList, contractChangeData) => {
  return request(
    "api/v1/company/contract-tab/change-contract/",
    "post",
    { Authorization: signToken },
    {
      pList: pList,
      addList: addList,
      ...contractChangeData,
    }
  );
};

//변경계약 수락

export const changeContractCompleteApi = async (ct_no) => {
  return request(
    "api/v1/company/contract-tab/complete-contract",
    "post",
    { Authorization: signToken },
    { ct_no: ct_no }
  );
};

//변경계약요청취소

export const changeContractCancelApi = async (ct_no) => {
  return request(
    "api/v1/company/contract-tab/change-contract/cancel/" + ct_no,
    "put",
    { Authorization: signToken },
    null
  );
};

export const getMy_ContractAllInfoApi = async (ct_no) => {
  return request(
    "api/v1/company/contract-tab/detail/" + ct_no,
    "get",
    { Authorization: signToken },
    null
  );
};

// 견적 - 엑셀 간편 입력

export const estimateEasyApi = async (bidProductList) => {
  return request(
    "v2/bid/validate",
    "post",
    { Authorization: signToken },
    { bidProductList: bidProductList }
  );
};

export const uploadExcel = async (formData) => {
  return request("api/v1/util/partner/load-excel", "post", {}, formData);
};

export const myPageInfoApi = async (s_no) => {
  return request(
    "api/v1/site/mypage/my-info/" + s_no,
    "get",
    { Authorization: signToken },
    null
  );
};

// 견적 - 품목 직접 검색

export const bidSearchProcess = async () => {
  return request(
    "api/v1/site/bid/process-list",
    "get",
    {
      Authorization: signToken,
    },
    null
  );
};

export const bidSearchFirstCategory = async (value) => {
  return request(
    "api/v1/site/bid/first-category-list/" + value,
    "get",
    {
      Authorization: signToken,
    },
    null
  );
};

export const bidSearchSecondCategory = async (value) => {
  return request(
    "api/v1/site/bid/second-category-list/" + value,
    "get",
    {
      Authorization: signToken,
    },
    null
  );
};

export const bidSearchThirdCategory = async (value) => {
  return request(
    "api/v1/site/bid/third-category-list/" + value,
    "get",
    {
      Authorization: signToken,
    },
    null
  );
};

export const estimateDirectApi = async (data) => {
  return request(
    "api/v1/site/bid/pick/search-post",
    "get",
    { Authorization: signToken },
    {
      ...data,
      s_key: encodeURIComponent(data.s_key),
    }
  );
};

export const productFindApi = async (data) => {
  return request(
    "v2/product/find",
    "post",
    { Authorization: signToken },
    {
      ...data,
    }
  );
};

export const fetchSiteListApi = async (c_no) => {
  return request(
    "v2/site/list/construction/" + c_no,
    "get",
    { Authorization: signToken },
    null
  );
};

export const fetchUserListApi = async (s_no) => {
  return request(
    "v2/user/list/site/" + s_no,
    "get",
    { Authorization: signToken },
    null
  );
};

export const estimatePreviewApi = async (data) => {
  return request(
    "v2/orders/create/pdf",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const bidCreateApi = async (data) => {
  return request(
    "v2/orders/create/pre",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const bidPreviewApi = async (data) => {
  return request(
    "v2/orders/create/sheet",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const estimateEasyExcelApi = async (productInfoList) => {
  return request(
    "v2/orders/product/validate",
    "post",
    { Authorization: signToken },
    { productInfoList: productInfoList }
  );
};

export const showOrderSheetApi = async (ordersNo) => {
  return request(
    "v2/orders/pdf/sheet/" + ordersNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const historyListApi = async (data) => {
  return request(
    "v2/history/partner/list",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// ===========
// INVOICE API
// ===========

export const invoiceCountApi = async (companyNo) => {
  return request(
    "v2/invoice/company/count/" + companyNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const invoiceListApi = async (companyNo, state) => {
  return request(
    "v2/invoice/company/list/" + companyNo + "/" + state,
    "get",
    { Authorization: signToken },
    null
  );
};

export const invoiceListItemApi = async (invoiceNo) => {
  return request(
    "v2/invoice/company/detail/" + invoiceNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const invoiceUploadApi = async (invoiceNo, fileInfoNo) => {
  return request(
    "v2/invoice/company/upload/tax",
    "post",
    { Authorization: signToken },
    {
      invoiceNo: invoiceNo,
      fileInfoNo: fileInfoNo,
    }
  );
};

export const invoiceExcelDownloadApi = async (invoiceNo) => {
  try {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/v2/invoice/company/excel/${invoiceNo}`,
      responseType: "arraybuffer",
      headers: {
        Authorization: signToken, // replace with your own authentication token
        "Content-Type": "application/json",
      },
    });

    const data = response.data;
    const fileName = `Invoice_${invoiceNo}.xlsx`;
    const workbook = XLSX.read(data, { type: "array" });
    const fileContent = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([fileContent], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const invoiceRejectApi = async (invoiceNo, rejectComment) => {
  return request(
    "v2/invoice/company/reject",
    "post",
    { Authorization: signToken },
    {
      invoiceNo: invoiceNo,
      rejectComment: rejectComment,
    },
    null
  );
};

export const invoiceCompleteApi = async (invoiceNo) => {
  return request(
    "v2/invoice/company/complete/" + invoiceNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const ordersCheckApi = async (data) => {
  return request(
    "v2/orders/check",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const ordersModifyApi = async (data, type, endDate) => {
  return request(
    "v2/orders/modify",
    "post",
    { Authorization: signToken },
    { ...data, type: type, endDate: endDate, userType: "C" }
  );
};

export const productDetailApi = async (productNo) => {
  return request(
    "v2/product/detail/" + productNo,
    "get",
    { Authorization: signToken },
    null
  );
};

// ======================
// MANAGEMENT - PRICE API
// ======================
export const fetchPriceListApi = async () => {
  return request("v2/price/list", "get", { Authorization: signToken }, null);
};

export const priceExcelUploadApi = async (formData) => {
  return request(
    "v2/price/upload",
    "post",
    { Authorization: signToken },
    formData
  );
};

export const priceExcelDownloadApi = async () => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/v2/price/download`,
    responseType: "blob",
    headers: {
      Authorization: signToken,
      "Content-Type": "application/octet-stream",
    },
  });

  return response;
};

export const paymentRequestDownloadApi = async (invoiceNo) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/v2/invoice/company/payment-detail/${invoiceNo}`,
    responseType: "blob",
    headers: {
      Authorization: signToken,
      "Content-Type": "application/octet-stream",
    },
  });

  return response;
};

// ====================
// 견적/계약/주문상세 v2 이전
// ====================
export const ordersDetailApi = async (ordersNo) => {
  return request(
    "v2/orders/detail/" + ordersNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const ordersModifyHistoryGroupApi = async (ordersGroupNo) => {
  return request(
    "v2/orders/group/" + ordersGroupNo,
    "get",
    { Authorization: signToken },
    null
  );
};

// ====================
// 견적 상세보기 - api
// ====================
export const bidDetailApi = async (bidNo) => {
  return request(
    "v2/bid/detail/" + bidNo,
    "get",
    { Authorization: signToken },
    null
  );
};

// ====================
// 계약 상세보기 - api
// ====================
export const contractDetailApi = async (ctNo) => {
  return request(
    "api/v1/site/contract/detail/" + ctNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const tagRemoveApi = async (tag) => {
  return request(
    "v2/company/tag/remove",
    "post",
    { Authorization: signToken },
    {
      tag: tag,
    }
  );
};

export const tagAddApi = async (tag) => {
  return request(
    "v2/company/tag/add",
    "post",
    { Authorization: signToken },
    {
      tag: tag,
    }
  );
};

export const tagListApi = async () => {
  return request(
    "v2/company/tag/list",
    "get",
    { Authorization: signToken },
    null
  );
};

export const tagFindApi = async (keyword) => {
  return request(
    "v2/company/tag/find",
    "post",
    { Authorization: signToken },
    {
      keyword: keyword,
    }
  );
};

// ==========
// 주문서 역발행
// ==========
export const fetchConstructionListApi = async () => {
  return request(
    "v2/construction/matching-list",
    "get",
    { Authorization: signToken },
    null
  );
};

export const fetchQuickSiteListApi = async (constructionNo) => {
  return request(
    "v2/quick/site/list/" + constructionNo,
    "get",
    { Authorization: signToken },
    null
  );
};

export const fetchQuickUserListApi = async (siteNo) => {
  return request(
    "v2/quick/user/list/" + siteNo,
    "get",
    { Authorization: signToken },
    null
  );
};

// 아찾비찾 - 인증번호 보내기
export const sendCodeApi = async (phone) => {
  return request(
    "v2/auth/send/code",
    "post",
    {},
    {
      userType: "C",
      phone: phone,
    }
  );
};
// 아찾비찾 - 인증번호 확인
export const verifyCodeApi = async (phone, code) => {
  return request(
    "v2/auth/verify/code",
    "post",
    {},
    {
      userType: "C",
      phone: phone,
      code: code,
    }
  );
};
// 아이디 찾기 - return ID
export const findIdApi = async (userNo) => {
  return request(
    "v2/auth/find/id",
    "post",
    {},
    {
      userNo: userNo,
    }
  );
};

//비밀번호 변경
export const changePwApi = async (userNo, password) => {
  return request(
    "v2/auth/change/password",
    "post",
    {},
    {
      userNo: userNo,
      password: password,
    }
  );
};

// 메세지 전송
export const messageSendApi = async (data) => {
  return request(
    "v2/chat/message/send",
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

//주문관리 - 주문서 발행 취소48

export const orderCancelApi = async (ordersNo, reason) => {
  return request(
    "v2/orders/cancel",
    "post",
    { Authorization: signToken },
    {
      ordersNo: ordersNo,
      reason: reason,
    }
  );
};

// 주문관리 - 반려
export const orderRejectApi = async (ordersNo, rejectReason) => {
  return request(
    "v2/orders/reject",
    "post",
    { Authorization: signToken },
    {
      ordersNo: ordersNo,
      rejectReason: rejectReason,
    }
  );
};

// 주문관리 > 예비 정산내역 엑셀 다운로드
export const preInvoiceExcelDownApi = async (
  siteOrCompanyNo,
  state,
  startDate,
  endDate,
  filterSiteOrCompanyNo,
  keyword
) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}/v2/orders/detail/excel/${siteOrCompanyNo}/${state}/${startDate}/${endDate}/${filterSiteOrCompanyNo}/${keyword}`,
    responseType: "blob",
    headers: {
      Authorization: signToken,
      "Content-Type": "application/octet-stream",
    },
  });

  return response;
};

// 메인 대시보드
export const fetchDashboardApi = async (data) => {
  return request(
    `v2/dashboard/info/company`,
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

// 주문상세 > 반품 주문서 생성
export const createOrderReturnApi = async (data) => {
  return request(
    `v2/orders/return`,
    "post",
    { Authorization: signToken },
    { ...data }
  );
};

export const allTradeHistoryListApi = async (keyword) => {
  return request(
    "v2/history/delivery",
    "post",
    { Authorization: signToken },
    { keyword: keyword }
  );
};
