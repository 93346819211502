import React from "react";
import { useNavigate } from "react-router-dom";
import { postReqPayment_correctionApi } from "../../../api/auth";
//component
import Mheader from "../../../component/common/Mheader";

//sweet alert
import swal from "sweetalert";

export default function PaymentRequest2(props) {
  const navigate = useNavigate();
  const orMap = props.location.state.orMap;

  // 뒤로가기
  const goBack = () => {
    window.history.back();
  };

  //공급계약요청버튼 클릭이벤트
  const onClick_postReqPayment_correction = async () => {
    const res = await postReqPayment_correctionApi(orMap);
    if (res.status === "success") {
      navigate("/management/order/completed");
    } else {
      swal("공급계약요청이 실패하였습니다.", "", "warning");
    }
  };

  return (
    <div className="payment_request_wrap">
      <Mheader title="지급 요청하기" />
      <div className="payment_request_container wd">
        <div className="top_pagenation">
          <h2>지급 요청하기</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p className="active">2</p>
            <p>3</p>
          </div>
        </div>
        <div className="payment_request2_info">
          <h4>공급계약이 신청되었습니다.</h4>
          <div className="request_img"></div>
          <div className="btn_box">
            <div className="cancel">
              <p onClick={goBack}>취소</p>
            </div>
            <div className="next" onClick={onClick_postReqPayment_correction}>
              요청하기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
