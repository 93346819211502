import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

export default function usePreventBack() {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = () => {
      // 뒤로 가기를 막는 기능 구현
      swal("", "뒤로가기 할 수 없습니다\n홈으로 이동합니다.", "warning");
      navigate("/");
    };

    // 현재 상태를 기록하고, 새로운 상태를 푸시하여 뒤로 가기를 막음
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", handlePopState);

    // popstate 이벤트 리스너 해제
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
}
