import React from "react";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/policy.css";

export default function PrivacyAgree() {
  return (
    <div className="privacy_wrap">
      <Mheader title="개인정보처리방침" />
      <div className="privacy_container">
        <h2 className="policy_tit">개인정보수집이용동의</h2>
        <div className="privacy_contents">
          <div className="privacy_box">
            <h4>
              수집하는 개인정보 항목 / 수집 및 이용목적 / 보유 및 이용기간
            </h4>
            <div className="table_container">
              <table>
                <tr>
                  <td>수집방법</td>
                  <td>수집항목</td>
                  <td>수집 및 이용목적</td>
                  <td>보유 및 이용기간</td>
                </tr>
                <tr>
                  <td>회원가입</td>
                  <td>
                    회원) ID, 비밀번호, 이름, 직급 <br />
                    공급사) 공급사 업체명, 사업자명, 사업자구분, 사업자
                    등록번호, 대표자명, 대표번호, 업종, 업태, 주소, 사업자
                    등록증 이미지, 정산받을 계좌 정보, 정산받을 통장 이미지,
                    공급업체 분류, 납품가능지역
                    <br />
                  </td>
                  <td>
                    사이트의 전반적인 기능 이용 <br />( 입찰, 계약, 주문, 정산,
                    채팅, 인근현장찾기 등 )
                  </td>
                  <td>회원탈퇴 후 탈퇴한 정보는 비식별처리</td>
                </tr>
                <tr>
                  <td>입찰/계약/주문</td>
                  <td>
                    입찰) 진행공정, 입찰예산, 투찰기한,
                    <br /> 배송비 포함 업무, 하차역무 포함여부, 우선사항,
                    계약기간, 결제주기, 납품주소, 납품일시
                  </td>
                  <td>자재 입찰 후 공급사 투찰 서비스 제공</td>
                  <td rowSpan="3">
                    {" "}
                    부정이용 방지를 위하여 회원탈퇴 후에도
                    입찰/계약/주문/정산/채팅 정보는 보관합니다.
                  </td>
                </tr>
                <tr>
                  <td>질의응답</td>
                  <td>
                    채팅내용, 첨부된 이미지, 첨부된 파일
                    <br />
                  </td>
                  <td>입찰건에 대한 고객사, 공급사간의 질의응답 서비스 제공</td>
                </tr>
                <tr>
                  <td>오류</td>
                  <td>
                    단말기정보, 화면사이즈, 디바이스 아이디, IP주소, 오류일시 등
                    <br />
                  </td>
                  <td>사이트 이용 중 오류 및 불편사항 추적</td>
                </tr>
              </table>
            </div>
          </div>
          <div className="privacy_box">
            <p>
              단, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의
              규정에 따라 거래 관계 확인을 위해 개인정보를 일정기간 보유 할 수
              있습니다.
            </p>
          </div>
          <div className="privacy_box">
            <h4>관계법령에 의한 개인정보 보존기간</h4>
            <p>
              <ul style={{ marginLeft: "10px" }}>
                <li>서비스이용기록, 접속로그 (통신비밀보호법 :3개월)</li>
                <li>계약 또는 청약철회 등에 관한 기록 (전자상거래법: 5년)</li>
                <li>
                  대금결제 및 대금납부 등 재화 공급에 관한 기록 (전자상거래법:
                  5년)
                </li>
                <li>
                  소비자의 불만 또는 분쟁처리에 관한 기록 (전자상거래법: 3년)
                </li>
              </ul>
            </p>
          </div>
          <div className="privacy_box">
            <p>
              <h4>동의를 거부할 권리 및 거부 경우의 불이익</h4>
              다만, 수집 및 이용을 거부하신 정보와 연계된 서비스(입찰, 계약,
              주문, 정산관리 등)는 동의 전까지 일부 또는 전체를 이용하실 수
              없습니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
