import React, { useState } from 'react';
//css
import '../../asset/css/myCart.css';
//image
import i_x_gray from '../../asset/images/w-cancle-bt.png';
import i_cancel from '../../asset/images/w-cancel-icon.png';
import i_tng from '../../asset/images/tng.png';
import { wonComma } from '../../util/Util';

export default function MyCart({ setCart, cartItem, setCartItem }) {
  
  return (
    <div className='my_cart_wrap' onClick={() => setCart(false)}>
      <div
        className='my_cart_container'
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <img alt='' src={i_tng} className='i_tng' />
        <div>
          <h3>
            <p>
              내 장바구니 (<span>{cartItem.length}</span>)
            </p>
            <img alt='' src={i_cancel} onClick={() => setCart(false)} />
          </h3>
          <ul className='my_cart_list'>
            {cartItem.map((data, i) => {
              return (
                <li key={i}>
                  <div className='my_cart_item_img'>
                    <img src={data.file} alt={data.product.name} />
                  </div>
                  <div className='my_cart_item_text'>
                    <p className='name'>{data.product.name}</p>
                    <p className='option'>{data.product.standard}</p>
                    {/* <p className='price'>
                      {wonComma(data.p_price)}원 / {data.p_unit}
                    </p> */}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: '8px',
                      }}
                    >
                      <input
                        type={'number'}
                        placeholder={'주문 갯수(숫자만)'}
                        defaultValue={data.cartAmount}
                        style={{
                          height: '25px',
                          width: '80px',
                          marginRight: '5px',
                        }}
                        onChange={(e) => {
                          let newCartList = cartItem;
                          newCartList[i].cartAmount = e.target.value;
                          setCartItem(newCartList);
                          data.cartAmount = e.target.value;
                        }}
                      />
                      <p className='name'>개</p>
                    </div>
                  </div>
                  <img
                    alt=''
                    src={i_x_gray}
                    className='i_x'
                    onClick={() => {
                      const deleteData = cartItem.filter(
                        (item) => item.product.no !== data.product.no
                      );
                      setCartItem(deleteData);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
