import styled from "@emotion/styled";

export const ProductNameWrap = styled.td`
  text-align: left;
  span {
    width: 70%;
    @media (max-width: 1080px) {
      width: 60%;
    }
  }
`;

export const ProductImage = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: zoom-in;
  @media (max-width: 1080px) {
    margin-right: 2rem;
    width: 7rem;
    height: 7rem;
  }
`;

export const DefaultFileImgWrap = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: not-allowed;
  @media (max-width: 1080px) {
    margin-right: 2rem;
    width: 7rem;
    height: 7rem;
  }
`;

export const TableInputName = styled.input`
  border: 1px solid rgb(204, 204, 204);
  text-align: center;
  background: rgba(241, 244, 248, 0.9);
  width: 80%;
  height: 100%;
  text-indent: 0;
  border-radius: 0;
  @media (max-width: 1080px) {
    width: 60%;
  }
`;

export const TableInputStandard = styled.textarea`
  border: 1px solid rgb(204, 204, 204);
  text-align: center;
  background: rgba(241, 244, 248, 0.9);
  width: 100%;
  height: 100%;
  text-indent: 0;
  border-radius: 0;
  resize: none;
`;

export const TableInput = styled.input`
  border: 1px solid rgb(204, 204, 204);
  text-align: center;
  background: rgba(241, 244, 248, 0.9);
  width: 80%;
  height: 100%;
  text-indent: 0;
  border-radius: 0;
`;

export const TableInputNumber = styled.input`
  border: 1px solid rgb(204, 204, 204);
  text-align: center;
  background: rgba(241, 244, 248, 0.9);
  width: 100%;
  height: 100%;
  text-indent: 0;
  border-radius: 0;
  &[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 0 !important;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const WarningMsgBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
  @media (max-width: 1080px) {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
`;

export const WarningMsgText = styled.div`
  color: #999;
  font-size: 14px;
  font-weight: 400;
`;

export const AddRowImg = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  vertical-align: middle;
`;

export const AddRowText = styled.span`
  vertical-align: middle;
`;

export const RequiredPoint = styled.span`
  margin-left: 2.5px;
  color: red;
`;

export const DeleteRowImg = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  vertical-align: text-top;
  cursor: pointer;
`;

export const BadgeWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Badge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #254574;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-right: 10px;
  background: ${(props) =>
    (props.state === "START" && "#107C41") ||
    (props.state === "CHECK" && "#005BAA") ||
    (props.state === "ING" && "#005BAA") ||
    (props.state === "DONE" && "#005BAA") ||
    (props.state === "TESTED" && "#005BAA") ||
    (props.state === "REJECT" && "#ff6600") ||
    (props.state === "CANCEL" && "#9C9EA1") ||
    (props.state === "END" && "#9C9EA1")};
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const TypeBadge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #fff;
  border: 1px solid
    ${(props) =>
      (props.type === "A" && "#33C481") ||
      (props.type === "S" && "#21A366") ||
      (props.type === "T" && "#0064FF") ||
      (props.type === "P" && "#005BAA") ||
      (props.type === "Q" && "#0096D1")};
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) =>
    (props.type === "A" && "#33C481") ||
    (props.type === "S" && "#21A366") ||
    (props.type === "T" && "#0064FF") ||
    (props.type === "P" && "#005BAA") ||
    (props.type === "Q" && "#0096D1")};
  margin-bottom: 10px;
  margin-right: 10px;
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
`;

export const Title = styled.div`
  font-size: 19px;
  font-weight: 600;
  margin-top: 50px;
`;

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

export const TabWrap = styled.div`
  margin-top: 20px;
  @media (max-width: 1080px) {
    margin-top: 3rem;
  }
`;

export const TabBtn = styled.button`
  width: 90px;
  height: 30px;
  padding: 0px 10px;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #c6c6c8;
  border-bottom: none;
  color: #c6c6c8;
  &:hover {
    font-weight: 600;
    border-bottom: none;
    color: #000;
    background: #f7f7f7;
  }
  @media (max-width: 1080px) {
    width: 20rem;
    height: 8rem;
    font-size: 3.5rem;
  }
`;

export const SelectedTabBtn = styled(TabBtn)`
  font-weight: 600;
  border-bottom: none;
  color: #000;
  background: #f7f7f7;
`;
