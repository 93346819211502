import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "../../../asset/css/requestComplete.css";

import Mheader from "../../../component/common/Mheader";
import usePreventBack from "../../main/customHooks/usePreventBack";

export default function RequestComplete() {
  const navigate = useNavigate();
  const location = useLocation();
  const orderNo = location.state.no;

  usePreventBack();
  return (
    <div className="request_complete_wrap">
      <Mheader title="주문서 작성 완료" />
      <div className="request_complete_container">
        <div className="top_progress_bar_container">
          <div className="top_progress_bar">
            <div className="menus">주문서 작성하기</div>
            <div className="menus">상세정보 입력</div>
            <div className="menus">상세정보 확인</div>
            <div className="active_menus">주문서 작성 완료</div>
          </div>
        </div>

        <div className="request_complete_text">
          <h2>주문서 작성이 완료되었습니다.</h2>
          <p>
            <span>
              주문서 작성이 완료되었습니다.
              <br />
              주문관리 페이지에서 확인이 가능하며,
              <br />
              기존 구매 프로세스와 동일하게 진행됩니다.
            </span>
          </p>
        </div>

        <div className="conclude_btn btn_box request_complete_btn_box">
          <div className="view_estimate_info">
            <p
              onClick={() => {
                navigate("/management/order/document/" + orderNo);
              }}
            >
              주문 정보 보기
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
