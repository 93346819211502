import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Mheader from "../../../component/common/Mheader";
import ContractTermsPop from "../../../component/common/Pop/ContractTermsPop";
import ContractTermsStopPop from "../../../component/common/Pop/ContractTermsStopPop";
import * as S from "../../../asset/css/contract.styles";
import "react-datepicker/dist/react-datepicker.css";
import "../../../asset/css/contract.css";
import { getMy_ContractInfoListApi } from "../../../api/auth";
import { useSelector } from "react-redux";
import DashBoardTabs from "../../../component/etc/DashBoardTabs";
import { useMediaQuery } from "react-responsive";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { ko } from "date-fns/esm/locale";
import Pagination from "../../../component/ui/pagination/Pagination";

export default function Contract() {
  const { page } = useParams();

  const url = "/management/contract/";
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });

  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [formCt_no, setFormCt_no] = useState([]);
  const [termsPop, setTermsPop] = useState(false);
  const [termsStopPop, setTermsStopPop] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);
  const [sortPageList, setSortPageList] = useState({
    pageSort: "ALL",
    nowPage: 1,
    startDate: "2021-01-01",
    endDate: moment().format("yyyy-MM-DD"),
  });

  const [ctList, setCtist] = useState([]);
  const [stateMap, setStateMap] = useState([]);
  const [ctListOne, setCtListOne] = useState([]);

  const onClickgetMy_ContractInfoList = async () => {
    const uc_no = userData.uc_no;

    const res = await getMy_ContractInfoListApi(
      uc_no,
      sortPageList.pageSort,
      currentPage,
      sortPageList.startDate,
      sortPageList.endDate
    );

    if (res.status === "success") {
      setCtist(res.data.ctList);
      setStateMap(res.data.stateMap);
      setMaxCount(res.data.maxCount);
    }
  };

  useEffect(() => {
    onClickgetMy_ContractInfoList();
  }, [sortPageList, currentPage]);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const endDateFnc = (databid, bidTerm) => {
    return formatDate(databid.setMonth(databid.getMonth() + Number(bidTerm)));
  };

  const _move2Success = () => {
    onClickgetMy_ContractInfoList();
    setTermsPop(false);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("전체");

  const onChangeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setSortPageList({
      ...sortPageList,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    });
  };

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setSortPageList({
        ...sortPageList,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    }
  }, [selectValue]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`${url}${page}`);
  };

  return (
    <S.Wrapper>
      <Mheader title="계약관리" />
      <S.DashboardContainer>
        {isDesktop && <DashBoardTabs />}
        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>나의 계약관리 현황</S.Title>
              {isDesktop && (
                <>
                  <div>|</div>
                  <S.SelectedDate>
                    {sortPageList?.startDate} ~ {sortPageList?.endDate}
                  </S.SelectedDate>
                </>
              )}
            </S.TitleWrap>

            {isDesktop && (
              <S.AlignWrap>
                <S.DateRangeSelect
                  onChange={onChangeSelectValue}
                  value={selectValue}
                >
                  <option>최근 1개월</option>
                  <option>최근 3개월</option>
                  <option>최근 6개월</option>
                  <option>최근 1년</option>
                  <option>전체</option>
                </S.DateRangeSelect>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setSortPageList({
                        ...sortPageList,
                        startDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                      setCurrentPage(1);
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setSortPageList({
                        ...sortPageList,
                        endDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                      setCurrentPage(1);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>
              </S.AlignWrap>
            )}
          </S.ContentHeader>

          <S.ContentBody>
            <S.ContractStateWrap>
              <S.ContractState>계약 전</S.ContractState>
              <S.ContractStartBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "S",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.start}건
              </S.ContractStartBtn>
            </S.ContractStateWrap>

            <S.ContractStateWrap>
              <S.ContractState>계약완료</S.ContractState>
              <S.ContractAliveBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "A",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.alive}건
              </S.ContractAliveBtn>
            </S.ContractStateWrap>

            {isDesktop && <S.DivideLine></S.DivideLine>}

            <S.ContractStateWrap>
              <S.ContractState>전체보기</S.ContractState>
              <S.ContractAllBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "ALL",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {Object.values(stateMap).reduce((acc, cur) => +acc + +cur, 0)}건
              </S.ContractAllBtn>
            </S.ContractStateWrap>
          </S.ContentBody>

          <S.ContentFooter>
            <S.CancelStopNumBtn
              sortPageList={sortPageList}
              onClick={() => {
                setSortPageList({
                  ...sortPageList,
                  pageSort: "CS",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              계약중지 <span>{stateMap.cs}</span>건
            </S.CancelStopNumBtn>
            <S.GraceNumBtn
              sortPageList={sortPageList}
              onClick={() => {
                setSortPageList({
                  ...sortPageList,
                  pageSort: "G",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              연장가능 계약 <span>{stateMap.grace}</span>건
            </S.GraceNumBtn>
            <S.EndNumBtn
              sortPageList={sortPageList}
              onClick={() => {
                setSortPageList({
                  ...sortPageList,
                  pageSort: "E",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              계약종료 <span>{stateMap.end}</span>건
            </S.EndNumBtn>
          </S.ContentFooter>
        </S.DashBoardContent>

        {isDesktop && (
          <>
            <S.SearchResultWrap>
              <S.SearchResult>
                총 <span>{maxCount}</span>건이 검색되었습니다.
              </S.SearchResult>
            </S.SearchResultWrap>

            {ctList?.map((el, idx) => (
              <S.ContractListWrapper key={idx}>
                <S.ListHeader>
                  <S.ContractNo>계약번호 : {el.ct_code}</S.ContractNo>
                  <S.SiteName>현장명 : {el.s_name}</S.SiteName>
                  <S.ContractStep el={el}>
                    <S.Step></S.Step>
                    <S.Step>
                      <p>계약 전</p>
                    </S.Step>
                    <S.Step>
                      <p>계약완료</p>
                    </S.Step>
                  </S.ContractStep>
                </S.ListHeader>

                <S.ListBody>
                  <S.ListInfo>
                    <S.Badge el={el}>
                      {el.ct_state === "START" && "계약 전"}
                      {el.ct_state === "ALIVE" && "계약완료"}
                      {el.ct_state === "CANCEL" && "계약취소"}
                      {el.ct_state === "HOLD" && "중지요청"}
                      {el.ct_state === "STOP" && "계약중지"}
                      {el.ct_state === "GRACE" && "연장가능계약"}
                      {el.ct_state === "END" && "계약종료"}
                    </S.Badge>
                    <S.NameWrapper>
                      <S.CompanyName>{el.con_name}</S.CompanyName>
                      <div>|</div>
                      <S.ProductName>{el.product_desc}</S.ProductName>
                    </S.NameWrapper>
                    <S.DetailInfo>
                      <S.BidType>
                        견적형태 : 지정단가계약 {`(${el.bid_term}개월)`}
                      </S.BidType>
                      <S.BidDate>
                        계약기간 : {el.ct_sdate.slice(0, 10)} -{" "}
                        {el.ct_edate.slice(0, 10)}
                      </S.BidDate>
                      <S.BidCompany>
                        계약금액 : {el.total_amount.toLocaleString("ko")}원
                      </S.BidCompany>
                    </S.DetailInfo>
                  </S.ListInfo>
                  <S.BtnWrapper>
                    {(el.ct_state === "START" ||
                      "ALIVE" ||
                      "END" ||
                      "HOLD" ||
                      "CANCEL") && (
                      <S.ContractContentBtn
                        onClick={() => {
                          navigate(`/management/contract/document/${el.ct_no}`);
                        }}
                      >
                        계약 내역 확인
                      </S.ContractContentBtn>
                    )}
                    {el.ct_state === "START" && (
                      <S.ContractAgreeBtn
                        onClick={() => {
                          setFormCt_no({
                            ...formCt_no,
                            ct_no: el.ct_no,
                            contract_file: el.contract_file,
                          });
                          setTermsPop(true);
                          setCtListOne(ctList[idx]);
                        }}
                      >
                        계약체결 동의
                      </S.ContractAgreeBtn>
                    )}
                    {el.ct_state === "HOLD" && (
                      <S.ContractStopBtn
                        onClick={() => {
                          setTermsStopPop(true);
                          setFormCt_no({ ...formCt_no, ct_no: el.ct_no });
                        }}
                      >
                        계약중지 동의
                      </S.ContractStopBtn>
                    )}
                  </S.BtnWrapper>
                </S.ListBody>
              </S.ContractListWrapper>
            ))}
            <div className="contract_pagination_container">
              <Pagination
                current={currentPage}
                total={maxCount}
                onChange={handlePageChange}
                pageSize={10}
              />
            </div>
          </>
        )}
      </S.DashboardContainer>

      {termsPop && (
        <ContractTermsPop
          setTermsPop={setTermsPop}
          formCt_no={formCt_no.ct_no}
          contract_file={formCt_no.contract_file}
          move2Success={_move2Success}
        />
      )}
      {termsStopPop && (
        <ContractTermsStopPop
          move2Success={_move2Success}
          setTermsStopPop={setTermsStopPop}
          formCt_no={formCt_no.ct_no}
        />
      )}

      {isMobile && (
        <div className="contract_wrap">
          <S.AlignWrap>
            <S.DateWrap>
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setSortPageList({
                    ...sortPageList,
                    startDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>

            <S.DateWrap>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setSortPageList({
                    ...sortPageList,
                    endDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>
          </S.AlignWrap>

          <S.SearchResultWrap>
            <S.SearchResult>
              검색결과 : <span>{maxCount}</span>건
            </S.SearchResult>
          </S.SearchResultWrap>

          <div className="contract_container">
            <div className="contract_list">
              <div className="contract_list_btm">
                {ctList.map((el, i) => {
                  return (
                    <div className="contract_content" key={i} value={i}>
                      <div className="content_top">
                        <div>
                          <span>계약서 번호 :&nbsp;</span>
                          <span className="public_code">{el.ct_code}</span>
                        </div>
                      </div>
                      <div className="content_btm">
                        <div className="content_box">
                          <img className="company_img" src={el.f_root} alt="" />
                          <div className="contract_info">
                            <div className="icon_box">
                              <S.Badge el={el}>
                                {el.ct_state === "START" && "계약 전"}
                                {el.ct_state === "ALIVE" && "계약완료"}
                                {el.ct_state === "CANCEL" && "계약취소"}
                                {el.ct_state === "HOLD" && "중지요청"}
                                {el.ct_state === "STOP" && "계약중지"}
                                {el.ct_state === "GRACE" && "연장가능계약"}
                                {el.ct_state === "END" && "계약종료"}
                              </S.Badge>
                            </div>
                            <p className="contractor">
                              <span>
                                [{el.con_name}]&nbsp;{el.s_name}
                              </span>
                              <span>
                                {el.bid_type === "T" || el.bid_type === "C"
                                  ? "지정 기간 단가계약"
                                  : el.bid_type === "S"
                                  ? "단일건 계약"
                                  : null}
                              </span>
                            </p>
                            <div className="contract_text">
                              {el.bid_type === "T" || el.bid_type === "C" ? (
                                <p className="company_info">
                                  계약기간 : {el.ct_sdate}&nbsp;-&nbsp;
                                  {endDateFnc(
                                    new Date(el.ct_sdate),
                                    el.bid_term
                                  )}
                                </p>
                              ) : el.bid_type === "S" ? null : null}
                              {el.ct_state === "ALIVE" || "END" ? (
                                <p className="company_info">
                                  계약 생성일시 : {el.ct_regdate}
                                </p>
                              ) : null}
                              {el.ct_state === "CS" && (
                                <p>계약중지 : 고객사 사정으로 계약 중지</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="contract_more">
                          {el.ct_state === "START" ||
                          "ALIVE" ||
                          "END" ||
                          "HOLD" ||
                          "CANCEL" ? (
                            <p
                              onClick={() => {
                                navigate(
                                  `/management/contract/document/${el.ct_no}`
                                  // { state: { ctList: ctList[i] } }
                                );
                              }}
                            >
                              계약 내역 확인
                            </p>
                          ) : null}
                          {el.ct_state === "START" && (
                            <p
                              onClick={() => {
                                setFormCt_no({
                                  ...formCt_no,
                                  ct_no: el.ct_no,
                                  contract_file: el.contract_file,
                                });
                                setTermsPop(true);
                                setCtListOne(ctList[i]);
                              }}
                              style={{
                                border: "solid 1px #212121",
                                fontWeight: "bold",
                              }}
                            >
                              계약체결 동의
                            </p>
                          )}
                          {el.ct_state === "HOLD" && (
                            <p
                              onClick={() => {
                                setTermsStopPop(true);
                                setFormCt_no({
                                  ...formCt_no,
                                  ct_no: el.ct_no,
                                });
                              }}
                            >
                              계약중지 동의
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="contract_pagination_container">
                <Pagination
                  current={currentPage}
                  total={maxCount}
                  onChange={handlePageChange}
                  pageSize={10}
                />
              </div>
            </div>
          </div>

          {termsPop && (
            <ContractTermsPop
              setTermsPop={setTermsPop}
              formCt_no={formCt_no.ct_no}
              contract_file={formCt_no.contract_file}
              move2Success={_move2Success}
            />
          )}
          {termsStopPop && (
            <ContractTermsStopPop
              move2Success={_move2Success}
              setTermsStopPop={setTermsStopPop}
              formCt_no={formCt_no.ct_no}
            />
          )}
        </div>
      )}
    </S.Wrapper>
  );
}
