import * as S from "../../asset/css/shareTooltip.styles.js";
import i_cancel_btn from "../../asset/images/cancel-btn.png";
import swal from "sweetalert";

export default function ShareTooltip(props) {
  const API_URL = process.env.REACT_APP_URL;
const url =
    props.startingPoint === "ProductShareList"
      ? `${API_URL}/management/order/product_share/detail/${props.data.no}`
      : props.startingPoint === "RequestChoice"
      ? `${API_URL}/management/order/request_choice/detail/${props.data.no}`
      : props.startingPoint === "ProductShareDetail"
      ? `${API_URL}/management/order/request_choice/detail/${props.data.no}`
      : null;
  const alertCopy = () => {
    swal({
      title: "클립보드에 복사되었습니다.",
      icon: "success",
    });
  };
  const onClickCopyUrl = () => {
    navigator.clipboard.writeText(url);
    alertCopy();
  };

  return (
    <S.TooltipBottom
      popUpLocation={props.popUpLocation}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <S.Header>
        <S.CancelBtn
          onClick={(e) => {
            props.setOpenShareTooltip(!props.openShareTooltip);
          }}
        >
          <S.CancelIcon alt="" src={i_cancel_btn} />
        </S.CancelBtn>
      </S.Header>

      <S.Title>공유하기</S.Title>

      <S.footer>
        <S.Url value={url} readOnly />
        <S.CopyBtn onClick={onClickCopyUrl}>URL 복사</S.CopyBtn>
      </S.footer>
    </S.TooltipBottom>
  );
}
