import React, { useState } from 'react';
//css
import '../../asset/css/myCart.css';
//image
import i_x_gray from '../../asset/images/w-cancle-bt.png';
import i_cancel from '../../asset/images/w-cancel-icon.png';
import i_tng from '../../asset/images/tng.png';

import { wonComma } from '../../util/Util';

export default function MyCart({ cart, setCart, cartList, setCartList }) {
  
  return (
    <div className='my_cart_wrap' onClick={() => setCart(false)}>
      <div
        className='my_cart_container'
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <img alt='' src={i_tng} className='i_tng' />
        <div>
          <h3>
            <p>
              선택된 공급품목 (<span>{cartList ? cartList.length : 0}</span>)
            </p>
            <img alt='' src={i_cancel} onClick={() => setCart(false)} />
          </h3>
          <ul className='my_cart_list'>
            {cartList.map((data, i) => {
              return (
                <li key={i}>
                  <div className='my_cart_item_img'>
                    <img src={data.f_root} alt={data.p_name} />
                  </div>
                  <div className='my_cart_item_text'>
                    <p className='name'>{data.p_name}</p>
                    <p className='option'>{data.p_standard}</p>
                    {/* <p className='price'>
                      {wonComma(data.p_price)}원 / {data.p_unit}
                    </p> */}
                  </div>
                  <img
                    alt=''
                    src={i_x_gray}
                    className='i_x'
                    onClick={() => {
                      const deleteData = cartList.filter(
                        (item) => item.p_no !== data.p_no
                      );
                      setCartList(deleteData);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
