import React, { useState, useEffect } from "react";
import { getReqProduct_infoApi } from "../../api/auth";
//css
import "../../asset/css/itemInfo.css";
//etc
import i_cancel from "../../asset/images/w-cancel-icon.png";
import i_download from "../../asset/images/download_icon.png";
import i_magnifier_black from "../../asset/images/magnifier-icon-black.png";

import { wonComma } from "../../util/Util";

//sweet alert
import swal from "sweetalert";

export default function ItemInfo({
  openDetail,
  setOpenDetail,
  p_no,
  cartList,
  setCartList,
}) {
  //상세정보리스트받아오기

  const [reqProduct, setReqProduct] = useState();
  const [state, setState] = useState(false);
  const p_noData = p_no.p_no;
  const getReqProduct_info = async () => {
    const res = await getReqProduct_infoApi(p_noData);

    if (res.status === "success") {
      setReqProduct(res.data);
    }
  };

  useEffect(() => {
    getReqProduct_info();
  }, [p_noData]);

  useEffect(() => {
    if (!cartList.p_no) {
      setState(true);
    }
    cartList.map((item) => {
      if (Number(item.p_no) === p_no.p_no) {
        setState(false);
      }
    });
  }, []);

  const onClickAddCartList = () => {
    if (state) {
      setCartList([...cartList, reqProduct]);
      setOpenDetail(false);
    }
    if (!state) {
      swal("품목이 중복 되었습니다.", "", "warning");
      setCartList(cartList);
      setOpenDetail(false);
    }
  };

  return (
    // <div className='item_info_wrap pop'>
    //   <div className='pop_dim' onClick={() => setOpenDetail(false)}></div>
    //   <div className='item_info_container edit_position_pop'>
    //     <div className='item_info_top'>
    //       <h3>상품 상세보기</h3>
    //       <div className='i_x' onClick={() => setOpenDetail(false)}>
    //         <img alt='' src={i_cancel} />
    //       </div>
    //     </div>
    //     <div className='item_info_detail'>
    //       <div className='item_info_img_wrap'>
    //         <img
    //           className='item_info_img'
    //           src={reqProduct && reqProduct.f_root}
    //           alt=''
    //         />
    //       </div>
    //       <div className='item_info_text_wrap1'>
    //         <div className='wrap1'>
    //           <p className='name'>
    //             {reqProduct && reqProduct.p_brand}&nbsp;
    //             {reqProduct && reqProduct.p_name}{' '}
    //           </p>
    //           <div className='wrap1_desc'>
    //             <p className='model'>모델명 :&nbsp;</p>
    //             <p>{reqProduct && reqProduct.p_model_code}</p>
    //           </div>
    //           <p className='desc_name2'>
    //             {reqProduct && reqProduct.p_standard.replace(/, /gi, '\n')}
    //           </p>

    //           <p className='option'>{reqProduct && reqProduct.p_etc}</p>
    //           <p className='price'>
    //             {reqProduct && reqProduct.p_unit}
    //           </p>
    //         </div>
    //         <div className='item_info_text_wrap'>
    //           <div className='wrap2'>
    //             <p>{reqProduct && reqProduct.p_comment}</p>
    //           </div>
    //         </div>
    //       </div>

    //       <div className='add_this pc_on' onClick={onClickAddCartList}>
    //         선택하기
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="item_info_wrap pop">
      <div className="pop_dim" onClick={() => setOpenDetail(false)}></div>
      <div className="item_info_container edit_position_pop">
        <div className="item_info_top">
          <h3>상품 상세보기</h3>
          <div className="i_x" onClick={() => setOpenDetail(false)}>
            <img alt="" src={i_cancel} />
          </div>
        </div>
        <div className="item_info_detail">
          <div className="item_info_img_wrap">
            <div className="item_info_img">
              <img
                src={reqProduct && reqProduct.f_root}
                alt={reqProduct && reqProduct.p_name}
              />
              <img
                src={i_magnifier_black}
                className="item_plus_icon"
                onClick={() => {
                  window.open(reqProduct.f_root);
                }}
                alt=""
              />
            </div>
          </div>
          <div className="item_info_text_wrap1">
            <div className="wrap1">
              <p className="name">
                <p className="goods_name">{reqProduct && reqProduct.p_name}</p>
                <p className="goods_brand">
                  {reqProduct && reqProduct.p_brand}
                </p>
              </p>
              {/* <div className="wrap1_desc">
                    <p className="model">모델명 :&nbsp;</p>
                    <p>{item.p_model_code}</p>
                  </div> */}
              <p className="desc_goods_title">상품 설명</p>
              <p className="desc_name2">
                {reqProduct && reqProduct.p_standard.replace(/, /gi, "\n")}
              </p>
              {/* <p className='price'>
                {wonComma(item.p_price)}원 | {item.p_unit}
              </p> */}
            </div>
          </div>
          <div className="item_info_text_wrap">
            <div className="wrap2">
              {reqProduct && reqProduct.p_comment && (
                <>
                  <p className="desc_char_title">특징</p>
                  <pre className="desc_char">
                    {reqProduct && reqProduct.p_comment}
                  </pre>
                </>
              )}
              {reqProduct && reqProduct.p_etc && (
                <>
                  <p className="desc_char_title">기타</p>
                  <p className="option">{reqProduct && reqProduct.p_etc}</p>
                </>
              )}
              {/* <p>원산지 : 한국　|　제조사 : 건창CD</p> */}
            </div>
          </div>
          <div className="add_this pc_on" onClick={onClickAddCartList}>
            선택하기
          </div>
          {reqProduct &&
            reqProduct?.attachFileBeanList?.map((data) => {
              return (
                <div
                  className="item_pdf_download"
                  onClick={() => {
                    window.open(data.f_root);
                  }}
                >
                  <span>{data.f_origname} 다운로드</span>
                  <img src={i_download} className="pdf_download_icon" />
                </div>
              );
            })}
        </div>

        {/* <div className="recom_package">
          <h3 className="recom_package_h3">패키지 추천</h3>
          <div className="recom_package_wrap">
            <div></div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check1" />
              <label for="package_check1" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check2" />
              <label for="package_check2" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check3" />
              <label for="package_check3" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check4" />
              <label for="package_check4" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check4" />
              <label for="package_check4" />
            </div>
            <img alt="" src={i_plus} className="s_plus" />
            <div>
              <input type="checkbox" id="package_check4" />
              <label for="package_check4" />
            </div>
          </div>
          <div className="btn_add_all">모두 담기</div>
        </div> */}
      </div>
    </div>
  );
}
