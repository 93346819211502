import React, { useEffect, useState } from "react";
import "../../../asset/css/pop.css";
import s from "../../../asset/css/calculatePricePop.module.css";
import useFee from "../../../page/main/customHooks/useFee";
import swal from "sweetalert";

export default function CalculatePricePop({ closeCalcModal, name, onConfirm }) {
  const fee = useFee(); // 현재 6%
  const feePercent = (100 + fee) / 100;
  const [noTaxPrice, setNoTaxPrice] = useState(0); // 수수료 없는 값
  const [taxPrice, setTaxPrice] = useState(0); // 수수료 있는 값
  const [feePrice, setFeePrice] = useState(0);
  const [feeAlert, setFeeAlert] = useState(false);
  const [noTaxCommaPrice, setNoTaxCommaPrice] = useState();
  const onChangePrice = (e) => {
    // 소수점을 먼저 제거
    let input = e.target.value.split(".")[0];
    // 숫자 이외의 모든 문자를 제거
    input = input.replace(/[^\d]/g, "");

    const onlyNumberValue = input ? input.replace(/,/g, "") : "";

    let finalValue = onlyNumberValue;
    if (finalValue.length > 9) {
      finalValue = finalValue.slice(0, 9);
    }

    calculatePrices(finalValue);

    if (!isNaN(finalValue)) {
      const formattedValue = Number(finalValue).toLocaleString("ko");
      setNoTaxCommaPrice(formattedValue); // 수수료 없는 값 - 보여주기에 이용되는
      setNoTaxPrice(Number(finalValue));
    }
  };

  const calculatePrices = (input) => {
    // 고객사 납품단가 구하기
    const taxValue = Math.floor(input * feePercent);
    // 거래 수수료 구하기
    const feeValue = taxValue - input;

    // value가 0에서 1이면 숫자 더 해주기
    if (0 < feeValue < 1) {
      setTaxPrice(taxValue + 1);
      setFeePrice(1);
      setFeeAlert(true);
    } else {
      setFeeAlert(false);
      setTaxPrice(taxValue);
      setFeePrice(feeValue);
    }
  };

  const validateConfirm = (taxPrice) => {
    if (taxPrice > 999999999) {
      swal(
        "",
        "고객사 납품단가가 1,000,000,000원을 초과할 수 없습니다.",
        "error"
      );
    } else {
      onConfirm(taxPrice);
    }
  };

  // useEffect(() => {
  //   // valueMapping();
  //   // setFeeAlert(true);
  // }, [fee]);

  // 정산단가 입력하면 거래수수료, 고객사 납품단가 계산 후
  // 확인 누르면 값 매핑하기
  // 인덱스? or 전체가져오기?

  return (
    <div className="pop">
      <div className="pop_dim" onClick={closeCalcModal}></div>
      <div className={s.calc_pop}>
        <div className={s.main_title}>단가입력계산기</div>

        <div className={s.container}>
          <div className={s.inner}>
            <div className={s.title}>품목명</div>
            <div className={s.contents}>{name || "-"}</div>
          </div>
          <div className={s.inner}>
            <div className={s.title}>나의 정산 단가</div>
            <div className={s.contents}>
              <input
                className={s.input}
                value={noTaxCommaPrice}
                onChange={onChangePrice}
              />
            </div>
          </div>
          <div className={s.inner}>
            <div className={s.title}>
              거래수수료
              <span className={s.sub_title}>(약 {fee}%)</span>
            </div>
            <div className={s.contents}>
              {feePrice.toLocaleString("ko")}원
              {feeAlert && (
                <div className={s.fee_alert}>
                  <span>최소수수료 1원이 부과됩니다.</span>
                  <span>거래수수료 6%를 초과할 수 있습니다.</span>
                </div>
              )}
            </div>
          </div>
          <div className={s.important}>
            <div className={s.title}>고객사 납품단가</div>
            <div className={s.contents}>{taxPrice.toLocaleString("ko")}원</div>
          </div>
          <div className={s.alert}>
            확인을 누르면 고객사 납품단가가 내역에 입력됩니다.
          </div>
          <div className={s.btn_wrap}>
            <div className={s.cancel} onClick={closeCalcModal}>
              취소
            </div>
            <div className={s.submit} onClick={() => validateConfirm(taxPrice)}>
              확인
            </div>
          </div>
        </div>
      </div>
      {/* {generating && <Loading msg={"로딩중 입니다."} />} */}
    </div>
  );
}
