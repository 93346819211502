import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//library
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
//component
import Mheader from "../../../component/common/Mheader";
// img
import i_cancel from "../../../asset/images/address-cancel.png";
import DaumPostApi from "../../../component/etc/DaumPostApi";
import {
  getCompany_InfoApi,
  getImg_pathApi,
  imgUpApi,
  Update_CompanyInfoApi,
} from "../../../api/auth";

import MyInfoPreviewPop from "../../../component/common/Pop/MyInfoPreviewPop";

import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";

export default function MyInfoEdit(props) {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  //공급사 수정
  const [conInfo, setConInfo] = useState(false);

  //계좌
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState();

  //미리보기 팝업
  const [previewPop, setPreviewPop] = useState(false);
  const [popImgPath, setPopImgPath] = useState(null);

  //이미지 받아오기
  const getImg_path = async () => {
    if (conInfo.c_f_logo) {
      const resLogo = await getImg_pathApi(conInfo.c_f_logo);
      if (resLogo.status === "success") {
        setSelectFile(resLogo.data.f_origname);
      }
    }

    if (conInfo.c_f_license) {
      const reslicense = await getImg_pathApi(conInfo.c_f_license);
      if (reslicense.status === "success") {
        setSelectFile2(reslicense.data.f_origname);
      }
    }

    if (conInfo.c_f_intro) {
      const resintro = await getImg_pathApi(conInfo.c_f_intro);
      if (resintro.status === "success") {
        setSelectFile3(resintro.data.f_origname);
      }
    }

    if (conInfo.c_f_bankbook) {
      const resbankbook = await getImg_pathApi(conInfo.c_f_bankbook);
      if (resbankbook.status === "success") {
        setSelectFile4(resbankbook.data.f_origname);
      }
    }

    if (conInfo.c_f_credit) {
      const rescredit = await getImg_pathApi(conInfo.c_f_credit);
      if (rescredit.status === "success") {
        setSelectFile5(rescredit.data.f_origname);
      }
    }

    if (conInfo.c_f_workspace) {
      const resworkspace = await getImg_pathApi(conInfo.c_f_workspace);
      if (resworkspace.status === "success") {
        setSelectFile6(resworkspace.data.f_origname);
      }
    }
  };

  //기존 정보 받아오기

  const getCompany_Info = async () => {
    const res = await getCompany_InfoApi(userData.c_no);
    if (res.status === "success") {
      setConInfo(res.data);
      setShowArea(res.data.areaList);
      setSendArea(res.data.areaList.map((item) => item.ca_addr));

      // setBankName(e.target.value);
      setBankName(res.data.c_account.split(":")[0]);
      setAccountNumber(res.data.c_account.split(":")[1]);

      if (!conInfo) {
        await getImg_path();
      }
    }
  };

  useEffect(async () => {
    await getCompany_Info();
  }, []);

  useEffect(() => {
    // if(!conInfo){
    getImg_path();
    // }
  }, [conInfo.c_f_logo]);

  //주소검색
  const [addrPopup, setAddrPopup] = useState(false);

  //로고 이미지 업로드
  const [logoImg, setLogoImage] = useState(null);
  const [selectFile, setSelectFile] = useState();

  const handleLogoChg = async (e) => {
    setSelectFile(e.target.files[0].name);
    setLogoImage(e.target.files[0]);
  };
  const handleLogoIgForm = async () => {
    let form = new FormData();
    form.append("file", logoImg);
    const response = await imgUpApi(form);

    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_logo: response.data.f_no });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };
  useEffect(() => {
    if (logoImg) {
      handleLogoIgForm();
    }
  }, [logoImg]);

  //사업자등록증 첨부
  const [selectFile2, setSelectFile2] = useState();
  const [licenseImg, setLicenseImag] = useState(null);
  const handleLicenseChg = (e) => {
    setSelectFile2(e.target.files[0].name);
    setLicenseImag(e.target.files[0]);
  };

  const handleLicenseForm = async () => {
    let form = new FormData();
    form.append("file", licenseImg);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_license: response.data.f_no });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };

  useEffect(() => {
    if (licenseImg) {
      handleLicenseForm();
    }
  }, [licenseImg]);

  //사업자도장파일번호 >> 회사소개서
  const [selectFile3, setSelectFile3] = useState();
  const [introFile, setIntroFile] = useState(null);
  const handleIntroChg = (e) => {
    setSelectFile3(e.target.files[0].name);
    setIntroFile(e.target.files[0]);
  };
  const handleIntroForm = async () => {
    let form = new FormData();
    form.append("file", introFile);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_intro: response.data.f_no });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };

  useEffect(() => {
    if (introFile) {
      handleIntroForm();
    }
  }, [introFile]);

  //통장사본파일번호 첨부
  const [selectFile4, setSelectFile4] = useState();
  const [bankbookImg, setbankbookImg] = useState(null);
  const handleBankbookChg = (e) => {
    setSelectFile4(e.target.files[0].name);
    setbankbookImg(e.target.files[0]);
  };
  const handleBankbookForm = async () => {
    let form = new FormData();
    form.append("file", bankbookImg);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_bankbook: response.data.f_no });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };

  useEffect(() => {
    if (bankbookImg) {
      handleBankbookForm();
    }
  }, [bankbookImg]);

  //신용평가서 첨부
  const [selectFile5, setSelectFile5] = useState();
  const [creditImg, setCreditImg] = useState(null);
  const handlecreditChg = (e) => {
    setSelectFile5(e.target.files[0].name);
    setCreditImg(e.target.files[0]);
  };

  const handleCreditImgForm = async () => {
    let form = new FormData();
    form.append("file", creditImg);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_credit: response.data.f_no });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };
  useEffect(() => {
    if (creditImg) {
      handleCreditImgForm();
    }
  }, [creditImg]);

  //사업장 및 대지사진
  const [selectFile6, setSelectFile6] = useState();
  const [workspaceImg, setWorkspaceImg] = useState(null);
  const handleWorkspaceChg = (e) => {
    setSelectFile6(e.target.files[0].name);
    setWorkspaceImg(e.target.files[0]);
  };
  const handleWorkspaceForm = async () => {
    let form = new FormData();
    form.append("file", workspaceImg);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_workspace: response.data.f_no });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };

  useEffect(() => {
    if (workspaceImg) {
      handleWorkspaceForm();
    }
  }, [workspaceImg]);

  //납품가능한 지역
  const areaGuList = [
    {
      시: {},
      서울: {
        서울: [
          "구",
          "강남구",
          "강동구",
          "강북구",
          "강서구",
          "관악구",
          "광진구",
          "구로구",
          "금천구",
          "노원구",
          "도봉구",
          "동대문구",
          "동작구",
          "마포구",
          "서대문구",
          "서초구",
          "성동구",
          "성북구",
          "송파구",
          "양천구",
          "영등포구",
          "용산구",
          "은평구",
          "종로구",
          "중구",
          "중랑구",
        ],
      },
      경기: {
        경기: [
          "구",
          "수원시 장안구",
          "수원시 권선구",
          "수원시 팔달구",
          "수원시 영통구",
          "성남시 수정구",
          "성남시 중원구",
          "성남시 분당구",
          "의정부시",
          "안양시 만안구",
          "안양시 동안구",
          "부천시",
          "광명시",
          "평택시",
          "동두천시",
          "안산시 상록구",
          "안산시 단원구",
          "고양시 덕양구",
          "고양시 일산동구",
          "고양시 일산서구",
          "과천시",
          "구리시",
          "남양주시",
          "오산시",
          "시흥시",
          "군포시",
          "의왕시",
          "하남시",
          "용인시 처인구",
          "용인시 기흥구",
          "용인시 수지구",
          "파주시",
          "이천시",
          "안성시",
          "김포시",
          "화성시",
          "광주시",
          "양주시",
          "포천시",
          "여주시",
          "연천군",
          "가평군",
          "양평군",
        ],
      },
      인천: {
        인천: [
          "구",
          "계양구",
          "미추홀구",
          "남동구",
          "동구",
          "부평구",
          "서구",
          "연수구",
          "중구",
          "강화군",
          "옹진군",
        ],
      },
      강원: {
        강원: [
          "구",
          "춘천시",
          "원주시",
          "강릉시",
          "동해시",
          "태백시",
          "속초시",
          "삼척시",
          "홍천군",
          "횡성군",
          "영월군",
          "평창군",
          "정선군",
          "철원군",
          "화천군",
          "양구군",
          "인제군",
          "고성군",
          "양양군",
        ],
      },
      충북: {
        충북: [
          "구",
          "청주시 상당구",
          "청주시 서원구",
          "청주시 흥덕구",
          "청주시 청원구",
          "충주시",
          "제천시",
          "보은군",
          "옥천군",
          "영동군",
          "증평군",
          "진천군",
          "괴산군",
          "음성군",
          "단양군",
        ],
      },
      충남: {
        충남: [
          "구",
          "천안시 동남구",
          "천안시 서북구",
          "공주시",
          "보령시",
          "아산시",
          "서산시",
          "논산시",
          "계룡시",
          "당진시",
          "금산군",
          "부여군",
          "서천군",
          "청양군",
          "홍성군",
          "예산군",
          "태안군",
        ],
      },
      대전: {
        대전: ["구", "대덕구", "동구", "서구", "유성구", "중구"],
      },
      세종특별자치시: {
        세종특별자치시: ["구", "세종특별자치시"],
      },
      전북: {
        전북: [
          "구",
          "전주시 완산구",
          "전주시 덕진구",
          "군산시",
          "익산시",
          "정읍시",
          "남원시",
          "김제시",
          "완주군",
          "진안군",
          "무주군",
          "장수군",
          "임실군",
          "순창군",
          "고창군",
          "부안군",
        ],
      },
      전남: {
        전남: [
          "구",
          "목포시",
          "여수시",
          "순천시",
          "나주시",
          "광양시",
          "담양군",
          "곡성군",
          "구례군",
          "고흥군",
          "보성군",
          "화순군",
          "장흥군",
          "강진군",
          "해남군",
          "영암군",
          "무안군",
          "함평군",
          "영광군",
          "장성군",
          "완도군",
          "진도군",
          "신안군",
        ],
      },
      광주: {
        광주: ["구", "광산구", "남구", "동구", "북구", "서구"],
      },
      경북: {
        경북: [
          "구",
          "포항시 남구",
          "포항시 북구",
          "경주시",
          "김천시",
          "안동시",
          "구미시",
          "영주시",
          "영천시",
          "상주시",
          "문경시",
          "경산시",
          "군위군",
          "의성군",
          "청송군",
          "영양군",
          "영덕군",
          "청도군",
          "고령군",
          "성주군",
          "칠곡군",
          "예천군",
          "봉화군",
          "울진군",
          "울릉군",
        ],
      },
      경남: {
        경남: [
          "구",
          "창원시 의창구",
          "창원시 성산구",
          "창원시 마산합포구",
          "창원시 마산회원구",
          "창원시 진해구",
          "진주시",
          "통영시",
          "사천시",
          "김해시",
          "밀양시",
          "거제시",
          "양산시",
          "의령군",
          "함안군",
          "창녕군",
          "고성군",
          "남해군",
          "하동군",
          "산청군",
          "함양군",
          "거창군",
          "합천군",
        ],
      },
      부산: {
        부산: [
          "구",
          "강서구",
          "금정구",
          "남구",
          "동구",
          "동래구",
          "부산진구",
          "북구",
          "사상구",
          "사하구",
          "서구",
          "수영구",
          "연제구",
          "영도구",
          "중구",
          "해운대구",
          "기장군",
        ],
      },
      대구: {
        대구: [
          "구",
          "남구",
          "달서구",
          "동구",
          "북구",
          "서구",
          "수성구",
          "중구",
          "달성군",
        ],
      },
      울산: {
        울산: ["구", "남구", "동구", "북구", "중구", "울주군"],
      },
      제주특별자치도: {
        제주특별자치도: ["구", "서귀포시", "제주시"],
      },
    },
  ];

  const bankList = [
    "NH농협",
    "KB국민은행",
    "카카오뱅크",
    "신한은행",
    "우리은행",
    "IBK기업은행",
    "하나은행",
    "새마을금고",
    "대구은행",
    "부산은행",
    "케이뱅크",
    "우체국",
    "신협",
    "SC제일은행",
    "경남은행",
    "수협은행",
    "광주은행",
    "전북은행",
    "토스뱅크",
    "상호저축은행",
    "중국공상은행",
    "JP모간체이스은행",
    "BNP파리바",
    "씨티은행",
    "제주은행",
    "KDB산업은행",
    "SBI저축은행",
    "산림조합중앙회",
    "BOA",
    "HSBC은행",
    "중국은행",
    "도이치은행",
    "중국건설은행",
  ];
  const [selectedAreaSi, setSelectedAreaSi] = useState("");
  const handleSelectSi = (e) => {
    setSelectedAreaSi(e.target.value);
  };
  //구
  const [selectedAreaGu, setSelectedAreaGu] = useState("");
  const [stateArea, setStateArea] = useState([]);
  const [cityArea, setCityArea] = useState([]);
  const [cityOpen, setCityOpen] = useState(true);
  const handleSelectGu = (e) => {
    setSelectedAreaGu(e.target.value);
  };
  //구 주소받기
  const cityMap = () => {
    areaGuList.map((item, index) => {
      const stateKeys = Object.keys(item);
      setStateArea(stateKeys);
      if (selectedAreaSi) {
        const cityKeys = Object.values(item[selectedAreaSi]);
        cityKeys.map((itm, i) => {
          setCityArea(itm);
        });
        setCityOpen(false);
        return cityKeys;
      } else {
        setCityOpen(true);
      }
    });
  };
  useEffect(() => {
    cityMap();
  }, [selectedAreaSi]);

  //납품 가능한 지역 어레이 추가
  const [showArea, setShowArea] = useState([]);

  const [sendArea, setSendArea] = useState([]);
  const onClinkAddArea = () => {
    const areaData = selectedAreaSi + " " + selectedAreaGu;
    setShowArea([...showArea, { ca_addr: areaData }]);
    setSendArea([...sendArea, areaData]);
    //중복 validation
    showArea.map((itm) => {
      if (itm.ca_addr !== areaData) {
        setShowArea([...showArea, { ca_addr: areaData }]);
        setSendArea([...sendArea, areaData]);
      }
      if (itm.ca_addr === areaData) {
        swal("납품 가능한 지역이 중복 되었습니다.", "", "warning");
        setShowArea(showArea);
        setSendArea(sendArea);
      }
    });
  };

  // 납품가능지역 변경
  const chgAreaList = () => {
    const str = sendArea.join();
    setConInfo({ ...conInfo, addrList: str });
  };
  useEffect(() => {
    chgAreaList();
  }, [sendArea]);

  //popup function
  const previewPopFnc = async (imgUrl) => {
    // if(previewPop === false){
    setPopImgPath(null);
    // }
    const res = await getImg_pathApi(imgUrl);
    if (res.status === "success") {
      setPopImgPath(res.data);
    }
    setPreviewPop(true);
  };

  //정보수정클릭이벤트

  const formData = {
    c_no: conInfo.c_no,
    c_name: conInfo.c_name,
    c_bsname: conInfo.c_bsname,
    c_div: conInfo.c_div,
    c_num: conInfo.c_num,
    c_ceo: conInfo.c_ceo,
    c_sec: conInfo.c_sec,
    c_bc: conInfo.c_bc,
    c_phone: conInfo.c_phone,
    c_zip: conInfo.c_zip,
    c_addr1: conInfo.c_addr1,
    c_addr2: conInfo.c_addr2,
    c_lat: conInfo.c_lat,
    c_lng: conInfo.c_lng,
    c_f_logo: conInfo.c_f_logo,
    c_f_license: conInfo.c_f_license,
    c_f_stamp: conInfo.c_f_stamp,
    c_account: conInfo.c_account,
    c_f_bankbook: conInfo.c_f_bankbook,
    c_f_credit: conInfo.c_f_credit,
    c_f_workspace: conInfo.c_f_workspace, //사업장 및 대지사진
    c_f_intro: conInfo.c_f_intro, //회사소개서
    c_type: conInfo.c_type,
    areaStr: conInfo.addrList,
  };

  const onClinck_Update_CompanyInfo = async () => {
    if (formData.c_no === "") {
      swal("공급사명을 입력해주세요", "", "warning");
      return;
    }
    if (formData.c_name === "") {
      swal("사업자명을 입력해주세요", "", "warning");
      return;
    }
    if (formData.c_bsname === "") {
      swal("사업자 구분을 선택해주세요", "", "warning");
      return;
    }
    if (formData.c_no === "") {
      swal("공급사명을 입력해주세요", "", "warning");
      return;
    }
    if (formData.c_num === "") {
      swal("사업자등록번호를 입력해주세요", "", "warning");
      return;
    }
    if (formData.c_ceo === "") {
      swal("대표자명을 입력해주세요", "", "warning");
      return;
    }
    if (formData.c_sec === "") {
      swal("업종을 입력해주세요", "", "warning");
      return;
    }
    if (formData.c_bc === "") {
      swal("업태를 입력해주세요", "", "warning");
      return;
    }
    if (formData.c_addr1 === "") {
      swal("주소를 입력해주세요", "", "warning");
      return;
    }
    if (formData.c_f_logo === "") {
      swal("로고 이미지를 첨부해주세요", "", "warning");
      return;
    }
    if (formData.c_f_license === "") {
      swal("사업자등록증을 첨부해주세요", "", "warning");
      return;
    }
    if (formData.c_no === "") {
      swal("공급사명을 입력해 주세요", "", "warning");
      return;
    }
    if (formData.c_account === "" || bankName === "" || accountNumber === "") {
      swal("정산받을 통장 번호를 입력해주세요.", "", "warning");
      return;
    }
    if (formData.c_f_bankbook === "") {
      swal("정산받을 통장사본을 첨부해주세요.", "", "warning");
      return;
    }
    if (formData.c_f_intro === "") {
      swal("회사소개서를 첨부해주세요.", "", "warning");
      return;
    }
    if (formData.areaStr === "") {
      swal("납품가능한 지역을 선택해주세요", "", "warning");
      return;
    }
    if (formData.c_type === "") {
      swal("공급업체분류를 선택해주세요.", "", "warning");
      return;
    }
    if (formData.c_phone === "") {
      swal("대표번호를 입력해주세요.", "", "warning");
      return;
    }
    const res = await Update_CompanyInfoApi(formData);
    if (res.status === "success") {
      navigate("/my_page/my_info/edit_completed");
    } else {
      swal("정보수정이 실패하였습니다.", "", "error");
    }
  };
  return (
    <div className="myinfo_edit_wrap">
      <Mheader title="공급사정보 수정" />
      <div className="myinfo_edit_container wd">
        <div className="top_pagenation">
          <h2>공급사정보 수정</h2>
          <div className="top_pagenation_num">
            <p className="active">1</p>
            <p>2</p>
          </div>
        </div>
        <div className="myinfo_edit_info">
          <div className="myinfo_edit_content">
            <p>
              * 수정된 정보는 공새로 승인 이후 반영됩니다.(영업일 기준 약 1~2일
              소요)
            </p>
            <dl className="construction_tab2">
              <dt>공급사명</dt>
              <dd>
                <input
                  type="text"
                  placeholder="공급사명을 입력해주세요."
                  defaultValue={conInfo.c_name}
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_name: e.target.value });
                  }}
                />
              </dd>
              <dt>사업자명</dt>
              <dd>
                <input
                  type="text"
                  placeholder="사업자명을 입력해주세요."
                  defaultValue={conInfo.c_bsname}
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_bsname: e.target.value });
                  }}
                />
              </dd>
              <dt>사업자구분</dt>
              <dd>
                <select
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_div: e.target.value });
                  }}
                >
                  <option value="" selected disabled hidden>
                    {conInfo.c_div === "P" ? "개인" : "법인"}
                  </option>
                  <option value="P">개인</option>
                  <option value="C">법인</option>
                </select>
              </dd>
              <dt>사업자등록번호</dt>
              <dd>
                <input
                  defaultValue={conInfo.c_num}
                  type="text"
                  placeholder="사업자등록번호를 입력해주세요."
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_num: e.target.value });
                  }}
                />
              </dd>
              <dt>대표자명</dt>
              <dd>
                <input
                  type="text"
                  placeholder="대표자명을 입력해주세요."
                  defaultValue={conInfo.c_ceo}
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_ceo: e.target.value });
                  }}
                />
              </dd>
              <dt>대표번호</dt>
              <dd>
                <input
                  type="text"
                  placeholder="대표번호를 입력해주세요.(- 을 제외하고 입력)"
                  defaultValue={conInfo.c_phone}
                  maxLength={11}
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_phone: e.target.value });
                  }}
                />
              </dd>
              <dt>업종</dt>
              <dd>
                <input
                  type="text"
                  placeholder="업종을 입력해주세요"
                  defaultValue={conInfo.c_sec}
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_sec: e.target.value });
                  }}
                />
              </dd>
              <dt>업태</dt>
              <dd>
                <input
                  type="text"
                  placeholder="업태를 입력해주세요"
                  defaultValue={conInfo.c_bc}
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_bc: e.target.value });
                  }}
                />
              </dd>
              <dt>주소</dt>
              <dd className="address_search">
                <input
                  defaultValue={conInfo.c_addr1}
                  type="text"
                  readOnly
                  id="my_address"
                />
                <label
                  htmlFor="my_address"
                  onClick={() => {
                    setAddrPopup(!addrPopup);
                  }}
                >
                  검색
                </label>
                <input
                  defaultValue={conInfo.c_addr2}
                  type="text"
                  placeholder="상세주소를 입력해주세요."
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_addr2: e.target.value });
                  }}
                />
                {addrPopup ? (
                  <DaumPostApi
                    conInfo={conInfo}
                    setConInfo={setConInfo}
                    setAddrPopup={setAddrPopup}
                    addrPopup={addrPopup}
                  />
                ) : null}
              </dd>
              <dt>로고이미지</dt>
              <dd className="img_upload">
                <input
                  type="file"
                  id="logo_upload"
                  accept="image/*"
                  onChange={handleLogoChg}
                />
                <p>{selectFile}</p>
                <a
                  className="preview_btn"
                  onClick={() => {
                    previewPopFnc(conInfo?.c_f_logo);
                  }}
                >
                  미리보기
                </a>
                <label htmlFor="logo_upload">찾아보기</label>
              </dd>
              <dt>사업자등록증</dt>
              <dd className="img_upload upload2">
                <input
                  type="file"
                  id="logo_upload2"
                  accept="image/*"
                  onChange={handleLicenseChg}
                />
                <p>{selectFile2}</p>
                <a
                  className="preview_btn"
                  onClick={() => {
                    previewPopFnc(conInfo?.c_f_license);
                  }}
                >
                  미리보기
                </a>
                <label htmlFor="logo_upload2">찾아보기</label>
              </dd>
              {/* <dt>사업자 도장 이미지</dt>
              <dd className='img_upload upload3'>
                <input
                  type='file'
                  id='logo_upload3'
                  accept='image/*'
                  onChange={handleStampChg}
                />
                <p>{selectFile3}</p>
                <label htmlFor='logo_upload3'>찾아보기</label>
              </dd> */}
              <dt>신용평가서 첨부</dt>
              <dd className="img_upload upload5">
                <input
                  type="file"
                  id="logo_upload5"
                  onChange={handlecreditChg}
                />
                <p>{selectFile5}</p>
                <a
                  className="preview_btn"
                  onClick={() => {
                    previewPopFnc(conInfo?.c_f_credit);
                  }}
                >
                  미리보기
                </a>
                <label htmlFor="logo_upload5">찾아보기</label>
              </dd>
              <dt>정산받을 통장 사본 이미지</dt>
              <dd className="img_upload upload4">
                <input
                  type="file"
                  id="logo_upload4"
                  accept="image/*"
                  onChange={handleBankbookChg}
                />
                <p>{selectFile4}</p>
                <a
                  className="preview_btn"
                  onClick={() => {
                    previewPopFnc(conInfo?.c_f_bankbook);
                  }}
                >
                  미리보기
                </a>
                <label htmlFor="logo_upload4">찾아보기</label>
              </dd>

              <dt>회사소개서</dt>
              <dd className="img_upload upload3">
                <input
                  type="file"
                  id="logo_upload3"
                  onChange={handleIntroChg}
                />
                <p>{selectFile3}</p>
                <a
                  className="preview_btn"
                  onClick={() => {
                    previewPopFnc(conInfo?.c_f_intro);
                  }}
                >
                  미리보기
                </a>
                <label htmlFor="logo_upload3">찾아보기</label>
              </dd>

              <dt>사업장 및 토지 사진</dt>
              <dd className="img_upload upload6">
                <input
                  type="file"
                  id="logo_upload6"
                  accept="image/*"
                  onChange={handleWorkspaceChg}
                />
                <p>{selectFile6}</p>
                <a
                  className="preview_btn"
                  onClick={() => {
                    previewPopFnc(conInfo?.c_f_workspace);
                  }}
                >
                  미리보기
                </a>
                <label htmlFor="logo_upload6">찾아보기</label>
              </dd>

              <dt>정산받을 통장 계좌</dt>
              <dd>
                <div className="newinfo_bank">
                  <select
                    onChange={(e) => {
                      setBankName(e.target.value);
                      setConInfo({
                        ...conInfo,
                        c_account:
                          e.target.value +
                          ":" +
                          conInfo.c_account.split(":")[1],
                      });
                    }}
                  >
                    <option value="" selected>
                      {conInfo.c_account
                        ? conInfo.c_account.split(":", 1)
                        : null}
                      {/* {conInfo.c_account.split(':',1)} */}
                    </option>
                    {/* <option value={conInfo?.c_account} selected>
                    {conInfo?.c_account}
                  </option> */}
                    {bankList.map((data, i) => {
                      return <option value={data}>{data}</option>;
                    })}
                  </select>
                  <input
                    type="text"
                    placeholder="계좌번호를 입력해주세요( - 제외)"
                    value={
                      conInfo.c_account ? conInfo.c_account.split(":")[1] : null
                    }
                    onChange={(e) => {
                      setAccountNumber(e.target.value);
                      setConInfo({
                        ...conInfo,
                        c_account:
                          conInfo.c_account.split(":")[0] +
                          ":" +
                          e.target.value,
                      });
                    }}
                  />
                </div>
              </dd>
              <dt>납품 가능한 지역</dt>
              <dd>
                <div className="newinfo_address flex">
                  <select
                    placeholder="시"
                    onChange={handleSelectSi}
                    value={selectedAreaSi}
                  >
                    {stateArea.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>

                  {cityOpen ? (
                    <select placeholder="구">
                      <option>구</option>
                    </select>
                  ) : (
                    <select
                      placeholder="시"
                      onChange={handleSelectGu}
                      value={selectedAreaGu}
                    >
                      {cityArea.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  )}

                  <div class="newinfo_address_add_btn">
                    <p onClick={onClinkAddArea}>추가</p>
                  </div>
                </div>
                <div className="newinfo_area">
                  {showArea.map((item, index) => (
                    <p key={index} value={item}>
                      {item.ca_addr}
                      <span>
                        <img
                          onClick={() => {
                            const delData = showArea.filter(
                              (data) => data.ca_addr !== item.ca_addr
                            );
                            const delSendData = sendArea.filter(
                              (data) => data !== item.ca_addr
                            );
                            setShowArea(delData);
                            setSendArea(delSendData);
                          }}
                          src={i_cancel}
                          alt=""
                        />
                      </span>
                    </p>
                  ))}
                </div>
              </dd>
              <dt>공급업체 분류</dt>
              <dd>
                <select
                  onChange={(e) => {
                    setConInfo({ ...conInfo, c_type: e.target.value });
                  }}
                >
                  <option value="" selected disabled hidden>
                    {conInfo.c_type === "건설"
                      ? "건설"
                      : "토목"
                      ? "토목"
                      : "기타"
                      ? "기타"
                      : null}
                  </option>
                  <option value="건설">건설</option>
                  <option value="토목">토목</option>
                  <option value="기타">기타</option>
                </select>
              </dd>
            </dl>
          </div>
        </div>
        <div className="btn_box">
          <div className="prev">
            <Link to="/my_page/my_info">이전</Link>
          </div>
          <div className="next" onClick={onClinck_Update_CompanyInfo}>
            정보수정
          </div>
        </div>
      </div>
      {previewPop && (
        <MyInfoPreviewPop
          setPreviewPop={setPreviewPop}
          popImgPath={popImgPath}
        />
      )}
    </div>
  );
}
