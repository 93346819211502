import styled from "@emotion/styled";

export const Wrapper = styled.div``;

// =========
// DashBoard
// =========
export const DashboardContainer = styled.div`
  width: 1440px;
  margin: 0 auto;
  padding: 60px 30px 150px 230px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5rem 5.5rem 0 5.5rem;
  }
`;

export const DashBoardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  margin-bottom: 90px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-bottom: 0;
  }
`;

// ==================
// DashBoard - header
// ==================
export const ContentHeader = styled.div`
  width: 100%;
  height: 50px;
  background: #f2f5f9;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  @media (max-width: 1080px) {
    height: 10rem;
    padding: 0 2.5rem;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  padding-right: 15px;
  @media (max-width: 1080px) {
    font-size: 4.5rem;
  }
`;

export const SelectedDate = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  padding-left: 15px;
`;

// ================
// Dashboard - body
// ================
export const ContentBody = styled.div`
  width: 100%;
  height: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1080px) {
    width: 100%;
    height: 30rem;
    justify-content: space-around;
  }

  button {
    width: 130px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
    border: 2px solid rgba(0, 40, 99, 0.8);
    border-radius: 4px;
    font-weight: 700;
    font-size: 18px;
    color: #254574;
    transition: all 0.2s;
    &:hover {
      background: #254574;
      color: #ffffff;
    }
    @media (max-width: 1080px) {
      width: 80%;
      height: 10rem;
      font-size: 4.5rem;
    }
  }
`;

export const DivideLine = styled.div`
  opacity: 0.3;
  border: 1px solid #000000;
  height: 80px;
  margin-left: 60px;
`;

export const EstimateStateWrap = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 60px;
  @media (max-width: 1080px) {
    width: 80%;
    margin-left: 0;
  }
`;

export const EstimateState = styled.div`
  height: 40px;
  font-weight: 700;
  font-size: 18px;
  @media (max-width: 1080px) {
    height: 10rem;
    font-size: 4rem;
  }
`;

export const EstimateAliveBtn = styled.button`
  background: ${(props) => {
    if (props.sortPageList?.pageSort === "A") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.sortPageList?.pageSort === "A") return "#ffffff";
  }} !important;
`;

export const EstimateChooseBtn = styled.button`
  background: ${(props) => {
    if (props.sortPageList?.pageSort === "CHOOSE") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.sortPageList?.pageSort === "CHOOSE") return "#ffffff";
  }} !important;
`;

export const EstimateEndBtn = styled.button`
  background: ${(props) => {
    if (props.sortPageList?.pageSort === "E") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.sortPageList?.pageSort === "E") return "#ffffff";
  }} !important;
`;

export const EstimateAllBtn = styled.button`
  background: ${(props) => {
    if (props.sortPageList?.pageSort === "ALL") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.sortPageList?.pageSort === "ALL") return "#ffffff";
  }} !important;
`;

// ==================
// Dashboard - footer
// ==================
export const ContentFooter = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f2f5f9;
  border-top: 1px solid #dddddd;
  span {
    color: #ff6600;
  }
  @media (max-width: 1080px) {
    height: 14rem;
  }
`;

export const CancelNumBtn = styled.button`
  width: 130px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
  border-radius: 4px;
  font-weight: 700;
  font-size: 16px;
  color: #254574;
  margin-left: 30px;
  transition: all 0.2s;
  &:hover {
    background: #254574;
    color: #ffffff;
  }
  background: ${(props) => {
    if (props.sortPageList?.pageSort === "C") return "#254574";
  }} !important;
  color: ${(props) => {
    if (props.sortPageList?.pageSort === "C") return "#ffffff";
  }} !important;
  @media (max-width: 1080px) {
    width: 20rem;
    height: 8rem;
    font-size: 3.5rem;
    margin-left: 2.5rem;
  }
`;

// ============
// EstimateList
// ============
export const EstimateListWrapper = styled.div`
  width: 100%;
  height: 215px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  margin-bottom: 40px;
`;

// =====================
// EstimateList - header
// =====================
export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid #dddddd;
  background: #f2f5f9;
`;
export const EstimateNo = styled.div`
  width: 32%;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
`;
export const SiteName = styled.div`
  width: 32%;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
`;
export const EstimateStep = styled.div`
  width: 36%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  div {
    position: relative;
  }

  div:not(:first-of-type)::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: -30px;
    border-bottom: 30px solid #ffffff;
    border-left: 30px solid transparent;
    z-index: 10;
  }

  div:not(:last-of-type)::before {
    content: "";
    position: absolute;
    right: -30px;
    border-top: 30px solid #ffffff;
    border-right: 30px solid transparent;
    z-index: 10;
  }

  div:nth-child(1) {
    border-radius: 5px 0 0 5px;
    border-bottom: ${(props) => {
      if (props.el.bid_state === "ALIVE") return "30px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.bid_state === "ALIVE") return "#ffffff";
    }};
  }

  div:nth-child(1)::before {
    border-top: ${(props) => {
      if (props.el.bid_state === "ALIVE") return "30px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.bid_state === "ALIVE") return "#ffffff";
    }};
  }

  div:nth-child(2) {
    background: ${(props) => {
      if (props.el.bid_state === "CHOOSE") return "#254574";
    }};
    color: ${(props) => {
      if (props.el.bid_state === "CHOOSE") return "#ffffff";
    }};
  }

  div:nth-child(2)::before {
    border-top: ${(props) => {
      if (props.el.bid_state === "CHOOSE") return "30px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.bid_state === "CHOOSE") return "#ffffff";
    }};
  }

  div:nth-child(2)::after {
    border-bottom: ${(props) => {
      if (props.el.bid_state === "CHOOSE") return "30px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.bid_state === "CHOOSE") return "#ffffff";
    }};
  }

  div:nth-child(3) {
    border-radius: 0 5px 5px 0;
    background: ${(props) => {
      if (props.el.bid_state === "END") return "#254574";
    }};
    color: ${(props) => {
      if (props.el.bid_state === "END") return "#ffffff";
    }};
  }

  div:nth-child(3)::after {
    border-bottom: ${(props) => {
      if (props.el.bid_state === "END") return "30px solid #254574";
    }};
    color: ${(props) => {
      if (props.el.bid_state === "END") return "#ffffff";
    }};
  }
`;
export const Step = styled.div`
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: 600;
  font-size: 16px;
  margin-left: 35px;
  background: #ffffff;
  color: #333333;
  filter: drop-shadow(1.5px 1.5px 1.5px rgba(0, 0, 0, 0.25));
`;

// ===================
// EstimateList - body
// ===================
export const ListBody = styled.div`
  width: 100%;
  height: 175px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
`;

export const ListInfo = styled.div`
  width: 60%;
`;

export const Badge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  background: ${(props) =>
    (props.el.bid_state === "ALIVE" && "#107C41") ||
    (props.el.bid_state === "CHOOSE" && "#005BAA") ||
    (props.el.bid_state === "END" && "#005BAA") ||
    (props.el.bid_state === "CANCEL" && "#9C9EA1") ||
    (props.el.bid_state === "FAIL" && "#9C9EA1")};
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
`;

export const ReBadge = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  background: #254574;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-left: 10px;
  margin-bottom: 10px;
  background: #9c9ea1;
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-left: 1rem;
    margin-bottom: 1.5rem;
  }
`;
// props의 형태가 달라서 따로 뺐습니다.
export const BadgeDetail = styled.div`
  display: inline-block;
  padding: 0 12px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
  background: ${(props) =>
    (props.el.bid.state === "ALIVE" && "#107C41") ||
    (props.el.bid.state === "CHOOSE" && "#005BAA") ||
    (props.el.bid.state === "END" && "#005BAA") ||
    (props.el.bid.state === "CANCEL" && "#9C9EA1") ||
    (props.el.bid.state === "FAIL" && "#9C9EA1")};
  @media (max-width: 1080px) {
    height: 5.3rem;
    line-height: 5.3rem;
    padding: 0 2rem;
    border-radius: 1rem;
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const CompanyName = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  margin-right: 20px;
`;

export const ProductName = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  margin-left: 20px;
`;

export const DetailInfo = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #999999;
`;

export const BidType = styled.span`
  display: inline-block;
  width: 250px;
  margin-right: 50px;
  margin-bottom: 10px;
`;

export const BidDate = styled.span`
  display: inline-block;
  width: 300px;
`;

export const BidCompany = styled.span`
  display: inline-block;
  width: 250px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BidPriceBtn = styled.button`
  width: 145px;
  height: 40px;
  border: 2px solid rgba(0, 40, 99, 0.8);
  filter: drop-shadow(0px 4px 3px rgba(67, 67, 87, 0.31));
  border-radius: 4px;
  background: #ffffff;
  color: #313e57;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
  transition: all 0.2s;
  &:hover {
    background: #313e57;
    color: #ffffff;
  }
`;

export const MyEstimateBtn = styled.button`
  width: 145px;
  height: 40px;
  border: 2px solid rgba(0, 40, 99, 0.8);
  filter: drop-shadow(0px 4px 3px rgba(67, 67, 87, 0.31));
  border-radius: 4px;
  background: #ffffff;
  color: #313e57;
  font-weight: 700;
  font-size: 16px;
  transition: all 0.2s;
  &:hover {
    background: #313e57;
    color: #ffffff;
  }
`;

export const AlignWrap = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1080px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5rem 5.5rem 0 5.5rem;
  }
`;

export const DateRangeSelect = styled.select`
  width: 140px;
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  background-color: #fff;
`;

export const DateWrap = styled.div`
  width: 140px !important;
  margin-right: 10px;
  @media (max-width: 1080px) {
    width: 48% !important;
    margin-right: 0 !important;
  }
  .react-datepicker__input-container input {
    height: 35px !important;
    background: #fff !important;
    @media (max-width: 1080px) {
      height: 8rem !important;
    }
  }
  .react-datepicker__input-container {
    &::after {
      z-index: 1;
    }
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    height: 30px !important;
    line-height: 30px !important;
    background-color: #fff !important;
    margin-top: 10px !important;
  }
`;

export const SearchResultWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 1080px) {
    padding: 0 5.5rem;
    margin-top: 5rem;
    margin-bottom: 0;
    justify-content: flex-end;
  }
`;
export const SearchResult = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  @media (max-width: 1080px) {
    font-weight: 400;
    font-size: 3.5rem;
  }
  > span {
    color: #ff6600;
  }
`;
