import React, { useState, useEffect } from "react";
//library
import { useLocation, useNavigate } from "react-router";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/newinfo.css";
//etc
import { emailCheckApi, joinApiStep1, socialJoin } from "../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function NewInfo() {
  const navigate = useNavigate();
  const location = useLocation();

  //직접입력선택
  const [isSelect, setIsSelect] = useState(false);

  //social check
  const { socialType, socialValue } = location.state;
  const isSocial =
    socialType && socialValue && socialType !== "" && socialValue !== ""
      ? true
      : false;

  const [joinInfo, setJoinInfo] = useState({
    u_no: location.state.u_no,
    u_id: "",
    u_pw: "",
    u_pwc: "",
    u_name: location.state.u_name,
    u_dept: "",
    u_rank: "",
    u_root: "C",
  });

  const [idStatus, setIdStatus] = useState(true);
  const [idAlert, setIdAlert] = useState("");

  //회원가입 step1 api
  const userSignUp = async () => {
    let response = false;
    if (!isSocial) {
      response = await joinApiStep1(
        joinInfo.u_no,
        joinInfo.u_id,
        joinInfo.u_pw,
        joinInfo.u_dept,
        joinInfo.u_rank,
        joinInfo.u_root
      );
    } else {
      response = await socialJoin(
        joinInfo.u_no,
        joinInfo.u_id,
        joinInfo.u_pw,
        joinInfo.u_dept,
        joinInfo.u_rank,
        joinInfo.u_root,
        socialType,
        socialValue
      );
    }

    if (response.status === "success") {
      navigate("/join/newinfo2", {
        state: { u_no: location.state.u_no },
        replace: true,
      });
    } else {
      swal("다시 시도해주세요.", "", "warning");
    }
  };

  //비밀번호 영문+숫자+특수문자 8자리 이상
  const patternPwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;
  const pwdResult = patternPwd.test(joinInfo.u_pw);
  const [pwdStatus, setPwdStatus] = useState(true);
  //한글 이름
  const patternName = /^[가-힣]{2,4}$/;
  const nameResult = patternName.test(joinInfo.u_name);

  //유저 이메일 valildation && check
  const pattern_email =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
  const emailCheck = async () => {
    if (joinInfo.u_id === "" || !pattern_email.test(joinInfo.u_id)) {
      setIdStatus(false);
      setIdAlert("올바른 이메일을 입력해 주세요");
    } else {
      const response = await emailCheckApi(joinInfo.u_id);
      if (response.message === "ID-overlap") {
        setIdStatus(false);
        setIdAlert("이메일이 중복 되었습니다.");
      } else if (response.message === "Nodata") {
        setIdStatus(false);
        setIdAlert("이메일이 중복 되었습니다.");
      } else if (response.status === "success") {
        setIdStatus(true);
      }
    }
  };

  useEffect(() => {
    if (joinInfo.u_id !== "") {
      emailCheck();
    }
  }, [joinInfo.u_id]);

  //유저 비밀번호 형식 구분
  const userPwdValidation = () => {
    if (!pwdResult) {
      setPwdStatus(false);
    } else {
      setPwdStatus(true);
    }
  };

  const onClickNext = () => {
    if (!idStatus || joinInfo.u_id === "" || !joinInfo.u_id) {
      swal("이메일을 확인해주세요", "", "warning");
    } else if (
      !isSocial &&
      (!pwdResult ||
        joinInfo.u_pw === "" ||
        joinInfo.u_pwc === "" ||
        joinInfo.u_pw !== joinInfo.u_pwc ||
        !pwdResult ||
        joinInfo.u_pw.length < 8 ||
        joinInfo.u_pw > 16)
    ) {
      swal("올바른 비밀번호를 입력해주세요", "", "warning");
    } else if (joinInfo.u_name === "" || !nameResult) {
      swal("올바른 이름을 입력해주세요", "", "warning");
    } else if (joinInfo.u_rank === "" || joinInfo.u_rank === "직접입력") {
      swal("직급을 선택해주세요", "", "warning");
    } else {
      userSignUp();
    }
  };

  return (
    <div className="newinfo_wrap">
      <Mheader title="회원가입" />
      <div className="newinfo_container">
        <div className="join_top">
          <h2>회원정보 입력</h2>
          <div className="join_pagenation">
            <p>1</p>
            <p className="active">2</p>
            <p>3</p>
            <p>4</p>
          </div>
        </div>
        <dl className="info_wrap">
          <h3>회원정보 입력</h3>
          <dt className={idStatus ? "info_title" : "info_title_false"}>
            이메일 주소
          </dt>
          <dd className={idStatus ? "info_input" : "info_input_false "}>
            <input
              type="text"
              placeholder="이메일 주소를 입력해주세요."
              onChange={(e) => {
                setJoinInfo({ ...joinInfo, u_id: e.target.value });
              }}
              onBlur={emailCheck}
            />
          </dd>
          {!idStatus && <dd className="info_alert">{idAlert}</dd>}

          {!isSocial && (
            <>
              <dt className={pwdStatus ? "info_title" : "info_title_false"}>
                비밀번호
              </dt>
              <dd className={pwdStatus ? "info_input" : "info_input_false "}>
                <input
                  type="password"
                  placeholder="비밀번호를 입력해주세요."
                  onChange={(e) => {
                    setJoinInfo({ ...joinInfo, u_pw: e.target.value });
                  }}
                  onBlur={userPwdValidation}
                />
              </dd>

              {!pwdStatus && (
                <dd className="info_alert">
                  영문, 숫자, 특수문자를 혼합한 8~15자리의 비밀번호를
                  입력해주세요.
                </dd>
              )}
              <dt
                className={
                  joinInfo.u_pw !== joinInfo.u_pwc
                    ? "info_title_false"
                    : "info_title"
                }
              >
                비밀번호 확인
              </dt>
              <dd
                className={
                  joinInfo.u_pw !== joinInfo.u_pwc
                    ? "info_input_false"
                    : "info_input"
                }
              >
                <input
                  type="password"
                  placeholder="비밀번호를 한번 더 입력해주세요."
                  onChange={(e) => {
                    setJoinInfo({ ...joinInfo, u_pwc: e.target.value });
                  }}
                />
              </dd>
              {joinInfo.u_pw !== joinInfo.u_pwc && (
                <dd className="info_alert">비밀번호가 일치하지 않습니다.</dd>
              )}
            </>
          )}

          <dt className={"info_title"}>이름</dt>
          <dd className={"info_input"}>
            <input
              type="text"
              placeholder="이름을 입력해주세요."
              readOnly
              value={joinInfo.u_name}
            />
          </dd>
          <dt className={"info_title"}>부서</dt>
          <dd className={"info_input"}>
            <input
              placeholder="부서명을 입력해주세요."
              onChange={(e) => {
                setJoinInfo({ ...joinInfo, u_dept: e.target.value });
              }}
            />
          </dd>
          <dt className="info_title">직급</dt>
          <dd>
            <select
              onChange={(e) => {
                e.target.value === "직접입력"
                  ? setIsSelect(true)
                  : setIsSelect(false);
                setJoinInfo({ ...joinInfo, u_rank: e.target.value });
              }}
            >
              <option value="" selected>
                직급을 선택해주세요.
              </option>
              <option value="사원">사원</option>
              <option value="대리">대리</option>
              <option value="과장">과장</option>
              <option value="이사">이사</option>
              <option value="대표">대표</option>
              <option value="직접입력">직접입력</option>
            </select>
            {isSelect && (
              <input
                placeholder="직급을 입력 해주세요."
                style={{ marginTop: "5px" }}
                onChange={(e) => {
                  setJoinInfo({ ...joinInfo, u_rank: e.target.value });
                }}
              />
            )}
          </dd>
        </dl>
        <div className="move_btn btn_box">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="prev"
          >
            <p>이전</p>
          </div>
          <div onClick={onClickNext} className="next">
            <p>다음</p>
          </div>
        </div>
      </div>
    </div>
  );
}
