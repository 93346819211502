import { useEffect, useState } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import swal from "sweetalert";
import Mheader from "../../../component/common/Mheader";
import MyCart from "../../../component/etc/MyCartForProduct";
import ItemInfo from "../../../component/etc/ItemInfoForProduct";
import {
  bidSearchProcess,
  bidSearchFirstCategory,
  bidSearchSecondCategory,
  bidSearchThirdCategory,
  productFindApi,
} from "../../../api/auth";
import "../../../asset/css/requestChoice.css";
import cart_btn from "../../../asset/images/cart-btn.png";
import share_btn from "../../../asset/images/share-btn.png";
import { actions } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { wonComma } from "../../../util/Util";
import useDidMountEffect from "../../../component/etc/useDidMountEffect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive";
import Basket from "../../../component/etc/Basket";
import ShareTooltip from "../../../component/etc/ShareTooltip";
import Pagination from "../../../component/ui/pagination/Pagination";

export default function RequestChoice() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });
  const [sortPageList, setSortPageList] = useState({
    pageSort: "ALIVE",
    nowPage: 1,
  });
  const { excel_list, choice_list, bid_period, bid_single, bid_state } =
    useSelector((state) => ({
      excel_list: state.estimate.excel_list,
      choice_list: state.estimate.choice_list,
      bid_period: state.estimate.bid_period,
      bid_single: state.estimate.bid_single,
      bid_state: state.estimate.bid_state,
    }));

  const headerKeyword = location.state?.headerKeyword;
  const headerKeywordKind = location.state?.keyKind;

  const [cart, setCart] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const [selectedItem, setSelectedItem] = useState(false);
  const [cartItem, setCartItem] = useState([]);

  const [itemList, setItemList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [, setProcessList] = useState([]);
  const [firstList, setFirstList] = useState([]);
  const [secondList, setSecondList] = useState([]);
  const [thirdList, setThirdList] = useState([]);

  const [selectedProcess, setSelectedProcess] = useState(7);
  const [selectedFirst, setSelectedFirst] = useState(7);
  const [selectedSecond, setSelectedSecond] = useState(null);
  const [selectedThird, setSelectedThird] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [enteredKeyword, setEnteredKeyword] = useState(1);
  const [selectedKeyword, setSelectedKeyword] = useState("");

  const _bidSearchProcess = async () => {
    const response = await bidSearchProcess();
    if (response.status === "success") setProcessList(response.data);
  };
  const _bidSearchFirstCategory = async () => {
    const response = await bidSearchFirstCategory(selectedProcess);
    if (response.status === "success") setFirstList(response.data);
  };
  const _bidSearchSecondCategory = async () => {
    const response = await bidSearchSecondCategory(selectedFirst);
    if (response.status === "success") setSecondList(response.data);
  };
  const _bidSearchThirdCategory = async () => {
    const response = await bidSearchThirdCategory(selectedSecond);
    if (response.status === "success") setThirdList(response.data);
  };

  const searchProduct = async () => {
    const searchData = {
      processNo: selectedProcess ? selectedProcess : null,
      lcNo: selectedFirst ? selectedFirst : null,
      mcNo: selectedSecond ? selectedSecond : null,
      scNo: selectedThird ? selectedThird : null,
      keyword: keyword ? keyword : "",
      pageNum: sortPageList.nowPage === 0 ? 1 : sortPageList.nowPage,
      pageCount: 32,
    };

    const response = await productFindApi(searchData);

    if (response.status === "success") {
      setItemList(response.data);
      setTotalCount(response.data.totalCount);
    }
  };

  const headerSearchProduct = async (headerKeyword) => {
    const searchData = {
      processNo: null,
      lcNo: null,
      mcNo: null,
      scNo: null,
      keyword: headerKeyword ? headerKeyword : "",
      pageNum: sortPageList.nowPage,
      pageCount: 32,
    };

    setKeyword(headerKeyword);
    setEnteredKeyword(headerKeyword);

    const response = await productFindApi(searchData);

    if (response.status === "success") {
      setItemList(response.data);
      setTotalCount(response.data.totalCount);
    }
  };

  //cart
  const _addItem2Cart = (item) => {
    if (cartItem?.some((product) => product.no === item.no)) {
      notifyFail();
    } else {
      let newCartList = cartItem;
      newCartList.push(item);

      setCartItem(newCartList);
    }

    // dispatch해주기
    let totalBid = {
      excel_list: excel_list,
      choice_list: cartItem, // 초이스리스트 저장
      bid_period: bid_period, // 해당 객체에 코멘트 추가저장
      bid_single: bid_single, // 해당 객체에 코멘트 추가저장
      bid_state: "choice", // 마지막 비드타입 저장
    };

    dispatch(actions.setEstimate(totalBid));
  };

  const _editCartItemAmount = (item) => {
    setCartItem(item);
  };

  const sendData = async (step) => {
    const nextStep = async () => {
      if (cartItem.length === 0) {
        swal("품목을 선택해주세요.", "", "warning");
      } else {
        navigate("/management/order/request_choice2", {
          state: {
            cart: cartItem,
          },
        });
      }
    };

    if (step === "go_step2-2") {
      nextStep();
    }

    if (step === "go_step1") {
      navigate("/management/order/request_type", {
        state: {
          againFirst: true,
        },
      });
    }
  };

  useEffect(() => {
    _bidSearchProcess();
  }, []);

  useEffect(() => {
    if (selectedProcess !== false) {
      setSelectedFirst(false);
      setSelectedSecond(false);
      setSelectedThird(false);

      setFirstList([]);
      setSecondList([]);
      setThirdList([]);
      _bidSearchFirstCategory();
    }
  }, [selectedProcess]);

  useEffect(() => {
    if (selectedFirst !== false) {
      setSelectedSecond(false);
      setSelectedThird(false);

      setSecondList([]);
      setThirdList([]);
      _bidSearchSecondCategory();
    }
  }, [selectedFirst]);

  useEffect(() => {
    if (selectedSecond !== false) {
      setSelectedThird(false);

      setThirdList([]);
      _bidSearchThirdCategory();
    }
  }, [selectedSecond]);

  const majorGroupList = [
    { no: "7", process: "공통공사" },
    { no: "8", process: "POSCO 이노빌트" },
    { no: "9", process: "안전용품" },
    { no: "10", process: "폐기물" },
  ];

  useEffect(() => {
    if (headerKeywordKind) {
      const keyword = majorGroupList.filter(
        (el) => el.no === headerKeywordKind
      );
      setKeyword("");
      setEnteredKeyword("");
      setSelectedKeyword(keyword[0].process);
      setSelectedProcess(headerKeywordKind);
    } else {
      headerSearchProduct(headerKeyword);
    }
  }, [headerKeyword, headerKeywordKind]);

  useEffect(() => {
    if (choice_list) {
      setCartItem(choice_list);
    }
    if (headerKeywordKind === undefined && headerKeyword === undefined) {
      searchProduct();
    }
  }, []);

  useDidMountEffect(() => {
    searchProduct();
  }, [sortPageList.nowPage]);

  const [openShareTooltip, setOpenShareTooltip] = useState(false);
  const [active, setActive] = useState(false);

  const notifySuccess = () => toast.success("장바구니에 상품을 담았습니다.");
  const notifyFail = () => toast.error("이미 장바구니에 담긴 상품입니다.");
  const notifyWarn = () => toast.warn("상품 담기가 취소되었습니다.");

  const [showCardBtn, setShowCardBtn] = useState(false);
  const onMouseEnterCardBtn = () => {
    setShowCardBtn(true);
  };
  const onMouseLeaveCardBtn = () => {
    setShowCardBtn(false);
  };

  const handlePageChange = (page) => {
    setSortPageList((prev) => ({ ...prev, nowPage: page }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="order_search_wrap request_one3_wrap">
      <Mheader title="주문서 발행" />
      <div className="request_one3_container order_search_container">
        <div className="top_progress_bar_container">
          <div className="top_progress_bar">
            <div
              className="menus"
              onClick={() => {
                sendData("go_step1");
              }}
            >
              주문방법선택
            </div>
            <div className="active_menus color2">품목 직접 선택</div>
            <div
              className="menus"
              onClick={() => {
                sendData("go_step2-2");
              }}
            >
              상세정보 입력
            </div>
            <div
              className="menus not_allowed"
              onClick={() => {
                swal("완료상태로는 이동할 수 없습니다.", "", "error");
              }}
            >
              완료
            </div>
          </div>
        </div>
        <div className="order_search_top">
          <p className="not_s_tit">
            * 품목을 직접 선택하여 간편하게 입찰요청 해보세요
          </p>
          {isMobile && (
            <div className="wrap_btn">
              <div className="btn_my_cart" onClick={() => setCart(!cart)}>
                내 장바구니 (<span>{cartItem ? cartItem.length : 0}</span>)
              </div>
              <div
                className="btn_my_cart btn_next"
                onClick={() => {
                  sendData("go_step2-2");
                }}
              >
                다음
              </div>
            </div>
          )}
        </div>
        <div className="order_search_mid">
          <div className="order_search_mid_top">
            <div className="input_box">
              <p>대표공종</p>
              <select
                onChange={(e) => {
                  setSelectedProcess(e.target.value);
                }}
              >
                <option value={"null"} selected>
                  공종
                </option>
                {majorGroupList.map((el) => (
                  <option value={el.no}>{el.process}</option>
                ))}
              </select>
            </div>
            <div className="input_box">
              <p>대분류</p>
              <select
                onChange={(e) => {
                  setSelectedFirst(e.target.value);
                  setSelectedSecond(null);
                  setSelectedThird(null);
                }}
              >
                <option value={"null"}>대분류</option>
                {firstList.map((el, idx) => (
                  <option value={el.lc_no} key={idx}>
                    {el.lc_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_box">
              <p>중분류</p>
              <select
                onChange={(e) => {
                  setSelectedSecond(e.target.value);
                  setSelectedThird(null);
                }}
              >
                <option value={"null"}>중분류</option>
                {secondList.map((el, idx) => (
                  <option value={el.mc_no} key={idx}>
                    {el.mc_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_box">
              <p>소분류</p>
              <select
                onChange={(e) => {
                  setSelectedThird(e.target.value);
                }}
              >
                <option value={"null"}>소분류</option>
                {thirdList.map((el, idx) => (
                  <option value={el.sc_no} key={idx}>
                    {el.sc_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="order_search_mid_bot">
            <div className="input_box">
              <p>품목명</p>
              <input
                type="text"
                value={keyword}
                placeholder="품목명을 입력하세요."
                spellCheck="false"
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setKeyword(e.target.value);
                    setEnteredKeyword(e.target.value);
                    searchProduct();
                    setSortPageList({ ...sortPageList, nowPage: 1 });
                  }
                }}
              />
            </div>
            <div
              className="btn_search"
              onClick={() => {
                setEnteredKeyword(keyword);
                searchProduct();
                setSortPageList({ ...sortPageList, nowPage: 1 });
              }}
            >
              <p className="pc_on">검색</p>
            </div>
          </div>
          {isDesktop && (
            <Basket
              cartItemList={cartItem}
              setCartItem={_editCartItemAmount}
              sendData={sendData}
              notifyWarn={notifyWarn}
            />
          )}
        </div>
        <div className="order_search_finded">
          <div>
            <h3 className="s_tit">
              <p>
                검색결과{" "}
                {headerKeywordKind && (
                  <span>{`추천 키워드 : ${selectedKeyword}`}</span>
                )}
                {enteredKeyword && (
                  <span className="order_search_word">
                    {" "}
                    '{enteredKeyword}'{" "}
                  </span>
                )}{" "}
                <span className="order_search_num">{wonComma(totalCount)}</span>
                건
              </p>
            </h3>
          </div>
          <ul className="order_search_result">
            {itemList?.productList?.map((data, i) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    navigate(
                      `/management/order/request_choice/detail/${data.product.no}`,
                      {
                        state: {
                          background: location,
                        },
                      }
                    );
                  }}
                  onMouseEnter={() => {
                    setActive(data.product.no);
                    onMouseEnterCardBtn();
                  }}
                  onMouseLeave={() => {
                    onMouseLeaveCardBtn();
                    setOpenShareTooltip(false);
                  }}
                >
                  <div className="top_wrap">
                    <div className="order_search_result_top">
                      <div className="order_search_product_img">
                        <img
                          src={data.file}
                          alt={data.product.name}
                          className="product_img"
                        />
                      </div>
                    </div>
                    <div className="temp"></div>
                    {isDesktop && showCardBtn && active === data.product.no && (
                      <div className="order_search_result_mid0">
                        <div
                          className="share-btn"
                          onClick={(e) => {
                            setActive(data.product.no);
                            setOpenShareTooltip(!openShareTooltip);
                            e.stopPropagation();
                          }}
                        >
                          <img alt="" src={share_btn} id={i} />
                        </div>
                        <div>
                          <button
                            className="cart-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (isNaN(data.product.cartAmount)) {
                                data.product.cartAmount = 0;
                                data.product.file = "";
                              }
                              if (
                                !cartItem?.some(
                                  (product) => product.no === data.product.no
                                )
                              ) {
                                data.product.cartAmount += 1;
                                data.product.file = data.file;
                                notifySuccess();
                              }
                              setSelectedItem(data.product);
                              _addItem2Cart(data.product);
                            }}
                          >
                            <img alt="" src={cart_btn} />
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="order_search_result_mid">
                      <div
                        className="order_search_product_info"
                        onClick={() => {
                          if (isNaN(data.product.cartAmount)) {
                            data.product.cartAmount = 0;
                          }
                          data.product.cartAmount += 1;
                          setSelectedItem(data.product);
                        }}
                      >
                        <p className="name0">{data.product.brand}</p>
                        <p className="name1">{data.product.name}</p>
                        <p className="name2">
                          {data.product.standard.replace(/, /gi, " / ")}
                        </p>
                      </div>
                    </div>
                  </div>
                  {openShareTooltip && active === data.product.no && (
                    <ShareTooltip
                      openShareTooltip={openShareTooltip}
                      setOpenShareTooltip={setOpenShareTooltip}
                      data={data.product}
                      popUpLocation="RequestChoice"
                      startingPoint="RequestChoice"
                    />
                  )}
                </li>
              );
            })}
            <Outlet
              context={{
                setCartItem: setCartItem,
                cartItem: cartItem,
              }}
            />
          </ul>
        </div>
        <div className="request_choice_pagination_container">
          <Pagination
            current={sortPageList.nowPage}
            total={totalCount}
            pageSize={32}
            onChange={handlePageChange}
          />
        </div>
        {cart && (
          <div className="cart_dim" onClick={() => setCart(false)}></div>
        )}
        {cart && cartItem && (
          <MyCart
            setCart={setCart}
            cartItem={cartItem}
            setCartItem={_editCartItemAmount}
          />
        )}
        {openDetail && selectedItem !== false ? (
          <ItemInfo
            setOpenDetail={setOpenDetail}
            item={selectedItem}
            addCartItem={_addItem2Cart}
          />
        ) : null}
        <ToastContainer
          className="toastify"
          position="top-center"
          autoClose={3000}
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
