import { useCallback, useEffect, useState } from "react";
import { ordersModifyHistoryGroupApi } from "../../../api/auth";
import * as S from "../../../asset/css/ordersModifyHistoryTabs.styles";
import default_invoice_preview from "../../../asset/images/default-invoice-preview.png";
import { wonComma } from "../../../util/Util";
import { useMediaQuery } from "react-responsive";
import TableSummaryFooter from "../../../component/etc/TableFooter";

export default function OrdersModifyHistoryTabs(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const { ordersGroupNo, index, selectedTab, inOrderFee } = props;
  const [ordersModifyHistoryList, setOrdersModifyHistoryList] = useState();

  const fetchOrdersModifyHistoryList = useCallback(async () => {
    try {
      const response = await ordersModifyHistoryGroupApi(ordersGroupNo);
      setOrdersModifyHistoryList(response.data);
      console.log(ordersModifyHistoryList);
    } catch (error) {}
  }, [ordersGroupNo]);

  useEffect(() => {
    fetchOrdersModifyHistoryList();
  }, [fetchOrdersModifyHistoryList]);

  return (
    <div>
      {selectedTab !== 0 && selectedTab === index && (
        <div className="table_container">
          <table>
            <thead>
              <tr>
                <th className="table_index"></th>
                <th className="table_name">품목명</th>
                <th className="table_standard">규격</th>
                <th className="table_brand">
                  제조국
                  <p className="table_sub_desc">(브랜드)</p>
                </th>
                <th className="table_etc">비고</th>
                <th className="table_unit">단위</th>
                <th className="table_count">수량</th>
                <th className="table_total_amount">
                  고객사 납품단가
                  <p className="table_sub_desc">(수수료 포함 단가)</p>
                </th>
                <th className="table_total_amount">
                  최종 합계 금액
                  <p className="table_sub_desc">(고객사 납품 단가)</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {ordersModifyHistoryList?.productInfoList?.map(
                (productInfo, i) => (
                  <tr
                    className={
                      productInfo.state === "ADD" ? "subject_t_add" : null
                    }
                  >
                    <th>{i + 1}</th>
                    <S.ProductNameWrap>
                      {/* {productInfo.productImageUrl && (
                        <S.ProductImage
                          src={productInfo.productImageUrl}
                          alt=""
                          onClick={() =>
                            window.open(productInfo.productImageUrl, "_blank")
                          }
                        />
                      )}
                      {!productInfo.productImageUrl && (
                        <S.DefaultFileImgWrap
                          src={default_invoice_preview}
                          alt=""
                        />
                      )} */}
                      <span>{productInfo.bidProduct?.name}</span>
                    </S.ProductNameWrap>
                    <td>
                      <p className="table_overflow_text">
                        {productInfo.bidProduct?.standard?.replace(
                          /, /gi,
                          "\n"
                        )}
                      </p>
                    </td>
                    <td>{productInfo.bidProduct?.brand}</td>
                    <td>
                      <span className="table_overflow_text">
                        {productInfo.bidProduct?.etc}
                      </span>
                    </td>
                    <td>{productInfo.bidProduct?.unit}</td>
                    <td>{wonComma(productInfo.bidProduct?.count)}</td>
                    <td>{wonComma(productInfo.price)}</td>
                    <td>
                      {wonComma(
                        productInfo.price * productInfo.bidProduct?.count
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>

            <TableSummaryFooter
              list={ordersModifyHistoryList?.productInfoList}
              priceKey="price"
              unitPriceKey="unitPrice"
              countKey="bidProduct.count"
              rowSpan="3"
              colSpan="7"
              inOrderFee={inOrderFee}
            />
          </table>
        </div>
      )}
    </div>
  );
}
