import { FloatButton } from "antd";
import { UpCircleOutlined } from "@ant-design/icons";
import * as s from "../../asset/css/customFloatButton.module.css";

export default function CustomFloatButton() {
  return (
    <FloatButton.Group className={s.float_button_group} shape="circle">
      <FloatButton.BackTop
        className={s.float_button}
        icon={<UpCircleOutlined />}
      />
    </FloatButton.Group>
  );
}
