import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Mheader from "../../../component/common/Mheader";
import * as S from "../../../asset/css/estimate.styles";
import "../../../asset/css/estimate.css";
import { fetchWinningBidInfoApi, getMy_Bid_ListApi } from "../../../api/auth";
import { wonComma } from "../../../util/Util";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import DashBoardTabs from "../../../component/etc/DashBoardTabs";
import { useMediaQuery } from "react-responsive";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { ko } from "date-fns/esm/locale";
import Pagination from "../../../component/ui/pagination/Pagination";

export default function Estimate() {
  const { page } = useParams();

  const url = "/management/estimate/";
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });

  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [maxCount, setMaxCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  const [sortPageList, setSortPageList] = useState({
    pageSort: "ALL",
    nowPage: 1,
    startDate: "2021-01-01",
    endDate: moment().format("yyyy-MM-DD"),
  });

  const [bList, setBlist] = useState([]);

  const [stateMap, setStateMap] = useState([]);

  const getReq_list = async () => {
    const uc_no = userData.uc_no;
    const response = await getMy_Bid_ListApi(
      uc_no,
      sortPageList.pageSort,
      currentPage,
      sortPageList.startDate,
      sortPageList.endDate
    );

    if (response.status === "success") {
      setBlist(response.data.bList);
      setStateMap(response.data.stateMap);
      setMaxCount(response.data.maxCount);
    }
  };

  useEffect(() => {
    getReq_list();
  }, [sortPageList, currentPage]);

  const winningBidFnc = async (data) => {
    const res = await fetchWinningBidInfoApi(data.bid_no);

    swal({
      title: "낙찰 정보",
      text: `입찰 참여업체: ${res?.data?.offerCount}개사
      낙찰 금액: ${wonComma(res?.data?.totalPrice)}원
      선정 이유: ${!!res?.data?.selectReason ? res?.data?.selectReason : "-"}
      `,
      icon: "info",
    });
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("전체");

  const onChangeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setSortPageList({
      ...sortPageList,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    });
  };

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setSortPageList({
        ...sortPageList,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    }
  }, [selectValue]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`${url}${page}`);
  };

  return (
    <S.Wrapper>
      <Mheader title="입찰관리" />
      <S.DashboardContainer>
        {isDesktop && <DashBoardTabs />}
        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>나의 입찰관리 현황</S.Title>
              {isDesktop && (
                <>
                  <div>|</div>
                  <S.SelectedDate>
                    {sortPageList?.startDate} ~ {sortPageList?.endDate}
                  </S.SelectedDate>
                </>
              )}
            </S.TitleWrap>

            {isDesktop && (
              <S.AlignWrap>
                <S.DateRangeSelect
                  onChange={onChangeSelectValue}
                  value={selectValue}
                >
                  <option>최근 1개월</option>
                  <option>최근 3개월</option>
                  <option>최근 6개월</option>
                  <option>최근 1년</option>
                  <option>전체</option>
                </S.DateRangeSelect>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setSortPageList({
                        ...sortPageList,
                        startDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                      setCurrentPage(1);
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>

                <S.DateWrap>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setSortPageList({
                        ...sortPageList,
                        endDate: moment(date).format("yyyy-MM-DD"),
                      });
                      navigate(url + "1");
                      setCurrentPage(1);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="yyyy-MM-dd"
                    locale={ko}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </S.DateWrap>
              </S.AlignWrap>
            )}
          </S.ContentHeader>

          <S.ContentBody>
            <S.EstimateStateWrap>
              <S.EstimateState>입찰진행</S.EstimateState>
              <S.EstimateAliveBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "A",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.alive}건
              </S.EstimateAliveBtn>
            </S.EstimateStateWrap>

            <S.EstimateStateWrap>
              <S.EstimateState>선정대기</S.EstimateState>
              <S.EstimateChooseBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "CHOOSE",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.choose}건
              </S.EstimateChooseBtn>
            </S.EstimateStateWrap>

            <S.EstimateStateWrap>
              <S.EstimateState>입찰마감</S.EstimateState>
              <S.EstimateEndBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "E",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.end}건
              </S.EstimateEndBtn>
            </S.EstimateStateWrap>

            {isDesktop && <S.DivideLine></S.DivideLine>}

            <S.EstimateStateWrap>
              <S.EstimateState>전체보기</S.EstimateState>
              <S.EstimateAllBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "ALL",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {Object.values(stateMap).reduce((acc, cur) => +acc + +cur, 0)}건
              </S.EstimateAllBtn>
            </S.EstimateStateWrap>
          </S.ContentBody>

          <S.ContentFooter>
            <S.CancelNumBtn
              sortPageList={sortPageList}
              onClick={() => {
                setSortPageList({
                  ...sortPageList,
                  pageSort: "C",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              취소 <span>{stateMap.cancel}</span>건
            </S.CancelNumBtn>
          </S.ContentFooter>
        </S.DashBoardContent>

        {isDesktop && (
          <>
            <S.SearchResultWrap>
              <S.SearchResult>
                총 <span>{maxCount}</span>건이 검색되었습니다.
              </S.SearchResult>
            </S.SearchResultWrap>
            {bList?.map((el, idx) => (
              <S.EstimateListWrapper key={idx}>
                <S.ListHeader>
                  <S.EstimateNo>입찰번호 : {el.bid_code}</S.EstimateNo>
                  <S.SiteName>현장명 : {el.s_name}</S.SiteName>
                  <S.EstimateStep el={el}>
                    <S.Step>
                      <p>입찰진행</p>
                    </S.Step>
                    <S.Step>
                      <p>선정대기</p>
                    </S.Step>
                    <S.Step>
                      <p>입찰마감</p>
                    </S.Step>
                  </S.EstimateStep>
                </S.ListHeader>

                <S.ListBody>
                  <S.ListInfo>
                    <S.Badge el={el}>
                      {el.bid_state === "ALIVE" && "입찰진행중"}
                      {el.bid_state === "CHOOSE" && "선정대기"}
                      {el.bid_state === "END" && "입찰마감"}
                      {el.bid_state === "CANCEL" && "입찰취소"}
                      {el.bid_state === "FAIL" && "유찰"}
                    </S.Badge>
                    {el?.bid_extra_type !== null && (
                      <S.ReBadge el={el}>
                        {el.bid_extra_type === "REBID" && "재입찰"}
                        {el.bid_extra_type === "REESTIMATE" && "재공고입찰"}
                      </S.ReBadge>
                    )}
                    <S.NameWrapper>
                      <S.CompanyName>
                        {el.bid_state === "ALIVE"
                          ? "공급사 미선정"
                          : el.con_name}
                      </S.CompanyName>
                      <div>|</div>
                      <S.ProductName>{el.product_desc}</S.ProductName>
                    </S.NameWrapper>
                    <S.DetailInfo>
                      <S.BidType>
                        입찰형태 :
                        {(el.bid_type === "C" || el.bid_type === "T") &&
                          ` 단가계약 (${el.bid_term}개월)`}
                        {el.bid_type === "S" && ` 일회성 입찰`}
                      </S.BidType>
                      <S.BidDate>선정대기시간 : {el.bid_endate}</S.BidDate>
                    </S.DetailInfo>
                  </S.ListInfo>
                  <S.BtnWrapper>
                    {el.bid_state === "END" && (
                      <S.BidPriceBtn
                        onClick={() => {
                          winningBidFnc(el);
                        }}
                      >
                        낙찰 정보 보기
                      </S.BidPriceBtn>
                    )}
                    {(el.bid_state === "CANCEL" ||
                      el.bid_state === "END" ||
                      el.bid_state === "ALIVE" ||
                      el.bid_state === "CHOOSE" ||
                      el.bid_state === "FAIL") && (
                      <S.MyEstimateBtn
                        onClick={() => {
                          navigate(
                            `/management/estimate/document/${el.bid_no}`,
                            {
                              state: {
                                selectValue: selectValue,
                                sortPageList: sortPageList,
                              },
                            }
                          );
                        }}
                      >
                        내 입찰 내역
                      </S.MyEstimateBtn>
                    )}
                  </S.BtnWrapper>
                </S.ListBody>
              </S.EstimateListWrapper>
            ))}
            <div className="estimate_pagination_container">
              <Pagination
                current={currentPage}
                total={maxCount}
                onChange={handlePageChange}
                pageSize={10}
              />
            </div>
          </>
        )}
      </S.DashboardContainer>

      {isMobile && (
        <div className="estimate_wrap">
          <S.AlignWrap>
            <S.DateWrap>
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setSortPageList({
                    ...sortPageList,
                    startDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>

            <S.DateWrap>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setSortPageList({
                    ...sortPageList,
                    endDate: moment(date).format("yyyy-MM-DD"),
                    // nowPage: 1,
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>
          </S.AlignWrap>

          <S.SearchResultWrap>
            <S.SearchResult>
              검색결과 : <span>{maxCount}</span>건
            </S.SearchResult>
          </S.SearchResultWrap>

          <div className="estimate_container">
            <div className="contract_list">
              <div className="contract_list_btm">
                {bList.map((el, i) => {
                  return (
                    <div className="contract_content" key={i} value={i}>
                      <div className="content_top">
                        <span>입찰번호 :&nbsp;</span>
                        <span>{el.bid_code}</span>
                        <span className="num_line">|</span>
                        {el.bid_type === "T" || el.bid_type === "C" ? (
                          <span>
                            {el.bid_regdate.substring(0, 4)}.
                            {el.bid_regdate.substring(5, 7)}.
                            {el.bid_regdate.substring(8, 10)}
                          </span>
                        ) : el.bid_type === "S" ? (
                          <span>
                            {" "}
                            {el.bid_regdate.substring(0, 4)}.
                            {el.bid_regdate.substring(5, 7)}.
                            {el.bid_regdate.substring(8, 10)}
                          </span>
                        ) : null}
                      </div>
                      <div className="content_btm">
                        <div className="content_box">
                          <img className="company_img" src={el.f_root} alt="" />
                          <div className="contract_info">
                            <div className="icon_box">
                              <S.Badge el={el}>
                                {el.bid_state === "ALIVE" && "입찰진행중"}
                                {el.bid_state === "CHOOSE" && "선정대기"}
                                {el.bid_state === "END" && "입찰마감"}
                                {el.bid_state === "CANCEL" && "입찰취소"}
                                {el.bid_state === "FAIL" && "유찰"}
                              </S.Badge>
                              {el?.bid_extra_type !== null && (
                                <S.ReBadge el={el}>
                                  {el.bid_extra_type === "REBID" && "재입찰"}
                                  {el.bid_extra_type === "REESTIMATE" &&
                                    "재공고입찰"}
                                </S.ReBadge>
                              )}
                              {el.bid_state === "RE" ? (
                                <span class="re_text">
                                  입찰요청이 수정되었습니다. 재참여 해주세요.
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <p className="contractor">
                              <span>
                                [{el.con_name}]&nbsp;{el.s_name}
                              </span>
                              <span>
                                {el.bid_type === "T" || el.bid_type === "C"
                                  ? `단가계약(${el.bid_term}개월)`
                                  : el.bid_type === "S"
                                  ? "일회성 입찰"
                                  : null}
                              </span>
                            </p>
                            <div className="contract_text">
                              {el.bid_state === "ALIVE" && (
                                <p className="bidding_time">
                                  남은 투찰시간 : {el.bidTime}{" "}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="contract_more">
                          {el.e_party === "true" && (
                            <p
                              onClick={() => {
                                navigate(
                                  `/management/estimate/document/${el.bid_no}`
                                  // { state: { bList: bList[i] } }
                                );
                              }}
                            >
                              투찰 수정
                            </p>
                          )}
                          {el.bid_state === "RE" && (
                            <p
                              onClick={() => {
                                navigate(
                                  `/management/estimate/document/${el.bid_no}`
                                  // { state: { bList: bList[i] } }
                                );
                              }}
                            >
                              입찰 재참여
                            </p>
                          )}

                          {[
                            "CANCEL",
                            "END",
                            "ALIVE",
                            "CHOOSE",
                            "FAIL",
                          ].includes(el.bid_state) && (
                            <p
                              onClick={() => {
                                navigate(
                                  `/management/estimate/document/${el.bid_no}`,
                                  {
                                    state: {
                                      selectValue: selectValue,
                                      sortPageList: sortPageList,
                                    },
                                  }
                                );
                              }}
                            >
                              내 입찰 내역
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="estimate_pagination_container">
                <Pagination
                  current={currentPage}
                  total={maxCount}
                  onChange={handlePageChange}
                  pageSize={10}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </S.Wrapper>
  );
}
