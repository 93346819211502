import React, { useState } from "react";
//library
import { useNavigate } from "react-router-dom";

//component
import Mnavi from "./Mnavi";
//css
import "../../asset/css/common.css";
//etc
import i_back from "../../asset/images/m-right-arrow.png";
import home_img from "../../asset/images/m-home-icon.png";
import menu_img from "../../asset/images/m-menu-icon.png";
import { menuList } from "../../asset/js/dummy";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default.js";

//sweet alert
import swal from "sweetalert";

export default function Mheader({ title, isChat }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { signToken } = useSelector((state) => ({
    signToken: state.token,
  }));

  const { u_name, c_name } = useSelector((state) => ({
    u_name: state.user.u_name,
    u_rank: state.user.u_rank,
    c_name: state.user.c_name,
  }));

  const [naviSwitch, setNaviSwitch] = useState(false);
  const [comeList, setComeList] = useState(menuList);

  // 뒤로가기
  const goBack = () => {
    navigate(-1);
  };

  //로그아웃
  const logoutFnc = () => {
    swal({
      title: "LOGOUT",
      text: "로그아웃 하시겠습니까?",
      icon: "info",
      buttons: true,
      // dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(actions.setToken(false));
        dispatch(actions.setUser(userDefault));

        navigate("/", { replace: true });
      } else {
        swal("취소하셨습니다.");
      }
    });
  };

  return (
    <>
      <div>
        <div className="m_header">
          <div className="m_header_left">
            <div
              className="back"
              onClick={() => {
                if (isChat) {
                  navigate(0);
                } else {
                  goBack();
                }
              }}
            >
              <img src={i_back} alt="" />
            </div>
            <p>{title}</p>
          </div>
          {/* 햄버거 메뉴 들어가면 사용 */}
          {signToken !== false ? (
            <div className="m_header_right">
              <img
                src={home_img}
                className="home_icon"
                onClick={() => {
                  navigate("/");
                }}
              />
              <img
                src={menu_img}
                className="menu_icon"
                onClick={() => {
                  setNaviSwitch(true);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {signToken !== false ? (
        <Mnavi
          naviSwitch={naviSwitch}
          setNaviSwitch={setNaviSwitch}
          comeList={comeList}
          setComeList={setComeList}
          userName={u_name}
          selectedSite={c_name}
          logoutFnc={logoutFnc}
        />
      ) : null}
    </>
  );
}
