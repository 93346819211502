import { useCallback, useEffect, useState } from "react";
import { imgUpApi, invoiceUploadApi } from "../../../api/auth";

export default function useInvoiceUpload() {
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileImg, setUploadedFileImg] = useState(null);
  const [fileNo, setFileNo] = useState();
  const [filePath, setFilePath] = useState();

  const uploadInvoice = async (invoiceNo, fileInfoNo) => {
    await invoiceUploadApi(invoiceNo, fileInfoNo);
  };

  const handleUploadFile = useCallback(async () => {
    let form = new FormData();
    form.append("file", uploadedFileImg);
    const response = await imgUpApi(form);

    if (response.status === "success") {
      
      setFileNo(response.data.f_no);
      setFilePath(response.data.f_path);
    }
  }, [uploadedFileImg]);

  useEffect(() => {
    if (uploadedFileImg) handleUploadFile();
  }, [uploadedFileImg, handleUploadFile]);

  return {
    uploadedFile,
    setUploadedFile,
    uploadedFileImg,
    setUploadedFileImg,
    fileNo,
    setFileNo,
    filePath,
    setFilePath,
    uploadInvoice,
    handleUploadFile,
  };
}
