import React, { useState, useRef, useEffect } from "react";
// import { itemList } from "../../asset/js/productListDummy.js";
import "../../asset/css/excel.css";
import CalculatePricePop from "../common/Pop/CalculatePricePop";
import useFee from "../../page/main/customHooks/useFee";
// img
import calculator from "../../asset/images/icon_calculator.png";
import circle_minus from "../../asset/images/circle-minus.png";

const ExcelComponent = ({
  data,
  setData,
  cols,
  setCols,
  rows,
  setRows,
  addRow,
  eachPartnerElem,
  sumPartnerElem,
  emptyAlert,
}) => {
  //   const [rows, setRows] = useState(3);
  //   const [cols, setCols] = useState(5);

  //   // 초기값 지정
  //   const createGridData = (rows, cols) => {
  //     let count = 1;
  //     const data = [];
  //     for (let i = 0; i < rows; i++) {
  //       const rowData = [];
  //       for (let j = 0; j < cols; j++) {
  //         rowData.push({
  //           id: count++,
  //           value: j === 1 ? "식" : j === 2 ? "EA" : j === 3 ? "1" : "",
  //           isValid: false,
  //         });
  //       }
  //       data.push(rowData);
  //     }
  //     return data;
  //   };
  //   const [gridData, setGridData] = useState(createGridData(rows, cols));
  const [focusedCell, setFocusedCell] = useState({ row: 0, col: 0 });

  const inputRefs = useRef([]);

  useEffect(() => {
    // 초기 렌더링 시 첫 번째 input에 포커스를 주기 위해 실행
    inputRefs.current[0]?.focus();
  }, []);

  const placeHolderList = [
    "품목명을 입력해주세요.",
    "규격을 입력해주세요.",
    "제조국(브랜드)을 입력해주세요.",
    "특이사항 없음",
    "단위를 입력해주세요.",
    "0",
    "0",
  ];

  const handleKeyDown = (event, row, col) => {
    switch (event.key) {
      case "ArrowUp":
        moveFocus(row, col, -1, 0);
        break;
      case "ArrowDown":
        moveFocus(row, col, 1, 0);
        break;
      case "Enter":
        moveFocus(row, col, 1, 0);
        break;
      case "ArrowLeft":
        moveFocus(row, col, 0, -1);
        break;
      case "ArrowRight":
        moveFocus(row, col, 0, 1);
        break;
      default:
        break;
    }
  };

  const moveFocus = (row, col, rowOffset, colOffset) => {
    const newRow = row + rowOffset;
    const newCol = col + colOffset;

    if (newRow >= 0 && newRow < rows && newCol >= 0 && newCol < cols) {
      setFocusedCell({ row: newRow, col: newCol });

      setTimeout(() => {
        inputRefs.current[newRow * cols + newCol]?.focus();
      }, 0);
    }
  };

  const deleteRow = (idx) => {
    let copyArray = [...data];
    let clickedIndex = idx;

    if (copyArray.length === 1) {
      return;
    } else {
      copyArray.splice(clickedIndex, 1);

      //셀 한개일 경우 삭제불가
      setData(copyArray);
    }
  };

  const handleKeyChange = (e, rowIndex, colIndex) => {
    const updatedGridData = [...data];
    const inputValue = e.target.value;

    if (colIndex === 5 || colIndex === 6) {
      let onlyNumValue = inputValue.replace(/[^0-9]/g, "");

      if (colIndex === 5 && onlyNumValue.length > 7) {
        onlyNumValue = onlyNumValue.slice(0, 7);
      }
      if (colIndex === 6 && onlyNumValue.length > 9) {
        onlyNumValue = onlyNumValue.slice(0, 9);
      }

      updatedGridData[rowIndex][colIndex].value = onlyNumValue;
    } else {
      updatedGridData[rowIndex][colIndex].value = e.target.value;
    }

    setData(updatedGridData);
  };

  // 배열을 돌면 빈 곳 체크하는 함수
  const findEmptyCells = () => {
    const emptyCells = [];

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        if (data[i][j]?.value === "") {
          emptyCells.push({ row: i + 1, col: j + 1 });
        }
      }
    }

    return emptyCells;
  };

  // 빈곳체크하기
  const handleFindEmptyCells = () => {
    const emptyCells = findEmptyCells();
  };

  const createRowData = (count) => {
    const rowData = [];
    for (let j = 0; j < cols; j++) {
      rowData.push({ id: count++, value: "", isValid: false });
    }
    return rowData;
  };

  const handlePaste = (event, rowIndex, cols, colIndex) => {
    // 로직 : 복사해온 값을 가져온다(event 객체를 통해) -> 그 값을 Tab이나 enter로 값을 분리한다 -> 분리한 값을 순회하면서 값을 변경한다.
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    const updatedGridData = [...data];

    // 줄 바꿈을 기준으로 행을 분리하고 탭으로 구분된 값을 추출
    const splitRows = pastedText.split("\n");
    const values = splitRows.map((row) => row.split("\t"));

    // 붙혀넣은 값이 Griddata의 값을 넘어간다면, addRow를 해야함
    if (data.length < values.length + rowIndex) {
      let count = 1;

      for (let i = 0; i < values.length + rowIndex - data.length; i++) {
        updatedGridData.push(createRowData(count, cols));
      }
      setRows(rows + values.length + rowIndex - data.length);
    }
    // 클립보드에 복사된 값을 탭 단위로 나누고, 그 나눈 것을 각 Input에 매핑함
    values.forEach((row, rIdx) => {
      row.forEach((value, cIdx) => {
        if (colIndex + cIdx <= 6) {
          // 6칸 넘어가면 버림
          updatedGridData[rowIndex + rIdx][colIndex + cIdx].value = value;
        }

        if (colIndex + cIdx === 5 || colIndex + cIdx === 6) {
          // 1. 콤마 제거 (.과 숫자를 제외한)
          const removeValue = value.replace(/[^0-9.]/g, "");

          // 2. 문자열을 숫자로 변환
          const numberValue = Number(removeValue);

          // 3. 숫자가 유효한지 확인 (NaN 또는 Infinity 체크)
          const isValidNumber = !isNaN(numberValue) && isFinite(numberValue);

          // 4. 자리수 제한
          let limitedValue;
          if (colIndex + cIdx === 5) {
            // colIndex가 5일 경우 7자리까지 자르기
            limitedValue = numberValue.toString().slice(0, 7);
          } else if (colIndex + cIdx === 6) {
            // colIndex가 6일 경우 9자리까지 자르기
            limitedValue = numberValue.toString().slice(0, 9);
          }

          // 5. 유효한 숫자이면 자리수 제한 후 숫자로 변환
          let finalValue =
            isValidNumber && limitedValue ? Number(limitedValue) : 0;

          // 6. 소수점 제거 (버림)
          finalValue = Math.floor(finalValue);

          // 7. 업데이트된 값을 gridData에 설정
          updatedGridData[rowIndex + rIdx][colIndex + cIdx].value = finalValue;
        }
      });
    });

    // 마지막에 setGridData
    setData(updatedGridData);

    event.preventDefault(); // 기본 동작(텍스트 붙여넣기) 방지
  };

  // 계산기 모달 관련
  const [openModalId, setOpenModalId] = useState(null);
  const [finalTaxPrices, setFinalTaxPrices] = useState({});

  const openModal = (id) => {
    setOpenModalId(id);
  };

  const closeModal = () => {
    setOpenModalId(null);
  };

  const handleConfirm = (rowIndex, taxPrice) => {
    const updatedGridData = [...data];
    updatedGridData[rowIndex][6].value = taxPrice;
    setData(updatedGridData);
    closeModal();
  };

  return (
    <div className="excel_container">
      {/* <button onClick={addRow}>행추가</button> */}

      <div className="excel_inner">
        {/* {!isFirstVisible && (
          <div
            className="excel_dim"
            onClick={() => setIsVisible(true)}
            onTouchStart={() => setIsVisible(true)}
            onTouchMove={() => setIsVisible(true)}
            onTouchEnd={() => setIsVisible(true)}
          >
            화면을 좌, 우로 움직여
            <br /> 엑셀 값을 입력해주세요.
          </div>
        )} */}
        <table className="excel_table">
          <thead>
            <tr>
              <th className="table_index"></th>
              <th className="table_name">품목명</th>
              <th className="table_standard">규격</th>
              <th className="table_brand">
                제조국
                <p className="table_sub_desc">(브랜드)</p>
              </th>
              <th className="table_etc">비고</th>
              <th className="table_unit">단위</th>
              <th className="table_count">수량</th>
              <th className="table_total_amount">
                고객사 납품단가
                <p className="table_sub_desc">(수수료 포함 단가)</p>
              </th>
              <th className="table_total_amount">
                최종 합계 금액
                <p className="table_sub_desc">(고객사 납품 단가)</p>
              </th>
              <th className="table_index">삭제</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="excel_number_section sticky_column">
                  {rowIndex + 1}
                </td>
                {row.map((cell, colIndex) => {
                  return (
                    <td
                      key={colIndex}
                      className={
                        // 품목명,단위,수량,단가를 안 썼거나, 수량이 7자리 이상이거나, 숫자가 1이하이거나
                        emptyAlert &&
                        ((typeof data[rowIndex][colIndex]?.value === "string" &&
                          data[rowIndex][colIndex].value.trim() === "" &&
                          (colIndex === 0 ||
                            colIndex === 4 ||
                            colIndex === 5 ||
                            colIndex === 6)) ||
                          ((Number(data[rowIndex][6]?.value) <= 1 ||
                            Number(data[rowIndex][6]?.value) >= 1000000000 ||
                            isNaN(data[rowIndex][6]?.value)) &&
                            colIndex === 6) ||
                          ((Number(data[rowIndex][5]?.value) >= 10000000 ||
                            isNaN(data[rowIndex][5]?.value)) &&
                            colIndex === 5))
                          ? "excel_auto_empty_td"
                          : "excel_auto_td"
                      }
                    >
                      <div className="excel_cell_container">
                        <input
                          className="excel_input"
                          placeholder={placeHolderList[colIndex]}
                          ref={(ref) =>
                            (inputRefs.current[rowIndex * cols + colIndex] =
                              ref)
                          }
                          type="text"
                          readOnly={colIndex === 7 && true}
                          title={cell.value}
                          value={
                            // 6은 고객사 납품단가, 7은 최종합계 금액
                            colIndex === 5 || colIndex === 6
                              ? eachPartnerElem(row, colIndex)
                              : colIndex === 7
                              ? sumPartnerElem(row)
                              : cell.value
                            // colIndex === 7 ? sumPartnerElem(row) : cell.value
                          }
                          // 수량이 숫자가 아닌 경우
                          // 변경할 때마다 셀이 검증 되어야한다.
                          onChange={(e) => {
                            handleKeyChange(e, rowIndex, colIndex);
                          }}
                          onPaste={(e) => {
                            handlePaste(e, rowIndex, cols, colIndex);
                          }}
                          // className={}
                          onKeyDown={(e) =>
                            // colIndex === 0 &&
                            handleKeyDown(e, rowIndex, colIndex)
                          }
                        />
                        {colIndex === 6 && (
                          <>
                            <div
                              onClick={() => openModal(cell)}
                              className="table_calculate_img_box"
                            >
                              <img src={calculator} />
                            </div>
                            {openModalId === cell && (
                              <CalculatePricePop
                                closeCalcModal={closeModal}
                                name={row[0]?.value}
                                onConfirm={(taxPrice) =>
                                  handleConfirm(rowIndex, taxPrice)
                                }
                              />
                            )}
                          </>
                        )}
                      </div>
                    </td>
                  );
                })}
                <td
                  onClick={(e) => {
                    deleteRow(rowIndex);
                  }}
                  title={`${rowIndex + 1}번째 행 삭제`}
                  className="excel_delete_section"
                >
                  <img src={circle_minus} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExcelComponent;
