const types = {
  //user
  SET_USER: "SET_USER",

  //token
  SET_TOKEN: "SET_TOKEN",
  SET_PUSH_TOKEN: "SET_PUSH_TOKEN",

  //chat
  SET_SOCKET: "SET_SOCKET",

  //estimate
  SET_ESTIMATE: "SET_ESTIMATE",

  //activity
  SET_ACTIVITY: "SET_ACTIVITY",
};

export default types;
