import React, { useState } from "react";
//library
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
//component

//etc
import "../../asset/css/common.css";
import m_cancle from "../../asset/images/m-cancel-icon.png";
import m_down from "../../asset/images/m-side-down-icon.png";
import m_up from "../../asset/images/m-side-up-icon.png";
import w_history_icon from "../../asset/images/w-history-icon.png";

export default function Mnavi({
  naviSwitch,
  setNaviSwitch,
  comeList,
  setComeList,
  userName,
  selectedSite,
  logoutFnc,
}) {
  const navigate = useNavigate();

  return (
    <div className={naviSwitch ? "m_nav_wrap" : "m_nav_wrap_off"}>
      <div className="m_nav_container">
        <div className="m_nav_top">
          <div className="m_cancle_btn">
            <img
              src={m_cancle}
              onClick={() => {
                setNaviSwitch(false);
              }}
              alt=""
            />
          </div>

          <div className="m_top_info">
            <div className="m_info_left">
              <p>{selectedSite}</p>
              <p>{userName}</p>
            </div>
            <p onClick={logoutFnc}>로그아웃</p>
          </div>

          <div className="m_nav_icon_wrap">
            <div
              className="m_nav_icon"
              onClick={() => {
                navigate("/my_page/history");
              }}
            >
              <img
                src={w_history_icon}
                className="header_my_page_icon"
                alt=""
              />
              <p>히스토리</p>
            </div>
          </div>
        </div>
        <div className="m_nav_btm">
          <ul className="m_main_nav">
            {comeList.map((data, i) => {
              if (data.subNav) {
                return (
                  <li className="m_main_li" key={data.subNav}>
                    <div
                      className={
                        data.active ? "m_main_li_p sub_ok" : "m_main_li_p"
                      }
                      onClick={() => {
                        const CurrentData = comeList.map((item, index) =>
                          index === i && item.active === false
                            ? { ...item, active: true }
                            : { ...item, active: false }
                        );
                        setComeList(CurrentData);
                      }}
                    >
                      <p>{data.mainNav}</p>
                      <p className="sub_down">
                        <img src={data.active ? m_up : m_down} alt="" />
                      </p>
                    </div>
                    <ul className={data.active ? "m_sub_nav" : "m_sub_nav_off"}>
                      {data.subNav.map((sub, i) => {
                        return (
                          <li
                            onClick={() => {
                              navigate(sub.to);
                              setNaviSwitch(false);
                            }}
                            key={sub.title}
                          >
                            {sub.title}
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li
                    className="m_main_li"
                    onClick={() => setNaviSwitch(false)}
                  >
                    <Link to={data.uri} className="m_main_li_p">
                      <p>{data.mainNav}</p>
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
