import React, { useState } from "react";
import { Link } from "react-router-dom";

//menu
import { myInfoMenu } from "../../../asset/js/dummy.js";

//component
import Mheader from "../../../component/common/Mheader";

export default function RegistrationCompleted() {
  const [nowUrl, setNowUrl] = useState("/my_page/registration");

  return (
    <div className="registration_completed_wrap completed_wrap">
      <Mheader title="물품등록 요청" />
      <div className="sub">
        <div className="sub_title">
          {myInfoMenu.map((data, index) => (
            <Link
              to={data.to}
              onClick={() => setNowUrl(data.to)}
              className={nowUrl.includes(data.to) ? "sub_on" : "sub_off"}
            >
              {data.title}
            </Link>
          ))}
        </div>
      </div>

      <div className="completed_container wd" style={{ paddingTop: "100px" }}>
        <div className="top_pagenation">
          <h2>물품등록 요청</h2>
          <div className="top_pagenation_num">
            <p>1</p>
            <p className="active">2</p>
          </div>
        </div>
        <div className="completed_text">
          <h4>물품등록 요청이 완료되었습니다.</h4>
          <h4>승인 시 공새로 품목에 해당 물품이 업데이트 됩니다.</h4>
        </div>
        <div className="completed_btn">
          <Link to="/my_page/registration">확인</Link>
        </div>
      </div>
    </div>
  );
}
