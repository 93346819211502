import * as S from "../../../asset/css/history.styles.js";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { PLACEHOLDER } from "../../../constant/Placeholder.js";
import { historyListApi } from "../../../api/auth.js";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_TEXT } from "../../../constant/DefaultText.js";
import moment from "moment";
import HistoryPagenation from "../../../component/etc/HistoryPagenation.js";
import { useMediaQuery } from "react-responsive";
import Mheader from "../../../component/common/Mheader.js";
import { useNavigate } from "react-router-dom";

export default function History() {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const userData = useSelector((state) => state.user);
  const [defaultHistoryList, setDefaultHistoryList] = useState({
    companyNo: userData.c_no,
    key: null,
    nowPage: 1,
    startDate: "2021-01-01",
    endDate: moment().format("yyyy-MM-DD"),
  });
  const [fetchedHistoryList, setFetchedHistoryList] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("전체");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [maxNum, setMaxNum] = useState(0);
  const [tempVar, setTempVar] = useState(0);

  const onClickSetTempVar = () => {
    setTempVar((prev) => prev + 1);
  };

  const fetchHistoryList = async () => {
    try {
      const response = await historyListApi(defaultHistoryList);

      if (response.status === "success") {
        setFetchedHistoryList(response.data.historyList);
        setMaxNum(response.data.maxNo);
      }
    } catch (error) {}
  };

  const onKeyPressFetchHistoryList = (e) => {
    if (e.key === "Enter") fetchHistoryList();
  };

  const onChangeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
  };

  const onChangeSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setDefaultHistoryList({
      ...defaultHistoryList,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    });
  };

  useEffect(() => {
    fetchHistoryList();
  }, [tempVar]);

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setDefaultHistoryList({
        ...defaultHistoryList,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    }
  }, [selectValue]);

  useEffect(() => {
    setDefaultHistoryList({
      ...defaultHistoryList,
      key: searchKeyword,
    });
  }, [searchKeyword]);

  const navigateFnc = async (type, no) => {
    let path;

    if (type === "ORDERS") {
      path = `/management/order/document/${no}`;
    } else if (type === "CONTRACT") {
      path = `/management/contract/document/${no}`;
    } else if (type === "BID") {
      path = `/management/estimate/document/${no}`;
    }

    navigate(path);
  };

  return (
    <S.Wrap>
      <Mheader title="히스토리" />
      <S.Header>
        <S.Title>나의 히스토리 현황</S.Title>

        <S.DateRangeSelect onChange={onChangeSelectValue} value={selectValue}>
          <option>최근 1개월</option>
          <option>최근 3개월</option>
          <option>최근 6개월</option>
          <option>최근 1년</option>
          <option>전체</option>
        </S.DateRangeSelect>

        <S.DateContainer>
          <S.DateWrap>
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setDefaultHistoryList({
                  ...defaultHistoryList,
                  startDate: moment(date).format("yyyy-MM-DD"),
                  nowPage: 1,
                });
              }}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              locale={ko}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </S.DateWrap>

          <S.DateWrap>
            <ReactDatePicker
              selected={endDate}
              onChange={(date) => {
                setEndDate(date);
                setDefaultHistoryList({
                  ...defaultHistoryList,
                  endDate: moment(date).format("yyyy-MM-DD"),
                  nowPage: 1,
                });
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
              locale={ko}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </S.DateWrap>
        </S.DateContainer>

        <S.SearchInput
          placeholder={PLACEHOLDER.HISTORY_SEARCH_INPUT}
          onChange={onChangeSearchKeyword}
          onKeyPress={onKeyPressFetchHistoryList}
        />

        <S.SearchBtn onClick={fetchHistoryList}>검색</S.SearchBtn>
      </S.Header>

      <S.SearchResult>
        검색결과 :<span>{maxNum}</span>건
      </S.SearchResult>

      {isDesktop && (
        <S.HistoryListWrap>
          <S.ListTitle>
            <span>활동</span>
            <span>담당자</span>
            <span>번호</span>
            <span>현장명</span>
            <span>품목명</span>
            <span>날짜</span>
            <span> </span>
          </S.ListTitle>

          {fetchedHistoryList?.map((data) => (
            <S.ListContent key={data?.history?.no}>
              <span title={data?.history?.action}>
                {data?.history?.action || "-"}
              </span>
              <span title={data?.user?.name}>{data?.user?.name || "-"}</span>
              <span title={data?.history?.code}>
                {data?.history?.code || "-"}
              </span>
              <span title={data?.history?.siteName}>
                {data?.history?.siteName || "-"}
              </span>
              <span title={data?.history?.productName}>
                {data?.history?.productName || "-"}
              </span>
              <span
                title={data?.history?.created_date
                  ?.replace("T", " ")
                  .slice(0, 16)}
              >
                {data?.history?.created_date?.replace("T", " ").slice(0, 16) ||
                  "-"}
              </span>
              <button
                onClick={() =>
                  navigateFnc(data?.history?.type, data?.history?.typeNo)
                }
              >
                상세보기
              </button>
            </S.ListContent>
          ))}
        </S.HistoryListWrap>
      )}

      {isMobile && (
        <S.M_HistoryListContainer>
          {fetchedHistoryList?.map((data) => (
            <S.M_HistoryListWrap key={data?.history?.no}>
              <S.M_HistoryListHeader>
                {data?.history?.code || "-"} |{" "}
                {data?.history?.created_date?.replace("T", " ").slice(0, 16)}
              </S.M_HistoryListHeader>
              <S.M_HistoryListBody>
                <div>현장명 : {data?.history?.siteName || "-"}</div>
                <div>활동 : {data?.history?.action}</div>
                <div>담당자 : {data?.user?.name}</div>
                <div>품목명 : {data?.history?.productName}</div>
                <div>
                  가격 : {data?.history?.price?.toLocaleString("ko") || "0"}원
                </div>
              </S.M_HistoryListBody>
              <S.M_HistoryListFooter>
                <S.M_HistoryDetailBtn
                  onClick={() =>
                    navigateFnc(data?.history?.type, data?.history?.typeNo)
                  }
                >
                  상세보기
                </S.M_HistoryDetailBtn>
              </S.M_HistoryListFooter>
            </S.M_HistoryListWrap>
          ))}
        </S.M_HistoryListContainer>
      )}

      <HistoryPagenation
        maxNum={maxNum}
        nowNum={defaultHistoryList.nowPage}
        setFirstData={setDefaultHistoryList}
        firstData={defaultHistoryList}
        onClickSetTempVar={onClickSetTempVar}
      />
    </S.Wrap>
  );
}
