import styled from "@emotion/styled";

export const TooltipBottom = styled.div`
  position: absolute;
  bottom: ${(props) => {
    if (props.popUpLocation === "RequestChoice") return "-55px";
  }};
  bottom: ${(props) => {
    if (props.popUpLocation === "ProductShareList") return "25px";
  }};
  bottom: ${(props) => {
    if (props.popUpLocation === "ProductShareDetail") return "-140px";
  }};
  left: ${(props) => {
    if (props.popUpLocation === "RequestChoice") return "-5px";
  }};
  left: ${(props) => {
    if (props.popUpLocation === "ProductShareList") return "253px";
  }};
  left: ${(props) => {
    if (props.popUpLocation === "ProductShareDetail") return "380px";
  }};
  border-radius: 5px;

  --padding: 10px;

  width: 280px;
  height: 130px;
  background: #fff;
  padding: var(--padding);
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
  z-index: 11;
  cursor: default !important;

  &:after {
    content: "";
    position: absolute;
    top: 0%;
    left: 70%;
    left: ${(props) => {
      if (props.popUpLocation === "RequestChoice") return "38%";
    }};
    left: ${(props) => {
      if (props.popUpLocation === "ProductShareDetail") return "90%";
    }};
    transform: translate(-50%, -50%) rotate(45deg);
    background: #fff;
    height: 16px;
    width: 16px;
  }

  @media (max-width: 1080px) {
    width: 100%;
    left: 0;
    &:after {
      left: 95%;
      left: ${(props) => {
        if (props.popUpLocation === "ProductShareList") return "87%";
      }};
    }
    bottom: ${(props) => {
      if (props.popUpLocation === "ProductShareList") return "195px";
    }};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 20px;
`;

export const CancelBtn = styled.button`
  width: 15px !important;
  height: 15px !important;
  margin-bottom: 10px;
`;

export const CancelIcon = styled.img`
  width: 15px !important;
  height: 15px !important;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    padding-bottom: 15px;
  }
`;

export const MailBtn = styled.button`
  width: 35px !important;
  height: 35px !important;
  margin-bottom: 10px;
`;

export const MailIcon = styled.img`
  width: 35px !important;
  height: 35px !important;
`;

export const footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 0 5px;
  background-color: #ccc;
`;

export const Url = styled.input`
  width: 165px;
  height: 20px;
  line-height: 20px;
  text-indent: 0 !important;
  box-sizing: unset !important;
  padding: 0 5px;
  font-size: 11px;
  background: #fff;
  cursor: text;
  @media (max-width: 1080px) {
    width: 83%;
  }
`;

export const CopyBtn = styled.button`
  width: 70px;
  height: 20px;
  line-height: 20px;
  font-weight: 500;
  font-size: 9px;
  background: #fff;

  &:hover {
    background: #ff6600;
    color: #fff;
  }
`;
