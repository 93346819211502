import React, { useState, useEffect, useCallback } from "react";
//library
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { RingProgress, Pie, Column } from "@ant-design/plots";

//api
import { fetchDashboardApi, getMainApi } from "../../api/auth.js";

//css
import "../../asset/css/main.css";
import user_icon from "../../asset/images/dashboard-user.png";
import bid_request_icon from "../../asset/images/dashboard-bid-request.png";
import order_new_icon from "../../asset/images/dashboard-order-new.png";
import order_confirm_icon from "../../asset/images/dashboard-order-confirm.png";
import order_reject_icon from "../../asset/images/dashboard-order-reject.png";
import chat_icon from "../../asset/images/dashboard-chat.png";
import map_animated from "../../asset/images/dashboard-map-animated.gif";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default";
import { isSafari, isMobile } from "react-device-detect";

//sweet alert
import swal from "sweetalert";
import { Empty } from "antd";
import ReactDatePicker from "react-datepicker";
import ko from "date-fns/locale/ko"; // 한국어적용
import Mheader from "../../component/common/Mheader.js";

function Main() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });

  //redux
  const signToken = useSelector((state) => state.token);
  const userData = useSelector((state) => state.user);

  // 검색 관련
  const [keyword, setKeyword] = useState("");
  const [headerFocus, setHeaderFocus] = useState(false);
  const [recentKeyword, setRecentKeyword] = useState([]);
  const recentSearchList = useSelector((state) => state.activity.activity_list);

  const searchFnc = (headerKey) => {
    if (keyword === "" && headerKey === undefined) {
      return swal("검색어를 입력해주세요.", "", "warning");
    }
    //focus 닫기
    setHeaderFocus(false);
    let copy = recentKeyword; //여기서 분기해주기
    copy.unshift(keyword); //push는 뒤에 추가, unshift는 앞에 추가
    setRecentKeyword(copy);

    let total = {
      activity_list: recentKeyword,
    };

    dispatch(actions.setActivity(total));

    let totalKeyword = headerKey ? headerKey : keyword;

    //keyword갖고 이동
    navigate("/my_page/my_info/product_registration", {
      state: { headerKeyword: totalKeyword },
    });
  };

  const _onKeyPress = (e) => {
    if (e.key == "Enter") {
      searchFnc();
    }
  };

  // dashboard
  const date = new Date();
  const curMonth = date.getMonth() + 1;
  const curYear = date.getFullYear();

  const [dashboard, setDashboard] = useState();
  const [bidRateChartDate, setBidRateChartDate] = useState(
    new Date(`${curYear}-${curMonth}-01`)
  );
  const [amountPerSiteChartDate, setAmountPerSiteChartDate] = useState(
    new Date(`${curYear}-${curMonth}-01`)
  );
  const [amountPerMonthChartDate, setAmountPerMonthChartDate] = useState(
    new Date(`${curYear}-${curMonth}-01`)
  );
  const [realAmountPerMonthChartDate, setRealAmountPerMonthChartDate] =
    useState(curMonth === 1 ? new Date(`${curYear - 1}-01-01`) : new Date());

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const fetchDashboard = useCallback(async () => {
    try {
      const response = await fetchDashboardApi({
        companyNo: userData?.c_no,
        bidRateDate: formatDate(bidRateChartDate),
        amountPerSiteDate: formatDate(amountPerSiteChartDate),
        companyMonthAmountDate: formatDate(amountPerMonthChartDate),
        amountPerMonthDate: formatDate(realAmountPerMonthChartDate),
      });
      setDashboard(response.data);
    } catch (error) {
      swal("메인 정보를 가져오지 못하였습니다.", "", "warning");
      dispatch(actions.setToken(false));
      dispatch(actions.setUser(userDefault));
    }
  }, [
    dispatch,
    userData?.c_no,
    bidRateChartDate,
    amountPerSiteChartDate,
    amountPerMonthChartDate,
    realAmountPerMonthChartDate,
  ]);

  useEffect(() => {
    if (signToken !== false) {
      fetchDashboard();
    }
  }, [fetchDashboard, signToken]);

  // 입찰참여율
  const getBidRatePercent = (index) => {
    const bidCount = dashboard?.bidRateInfoList[index]?.offeredBidCount;
    const totalCount = dashboard?.bidRateInfoList[index]?.totalBidCount;

    const percent = bidCount / totalCount;

    return percent;
  };
  const getBidRateYear = (index) => {
    const year = dashboard?.bidRateInfoList[index]?.year;

    return year;
  };
  const getBidRateQuarter = (index) => {
    const quarter = dashboard?.bidRateInfoList[index]?.quarter;

    return quarter;
  };
  const prevBidRateConfig = {
    width: isMobile && 140,
    height: isMobile && 110,
    autoFit: false,
    percent: getBidRatePercent(0) || 0,
    color: ["#F4664A", "#E8EDF3"],
    innerRadius: 0.85,
    radius: 1,
    statistic: {
      title: {
        offsetY: -10,
        style: {
          color: "#6C6C6C",
          fontSize: "2rem",
        },
        formatter: () => `${getBidRateYear(0)}년 ${getBidRateQuarter(0)}분기`,
      },
      content: {
        offsetY: -6,
        style: {
          fontSize: "4rem",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#11366B",
          fontWeight: 600,
        },
      },
    },
  };
  const currentBidRateConfig = {
    width: isMobile && 140,
    height: isMobile && 110,
    autoFit: false,
    percent: getBidRatePercent(1) || 0,
    color: ["#F4664A", "#E8EDF3"],
    innerRadius: 0.85,
    radius: 1,
    statistic: {
      title: {
        offsetY: -10,
        style: {
          color: "#6C6C6C",
          fontSize: "2rem",
        },
        formatter: () => `${getBidRateYear(1)}년 ${getBidRateQuarter(1)}분기`,
      },
      content: {
        offsetY: -6,
        style: {
          fontSize: "4rem",
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#11366B",
          fontWeight: 600,
        },
      },
    },
  };

  // 현장별 수주액
  const realAmountPerSiteConfig = {
    height: isDesktop ? 400 : 270,
    data: dashboard?.realAmountPerSiteList,
    angleField: "amount",
    colorField: "siteName",
    legend: {
      position: "bottom",
    },
    radius: isDesktop ? 1 : 0.8,
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { name, value, color, data } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">
                        {Number(value)?.toLocaleString("ko")}원
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 12,
        textAlign: "center",
      },
      autoRotate: false,
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  // 월별 수주액
  const prevCurRealAmountPerMonthConfig = {
    appendPadding: 20,
    data: dashboard?.companyMonthAmountInfoList,
    xField: "month",
    yField: "amount",
    label: {
      position: "top",
      style: {
        fill: "#000",
        opacity: 0.6,
      },
      formatter: (v) => `${v?.amount?.toLocaleString("ko")}원`,
    },
    xAxis: {
      label: {
        formatter: (v) => `${v}월`,
      },
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ({ month }) => {
      if (month === dashboard?.companyMonthAmountInfoList[1].month) {
        return "#0047A5";
      }
      return "#B8E1FF";
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}월</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { value, color } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="g2-tooltip-list-item-value">
                        {Number(value)?.toLocaleString("ko")}원
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
  };

  // 월별 매출액
  const columnChartTotalAmount = dashboard?.realAmountPerMonthsList?.reduce(
    (acc, cur) => acc + +cur.amount,
    0
  );
  const realAmountPerMonthConfig = {
    appendPadding: 20,
    data: dashboard?.realAmountPerMonthsList,
    xField: "month",
    yField: "amount",
    label: isDesktop && {
      position: "top",
      style: {
        fill: "#000",
        opacity: 0.6,
      },
      formatter: (v) => `${v?.amount?.toLocaleString("ko")}원`,
    },
    xAxis: {
      label: {
        formatter: (v) => `${v}월`,
      },
    },
    yAxis: {
      label: {
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    scrollbar: {
      height: isDesktop ? 0 : 8,
    },
    minColumnWidth: 32,
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}월</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { value, color } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <span className="g2-tooltip-list-item-value">
                        {Number(value)?.toLocaleString("ko")}원
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
  };

  return signToken !== false && dashboard !== false ? (
    <div className="dashboard_wrap">
      <Mheader title="대시보드" />
      <div className="dashboard_container">
        <div className="dashboard_inner_container">
          {/* main status */}
          <div className="dashboard_count_container">
            <div
              className="dashboard_count_inner_container"
              onClick={() => {
                navigate("/request/1", {
                  state: {
                    fromPage: "dashboard_page",
                  },
                });
              }}
            >
              <img src={bid_request_icon} alt="bid_request_icon" />
              <div>
                <div className="dashboard_count_title">입찰 공고</div>
                <div className="dashboard_count">
                  {dashboard?.mainStatus?.requestBid}건
                </div>
              </div>
            </div>
            <div
              className="dashboard_count_inner_container"
              onClick={() => {
                navigate("/management/order/1", {
                  state: {
                    fromPage: "dashboard_page_order_new",
                  },
                });
              }}
            >
              <img src={order_new_icon} alt="order_new_icon" />
              <div>
                <div className="dashboard_count_title">발행완료</div>
                <div className="dashboard_count">
                  {dashboard?.mainStatus?.newOrder}건
                </div>
              </div>
            </div>
            <div
              className="dashboard_count_inner_container"
              onClick={() => {
                navigate("/management/order/1", {
                  state: {
                    fromPage: "dashboard_page_order_confirm",
                  },
                });
              }}
            >
              <img src={order_confirm_icon} alt="order_confirm_icon" />
              <div>
                <div className="dashboard_count_title">주문확인</div>
                <div className="dashboard_count">
                  {dashboard?.mainStatus?.confirmedOrder}건
                </div>
              </div>
            </div>
            <div
              className="dashboard_count_inner_container"
              onClick={() => {
                navigate("/management/order/1", {
                  state: {
                    fromPage: "dashboard_page_reject",
                  },
                });
              }}
            >
              <img src={order_reject_icon} alt="bid_choose_icon" />
              <div>
                <div className="dashboard_count_title">검수반려</div>
                <div className="dashboard_count">
                  {dashboard?.mainStatus?.rejectOrder}건
                </div>
              </div>
            </div>
            <div
              className="dashboard_count_inner_container"
              onClick={() => navigate("/qna")}
            >
              <img src={chat_icon} alt="bid_choose_icon" />
              <div>
                <div className="dashboard_count_title">질의응답</div>
                <div className="dashboard_count">
                  {dashboard?.mainStatus?.newChatRoom}건
                </div>
              </div>
            </div>
            {isDesktop && (
              <div className="dashboard_count_user_container">
                <img src={user_icon} alt="dashboard_user_icon" />
              </div>
            )}
          </div>

          {/* tax invoice group */}
          <div className="dashboard_invoice_container">
            <div className="dashboard_title">세금계산서</div>
            <div className="dashboard_invoice_inner_container">
              {dashboard?.taxInvoiceGroupList?.map((invoice) => (
                <div className="dashboard_invoice">
                  <div className="dashboard_invoice_header">
                    <div>
                      {invoice.year}.{invoice.month}월
                    </div>
                    <div>{invoice.realAmountSum.toLocaleString()}원</div>
                  </div>

                  <div className="dashboard_invoice_divider" />

                  <div className="dashboard_invoice_body">
                    {invoice.taxInvoiceInfoList?.map((invoiceInfo) => (
                      <div className="dashboard_invoice_body_align">
                        <div>{invoiceInfo?.issueDate}</div>
                        <div>{invoiceInfo?.realAmount?.toLocaleString()}원</div>
                      </div>
                    ))}
                    {invoice.taxInvoiceInfoList?.length === 0 && (
                      <Empty
                        className="dashboard_invoice_empty"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span>내역이 존재하지 않습니다.</span>}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="dashboard_chart_container">
            {/* bid rate */}
            <div className="bid_rate_chart_container">
              <div className="dashboard_title">
                <span>입찰참여율</span>
              </div>
              <div className="dashboard_chart_inner_container">
                <div className="month_picker_container">
                  <ReactDatePicker
                    className="month_picker"
                    selected={bidRateChartDate}
                    onChange={(date) => setBidRateChartDate(date)}
                    showQuarterYearPicker
                    dateFormat="yyyy-QQQ"
                    locale={ko}
                  />
                </div>
                <div>
                  {dashboard && (
                    <RingProgress
                      {...prevBidRateConfig}
                      className="ring_chart"
                    />
                  )}
                  {dashboard && (
                    <RingProgress
                      {...currentBidRateConfig}
                      className="ring_chart"
                    />
                  )}
                </div>
              </div>
              <div className="dashboard_chart_footer">
                <div>※ 비율기준: 입찰참여 건수</div>
                <div>※ 입찰 마감 기준</div>
              </div>
            </div>

            {/* real amount per site */}
            <div className="real_amount_per_site_chart_container">
              <div className="dashboard_real_amount_title">
                <span>{`${amountPerSiteChartDate.getFullYear()}.${
                  amountPerSiteChartDate.getMonth() + 1
                }월 `}</span>
                <span>현장별 수주액</span>
              </div>
              <div>
                <div className="month_picker_container">
                  <ReactDatePicker
                    className="month_picker"
                    selected={amountPerSiteChartDate}
                    onChange={(date) => setAmountPerSiteChartDate(date)}
                    showMonthYearPicker
                    dateFormat="yyyy-MM"
                    locale={ko}
                  />
                </div>
                {dashboard?.realAmountPerSiteList?.length >= 1 && (
                  <Pie {...realAmountPerSiteConfig} className="pie_chart" />
                )}
                {dashboard?.realAmountPerSiteList?.length === 0 && (
                  <div className="dashboard_empty_container">
                    <Empty
                      description={<span>내역이 존재하지 않습니다.</span>}
                    />
                  </div>
                )}
              </div>
              <div className="dashboard_chart_footer">
                <div>※ 비율기준: 주문금액</div>
                <div>※ 주문서 발행일 기준</div>
              </div>
            </div>

            {/* prev, current real amount */}
            <div className="real_amount_per_site_chart_container">
              <div className="dashboard_real_amount_title">
                <span>{`${amountPerMonthChartDate.getFullYear()}.${
                  amountPerMonthChartDate.getMonth() + 1
                }월 `}</span>
                <span>월별 수주액</span>
              </div>
              <div>
                <div className="month_picker_container">
                  <ReactDatePicker
                    className="month_picker"
                    selected={amountPerMonthChartDate}
                    onChange={(date) => setAmountPerMonthChartDate(date)}
                    showMonthYearPicker
                    dateFormat="yyyy-MM"
                    locale={ko}
                  />
                </div>
                {dashboard?.companyMonthAmountInfoList?.length >= 1 && (
                  <Column
                    {...prevCurRealAmountPerMonthConfig}
                    className="column_chart"
                  />
                )}
                {dashboard?.companyMonthAmountInfoList?.length === 0 && (
                  <div className="dashboard_empty_container">
                    <Empty
                      description={<span>내역이 존재하지 않습니다.</span>}
                    />
                  </div>
                )}
              </div>
              <div className="dashboard_chart_footer">
                <div>※ 비율기준: 주문금액</div>
                <div>※ 주문서 발행일 기준</div>
              </div>
            </div>
          </div>

          {/* real amount per month */}
          <div className="real_amount_per_month_chart_container">
            <div className="dashboard_title">
              <span>월별 매출액</span>
              <span>{`${realAmountPerMonthChartDate.getFullYear()}년 총 주문금액: ${columnChartTotalAmount?.toLocaleString(
                "ko"
              )}원`}</span>
            </div>
            <div className="year_picker_container">
              <ReactDatePicker
                className="year_picker"
                selected={realAmountPerMonthChartDate}
                onChange={(date) => setRealAmountPerMonthChartDate(date)}
                showYearPicker
                dateFormat="yyyy"
              />
            </div>
            <div>
              {dashboard?.realAmountPerMonthsList?.length >= 1 && (
                <Column
                  {...realAmountPerMonthConfig}
                  className="column_chart"
                />
              )}
            </div>
            <div className="dashboard_chart_footer">
              <div>※ 세금계산서 발행일 기준</div>
            </div>
          </div>

          {/* site information */}
          <div className="site_info_container">
            <div className="site_nearby_container">
              <div className="dashboard_title">
                <span>인근현장정보</span>
                <span
                  className="site_look_route_btn"
                  onClick={() => navigate("look")}
                >{`바로가기 >`}</span>
              </div>
              <div
                className="site_img_container"
                onClick={() => navigate("look")}
              >
                <img src={map_animated} alt="map_animated" />
              </div>
            </div>
            <div className="site_new_container">
              <div className="dashboard_title">
                <span>신규현장현황</span>
              </div>
              <div className="site_new_content_container">
                <div className="site_new_content_header">
                  <div>고객사</div>
                  <div>현장명</div>
                  <div>등록일자</div>
                </div>
                <div className="site_new_content_divider" />
                <div className="site_new_content_body">
                  {dashboard?.newSiteInfoList?.map((site) => (
                    <div className="site_new_content_body_align">
                      <div title={site.constructionName}>
                        {site.constructionName}
                      </div>
                      <div title={site.siteName}>{site.siteName}</div>
                      <div>{site.createdDate?.slice(0, 10)}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="main_wrap">
      <div className="main_container">
        <p>
          공새로에 오신 것을 <br /> 환영합니다.
        </p>
        <p>지금 바로 공새로의 효율적인 발주 업무 경험을 누려보세요.</p>
        <div className="main_link">
          <Link to="/login">로그인</Link>
          <a
            onClick={() => {
              if (isMobile && isSafari) {
                swal(
                  "아이폰 사파리 유저의 경우에는 \n설정 > 사파리 > 팝업차단에서 팝업차단을 해제해주셔야 정상적으로 회원가입을 진행할 수 있습니다.",
                  "",
                  "info"
                );
              }
              navigate(`/join`, { state: { socialType: "", socialValue: "" } });
            }}
          >
            회원가입
          </a>
        </div>
      </div>
    </div>
  );
}

export default Main;
