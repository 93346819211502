import styled from "@emotion/styled";

export const BtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 50px;
  button {
    width: 170px;
    height: 40px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    margin-right: 20px;
    transition: 0.2s all;
  }
  @media (max-width: 1080px) {
    justify-content: space-between;
    padding: 0 6rem;
    button {
      width: 45%;
      height: 10rem;
      font-size: 4rem;
      margin-right: 0;
    }
  }
`;

export const DownloadBtn = styled.button`
  color: #002863;
  background: #fff;
  border: 2px solid rgba(0, 40, 99, 0.8);
  box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
  &:hover {
    background: #002863;
    color: #fff;
  }
`;

export const CompanionBtn = styled.button`
  color: #fff;
  background: #ff6600;
  border: 2px solid #ff6600;
  box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
  &:hover {
    border: 2px solid #ff6600;
    background: #fff;
    color: #ff6600;
  }
`;

export const UploadBtn = styled.button`
  color: #fff;
  background: #002863;
  border: 2px solid #002863;
  box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
  &:hover {
    border: 2px solid #002863;
    background: #fff;
    color: #002863;
  }
`;

export const CompleteBtn = styled.button`
  color: #fff;
  background: #002863;
  border: 2px solid #002863;
  box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
  &:hover {
    border: 2px solid #002863;
    background: #fff;
    color: #002863;
  }
`;
