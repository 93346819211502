import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
//library
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
//etc
import i_close from "../../asset/images/w-cancel-icon.png";
import share from "../../asset/images/icon_share.png";
import product from "../../asset/images/test_product_img.png";
//css
import "../../asset/css/productPop.css";

import ShareTooltip from "../../component/etc/ShareTooltip";

import { productDetailApi } from "../../api/auth";

//sweet alert
import swal from "sweetalert";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";

export default function Test() {
  //

  const dispatch = useDispatch();
  const location = useLocation();
  const { cartItem, setCartItem } = useOutletContext();
  const { id } = useParams();
  const navigate = useNavigate();

  const [category, setCategory] = useState("");
  const [productImg, setProductImg] = useState(""); //product img
  const [productInfo1, setProductInfo1] = useState([]); //product 정보1 - 이름과 브랜드만 쓰는 중
  const [productInfo2, setProductInfo2] = useState([]); //product 정보2 - 옵션 내용만 보기

  const [openShareTooltip, setOpenShareTooltip] = useState(false);
  const [counter, setCounter] = useState(1);

  const { excel_list, choice_list, bid_period, bid_single, bid_state } =
    useSelector((state) => ({
      excel_list: state.estimate.excel_list,
      choice_list: state.estimate.choice_list,
      bid_period: state.estimate.bid_period,
      bid_single: state.estimate.bid_single,
      bid_state: state.estimate.bid_state,
    }));

  //cart
  const _addItem2Cart = (item) => {
    const filterCartItem = {
      cartAmount: counter,
      file: productImg,
      brand: item.brand,
      comment: item.comment,
      etc: item.etc,
      fileAttach: item.fileAttach,
      fileImg: item.fileImg,
      modelCode: item.modelCode,
      name: item.name,
      no: item.no,
      price: item.price,
      reason: item.reason,
      regdate: null,
      scNo: item.scNo,
      standard: item.standard,
      state: item.state,
      unit: item.unit,
      update: item.updatedDate,
    };

    if (cartItem?.some((product) => product.no == filterCartItem.no)) {
      //이미 카트에 있는 상품
      swal(
        "",
        `이미 존재하는 품목입니다.
        장바구니에서 수량을 조절할 수 있습니다.`,
        "warning"
      );
      navigate("/management/order/request_choice");
    } else {
      //새로운 상품
      let newCartList = cartItem;
      newCartList.push(filterCartItem);
      setCartItem(newCartList);

      // dispatch해주기
      let totalBid = {
        excel_list: excel_list,
        choice_list: cartItem, // 초이스리스트 저장
        bid_period: bid_period, // 해당 객체에 코멘트 추가저장
        bid_single: bid_single, // 해당 객체에 코멘트 추가저장
        bid_state: "choice", // 마지막 비드타입 저장
      };

      dispatch(actions.setEstimate(totalBid));

      navigate("/management/order/request_choice");
    }
  };

  const productDetailFnc = async () => {
    const response = await productDetailApi(id);

    if (response.status === "success") {
      setCategory(response.data.categoryInfo);
      setProductImg(response.data.productImg);
      setProductInfo1(response.data.product);
      setProductInfo2(response.data.productOptionList);
    }
  };

  useEffect(() => {
    productDetailFnc();
  }, []);

  return (
    <div className="pop">
      <div className="pop_dim"></div>
      <div className="product_pop">
        {/* <Link className="close" to="/management/estimate/request_choice"> */}
        <div className="close">
          <img
            src={i_close}
            alt=""
            onClick={() => {
              navigate("/management/order/request_choice");
            }}
          />
        </div>
        {/* </Link> */}
        <h3 className="pop_tit">품목 보기</h3>
        <div className="product_info">
          <div className="product_left_container">
            <span>
              {category.categoryL} 〉{category.categoryM} 〉{category.categoryS}
            </span>
            <div className="product_img_box">
              <img alt="" src={productImg} />
            </div>
            <div className="product_package_box">
              <img alt="" src={product} />
              <div className="dim">현재 패키지 서비스는 준비중입니다</div>
            </div>
          </div>
          <div className="product_right_container">
            <div className="product_desc_header">
              <span>
                {productInfo1.brand}
                {productInfo1.name}
              </span>
              <div
                className="share_box"
                onClick={() => {
                  setOpenShareTooltip(!openShareTooltip);
                }}
              >
                <img alt="" src={share} />
              </div>
              {openShareTooltip && (
                <ShareTooltip
                  openShareTooltip={openShareTooltip}
                  setOpenShareTooltip={setOpenShareTooltip}
                  data={productInfo1}
                  startingPoint="ProductShareDetail"
                  popUpLocation="ProductShareDetail"
                />
              )}
            </div>

            <div className="product_desc_contents">
              {/* x6 */}
              {productInfo2.map((a, i) => {
                return (
                  <div className="product_desc_line">
                    <span className="product_desc_key">{a.name}</span>
                    <span className="product_desc_value">{a.value}</span>
                  </div>
                );
              })}
            </div>

            <div className="product_desc_footer">
              <h3>{productInfo1.name}</h3>
              <div className="option_box">
                <div className="option_select_box">
                  <span>옵션선택</span>
                  <select disabled className="option_select">
                    <option selected>현재 준비 중입니다.</option>
                  </select>
                </div>
                <div className="option_count">
                  <span
                    className="count_minus"
                    onClick={() => {
                      if (counter === 1) {
                        alert("0이하의 숫자는 입력할 수 없습니다.");
                        return false;
                      }
                      setCounter(counter - 1);
                    }}
                  >
                    {" "}
                    -{" "}
                  </span>
                  <span> {counter}개 </span>
                  <span
                    className="count_plus"
                    onClick={() => {
                      setCounter(counter + 1);
                    }}
                  >
                    {" "}
                    +{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="product_btn_box">
              <span
                className="product_btn1"
                onClick={() => {
                  // _addItem2Cart(productInfo1);
                  _addItem2Cart(productInfo1);
                }}
              >
                장바구니 담기
              </span>
              {/* <span className="product_btn2">바로 구매</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
