import React, { useEffect, useState } from "react";
import i_close from "../../../asset/images/w-cancel-icon.png";
import "../../../asset/css/contractSentPop.css";
import { wonComma } from "../../../util/Util";
import {
  getMy_ContractInfoApi,
  changeContractCancelApi,
} from "../../../api/auth";
import swal from "sweetalert";

export default function ContractChangeSentReqPop({
  setSentPop,
  conInfo,
  move2Success,
}) {
  // const [sentPlist, setSentPlist] = useState([]);
  const [sentConInfo, setSentConInfo] = useState(conInfo);
  //
  const [sentPlist, setSentPlist] = useState(conInfo.productList);
  // const getMy_ContractInfo = async() => {
  //     const value = countList.at(-1);
  //     const response = await getMy_ContractInfoApi(
  //         value.ct_no,
  //         value.ct_parent,
  //         value.ct_count,
  //     );

  //     if(response.status === 'success'){
  //
  //
  //         setSentConInfo(response.data.conInfo);
  //         setSentPlist(response.data.pList);
  //     }
  // }

  const changeContractCancelFnc = async () => {
    const response = await changeContractCancelApi(sentConInfo.ct_no);

    if (response.status === "success") {
      swal("취소되었습니다.", "", "success");
      setSentPop(false);
      move2Success();
    }
  };

  /** 변경계약요청 취소하기 */
  const sentCancelFnc = async () => {
    swal({
      title: "취소",
      text: "변경계약 요청을 취소 하시겠습니까?",
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        changeContractCancelFnc();
      }
    });
  };

  // useEffect(()=>{
  //     getMy_ContractInfo();
  // },[]);

  return (
    <div className="sent_info_pop">
      <div className="pop_dim" onClick={() => setSentPop(false)}></div>
      <div className="sent_info_pop_box">
        <div className="sent_close" onClick={() => setSentPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3>변경계약 요청내역</h3>

        <div className="sent_box">
          <div className="sent_target_box">
            <span></span>

            <div>
              <div className="table_container">
                <table>
                  <thead>
                    <tr>
                      <th className="table_name">품목명</th>
                      <th className="table_amount">단가 / 수량</th>
                      <th className="table_amount">금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sentPlist?.map((a, i) => {
                      return (
                        <tr>
                          <td>{a.bp_name}</td>
                          <td>
                            {wonComma(a.od_price)}원 &nbsp;/&nbsp;&nbsp;
                            {a.od_count}(개)
                          </td>
                          <td>{wonComma(a.od_count * a.od_price)}원</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr className="sent_sum">
                      <td>합계</td>
                      <td colSpan="2">
                        {wonComma(
                          sentPlist
                            ?.map((data) => data.bp_count * data.od_price)
                            .reduce((sum, current) => sum + current, 0)
                        )}
                        원
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div class="sent_date">
                <div class="sent_date_tit">
                  <p>계약일시</p>
                </div>
                <div class="sent_date_content">
                  <p>
                    {sentConInfo?.ct_sdate}&nbsp;~&nbsp;
                    {sentConInfo?.ct_edate}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="line"></div>

        <div className="caution_txt2">
          <span>
            고객사 및 공급사의 일방적인 계약중지에 따른 패널티가 발생할 수
            있습니다. <br />
            계약중지/취소 시 상호 간 사전협의 후 진행해주세요.
          </span>
          <span
            className="caution_txt_cancel"
            onClick={() => {
              sentCancelFnc();
            }}
          >
            취소하기
          </span>
        </div>

        <div className="edit_position_btn btn_box">
          <div className="ok" onClick={() => setSentPop(false)}>
            <p>확인</p>
          </div>
        </div>
      </div>
    </div>
  );
}
