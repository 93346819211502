import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { invoiceListApi } from "../../../api/auth";

export default function useInvoiceList() {
  const userData = useSelector((state) => state.user);
  const [invoiceList, setInvoiceList] = useState();
  const [state, setState] = useState("all");

  const fetchInvoiceList = useCallback(
    async (state) => {
      const response = await invoiceListApi(userData.c_no, state);
      
      if (response.status === "success") {
        setInvoiceList(response.data);
        setState(state);
      }
    },
    [userData.c_no]
  );

  useEffect(() => {
    fetchInvoiceList("all");
  }, [fetchInvoiceList]);

  return { invoiceList, state, fetchInvoiceList };
}
