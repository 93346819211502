import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { IMP_CODE } from "../../../constant/Constant";
import i_close from "../../../asset/images/w-cancel-icon.png";
//css
// import '../../../asset/css/myInfo.css';

import EditPositionPop from "../../../component/common/Pop/EditPositionPop";
import MyInfoEditPwPop from "../../../component/common/Pop/MyInfoEditPwPop";
import MyInfoWithdrawalPop from "../../../component/common/Pop/MyInfoWithdrawalPop";

import "../../../asset/css/myInfoEditContainerPop.css";

import { changePhone } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function ChatWhoPop({ setEditMyInfoPop, move2Success }) {
  const navigate = useNavigate();
  // maxwidth 구분
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });

  // get popup
  const [editPosition, setEditPosition] = useState(false);
  const [editPw, setEditPw] = useState(false);
  const [withdrawal, setWithdrawal] = useState(false);

  // 휴대폰 번호 변경
  const authData = {
    merchant_uid: `gong_auth_${new Date().getTime()}`,
  };

  const onClickCertification = () => {
    const { IMP } = window;
    IMP.init(IMP_CODE);
    IMP.certification(authData, onAuthCertificate);
  };

  const onAuthCertificate = async (response) => {
    const { success, merchant_uid, error_msg, imp_uid } = response;

    if (success) {
      if (merchant_uid !== authData.merchant_uid) {
        swal(`데이터가 변조되었습니다. 다시 시도해주세요.`, "", "warning");
        return;
      }
      //
      const reqData = {
        imp_uid: imp_uid,
      };
      const apiResponse = await changePhone(reqData);

      if (apiResponse.status === "success") {
        move2Success();
      } else {
        swal("변경에 실패하였습니다.", "", "warning");
      }
    } else {
      swal(`본인인증에 실패하였습니다. / 사유 : ${error_msg}`, "", "warning");
      return;
    }
  };

  return (
    <>
      {isResponsive ? (
        <div className="pop">
          <div
            className="pop_dim"
            onClick={() => setEditMyInfoPop(false)}
          ></div>
          <div className="myInfo_container_mobile_pop">
            <div className="close" onClick={() => setEditMyInfoPop(false)}>
              <img src={i_close} alt="" />
            </div>
            <h3 className="pop_tit">내 정보 변경</h3>
            <div className="">
              <ul className="myInfo_list">
                <li onClick={() => navigate("/my_page/my_info/edit")}>
                  <div>업체 정보 수정</div>
                </li>
                <li onClick={() => setEditPosition(true)}>
                  <div>직급 변경</div>
                </li>
                <li onClick={onClickCertification}>
                  <div>휴대폰번호 변경</div>
                </li>
                <li
                  onClick={() => {
                    setEditPw(true);
                  }}
                >
                  <div>비밀번호 변경</div>
                </li>
                <li
                  onClick={() => {
                    navigate("/my_page/my_info/hashtag");
                  }}
                >
                  <div>주요 키워드 변경</div>
                </li>
                <div className="myinfo_withdrawal_container">
                  <p
                    className="myinfo_withdrawal"
                    onClick={() => {
                      setWithdrawal(true);
                    }}
                  >
                    회원탈퇴
                  </p>
                </div>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="myInfo_container_pop">
          <h3>내 정보 변경</h3>
          <ul className="myInfo_container_list">
            <li onClick={() => navigate("/my_page/my_info/edit")} className="">
              <div>업체 정보 수정</div>
            </li>
            <li onClick={() => setEditPosition(true)}>
              <div>직급 변경</div>
            </li>
            <li onClick={onClickCertification}>
              <div>휴대폰번호 변경</div>
            </li>
            <li
              onClick={() => {
                setEditPw(true);
              }}
            >
              <div>비밀번호 변경</div>
            </li>
            <li
              onClick={() => {
                // setEditPw(true);
                navigate("/my_page/my_info/hashtag");
              }}
            >
              <div>주요 키워드 변경</div>
            </li>
            <div className="myinfo_withdrawal_container">
              <p
                className="myinfo_withdrawal"
                onClick={() => {
                  setWithdrawal(true);
                }}
              >
                회원탈퇴
              </p>
            </div>
          </ul>
        </div>
      )}
      {editPosition ? (
        <EditPositionPop
          editPosition={editPosition}
          setEditPosition={setEditPosition}
        />
      ) : null}
      {editPw ? (
        <MyInfoEditPwPop editPw={editPw} setEditPw={setEditPw} />
      ) : null}
      {withdrawal ? (
        <MyInfoWithdrawalPop
          withdrawal={withdrawal}
          setWithdrawal={setWithdrawal}
        />
      ) : null}
    </>
  );
}
