import swal from "sweetalert";

const MAX_FILE_SIZE = 32 * 1024 * 1024;
const VALID_EXTENSIONS = ["png", "jpg", "jpeg", "pdf", "gif"];

export function checkValidationFile(file) {
  const extension = file.name.split(".").pop();

  if (file?.size > MAX_FILE_SIZE) {
    swal("파일 용량이 너무 큽니다. (32MB 제한)", "", "error");
    return false;
  }

  if (!VALID_EXTENSIONS.includes(extension)) {
    swal(
      "파일 확장자가 올바르지 않습니다.",
      `(${VALID_EXTENSIONS.join(", ")}만 가능)`,
      "error"
    );
    return false;
  }

  return file;
}
