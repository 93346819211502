import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

//component
import Mheader from "../../../component/common/Mheader";
import EstimateModifyPop from "../../../component/common/Pop/EstimateModifyPop";

//css
import "../../../asset/css/contractDetail.css";
import * as S from "../../../asset/css/estimate.styles";
//api
import {
  attendChat,
  bidDetailCompanyApi,
  getMyPage_listApi,
  bidDetailApi,
  imgUpApi,
} from "../../../api/auth";

//constant
import { SERVER } from "../../../api/request";

//redux
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import TableSummaryFooter from "../../../component/etc/TableFooter";

// img
import calculator from "../../../asset/images/icon_calculator.png";
import CalculatePricePop from "../../../component/common/Pop/CalculatePricePop";

export default function EstimateDetail(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const userData = useSelector((state) => state.user);
  const pushToken = useSelector((state) => state.push);
  const params = useParams();
  const bid_no = params.id;
  const navigate = useNavigate();

  const [submitPop, setSubmitPop] = useState(false);
  const [modify, setModify] = useState(true);

  //내견적내역 정보받기
  const [pList, setPlist] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [comment, setComment] = useState("");

  const [detailInfo, setDetailInfo] = useState({
    bid: {},
    bidProductList: [],
    offerDetailInfoList: [],
  });

  // 계산기 모달 관련
  const [openModalId, setOpenModalId] = useState(null);

  const openModal = (id) => {
    setOpenModalId(id);
  };

  const closeModal = () => {
    setOpenModalId(null);
  };

  const uc_no = userData.uc_no;
  const bidDetailCompanyFnc = async () => {
    if (uc_no) {
      const res = await bidDetailCompanyApi(bid_no);
      console.log(res.data.productInfoList);
      if (res.status === "success") {
        setPlist(res.data.productInfoList);
        setImgList(res.data.fileInfoList);
        setDetailInfo(res.data);
      }
    }
  };

  useEffect(() => {
    bidDetailCompanyFnc();
  }, [bid_no, modify]);

  //od usestate
  const [od, setOd] = useState([]);
  const setOdState = () => {
    const odData = pList.map((item, index) => {
      const bidProductNo = item.bidProduct?.no;
      const count = item.bidProduct?.count;
      const price = item.offerDetail?.price;
      return {
        bidProductNo: bidProductNo,
        price: price,
        count: count,
      };
    });
    setOd(odData);
  };

  useEffect(() => {
    setOdState();
  }, [pList, modify]);

  const _attendChat = async () => {
    //
    // if(window.confirm("입력하신 단가가 저장되지않습니다.\n투찰 전 질의응답에 참여하시려면 확인을 눌러주세요.")){
    const reqData = {
      bidNo: bid_no,
      // uc_no: userData.uc_no,
    };

    const response = await attendChat(reqData);

    if (response.status === "success") {
      navigate("/qna", {
        state: {
          bidRoomNo: response.data.chatRoom.no,
          selectedChatItem: response.data,
        },
      });
    }
    // }
  };

  // 파일 업로드
  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedFileImg, setUploadedFileImg] = useState(null);
  const [file, setFile] = useState();
  // const [fileNo, setFileNo] = useState();

  const onChangeUploadFile = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadedFile(e.target.files[0]?.name);
    setUploadedFileImg(e.target.files[0]);
  };

  const handleUploadFile = useCallback(async () => {
    let form = new FormData();
    form.append("file", uploadedFileImg);
    const response = await imgUpApi(form);

    if (response.status === "success") {
      setFile(response.data);
    }
  }, [uploadedFileImg]);

  useEffect(() => {
    if (uploadedFileImg) handleUploadFile();
  }, [uploadedFileImg, handleUploadFile]);

  const handleChange = (e, i) => {
    let value = e.target.value;

    // 소수점 제거
    value = value.split(".")[0];

    // 숫자만 입력 가능하게 필터링
    value = value.replace(/[^0-9]/g, "").replace(/^0+/, "");
    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    // 세 자리마다 콤마 추가
    const formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    const updatedPList = [...pList]; // pList 배열을 복사
    updatedPList[i] = {
      ...updatedPList[i],
      offerDetail: {
        ...updatedPList[i].offerDetail,
        price: Number(value),
        displayPrice: formattedValue,
      },
    }; // id번째 요소 업데이트
    setPlist(updatedPList); // 상태 업데이트
  };

  const handleConfirm = (i, taxPrice) => {
    const convertTaxPrice = String(taxPrice);
    const formattedValue = convertTaxPrice.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    const updatedPList = [...pList]; // pList 배열을 복사
    updatedPList[i] = {
      ...updatedPList[i],
      offerDetail: {
        ...updatedPList[i].offerDetail,
        price: Number(convertTaxPrice),
        displayPrice: formattedValue,
      },
    }; // id번째 요소 업데이트
    setPlist(updatedPList); // 상태 업데이트
    closeModal();
  };

  // 초기 마운트 시 displayPrice를 설정하는 useEffect
  useEffect(() => {
    const formattedPList = pList.map((item) => {
      const price = item.offerDetail.price || 0;
      const formattedValue = String(price).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      return {
        ...item,
        offerDetail: {
          ...item.offerDetail,
          displayPrice: formattedValue,
        },
      };
    });
    setPlist(formattedPList);
  }, [modify]);

  return (
    <div class="estimate_ok_wrap">
      <Mheader title="내 입찰 내역" />
      <div class="estimate_ok_container wd">
        <div className="top_pagenation">
          <h2>내 입찰 내역</h2>
          {modify ? (
            <ul className="top_btn_box">
              <li>
                <p onClick={_attendChat}>질의응답</p>
              </li>
              {detailInfo.bid?.state === "ALIVE" && (
                <li>
                  <p
                    onClick={() => {
                      setModify(false);
                    }}
                  >
                    입찰수정
                  </p>
                </li>
              )}
            </ul>
          ) : null}
        </div>
        <div class="quote_info">
          <div className="contract_content">
            <div className="content_top">
              <span>입찰번호 :&nbsp;</span>
              <span className="public_code">{detailInfo.bid?.code}</span>
              {/* <span className="num_line">|</span> */}
            </div>
            <div className="content_btm">
              <div className="content_box">
                <img
                  className="company_img"
                  src={detailInfo?.constructionLogo}
                  alt=""
                />
                <div className="contract_info">
                  <S.BadgeDetail el={detailInfo}>
                    {detailInfo.bid?.state === "ALIVE" && "입찰진행중"}
                    {detailInfo.bid?.state === "CHOOSE" && "선정대기"}
                    {detailInfo.bid?.state === "END" && "입찰마감"}
                    {detailInfo.bid?.state === "CANCEL" && "입찰취소"}
                    {detailInfo.bid?.state === "FAIL" && "유찰"}
                  </S.BadgeDetail>
                  {detailInfo?.bid?.extraType !== null && (
                    <S.ReBadge>
                      {detailInfo?.bid?.extraType === "REBID" && "재입찰"}
                      {detailInfo?.bid?.extraType === "REESTIMATE" &&
                        "재공고입찰"}
                    </S.ReBadge>
                  )}
                  <p className="contractor">
                    <span>
                      [{detailInfo?.constructionName}]&nbsp;
                      {detailInfo?.site?.name}
                    </span>

                    <span>{detailInfo.bid?.createdDate} 발행</span>
                  </p>
                  <div className="contract_text">
                    <p>
                      입찰형태 :{" "}
                      {detailInfo.bid?.type === "T" ||
                      detailInfo.bid?.type === "C"
                        ? `단가계약(${detailInfo.bid?.term}개월)`
                        : detailInfo.bid?.type === "S"
                        ? "일회성 주문"
                        : null}
                    </p>
                    {detailInfo?.bid?.state === "ALIVE" && (
                      <>
                        <p className="bidding_time">
                          남은 투찰시간 : {detailInfo.bidTime}
                          {" 남음"}
                        </p>
                        <p className="bidding_time">
                          투찰 마감일시 : {detailInfo.bid?.endate}{" "}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="quote_content">
            <h3 className="s_tit">
              <p>입찰내용</p>
            </h3>
            <div className="quote_list_box">
              <ul className="quote_list">
                {/* <li>
                  <p>진행공정</p>
                  <span>{detailInfo.processName}</span>
                </li> */}

                {detailInfo.bid?.type === "T" ||
                detailInfo.bid?.type === "C" ? (
                  <li>
                    <p>계약기간</p>
                    <span>{detailInfo.bid?.term}개월</span>
                  </li>
                ) : null}
                {detailInfo.bid?.type === "S" ? (
                  <li>
                    <p>납품기한</p>
                    <span>{detailInfo.bid?.deldate}</span>
                  </li>
                ) : null}

                <li>
                  <p>비용정산 방법</p>
                  <span>{detailInfo.bid?.refund}</span>
                </li>
                <li>
                  <p>납품주소</p>
                  <span>
                    {detailInfo.bid?.addr1}, {detailInfo.bid?.addr2}
                  </span>
                </li>
                <li>
                  <p>현장 대표 번호</p>
                  <span>
                    {detailInfo?.site?.phone?.replace(
                      /^(\d{2,3})(\d{3,4})(\d{4})$/,
                      `$1-$2-$3`
                    )}
                  </span>
                </li>
              </ul>
              <ul className="quote_list">
                <li>
                  <p>배송비 포함여부</p>
                  <span>
                    {detailInfo.bid?.fee === "Y"
                      ? "포함"
                      : detailInfo.bid?.fee === "N"
                      ? "미포함"
                      : null}
                  </span>
                </li>
                <li>
                  <p>하차역무 포함여부</p>
                  <span>
                    {detailInfo.bid?.drop === "L"
                      ? "하차 역무 있음 ⭕️"
                      : detailInfo.bid?.drop === "U"
                      ? "하차 역무 없음 ❌"
                      : null}
                  </span>
                </li>
                <li>
                  <p>우선사항</p>
                  <span>{detailInfo.bid?.priority}</span>
                </li>
                <li>
                  <p>담당자</p>
                  <span>
                    {detailInfo?.user?.name} {detailInfo?.user?.rank}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div className="conclude_est">
            <h3 className="s_tit">
              <p>입찰내역</p>
            </h3>
            <div className="table_container">
              <table>
                <thead>
                  <tr>
                    <th className="table_index"></th>
                    <th className="table_name">품목명</th>
                    <th className="table_standard">규격</th>
                    <th className="table_brand">
                      제조국
                      <p className="table_sub_desc">(브랜드)</p>
                    </th>
                    <th className="table_etc">비고</th>
                    <th className="table_unit">단위</th>
                    <th className="table_count">수량</th>
                    <th className="table_total_amount">
                      고객사 납품단가
                      <p className="table_sub_desc">(수수료 포함 단가)</p>
                    </th>
                    <th className="table_total_amount">
                      최종 합계 금액
                      <p className="table_sub_desc">(고객사 납품 단가)</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {modify
                    ? pList?.map((data, i) => {
                        return (
                          <tr value={data} key={i}>
                            <th>{i + 1}</th>
                            <td>{data?.bidProduct.name}</td>
                            <td>
                              <p className="table_overflow_text">
                                {data?.bidProduct.standard.replace(
                                  /, /gi,
                                  "\n"
                                )}
                              </p>
                            </td>
                            <td>
                              <p>{data?.bidProduct.brand}</p>
                            </td>
                            <td>
                              <p className="table_overflow_text">
                                {data?.bidProduct.etc}
                              </p>
                            </td>
                            <td>{data?.bidProduct.unit}</td>
                            <td>{data?.bidProduct.count}</td>
                            <td>
                              {data?.offerDetail?.price?.toLocaleString("ko")}
                            </td>
                            <td>
                              {(
                                data?.bidProduct?.count *
                                data?.offerDetail?.price
                              ).toLocaleString("ko")}
                            </td>
                          </tr>
                        );
                      })
                    : pList.map((data, i) => {
                        return (
                          <tr value={data} key={i}>
                            <th>{i + 1}</th>
                            <td>{data?.bidProduct.name}</td>
                            <td>
                              <p className="table_overflow_text">
                                {data?.bidProduct.standard.replace(
                                  /, /gi,
                                  "\n"
                                )}
                              </p>
                            </td>
                            <td>{data?.bidProduct.brand}</td>
                            <td>
                              <span
                                style={{
                                  maxHeight: "100px",
                                  minWidth: "100px",
                                  overflow: "scroll",
                                  display: "block",
                                }}
                              >
                                {data?.bidProduct.etc}
                              </span>
                            </td>
                            <td>{data?.bidProduct.unit}</td>
                            <td>{data?.bidProduct.count}</td>
                            <td>
                              <div className="table_calculate_box">
                                <input
                                  onChange={(e) => handleChange(e, i, data)}
                                  // onBlur={(e) => handleBlur(e, i, data)}
                                  placeholder="단가를 입력해주세요"
                                  value={
                                    data.offerDetail?.displayPrice ||
                                    data.offerDetail?.price
                                  } // 콤마가 포함된 값 표시
                                  className="unit_price"
                                  type="text"
                                />
                                <div
                                  onClick={() => openModal(i)}
                                  className="table_calculate_img_box"
                                >
                                  <img src={calculator} />
                                </div>
                              </div>
                              {openModalId === i && (
                                <CalculatePricePop
                                  closeCalcModal={closeModal}
                                  name={data.bidProduct.name}
                                  onConfirm={(taxPrice) =>
                                    handleConfirm(i, taxPrice)
                                  }
                                />
                              )}
                            </td>
                            {/* 최종단가 * 수량 */}
                            <td>
                              {(
                                data?.bidProduct.count *
                                data?.offerDetail?.price
                              ).toLocaleString("ko")}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>

                <TableSummaryFooter
                  list={pList}
                  priceKey="offerDetail.price"
                  countKey="bidProduct.count"
                  rowSpan="3"
                  colSpan="7"
                />
              </table>
            </div>
          </div>

          <div className="uniqueness_box">
            <h3 className="uniqueness_constructor">
              <p>고객사</p>
            </h3>
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>
                  <span>입찰 관련</span>
                  <span>추가 내용</span>
                </p>
              </div>
              <div className="uniqueness_content">
                <p>{detailInfo.bid?.comment}</p>
              </div>
            </div>
            {/* {imgList && (
              <div className="uniqueness_attachments">
                {imgList.map((item, index) => (
                  <ul
                    className="uniqueness_attachments_list"
                    key={index}
                    value={index}
                  >
                    <li>
                      <div className="uniqueness_attachments_file_down">
                        <p>
                          <a href={`${SERVER}/api/v1/download/${item.f_no}`}>
                            첨부파일 다운
                          </a>
                        </p>
                      </div>
                      <p>{item.f_origname}</p>
                    </li>
                  </ul>
                ))}
              </div>
            )} */}
            {imgList?.length === 0 ? null : (
              <div className="look_info payment_account">
                <h3 className="s_tit">
                  <p>첨부파일 정보</p>
                </h3>
                {imgList?.map((a, index) => {
                  return (
                    <div>
                      <p
                        onClick={() => {
                          window.open(a.root);
                        }}
                      >
                        첨부파일 {index + 1} 보기
                      </p>
                      <span> {a.origname} </span>
                    </div>
                  );
                })}
              </div>
            )}

            <h3 className="uniqueness_company">
              <p>공급사</p>
            </h3>
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>
                  <span>내가 작성한</span>
                  <span>특이사항</span>
                </p>
              </div>
              <div className="uniqueness_content">
                {modify ? (
                  <p>{detailInfo?.offer?.comment}</p>
                ) : (
                  <textarea
                    defaultValue={detailInfo?.offer?.comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  ></textarea>
                )}
              </div>
            </div>

            <div className="uniqueness_attachments">
              <ul className="uniqueness_attachments_list">
                <li>
                  {detailInfo?.offerFileUrl && (
                    <div className="uniqueness_attachments_file_down">
                      <p>
                        <a
                          href={file?.f_path || detailInfo?.offerFileUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          내가 첨부한 파일 보기
                        </a>
                      </p>
                    </div>
                  )}
                  {!modify && (
                    <>
                      <input
                        type="file"
                        id="upload"
                        onChange={onChangeUploadFile}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="upload"
                        className="uniqueness_attachments_file_update"
                        // onClick={onChangeUploadFile}
                      >
                        {detailInfo?.offerFileUrl || file
                          ? "수정하기"
                          : "첨부하기"}
                      </label>
                    </>
                  )}
                  <p>{file?.f_name || detailInfo?.offerFileName}</p>
                </li>
              </ul>
            </div>

            {detailInfo.bid?.state === "FAIL" && (
              <div className="fail_container">
                <h3 className="fail_title">
                  <p>유찰 사유</p>
                  {detailInfo.nextBidCode && (
                    <p>
                      ( {detailInfo.nextBidCode} {detailInfo.nextBidType} )
                    </p>
                  )}
                </h3>

                <div className="fail_box">
                  <div className="fail_left_box">
                    <p>
                      <span>사유</span>
                    </p>
                  </div>
                  <div className="fail_content">
                    <p>{detailInfo?.bid?.selectReason}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div class="btn_box">
          {modify ? null : (
            <div class="cancel">
              <p
                onClick={() => {
                  setModify(true);
                }}
              >
                취소
              </p>
            </div>
          )}

          {modify ? null : (
            <div class="ok">
              <p onClick={() => setSubmitPop(true)}>입찰수정</p>
            </div>
          )}
        </div>
      </div>
      {submitPop && (
        <EstimateModifyPop
          setSubmitPop={setSubmitPop}
          comment={comment || detailInfo?.offer?.comment} // 수정시 아무것도 건드리지 않았다면 기존값 매핑
          od={od}
          pList={pList}
          setModify={setModify}
          fileNo={file?.f_no || detailInfo.offer.fileNo} // 수정시 아무것도 건드리지 않았다면 기존값 매핑
        />
      )}
    </div>
  );
}
