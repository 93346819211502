import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { invoiceCountApi } from "../../../api/auth";

export default function useInvoiceCount() {
  const userData = useSelector((state) => state.user);
  const [countByState, setCountByState] = useState();

  const fetchInvoiceCountByState = useCallback(async () => {
    const response = await invoiceCountApi(userData.c_no);
    if (response.status === "success") setCountByState(response.data);
  }, [userData.c_no]);

  useEffect(() => {
    fetchInvoiceCountByState();
  }, [fetchInvoiceCountByState]);

  return { countByState, fetchInvoiceCountByState };
}
