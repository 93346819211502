import React, { useState, useEffect } from "react";
//library
import { useNavigate } from "react-router";
//component
import Mheader from "../../component/common/Mheader";
//css
import "../../asset/css/login.css";
//etc
import { loginApi, tokenVerification } from "../../api/auth";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";

//social
import KakaoLogin from "react-kakao-login";
import GoogleLogin from "react-google-login";

import kakaoIcon from "../../asset/images/w-kakao-icon.png";
import googleIcon from "../../asset/images/w-google-icon.png";
import {
  GOOGLE_ID,
  KAKAO_ID,
  NAVER_CALLBACK_URL,
  NAVER_CLIENT_ID,
} from "../../constant/Constant";
import { processSocialLogin } from "../../util/Util";
import { isSafari, isMobile } from "react-device-detect";

//sweet alert
import swal from "sweetalert";

export default function Login() {
  const { naver } = window;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signToken = useSelector((state) => state.token);
  const pushToken = useSelector((state) => state.push);

  const [loginInfo, setLoginInfo] = useState({
    u_id: "",
    u_pw: "",
  });
  const [loginState, setLoginState] = useState(true);

  //social login
  const _clickSnsLoginKakao = (e) => {
    let kakaoid = e.profile.id;
    processSocialLogin("KAKAO", kakaoid, navigate, dispatch, pushToken);
  };

  const _clickSnsLoginGoogle = (e) => {
    let googleid = e.googleId;

    processSocialLogin("GOOGLE", googleid, navigate, dispatch, pushToken);
  };

  const initializeNaverLogin = () => {
    if (false) {
      const naverLogin = new naver.LoginWithNaverId({
        clientId: NAVER_CLIENT_ID,
        callbackUrl: NAVER_CALLBACK_URL,
        isPopup: false,
        loginButton: { color: "white", type: 1, height: "0" },
      });
      naverLogin.init();
    }
  };

  const login = async () => {
    const response = await loginApi(loginInfo.u_id, loginInfo.u_pw, pushToken);

    if (response.status === "success") {
      if (response.data.u_type !== "C") {
        swal(
          "공급사 관리자만 로그인이 가능합니다. 현장 관계자께서는 현장 페이지를 이용해주세요.",
          "",
          "info"
        );
        return;
      }

      let newUserData = {
        u_no: response.data.u_no,
        u_name: response.data.u_name,
        u_rank: response.data.u_rank,

        uc_no: response.data.uc_no,
        c_no: response.data.c_no,
        c_name: response.data.c_name,
        c_bsname: response.data.c_bsname,
      };

      dispatch(actions.setUser(newUserData));
      dispatch(actions.setToken(response.data.signToken));
      navigate("/", { replace: true });
    } else {
      if (response.message === "HOLD") {
        swal({
          title: "승인대기",
          text: "현재 승인 대기중입니다. 확인을 누르면 공급사 안내매뉴얼 영상으로 이동합니다.\n (문의 : 032-205-0479)",
          icon: "info",
          buttons: true,
          // dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            window.open("https://www.youtube.com/watch?v=435MQaEXclA");
          } else {
            swal("취소하셨습니다.");
          }
        });

        return;
      } else if (
        response.status === "fail" &&
        response.message === "Customer Id"
      ) {
        swal({
          title: "승인대기",
          text: "현재 승인 대기중입니다. 확인을 누르면 공급사 안내메뉴얼 영상으로 이동합니다.\n (문의 : 032-205-0479)",
          icon: "info",
          buttons: true,
          // dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            window.open("https://customer.gongsaero.com");
          } else {
            swal("취소하셨습니다.");
          }
        });
      } else {
        swal("회원 정보가 일치하지 않습니다.", "", "warning");
      }
    }
  };

  //엔터키 이벤트
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  useEffect(() => {
    initializeNaverLogin();
  }, []);

  useEffect(() => {
    if (signToken) navigate("/", { replace: true });
  }, [navigate, signToken]);

  return (
    <div className="login_wrap">
      <Mheader title="로그인" />
      <div className="login_container">
        <h2>로그인</h2>
        <input
          type="text"
          placeholder="이메일를 입력해주세요."
          onChange={(e) => {
            setLoginInfo({ ...loginInfo, u_id: e.target.value });
          }}
          onKeyPress={onKeyPress}
        />
        <input
          type="password"
          placeholder="비밀번호를 입력해주세요."
          onChange={(e) => {
            setLoginInfo({ ...loginInfo, u_pw: e.target.value });
          }}
          onKeyPress={onKeyPress}
        />
        <div className="login_info">
          <div className="info_left">
            <input
              type="checkbox"
              id="maintain_login"
              name="로그인유지"
              onChange={() => {
                setLoginState(!loginState);
              }}
            />
            <label htmlFor="maintain_login">
              <span
                className={loginState ? "maintain_icon_chk" : "maintain_icon"}
              ></span>
              <span className="maintain_text">로그인 유지</span>
            </label>
          </div>
          <p
            style={{ cursor: "pointer" }}
            className="info_right"
            onClick={() => {
              navigate("/find", { replace: true });
            }}
          >
            아이디·비밀번호찾기
          </p>
        </div>
        <button onClick={login}>로그인</button>
        {/* {(!pushToken.type || !pushToken.value) && (
          <div className='social_login'>
            <div id='naverIdLogin'></div>

            <KakaoLogin
              tolen={KAKAO_ID}
              render={(renderProps) => (
                <div
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  style={{ marginRight: '20px', cursor: 'pointer' }}
                >
                  <img
                    src={kakaoIcon}
                    resizeMode={'contain'}
                    alt={'카카오 로그인'}
                  />
                </div>
              )}
              onSuccess={(e) => _clickSnsLoginKakao(e)}
              onFailure={(result) => {
                
              }}
            />
            <GoogleLogin
              clientId={GOOGLE_ID}
              buttonText='Google Login'
              render={(renderProps) => (
                <div
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={googleIcon}
                    resizeMode={'contain'}
                    alt={'구글 로그인'}
                  />
                </div>
              )}
              onSuccess={(e) => _clickSnsLoginGoogle(e)}
              onFailure={(result) => {
                
              }}
              cookiePolicy={'single_host_origin'}
            />
          </div>
        )} */}
        <button
          onClick={() => {
            if (isSafari && isMobile) {
              swal(
                "아이폰 사파리 유저의 경우에는 \n설정 > 사파리 > 팝업차단에서 팝업차단을 해제해주셔야 정상적으로 회원가입을 진행할 수 있습니다.",
                "",
                "info"
              );
            }
            navigate("/join");
          }}
        >
          회원가입
        </button>
      </div>
    </div>
  );
}
