import React from 'react';
import swal from 'sweetalert';

//etc
import loadingImg from '../../asset/images/loading.gif';

export default function Loading({ msg, color }) {
  
  // 10초 후에 새로고침 alert 출력
  // setTimeout(function(){
  //   swal('','데이터를 받아오는데에 실패했습니다.','error');

  //   swal({
  //     title: "통신오류",
  //     text: "데이터를 받아오지 못 했습니다.",
  //     icon: "error",
  //     closeOnClickOutside: false,
  //     closeOnEsc: false,
  //     buttons: {
  //       confirm: {
  //         text: "새로고침",
  //         value: true,
  //       },
  //     },
  //   }).then((result) => {
  //     if (result) {
  //       reloadingFnc();
  //     }
  //   });

  // },10000);

  // const reloadingFnc = () => {
  //   window.location.reload();
  // }
  
  return (
    <div
      style={{
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(32, 33, 33, 0.7);',
        position: 'fixed',
        zIndex: 9999,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <img
        src={loadingImg}
        style={{
          width: '150px',
          height: '150px',
        }}
      />

      <p
        style={{
          textAlign: 'center',
          color: color,
          fontWeight: 'bold',
          fontSize: '17px',
          lineHeight: '30px',
        }}
      >
        <span>
          {msg}
          <br />
          잠시만 기다려주세요.
        </span>
      </p>
    </div>
  );
}
