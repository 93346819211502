import React from 'react';
//component
import Mheader from '../../component/common/Mheader';
//css
import '../../asset/css/policy.css';

export default function Terms() {
  return (
    <div className='terms_wrap'>
      <Mheader title='이용약관' />
      <div className='terms_container'>
        <h2 className='policy_tit'>이용약관</h2>
        <div className='terms_contents'>
          <h1 className="terms_title">물품구매계약 일반약관</h1>
          <div className='terms_box'>
            <h4>제 1조 &#40;목 적&#41;</h4>
            <p>
            이 약관은 주식회사 공새로(이하 ‘회사’)가 제공하는 건자재 중개 및 관련 서비스(이하 ‘서비스’)의 이용과 관련하여 ‘회사’와 ‘회원’과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
            </p>
          </div>
          <div className='terms_box'>
            <h4>제 2조 &#40;정 의&#41;</h4>
            <p>
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            1. ‘회사’ 공새로 서비스를 운영하는 주체로서 회원간 거래를 중개하는 역할을 수행하며, 운영하는 사이트는 아래와 같습니다.(www.gongsaero.com / https://customer.gongsaero.com / https://partner.gongsaero.com 이하 ‘홈페이지’라 함) 을 비롯하여 추후 회사에서 공지하고 제공하는 기타 웹사이트, 스마트폰 및 이동통신기기를 통해 제공되는 모바일 어플리케이션 포함<br/>
            2. 이용자: 공새로에 접속하여 이 약관에 따라 서비스를 받는 회원 및 비회원을 말합니다.<br/>
            3. 회원: 회사에 개인정보를 제공하여 회원등록을 한 자 혹은 회사로서, 회사와 서비스 이용계약을 체결하고 회원 아이디(ID)를 부여 받아 사이트 내에 모든 서비스를 이용하는 구매자이자 판매자, 작업자이자 업체, 광고주 등을 말하며 고객사와 공급사를 포함합니다.<br/>
            4. 고객사: 회원 중 구매자를 지칭하며 고객사 서비스에 회원가입 한 서비스 이용자를 말합니다. <br/>
            5. 공급사: 회원 중 판매자를 지칭하며 공급사 서비스에 회원가입 한 서비스 이용자를 말하며 제휴사로도 지칭할 수 있습니다. <br/>
            6. 매칭요청: 요청자가 원하는 견적에 대한 구체적인 정보를 담고 있는 서비스 요청서입니다.<br/>
            7. 콘텐츠(Contents): 회사가 공새로에서 제공하는 정보, 요청서 작성, 견적 작성, 요청서 수신, 견적 수신, 정산 프로그램 등 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지ㆍ영상 및 복합체의 정보나 자료를 의미합니다.<br/>
            8. 회원 아이디(이하 “ID”): 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다.<br/>
            9. 비밀번호(Password): 이용자가 등록회원과 동일인인지 신원을 확인하고, 회원의 통신상 개인정보를 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.<br/>
            10. 비회원: 회원에 가입하지 않아 사이트에 모든 기능을 사용할 수 없는 자를 말합니다.<br/>
            11. 회원 탈퇴: 회원이 이용계약을 종료시키는 행위를 말합니다.<br/>
            12. 안전거래서비스: 서비스로서, 회원 상호 간의 금전 거래를 안전하고 용이하게 하기 위하여 회사가 제공하는 서비스를 의미합니다.<br/>
            </p>
          </div>
          <div className='terms_box'>
            <h4>제 3조 &#40;약관의 게시와 개정&#41;</h4>
            <p>
            1. ‘회원’이 서비스를 이용하면서 약관에 동의한 시점부터 약관 및 운영정책(이하 ‘약관 등’의 적용을 받고 약관 등의 변경이 있을 경우에는 변경의 효력이 발생한 시점부터 변경된 약관 등의 적용을 받습니다.<br/>
            2. 회사는 약관 등의 내용을 회원이 알 수 있도록 본 서비스 또는 회사에서 운영하는 홈페이지에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지합니다.<br/>
            3. ‘회사’는 필요하다고 인정되는 경우 이 약관을 변경할 수 있습니다. ‘회사’는 약관이 변경되는 경우에 변경된 약관의 내용과 적용일을 정하여, 그 적용일로부터 본 서비스 또는 홈페이지 내 공지사항에 게시하여 7일전 공지합니다. <br/>
            4. ‘회원’은 변경된 약관에 대해 거부할 권리가 있습니다. 본 약관의 변경에 대해 이의가 있는 ‘회원’은 ‘서비스’ 이용을 중단하고 이용계약을 해지(회원 탈퇴)할 수 있습니다. 변경된 이용약관의 시행일 이후에도 ‘서비스’를 계속 이용하는 경우에는 변경된 약관에 동의한 것으로 간주된다는 내용을 함께 공지하고, ‘회원’이 시행일 이후 ‘서비스’를 이용하는 경우 개정된 약관에 동의한 것으로 봅니다.단, 회원은 플랫폼을 통해 진행되고 있던 견적, 계약, 정산 등 고객과 연관된 서비스 처리가 완료가 되어야 탈퇴가 가능합니다. <br/>
            5. ‘회사’는 ‘회원’이 ‘회사’와 이 약관의 내용에 관하여 질의 및 응답할 수 있도록 조치를 취합니다.<br/>
            6. ‘회사’는 필요하다고 인정되는 경우 운영정책을 변경할 수 있습니다. 이 경우 회원의 권리 또는 의무에 중대한 변경을 가져오거나 약관 내용을 변경하는 것과 동일한 효력이 발생하는 운영정책 개정의 경우에는 본조 제2항의 절차에 따릅니다. 단, 운영정책 개정이 다음 각 호의 어느 하나에 해당하는 경우에는 홈페이지에 게시하거나 연결화면을 제공하는 방법으로 사전에 공지합니다.<br/>
            &nbsp; 1&#41;약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우<br/>
            &nbsp; 2&#41;회원의 권리·의무와 관련 없는 사항을 개정하는 경우<br/>
            &nbsp; 3&#41;운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 회원이 예측 가능한 범위 내에서 운영정책을 개정하는 경우<br/>


            </p>
          </div>
          <div className='terms_box'>
            <h4>제 4조 &#40;약관 외 준칙&#41;</h4>
            <p>
            1. 이 약관은 회사가 제공하는 개별서비스에 관한 이용안내(이하 ‘서비스 별 안내’라 합니다)와 함께 적용합니다.<br/>
            2. 이 약관에 명시되지 않은 사항에 대해서는 전기통신사업법, 전자거래기본법, 정보통신망법, 기타 관련법령 및 서비스 별 안내의 규정에 의합니다.<br/>

            </p>
          </div>

          <h1 className="terms_title">제 2장 서비스 이용 계약</h1>
          <div className='terms_box'>
            <h4>제 5조 &#40;대리 및 보증의 부인&#41;</h4>
            <p>
            1. ‘회사’는 고객사 및 공급사 회원간의 편리한 상품의 거래를 위한 중개 시스템을 운영 및 관리, 제공할 뿐이며, ‘회원’ 을 대리하지 않습니다. 또한, ‘회원’간’성립된 상품 거래에 관련한 책임과 ‘회원’ 이 제공한 정보에 대한 책임은 ‘회사’의 고의 또는 중과실이 없는 한 해당 ‘회원’ 이 직접 부담하여야 합니다. <br/>
            2. ‘회사’는 ‘서비스’를 통하여 이루어지는 고객사 및 공급사 ‘회원’간의 거래와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 상품의 품질, 완전성, 안전성, 적법성 및 타인의 권리에 대한 비침해성, ‘회원’이 입력한 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험은 ‘회사’의 고의 또는 중과실이 없는 한 해당 ‘회원’ 이 부담합니다.<br/>
            </p>
          </div>
          <div className='terms_box'>
            <h4>제 6조 &#40;서비스 이용&#41;</h4>
            <p>
            1. ‘회사’가 제공하는 유료서비스(매칭 수수료, 프리미엄서비스, 안전 결제 수수료, 배너이용, 우대등록 등 광고이용 등)는 거래금액에 대한 일정 요율 혹은 정액제 방식의 수수료를 책정합니다. 유료 서비스는 향후 당사 정책에 따라 변경 가능하며 회원사 페이지에서 조회할 수 있습니다.<br/>
            2. 유료서비스 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는 규정에 따릅니다.<br/>
            3. 회사는 통신판매중개업자로서 서비스 요금이 무료인 서비스와 일부 유료서비스 이용과 관련하여 회원들 사이에서 발생한 거래와 관련된 손해에 대해서는 회사에 고의 또는 중대한 과실이 있는 경우를 제외하고는 책임을 지지 않습니다.<br/>
            4. 회원(공급사)가 당 서비스를 통해 납품한 물품의 상태, 품질에 대해서 책임을 다해야 하며 회사의 사유에 해당되지 않는 한 회사는 책임을 지지 않습니다.<br/>
            5. 회원(공급사)은 당 서비스를 통해 체결된 서비스 및 재화에 대해서 고객사가 요청한 납품기한 내에 공급해야 하는 의무가 있습니다.<br/>
            6. 회원(공급사)은 고객에게 플랫폼 외 거래 유도, 납품 기한 미준수, 그 외 기타 부적절한 행위에 대하여 당사의 업체 제재기준에 의거하여 당 플랫폼 이용 관련 제재를 받습니다.<br/>
            7. 회사는 고객사가 거래 확정을 하면 바로 대금을 고객에게 청구해야 합니다. 고객사의 대금 지급 지침에 의거한 날짜에 대금 수령 후 14일 이내 공급사에게 지급하는 것을 원칙으로 합니다. 다만 고객사의 사정, 금융기관 또는 전기통신사업자의 귀책 등 회사의 귀책사유가 없는 사유로 지급이 늦어질 수 있습니다.<br/>
            8. 고객사는 용역 및 납품 이행이 완료하였을 때 지체 없이 거래 확정을 하여 상대방에게 대금이 원활히 지급될 수 있도록 하여야 합니다.<br/>
            </p>
          </div>
          <div className='terms_box'>
            <h4>제 7조 &#40;안전거래서비스&#41;</h4>
            <p>
            1. ’회사'는 ‘회원'이 특정서비스를 이용함에 있어 안전하고 편리한 금전 거래를 위하여 대금의 수령, 보관 및 송금업무로 이루어지는 에스크로 방식의 안전거래서비스를 제공할 수 있습니다. <br/>
            2.	안전거래를 위하여 잘못된 정보를 입력하여 잘못된 대금지급이 이루어졌을 경우 회사는 이에 대하여 책임지지 않습니다.<br/>
            3. 회원이 입력한 계좌의 예금주는 회원이 실명확인을 하여 가입할 때 동일인임을 원칙으로 합니다.<br/>
            4. 회사는 양 당사자 간의 거래에 대하여 책임을 부담하지 아니하며 특히 고객의 거래 확정으로 거래가 승인된 이후 발생한 분쟁에 대하여 책임지지 않습니다. 거래 확정 후 대금의 지급이 완료된 이후 발생한 분쟁 또는 불만 사항은 양 당사자가 협의를 통해 해결해야 합니다.<br/>
            5. 안전거래 진행 여부에 대한 동의를 하고, 안전거래를 위한 계좌를 등록한 회원에 한하여 안전거래 서비스를 이용할 수 있습니다.<br/>
            6. 각 거래에 대한 안전거래서비스는 ‘회원’ 요구에 따라 개시됩니다. ‘회원’은 거래금액과 예상 서비스 이행 완료일을 포함한 최종 안전거래 내용을 발송하며, ‘회원’은 서비스에 대한 수수료 안내를 받은 뒤 수수료와 거래금액을 합한 대금을 안전거래서비스에 입금합니다.<br/>
            7. 회사’는 안전거래서비스를 통해 ‘회원'이 입금한 대금을 보관합니다. ‘회원’이 ‘서비스’를 이행한 이후 서비스 상 거래 확정 버튼을 클릭하는 방식으로 거래 확정을 하면 ‘회사'는 보관하는 대금 중 수수료를 제외한 거래금액을 지급합니다.<br/>
            8. ’회사’는 안전 결제를 위한 통로로써 역할을 하며 ‘회원’간 분쟁으로 인한 문제는 책임이 없습니다.<br/>
            9. 대금 지급 지연이 발생한 경우 ‘회원’간 협의 후 일방 ‘회원’이 거래 확정을 하여 ‘회사’에 알려야 합니다<br/>
            10. 안전거래서비스는 회원과 고객 간의 거래의 안전과 신뢰성 향상을 위한 목적에서 회사가 제공하는 서비스의 일환이므로 회사는 안전거래서비스를 이용하는 양 당사자 간의 거래에 어떠한 책임도 지지 않습니다.<br/>
            11. 회사는 양 당사자 간의 거래에 대하여 책임을 부담하지 아니하며 특히 고객의 거래 확정으로 거래가 승인된 이후 발생한 분쟁에 대하여 책임지지 않습니다. 거래 확정 후 대금의 지급이 완료된 이후 발생한 분쟁 또는 불만 사항은 양 당사자가 협의를 통해 해결해야 합니다.<br/>

            </p>
          </div>
          <div className='terms_box'>
            <h4>제 8조 &#40;예치금의 지급&#41;</h4>
            <p>
            1.	회사는 고객이 거래 확정을 하면 바로 대금을 회원에게 지급하는 것을 원칙으로 합니다. 다만 금융기관 또는 전기통신사업자의 귀책 등 회사의 귀책사유가 없는 사유로 지급이 늦어질 수 있습니다.<br/>
            2.	고객은 용역 및 납품 이행이 완료하였을 때 지체 없이 거래 확정을 하여 상대방에게 대금이 원활히 지급될 수 있도록 하여야 합니다.<br/>
            3.	회사는 영업일 오전 9시부터 오후 6시까지 예치금 지급을 실행합니다. 거래 완료가 휴일 및 업무 시간 외에 진행된 경우 다음 영업일에 지급이 실행됩니다.<br/>
            4.	고객이 다음과 같이 정당한 사유 없이 거래 확정 의무를 다하지 않는 경우 회사는 고객이 거래 확정하였다고 간주하고 대금을 지급하고 관련 절차를 진행할 수 있습니다.<br/>
            &nbsp;&nbsp; 1&#41; 상대방이 용역 및 납품 이행을 완료하였음을 고지하였음에도 10일 이상 상대방이나 회사의 연락에 응하지 아니하는 경우<br/>
            &nbsp;&nbsp; 2&#41; 상대방이 용역 및 납품 이행을 완료하여 이를 고지하였고 고객이 이를 확인하였음에도 고객이 해당 확인 일자로부터 10일 이상 거래 확정을 하지 아니하는 경우<br/>
            &nbsp;&nbsp; 3&#41; 기타 고객이 정당한 사유 없이 거래확정을 아니하여 회사가 거래 확정처리를 하는 경우<br/>

            </p>
          </div>
          <div className='terms_box'>
            <h4>제 9조 &#40;과오금&#41;</h4>
            <p>
            1. 회사는 과오금이 발생한 경우 대금의 결제와 동일한 방법으로 과오금 전액을 환불합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.<br/>
            2. 회원의 책임 있는 사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야 합니다.<br/>
            3. 회사는 과오금의 환불절차를 디지털콘텐츠이용자보호지침에 따라 처리합니다<br/>
            </p>
          </div>
      
          <div className="terms_title">제 3장 서비스 제공 및 변경</div>
          <div className='terms_box'>
            <h4>제 10조 &#40;증빙서류&#41;</h4>
            <p>
            ‘회사’는 이 약관 제7조 1항, 3항에 따라 ‘가입신청자’가 제공한 정보의 정확성을 확인하기 위해 관련법령이 허용하는 범위 내에서 증빙자료의 제공을 요청할 수 있습니다.<br/>
            ‘가입신청자’가 부당하게 증빙자료를 제공하지 않는 경우 ‘회사’는 이용계약의 해지, 제공 서비스 제한 등의 조치를 취할 수 있으며, 이로 인하여 발생하는 손해에 대해 ‘회사’는 책임을 지지 않습니다.<br/>
            </p>
          </div>
          <div className='terms_box'>
            <h4>제 11조 &#40;서비스 이용계약의 해제, 해지, 이용제한 등&#41;</h4>
            <p>
            • ‘회원’은 언제든지 ‘서비스’ 이용계약 해지 신청을 할 수 있으며, 회사는 진행 중인 견적, 주문, 계약, 정산 내역 등 서비스 운영에 피해 영향 여부를 판단한 후 관련법 등이 정하는 바에 따라 이를 처리하여야 합니다.<br/>
            • ‘회원’이 ‘서비스’ 이용계약을 해지할 경우, 회사는 관련법 및 개인정보처리방침에 따라 처리합니다.<br/>
	          •	‘회사’는 ‘회원’이 이 약관에서 금지하는 행위를 하는 등 본 계약을 유지할 수 없는 중대한 사유가 있는 경우에는 기간을 정하여 ‘서비스’ 이용을 중지하거나 이용계약을 해지할 수 있으며 특정 IP대역에서 반복적으로 대량의 어뷰징 행위, 해킹, 불법 프로그램 사용 등이 확인되는 경우 해당 IP 대역에서의 ‘서비스’ 이용을 중지할 수 있습니다.<br/>
	          •	‘서비스’ 이용계약 해지로 인해 발생한 불이익에 대한 책임은 ‘회사’의 고의 또는 중과실이 없는 한 ‘회원’ 본인이 부담하여야 하며, ‘서비스’ 이용계약이 종료되면 ‘회사’는 ‘회원’에게 제공하던 ‘서비스’를 차단하고, 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.<br/>
	          •	‘회사’는 ‘회원’에게 다음 각 호에 해당하는 사유가 발생하거나 확인된 경우 ‘서비스’ 이용계약을 해지할 수 있습니다.<br/><br/>


            1&#41; ‘회원’이 ‘서비스’의 원활한 진행을 방해하는 행위를 하거나 시도한 경우<br/>
            2&#41; ‘회원’이 고의로 ‘회사’의 영업을 방해한 경우<br/>
            3&#41; 다른 ‘회원’의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국 법령 또는 선량한 풍속 기타 사회질서에 위배되는 행위를 한 경우<br/>
            4&#41; ‘회원’이 이 약관에 위배되는 행위를 한 경우<br/>
            5&#41; 기타 ‘회사’가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우<br/>
            6&#41;  회사가 ‘서비스’ 이용계약을 해지하더라도 이용계약의 해지 이전에 이미 체결된 ‘회원’간의 상품 거래 계약이 완결될 때까지는 그와 관련된 범위 내에서 이 약관이 계속 적용됩니다.<br/>
            7&#41;  ‘서비스’ 이용계약이 회사에 의해 해지되는 경우 ‘회원’의 재이용 신청에 대하여 회사는 그 승낙을 거절할 수 있습니다.<br/>
            8&#41;  ‘서비스’ 이용계약이 종료됨으로써 발생한 손해는 이용계약 종료 사유에 대해 귀책사유가 있는 ‘회원’이 책임을 부담하고, 회사는 고의, 중과실이 없는 한 책임을 지지 않습니다.<br/>

            </p>
          </div>
          <div className='terms_box'>
            <h4>제 12조 &#40;회사의 의무&#41;</h4>
            <p>
            1. ’회사’는 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적인 ‘서비스’를 제공하기 위하여 노력합니다.<br/>
            2. ‘회사’는 ‘회원’이 안전하게 ‘서비스’를 이용할 수 있도록 개인정보&#40;신용정보 포함&#41;보호를 위해 보안시스템을 갖추어야 하며, 개인정보처리방침을 공시하고 준수합니다.<br/>
            3. ‘회사’는 계속적이고 안정적인 ‘서비스’의 제공을 위하여 ‘서비스’ 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실, 훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.<br/>
            4. ‘회사’는 ‘회원’으로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우에는 즉시 처리합니다. 다만, 즉시 처리가 곤란한 경우에는 회원에게 사전에 동의 받아 수집한 전화나 e-mail로 그 사유와 처리일정을 통보할 수 있습니다.<br/>
            </p>
          </div>
          <div className='terms_box'>
            <h4>제 13조 &#40;회원의 의무&#41;</h4>
            <p>
            •	‘회원’은 다음 각 호의 행위를 하여서는 안 됩니다.<br/>
            •	회원가입신청 또는 변경 시 허위내용을 등록하는 행위<br/>
            •	‘회사'에 게시된 정보를 허위로 변경하는 행위<br/>
            •	‘회사’ 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위<br/>
            •	다른 ‘회원’의 ID를 도용하는 행위<br/>
            •	관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)의 전송 또는 게시하는 행위<br/>
            •	‘회사’의 직원이나 관리자를 가장하거나 사칭하여 또는 타인의 명의를 오용하여 글을 게시하거나 메일을 발송하는 행위<br/>
            •	컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타의 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편으로 발송하는 행위<br/>
            •	스토킹(stalking) 등 다른 회원을 괴롭히는 행위<br/>
            •	다른 ‘회원’에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위<br/>
            •	‘회사'가 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한 규정을 위반하는 행위<br/>
            •	외설 또는 폭력적인 메시지ㆍ화상ㆍ음성 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위<br/>
            •	사실관계를 왜곡하는 정보제공 등 기타 회사가 부적절하다고 판단하는 행위<br/>
            •	기타 관계 법령이나 ‘회사’에서 정한 규정에 위배되는 행위<br/>

            </p>
          </div>
          <div className='terms_box'>
            <h4>제 14조 &#40;회원의 서비스 관련 의무&#41;</h4>
            <p>
            •	'회원’은 서비스 및 재화의 제공, 계약내용의 변경, 서비스 및 재화 제공에 대한 대금 결제 및 환불 등 관련한 일체의 업무를 처리하며, 이를 신의성실 하게 이행하여야 할 의무가 있습니다. 또한 용역 제공과 관련하여 발생하는 모든 책임과 의무를 부담합니다.<br/>
            •	‘회원’이 작성한 견적, 프로필, 개인 및 기업정보 등에 허위 또는 과장, 기재누락, 오기가 있어서는 아니되며 이를 준수하지 아니하여 발생하는 모든 법적문제에 대하여는 회원이 모든 책임을 부담합니다. ‘회원'은 해당 견적 및 제공 용역에 대한 진실성과 적법성에 대해 보증합니다.<br/>
            •	‘회원’은 작성한 견적, 용역 및 재화 납품의 내용, 용역 및 재화 제공 시기 등 내용의 변경 사항이 있을 경우 이를 바로 갱신하여야 할 의무를 가지며 잘못 입력함으로 인해 발생하게 된 계약 불이행에 대해서는 규정에 의해 제재를 받습니다.<br/>
            •	‘회원’은 관련법령 등을 위반하는 불법적인 용역을 제공하지 않는 다른 점에 대해 보증하며 불법적이거나 본 이용약관을 위반하는 내용으로 견적 및 허위 내용을 작성하여 고객에게 송부할 수 없습니다.<br/>
            •	‘회원'은 견적 등 ‘회사’ 서비스 사용 과정에서 ‘회사’ 서비스에서 제공하는 메시지 기능 혹은 질의답변이나 전화 등 별도의 연결수단을 통하여 세부사항을 조정할 수 있습니다. ‘회사’는 견적 발송 이후 회원과 고객 간에 발생한 일체의 문제에 대하여 어떠한 책임도 지지 않습니다.<br/>
            •	‘공급사’는 잘못된 서비스나 재화 제공으로 인해 발생하는 ‘고객사’의 컴플레인에 대하여 신의성실을 다해 대응하며, 이에 대한 대응이 제대로 이루어지지 않을 경우 플랫폼 사용에 대한 불이익을 받을 수 있습니다.<br/>
            •	‘공급사'는 ‘고객사’가 요청한 견적건에 대하여 조달해야할 품목의 품질과 규격 특성 등에  대하여 명확하게 인지하고 투찰합니다. 모호한 견적 요청건에 대해서는 요청 고객사와의 질의 응답을 통해 확실한 답변을 받은 뒤 투찰합니다.<br/>
            •	‘회사'는 회원이 투찰한 단가를 분석하여 적정단가 여부를 분석할 수 있으며, 특정 품목이 과거 견적가에 비해 일정 수준 이상으로 상이하거나 지나친 저가 투찰이 의심될 경우 이를 제지할 수 있습니다.<br/>

            </p>
          </div>
          <div className='terms_box'>
            <h4>제 15조 &#40;손해배상&#41;</h4>
            <p>
              ‘회원’ 일방이 본 계약상 의무를 위반함으로 인하여 상대방에게 손해가 발생한 경우, 귀책사유가 있는 ‘회원’은 손해를 받은 ‘회원’이 입은 손해를 배상합니다.
            </p>
          </div>
          <div className="terms_title">제 4장 서비스 이용 계약의 해제, 해지</div>
          <div className='terms_box'>
            <h4>제 16조 &#40;회사의 면책&#41;</h4>
            <p>
            •	‘회사’는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 고의 또는 과실이 없는 한 ‘회원’에게 발생한 손해에 대하여 서비스 제공에 관한 책임이 면제됩니다.<br/>
            •	‘회사’는 ‘회원’의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.<br/>
            •	‘회사’는 ‘회원’이 서비스의 이용을 통해 예상했던 금전적인 수익을 얻지 못하게 되거나, ‘서비스’를 통하여 얻은 자료로 인해 손해를 보게 되더라도 이에 관하여 책임을 지지 않습니다.<br/>
            •	‘회사'는 ‘회원’이 ‘서비스’에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는, ‘회사’가 해당 정보의 허위성을 명백히 인지하였다는 특별한 사정이 없는 한, 책임을 지지 않습니다. ‘회사’가 본 약관과 관련 법령에 따른 조치를 취한 경우에도 같습니다.<br/>
            •	‘회사’는 ‘서비스’ 이용과 관련하여 회사의 고의, 과실에 의하지 아니한 손해에 대하여 책임을 지지 않습니다.<br/>
            •	‘회사’는 ‘회원’이 ‘서비스’를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.<br/>
            •	‘회사’는 ‘회원'들에게 요청서와 견적을 전달하거나 광고플랫폼 또는 거래서비스를 제공하고 이에 대한 부수적인 ‘서비스’를 제공할 뿐이므로 ‘회원’ 상호 간 서비스를 매개로 하여 거래를 한 경우 ‘회원’ 각자의 의무이행에 대한 책임은 각 ‘회원’에게 있으며 ‘회사’는 이에 대한 책임이 없습니다.<br/>
            •	‘회사’는 ‘회원’들에게 요청서와 견적을 전달하거나 광고플랫폼 또는 안전거래서비스를 제공하고 이에 대한 부수적인 ‘서비스’를 제공할 뿐이므로 ‘회원'간 거래와 관련하여 발생한 의무의 불완전 이행, 이행 지체 등 용역 이행 미비, 사후처리, 대금 정산, 완성품의 하자, 청약철회, 물품의 반품 등 어떠한 법적 분쟁 및 사후처리에 대해서도 개입하거나 책임지지 않습니다.<br/>
            •	‘회사’는 ‘서비스’ 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.<br/>
            •	‘회사’는 ‘회원' 간에 분쟁이 발생하였을 경우 회원 간 법적 문제에 대하여 책임을 지지 않습니다.<br/>
            </p>
          </div>
          <div className='terms_box'>
            <h4>제 17조 &#40;준거법 및 재판관할&#41;</h4>
            <p>
            1.  ‘서비스’ 이용과 관련하여 ‘회사’와 ‘회원’간에 발생한 분쟁에 대하여는 양 당사자 간의 합의에 의해 원만히 해결하여야 합니다.<br/>
            2.  만약 제1항의 분쟁이 원만히 해결되지 못하여 ‘회사’와 ‘회원’ 간 소송이 제기된 경우, 대한민국 법을 준거법으로 합니다.<br/>
            3.  ‘회사’와 ‘회원’간 발생한 분쟁에 관한 소송이 제기된 경우, 소송은 관련 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.<br/> 
            4.  해외에 주소나 거소가 있는 ‘회원’의 경우 ‘회사’와 ‘회원’간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.<br/>
            </p>
          </div>
          <div>
            <h4>부칙</h4>
            <br />
            <br />
            <h4>제 1조 (시행일) 이 약관은 2022년 8월 17일부터 시행합니다.</h4>
          </div>
        </div>

        {/* 여기는 Select해서 Modal? New Page?
          modal = 고객사 공급사에서 깔끔하게 보여줄 수 있음.
          New page = 랜딩페이지에서도 보여주기 좋을듯?
          iframe = 고객사거로만 보여주자!
        */}
        <div className="terms_ver">
          <select onChange={(e)=>{
            const selectValue = e.target.value;

            if(selectValue === '0811'){
              window.open('https://partner.gongsaero.com/terms0811','_blank');
            }
          }}>
            <option selected value="none">이전 이용약관보기</option>
            <option value="0811">~22.08.11 이용약관보기</option>
          </select>
        </div>
      
      </div>
    </div>
  );
}