import React from 'react';
import { Link } from 'react-router-dom';
//component
import Mheader from '../../../component/common/Mheader';

export default function MyInfoEditCompleted() {
  return (
    <div className='myinfo_edit_completed_wrap completed_wrap'>
      <Mheader title='공급사정보 수정' />
      <div className='completed_container wd'>
        <div className='top_pagenation'>
          <h2>공급사정보 수정</h2>
          <div className='top_pagenation_num'>
            <p>1</p>
            <p className='active'>2</p>
          </div>
        </div>
        <div className='completed_text'>
          <h4>정보 승인 요청이 완료되었습니다.</h4>
          <p>
            <span>
              <span className='dn'>*</span>승인 후 반영됩니다.
            </span>
            <span>승인까지 3~5일정도 소요 예정입니다.</span>
          </p>
        </div>
        <div className='completed_btn'>
          <Link to='/my_page/my_info'>내 정보 관리 바로가기</Link>
        </div>
      </div>
    </div>
  );
}
