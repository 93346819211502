import { useNavigate } from "react-router-dom";
import * as S from "../../asset/css/invoiceListItem.styles";

export default function InvoiceListItem(props) {
  const { invoiceItem } = props;
  const navigate = useNavigate();

  const onClickMoveToDetail = () => {
    navigate(`/management/order/document/${invoiceItem.ordersNo}`, {
      state: { orList: invoiceItem },
    });
  };

  const charge =
    (invoiceItem.totalAmount - invoiceItem.realAmount).toLocaleString("ko") ||
    "-";

  return (
    <S.Wrapper>
      <S.PaymentList>
        <div
          title={invoiceItem.createdDate?.replace("T", " ").slice(0, 16) || "-"}
        >
          {invoiceItem.createdDate?.replace("T", " ").slice(0, 16) || "-"}
        </div>
        <div title={invoiceItem.code || "-"}>{invoiceItem.code || "-"}</div>
        <div title={invoiceItem.siteName || "-"}>
          {invoiceItem.siteName || "-"}
        </div>
        <div title={invoiceItem.title || "-"}>{invoiceItem.title || "-"}</div>
        <div title={invoiceItem.totalAmount?.toLocaleString("ko") || "-"}>
          {invoiceItem.totalAmount?.toLocaleString("ko") || "-"}원
        </div>
        <div title={charge}>{charge}원</div>
        <div title={invoiceItem.realAmount?.toLocaleString("ko") || "-"}>
          {invoiceItem.realAmount?.toLocaleString("ko") || "-"}원
        </div>
        <div>
          <button onClick={onClickMoveToDetail}>
            상세보기
          </button>
        </div>
      </S.PaymentList>
    </S.Wrapper>
  );
}
