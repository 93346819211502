import styled from "@emotion/styled";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 900px;
  height: 700px;
  padding: 40px 60px;
  background: #fff;
  box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  @media (max-width: 1080px) {
    width: 95%;
    height: 80%;
    padding: 6rem 6rem;
    overflow: auto;
    justify-content: flex-start;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 28px;
  @media (max-width: 1080px) {
    font-size: 4.5rem;
    margin-bottom: 10rem;
  }
`;

export const UploadWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const UploadedFile = styled.input`
  width: 620px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  background: #fff !important;
  color: #ff6600 !important;
  @media (max-width: 1080px) {
    border-radius: 3px !important;
    text-indent: 2.5rem !important;
    font-size: 1.5rem;
    width: 70%;
    margin-bottom: 10rem;
    height: 10rem;
  }
`;

export const UploadBtn = styled.label`
  width: 115px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #dbe4f0;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  color: #002863;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background: #002863;
    color: #dbe4f0;
  }
  @media (max-width: 1080px) {
    font-size: 4rem;
    width: 25%;
    height: 10rem;
    line-height: 10rem;
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const TotalAmountContainer = styled.div`
  width: 380px;
  height: 300px;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  @media (max-width: 1080px) {
    width: 100%;
    height: 80rem;
    margin-bottom: 10rem;
  }
`;

export const TotalAmountTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  @media (max-width: 1080px) {
    font-size: 5rem;
  }
`;

export const DivideLine = styled.div`
  width: 100%;
  border-bottom: 1px solid #000;
  opacity: 0.3;
`;

export const TotalAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  @media (max-width: 1080px) {
    font-size: 5rem;
  }
`;

export const Fee = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  @media (max-width: 1080px) {
    font-size: 4.5rem;
  }
`;

export const AlignWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  @media (max-width: 1080px) {
    img {
      width: 5rem;
      height: 5rem;
      margin-right: 2rem;
    }
  }
`;

export const RealAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  @media (max-width: 1080px) {
    font-size: 5rem;
  }
`;

export const Vat = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  @media (max-width: 1080px) {
    font-size: 4.5rem;
  }
`;

export const RealVatAmount = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  span {
    color: #ff6600;
  }
  @media (max-width: 1080px) {
    font-size: 5rem;
  }
`;

export const Configure = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 14px;
  @media (max-width: 1080px) {
    font-size: 4rem;
  }
`;

export const PreviewContainer = styled.div`
  width: 380px;
  height: 300px;
  border: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  img {
    width: 300px;
    height: 200px;
    object-fit: contain;
  }
  @media (max-width: 1080px) {
    width: 100%;
    height: 80rem;
    margin-bottom: 10rem;
    img {
      width: 10rem;
      height: 10rem;
    }
  }
`;

export const PreviewTitle = styled.h1`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 40px;
  @media (max-width: 1080px) {
    font-size: 5rem;
    margin-bottom: 5rem;
  }
`;

export const PreviewImgWrap = styled.div`
  width: 350px;
  height: 300px;
  background: #eeebeb;
  opacity: 0.4;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 1080px) {
    width: 100%;
    height: 100rem;
  }
`;

export const InvoiceFileImgWrap = styled.div`
  width: 350px;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 1080px) {
    width: 100%;
    height: 100rem;
    img {
      width: 55rem;
      height: 55rem;
    }
  }
`;

export const DefaultFileImgWrap = styled.div`
  width: 350px;
  height: 300px;
  background: #eeebeb;
  opacity: 0.4;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
  }
  @media (max-width: 1080px) {
    width: 100%;
    height: 100rem;
  }
`;

export const ClickGuide = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 18px;
`;

export const BtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  @media (max-width: 1080px) {
    justify-content: space-between;
    button {
      width: 45%;
      height: 12rem;
      font-size: 4rem;
    }
  }
`;

export const CancelBtn = styled.button`
  width: 230px;
  height: 50px;
  background: #fff;
  border: 2px solid #002863;
  border-radius: 3px;
  font-weight: 500;
  font-size: 20px;
  color: #002863;
  transition: all 0.2s;
  &:hover {
    background: #002863;
    color: #fff;
  }
`;

export const SendBtn = styled.button`
  width: 230px;
  height: 50px;
  background: #002863;
  border: 2px solid #002863;
  border-radius: 3px;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  transition: all 0.2s;
  &:hover {
    background: #fff;
    color: #002863;
    border: 2px solid #002863;
  }
`;
