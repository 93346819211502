/*global kakao*/
import React, { useState, useEffect } from "react";
//library
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { Map, MapMarker, Circle } from "react-kakao-maps-sdk";
//component
import Mheader from "../../component/common/Mheader";
import FindDetailPop from "../../component/common/Pop/FindDetailPop";

//css
import "../../asset/css/look.css";
//etc
import { findUserRoleApi, silverFindApi } from "../../api/auth";

//redux
import { useSelector } from "react-redux";

import nowLocation from "../../asset/images/marker-now-location.png";

//sweet alert
import swal from "sweetalert";

export default function Look() {
  const userData = useSelector((state) => state.user);

  const navigate = useNavigate();
  const [isPayment, setIsPayment] = useState(false);
  const [siteMapList, setSiteMapList] = useState([]);
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });
  const [findCompany, setFindCompany] = useState(false);
  const mapStyle = {
    width: "100%",
    height: "600px",
  };

  const [km, setKm] = useState("5");
  const [map, setMap] = useState();

  //buyer
  const [buyerData, setBuyerData] = useState({});

  //role이 user면 인근현장 찾기 불가능, silver나 gold 일 때만 가능
  // const checkUserRole = async () => {
  //   const response = await findUserRoleApi();
  //   if (response.status === "success") {
  //     setBuyerData(response.data);
  //     if (
  //       response.data.r_auth === "ROLE_GOLD" ||
  //       response.data.r_auth === "ROLE_SILVER"
  //     ) {
  //       setIsPayment(true);
  //     }
  //   } else {
  //     swal("유저 정보를 불러오는데 실패했습니다.", "", "error");
  //   }
  // };

  //인근현장찾기 처음 위치
  const findCompanyFnc = async () => {
    const response = await silverFindApi(userData.c_no, km);

    if (response.status === "success") {
      let copy = {};
      copy.lat = response.data.c_lat;
      copy.lng = response.data.c_lng;
      setFindCompany(copy);
      setSiteMapList(response.data.ncList);
    }
  };

  useEffect(() => {
    // checkUserRole();
    // if (isPayment) {
    if (userData.c_no !== undefined) {
      findCompanyFnc();
    }
    // }
  }, [isPayment, km]);

  const zoomIn = () => {
    if (!map) return;
    map.setLevel(map.getLevel() - 1);
  };
  const zoomOut = () => {
    if (!map) return;
    map.setLevel(map.getLevel() + 1);
  };
  const setMapType = (maptype) => {
    if (!map) return;
    var roadmapControl = document.getElementById("btnRoadmap");
    var skyviewControl = document.getElementById("btnSkyview");
    if (maptype === "roadmap") {
      map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);
      roadmapControl.className = "selected_btn";
      skyviewControl.className = "btn";
    } else {
      map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);
      skyviewControl.className = "selected_btn";
      roadmapControl.className = "btn";
    }
  };

  const changeRadiusFnc = async (selectKm) => {
    //선택한 Km저장
    setKm(selectKm);

    // 반경변경
    switch (selectKm) {
      case "5":
        map.setLevel("7");
        break;
      case "10":
        map.setLevel("8");
        break;
      case "30":
        map.setLevel("9");
        break;
      case "50":
        map.setLevel("10");
        break;
      case "100":
        map.setLevel("11");
        break;
      case "500":
        map.setLevel("13");
        break;
      default:
        break;
    }
  };

  return (
    <div className="look_wrap">
      <Mheader title="인근 현장 찾기" />

      <div className="look_container">
        <div className="look_tit title1">
          <h2>인근 현장 찾기</h2>
          <p>업체 인근에 위치한 현장을 찾고 계시다면 아래 현장들은 어떤가요?</p>
        </div>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            paddingBottom: "10px",
          }}
        >
          <select
            className="look-top-filter"
            onChange={(e) => {
              changeRadiusFnc(e.target.value);
            }}
          >
            <option value="5" selected>
              업체 반경 5KM 이내
            </option>{" "}
            <option value="10">업체 반경 10KM 이내</option>
            <option value="30">업체 반경 30KM 이내</option>
            <option value="50">업체 반경 50KM 이내</option>
            <option value="100">업체 반경 100KM 이내</option>
            <option value="500">업체 반경 500KM 이내</option>
          </select>
        </div>
        {!isResponsive ? (
          <div className="look-wrapper-info">
            반경 <dd> {km}</dd>km내 검색된 현장은 <dd>{siteMapList.length}</dd>
            건입니다.
          </div>
        ) : null}

        {findCompany !== false &&
          findCompany.lat !== undefined &&
          findCompany.lng !== undefined && (
            <div className="look-wrapper">
              {isResponsive && siteMapList.length === 0 ? (
                <div className="look-no-result">
                  <span>근처 현장이 없습니다👷‍♂️</span>
                  <span>업체 반경을 조정해보세요.</span>
                </div>
              ) : null}
              <div className="look_map">
                <Map
                  id={"map"}
                  center={{ lat: findCompany.lat, lng: findCompany.lng }}
                  style={
                    isResponsive ? { width: "100%", height: "350px" } : mapStyle
                  }
                  level={7}
                  onCreate={setMap}
                  zoomable={!isResponsive ? true : false} // 모바일에선 에러때문에 손가락 줌 안 되게 변경
                >
                  {findCompany.lat && findCompany.lng && (
                    <Circle
                      center={{
                        lat: findCompany.lat,
                        lng: findCompany.lng,
                      }}
                      radius={Number.parseInt(km) * 1000}
                      strokeWeight={1}
                      strokeColor={"#95b9ed"}
                      strokeOpacity={2}
                      strokeStyle={"solid"}
                      // fillColor={'#CFE7FF'}
                      fillColor={"#c4e1f9"}
                      fillOpacity={0.4}
                    />
                  )}

                  <MapMarker
                    position={{
                      lat: findCompany.lat,
                      lng: findCompany.lng,
                    }}
                    image={{
                      // src: 'https://gongsaero.s3.ap-northeast-2.amazonaws.com/system/marker_gongsaero.png', // 마커이미지의 주소입니다
                      src: nowLocation, // 마커이미지의 주소입니다
                      size: {
                        width: 26,
                        height: 42,
                      },
                      // options: {
                      //   offset: {
                      //     x: 27,
                      //     y: 69,
                      //   },
                      // },
                    }}
                  />
                  {siteMapList.map((data, i) => {
                    return (
                      <>
                        <MapMarker
                          key={i}
                          position={{ lat: data.s_lat, lng: data.s_lng }}
                          onClick={() => {
                            const CurrentData = siteMapList.map((item, index) =>
                              index === i && item.flag === false
                                ? { ...item, flag: true }
                                : { ...item, flag: false }
                            );
                            setSiteMapList(CurrentData);
                          }}
                        />
                        {data.flag ? (
                          <FindDetailPop
                            data={data}
                            s_no={data.s_no}
                            siteMapList={siteMapList}
                            setSiteMapList={setSiteMapList}
                            i={i}
                          />
                        ) : null}
                      </>
                    );
                  })}
                </Map>
                <div className="custom_zoomcontrol radius_border">
                  <span onClick={zoomIn}>
                    <img
                      src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png"
                      alt="확대"
                    />
                  </span>
                  <span onClick={zoomOut}>
                    <img
                      src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png"
                      alt="축소"
                    />
                  </span>
                </div>
                <div className="custom_typecontrol radius_border">
                  <span
                    id="btnRoadmap"
                    className="selected_btn"
                    onClick={() => setMapType("roadmap")}
                  >
                    지도
                  </span>
                  <span
                    id="btnSkyview"
                    className="btn"
                    onClick={() => {
                      setMapType("skyview");
                    }}
                  >
                    스카이뷰
                  </span>
                </div>
              </div>

              {isResponsive ? (
                <>
                  <div className="look-list-info">
                    <span>
                      반경 <dd>{km}</dd>km내 검색된 현장은{" "}
                      <dd>{siteMapList.length}</dd>건입니다.
                    </span>
                    <span>
                      (마커와 아래 리스트를 클릭하여 업체정보를 볼 수 있습니다.)
                    </span>
                  </div>
                  <div className="look-list-title">근처 공급사 리스트</div>
                </>
              ) : null}

              <div className="look-list-wrapper">
                {siteMapList.length !== 0 ? (
                  siteMapList.map((data, i) => {
                    return (
                      <div
                        key={i}
                        className="look-list-item"
                        onClick={() => {
                          const CurrentData = siteMapList.map((item, index) =>
                            index === i && item.flag === false
                              ? { ...item, flag: true }
                              : { ...item, flag: false }
                          );
                          setSiteMapList(CurrentData);
                          if (isResponsive) {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }
                        }}
                      >
                        <div className="look-list-item-container">
                          <img
                            className="img"
                            src={data.logo}
                            alt={data.s_name + "_로고"}
                          />
                        </div>

                        <div className="info-wrapper">
                          <p className="name">{data.s_name}</p>
                          <p className="info">현장 분류 : {data.s_type}</p>
                          <p className="address">
                            {data.s_addr1}, {data.s_addr2}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="look-no-result">
                    <span>근처 현장이 없습니다👷‍♂️</span>
                    <span>업체 반경을 조정해보세요.</span>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>

      {/* <div className="look_container_false">
          <div className="look_tit title2">
            <h2>인근 현장 찾기</h2>
            <p>
              현장 인근에 위치한 업체를 찾고 계시다면 아래 업체들은 어떤가요?
            </p>
          </div>
          <div className="not_payed">
            {isResponsive ? (
              <>
                <p>잠깐! 해당 서비스는 공새로 월 유료 서비스를 결제하시면 </p>
                <p>이용 가능하십니다. 등급에 따라 제공되는 </p>
                <p>다양한 혜택을 받아보세요!</p>
              </>
            ) : (
              <p>
                잠깐! 해당 서비스는 공새로 월 유료 서비스를 결제하시면 이용
                가능하십니다. 등급에 따라 제공되는 다양한 혜택을 받아보세요!
              </p>
            )}
            <div className="pay_kinds">
              <div className="pay_way">
                <p className="gold_img"></p>
                <div className="way_info">
                  <h4>골드 등급</h4>
                  <p>인근 현장 찾기 이용가능</p>
                  <p>
                    6,600 <span>₩</span>
                  </p>
                </div>
              </div>
              <div className="pay_way">
                <p className="platinum_img"></p>
                <div className="way_info">
                  <h4>플래티넘 등급</h4>
                  {isResponsive ? (
                    <>
                      <p>인근 현장 찾기 이용</p>
                      <p>+ 월 매거진 이메일 수신 가능</p>
                    </>
                  ) : (
                    <p>인근 현장 찾기 이용 + 월 매거진 이메일 수신 가능</p>
                  )}
                  <p>
                    11,000 <span>₩</span>
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => {
                navigate("/look/payment", { state: buyerData });
              }}
            >
              서비스 결제하기
            </button>
          </div>
        </div> */}
    </div>
  );
}
