import { useEffect, useRef, useState } from "react";
//library
import { useNavigate } from "react-router-dom";
import { Spreadsheet } from "react-spreadsheet";
import ReactTooltip from "react-tooltip";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/requestExcel.css";

import circle_minus from "../../../asset/images/circle-minus.png";
import circle_plus from "../../../asset/images/circle-plus.png";
import question_mark from "../../../asset/images/question_icon_orange.png";
//etc
import {
  estimateEasyExcelApi,
  imgUpApi,
  uploadExcel,
  getMyPage_listApi,
} from "../../../api/auth";
import ExcelDownloadPop from "../../../component/common/Pop/ExcelDownloadPop";
import Loading from "../../../component/etc/Loading";
//redux
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/action";
import swal from "sweetalert";
import { wonComma } from "../../../util/Util";

import { useMediaQuery } from "react-responsive";
import useFee from "../../main/customHooks/useFee";
import ExcelComponent from "../../../component/etc/ExcelComponent";
import TableSummaryFooter from "../../../component/etc/TableFooter";

export default function RequestExcel() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { excel_list, bid_period, bid_state } = useSelector((state) => ({
    excel_list: state.estimate.excel_list,
    bid_period: state.estimate.bid_period,
    bid_state: state.estimate.bid_state,
  }));

  const [uploadFile, setUploadFile] = useState([{ file: "", id: 0 }]);
  const [uploadImg, setUploadImg] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [preSelectData, setPreSelectData] = useState([]);
  const [comment, setComment] = useState("");
  const [fList, setFList] = useState([]);
  const [excelPop, setExcelPop] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [generating2, setGenerating2] = useState(false);
  const [goBottom, setGoBottom] = useState(false);
  const fee = useFee();
  const [emptyAlert, setEmptyAlert] = useState(false);

  useEffect(() => {
    if (excel_list) {
      const pList = excel_list.productInfoList?.map((item) => [
        { value: item.bidProduct.name },
        { value: item.bidProduct.standard },
        { value: item.bidProduct.brand },
        { value: item.bidProduct.etc },
        { value: item.bidProduct.unit },
        { value: item.bidProduct.count },
        { value: item.price },
        { value: "" },
      ]);
      setData(pList);
      setComment(bid_period.comment);
      setUploadFile(excel_list.bidImgList.bidImgName);
      setFList(excel_list.bidImgList.bidImgNo);
    }
  }, []);

  // 파일 추가
  const onCreate = (i) => {
    const file = {
      file: "",
      id: i,
    };
    setUploadFile(uploadFile.concat(file));
  };

  // 파일 삭제
  const onRemove = (id) => {
    setUploadFile(uploadFile.filter((file) => file.id !== id));
    setFList(fList.filter((file) => file.fileIndex !== id));
  };

  // const [data, setData] = useState([
  //   [
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //   ],
  // ]);

  // const [data, setData] = useState([
  //   [
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //   ],
  //   [
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //   ],
  //   [
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //     { value: "" },
  //   ],
  // ]);

  const handleFileUpload = async (e, id) => {
    setGenerating2(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const response = await imgUpApi(formData);

    if (response.status === "success") {
      console.log(e);
      console.log(response.data);
      let copy = fList;
      copy.push({
        fileNo: response.data.f_no,
        fileIndex: id,
        fileName: response.data.f_name,
        fileRoot: response.data.f_path,
      });
      setFList(copy);
      setUploadImg([...uploadImg, e.target.files[0]]);
    }

    if (response.status === "NoData" || response.status === "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
    setGenerating2(false);
  };

  // 견적요청 완료하기
  const sendData = async () => {
    if (data.length === 0) {
      return swal("오류", "한줄 이상 입력해주세요.", "error");
    }

    setData(data);
    await saveAndFormat(data);
    await navigate("/management/order/request_input_info");
  };

  const saveAndFormat = (data) => {
    const pList = data.map((el) => ({
      bidProduct: {
        name: el[0]?.value, // 이름
        standard: el[1]?.value, //규격
        brand: el[2]?.value, //제조국
        etc: el[3]?.value, // 비고
        unit: el[4]?.value, //단위
        count: el[5]?.value != 0 ? Number(el[5]?.value) : "", // 수량
      },
      price: el[6]?.value != 0 ? Number(el[6]?.value) : "", //금액
    }));

    const fileList = {
      bidImgNo: fList,
      bidImgName: uploadFile,
    };

    let totalBid = {
      excel_list: { productInfoList: pList, bidImgList: fileList },
      bid_period: { ...bid_period, comment: comment },
      bid_state: "excel",
    };

    dispatch(actions.setEstimate(totalBid));
  };

  const checkAndAlert = () => {
    let hasEmptyFields = false;
    console.log(data[0][5].value);
    console.log(Number(data[0][5].value));

    for (const item of data) {
      if (
        !item[0].value ||
        !item[4].value ||
        !item[5].value ||
        item[5].value <= 0 ||
        isNaN(item[5].value) ||
        !Number.isInteger(Number(item[5].value)) || // 소수점인지 확인
        item[5].value >= 10000000 || // 수량 7자리 이상 안 받음
        !item[6].value ||
        isNaN(item[6].value) ||
        !Number.isInteger(Number(item[6].value)) || // 소수점인지 확인
        item[6].value <= 1 // 1원도 안 받음
      ) {
        hasEmptyFields = true;
        break;
      }
    }

    if (hasEmptyFields) {
      swal({
        title: "필수 정보가 누락된 항목이 존재합니다.",
        text: `품목명, 단위, 수량, 고객사 납품단가는 필수 입력 정보입니다.\n 누락된 항목을 확인하시어 입력해주시기 바랍니다.`,
        icon: "error",
        buttons: true,
      }).then(() => {
        setEmptyAlert(true);
        saveAndFormat(data);
      });
    } else {
      sendData(data);
    }
  };

  let isDisabled = false;

  useEffect(() => {
    if (selectData.length !== 0) {
      setPreSelectData(selectData);
    }
  }, [selectData]);

  const onClickPrev = () => {
    swal({
      title: "입력한 정보는 저장되지 않습니다.",
      text: "이전 단계로 이동하시겠습니까?",
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: {
        cancle: {
          text: "취소",
          value: false,
        },
        confirm: {
          text: "확인",
          value: true,
        },
      },
    }).then((result) => {
      if (result) {
        navigate("/management/order/request_type", {
          state: { againFirst: true },
        });
      }
    });
  };

  // 공급사 정산 금액 합
  const sumPartnerAll = () => {
    const a = data
      ?.map((data) => data[5]?.value * data[6]?.value)
      .reduce((sum, current) => sum + current, 0);

    if (isNaN(a)) {
      return "수량과 단가에 숫자를 입력해주세요.";
    } else {
      return wonComma(a) + "원";
    }
  };

  // 공급사 최종금액 개별
  const sumPartnerElem = (data) => {
    // is right 합계
    console.log(data[6]?.value);
    if (
      data[5]?.value === "" ||
      data[6]?.value === "" ||
      data[5]?.value === undefined ||
      data[6]?.value === undefined
    ) {
      return 0 + "원";
    } else {
      const totalValue = data[5]?.value * data[6]?.value;
      const formattedValue = totalValue.toLocaleString("ko");
      return formattedValue + "원";
    }
  };

  // 각 최종단가 개별
  const eachPartnerElem = (data, index) => {
    if (data[index].value) {
      const convertValue = Number(data[index]?.value);
      const formattedValue = convertValue.toLocaleString("ko");

      return formattedValue;
    } else {
      return 0;
    }
  };

  const deleteSelectRow = (row) => {
    if (data.length === 1) {
      return;
    }
    let targetList = Array(); //array 생성
    targetList.push(row); //[3]

    const delArr = data.map((item, index) => {
      //선택한 row가 없다면, 값 제대로 return
      //선택한 row는 Undefined return
      if (!targetList.includes(index)) {
        return item;
      }
    });
    //undefined row 제거
    const filtered = delArr.filter(function (x) {
      return x !== undefined;
    });

    setData(filtered);
  };

  const scrollRef = useRef();

  // useEffect(() => {
  //   scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  // }, [goBottom]);

  const [rows, setRows] = useState(3);
  const [cols, setCols] = useState(8);

  // 초기값 지정
  const createGridData = (rows, cols) => {
    let count = 1;
    const data = [];
    for (let i = 0; i < rows; i++) {
      const rowData = [];
      for (let j = 0; j < cols; j++) {
        rowData.push({
          id: count++,
          value: j === 5 ? "1" : j === 2 ? "무관" : "",
          isValid: false,
        });
      }
      data.push(rowData);
    }
    return data;
  };
  const [data, setData] = useState(createGridData(rows, cols));

  const addRow = () => {
    // setRows(rows + 1);
    let count = 10;
    const rowData = [];
    for (let j = 0; j < cols; j++) {
      rowData.push({
        id: count++,
        value: j === 5 ? "1" : j === 2 ? "무관" : "",
        isValid: false,
      });
    }
    setData([...data, rowData]);
    setRows(rows + 1);
    // setCols(20);
  };

  const deleteAll = () => {
    swal({
      title: "",
      text: `작성하신 엑셀 품목이 삭제됩니다.\n 정말 삭제하시겠습니까?`,
      icon: "warning",
      buttons: true,
    }).then((willDelete) => {
      if (willDelete) {
        setData(createGridData(3, 8));
      } else {
      }
    });
  };
  // 최종 합계 금액
  const totalInvoiceAmount = data
    .map((item) => item[5].value * item[6].value)
    .reduce((sum, current) => sum + current, 0);

  // 중개 수수료
  const feePercent = (100 + fee) / 100;
  const feeTotalPrice = data
    .map((item) => {
      const count = item[5].value;
      const price = item[6].value;

      if (price <= 0) return 0;

      const feeValue = price - Math.ceil(price / feePercent);
      const adjustedFeeValue = feeValue <= 1 ? 1 : feeValue;
      const finalPrice = adjustedFeeValue * count;

      return finalPrice;
    })
    .reduce((sum, current) => sum + current, 0);

  return (
    <div className="request_step4_wrap">
      <Mheader title="주문서 작성하기" />

      <div className="request_step4_container wd" style={{ paddingTop: "0" }}>
        {/* step info */}
        <div className="top_progress_bar">
          <div className="active_menus" onClick={onClickPrev}>
            주문서 작성하기
          </div>
          <div className="menus">상세정보 입력</div>
          <div className="menus">상세정보 확인</div>
          <div className="menus not_allowed">주문서 작성 완료</div>
        </div>

        <div className="top_request_step4">
          {/* excel input guide */}
          <p>
            * 엑셀란은 빈행없이 작성해주세요.
            <br />* 가지고 계신 엑셀 파일에서 복사/붙여넣기가 가능합니다.
          </p>
        </div>

        <div className="excel_add_container">
          <div className="excel_add_btn" onClick={addRow}>
            <img src={circle_plus} />
            <span>행 추가</span>
          </div>
        </div>

        <ExcelComponent
          data={data}
          setData={setData}
          cols={cols}
          setCols={setCols}
          rows={rows}
          setRows={setRows}
          addRow={addRow}
          eachPartnerElem={eachPartnerElem}
          sumPartnerElem={sumPartnerElem}
          emptyAlert={emptyAlert}
        />

        <div className="excel_inner">
          <table>
            <tfoot>
              <tr>
                <th rowSpan="3" colSpan="6">
                  <div className="tfoot_sum_desc">
                    * 최종 금액은 중개 수수료 {fee}%가 포함된 가격입니다.
                    <br />* 본 주문서의 중개수수료는{" "}
                    {feeTotalPrice.toLocaleString("ko")}원입니다.
                    <br />* 나의 정산금액(공새로 세금계산서 발행)은 (최종 합계
                    금액 - 중개수수료)인{" "}
                    {(totalInvoiceAmount - feeTotalPrice).toLocaleString("ko")}
                    원입니다.
                  </div>
                </th>

                <th className="table_total_amount">
                  <div className="tfoot_total_price">
                    최종 합계 금액
                    <br />
                    (고객사 납품 단가)
                  </div>
                </th>
                <td>{sumPartnerAll()}</td>
                <th className="table_index"></th>
              </tr>
            </tfoot>
            {/* <TableSummaryFooter
            list={data}
            priceKey="array(5).value"
            countKey="array(6).value"
            rowSpan="3"
            colSpan="7"
          /> */}
          </table>
        </div>
        <div className="excel_delete_controls">
          <span className="delete_control" onClick={deleteAll}>
            전체삭제
          </span>
        </div>

        <ul className="request_step4_list">
          {/* additional description about estimate */}
          <li>
            <p>주문 관련 추가 설명</p>
            <div className="textarea_div" spellCheck="false">
              <textarea
                placeholder="주문 관련 추가 설명이 있다면 작성해주세요."
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                defaultValue={bid_period.comment}
              />
            </div>
          </li>
          {/* image upload */}
          <li>
            <p>이미지 첨부</p>
            <div>
              {uploadFile.map((el, i) => (
                <div className="img_add" key={el.id}>
                  <input
                    type="file"
                    id={el.id}
                    accept="image/*"
                    onChange={(e) => {
                      const arr = uploadFile.map((f_name) =>
                        f_name.id === el.id
                          ? { ...f_name, file: e.target.files[0].name }
                          : { ...f_name }
                      );

                      setUploadFile(arr);
                      handleFileUpload(e, el.id);
                    }}
                  />
                  <p>{el.file}</p>
                  <label htmlFor={el.id}>찾아보기</label>
                  {el.id === 0 ? (
                    <div
                      className="btn_add"
                      onClick={() => {
                        // 5개까지만 칸 추가
                        if (uploadFile.length < 5) {
                          onCreate(uploadFile.length);
                        }
                      }}
                    >
                      추가 +
                    </div>
                  ) : (
                    <div
                      className="btn_add"
                      onClick={() => {
                        if (!uploadFile.length < 2) {
                          onRemove(uploadFile[i].id);
                        }
                      }}
                    >
                      삭제 -
                    </div>
                  )}
                </div>
              ))}
            </div>
          </li>
          <li>
            {/* page buttons */}
            <div className="page_btn">
              <div className="btn_prev" onClick={onClickPrev}>
                이전
              </div>
              <div className="btn_next" onClick={checkAndAlert}>
                다음
              </div>
            </div>
          </li>
        </ul>
      </div>

      {excelPop ? <ExcelDownloadPop setExcelPop={setExcelPop} /> : null}
      {generating && <Loading msg={"투찰 요청중입니다."} />}
      {generating2 && <Loading msg={"이미지 업로드중입니다."} />}
    </div>
  );
}
