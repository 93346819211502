import React, { useState } from "react";
import { Link } from "react-router-dom";
import i_close from "../../../asset/images/w-cancel-icon.png";
import "../../../asset/css/pop.css";
import { mypage_UpdateRankApi } from "../../../api/auth";

//sweet alert
import swal from "sweetalert";

export default function EditPositionPop({ editPosition, setEditPosition }) {
  //직급보내기
  const [uRank, setuRank] = useState("");
  const [customRank, setCustomRank] = useState("");

  const OnClick_mypage_UpdateRank = async () => {
    const res = await mypage_UpdateRankApi(
      uRank === "직접입력" ? customRank : uRank
    );

    if (res.status === "success") {
      window.location.replace("/my_page/my_info");
      swal("직급이 변경되었습니다.", "", "success");
    }
    if (res.status === "fail" || uRank === "") {
      swal("직급을 선택해주세요", "", "warning");
    }
  };

  const handleRankChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "직접입력") {
      // 직접입력이 선택된 경우, 입력란을 표시하고 사용자가 입력한 값을 저장합니다.
      setuRank("직접입력");
    } else {
      // 다른 옵션이 선택된 경우, 입력란을 숨기고 선택된 값을 저장합니다.
      setuRank(selectedValue);
    }
  };

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setEditPosition(false)}></div>
      <div className="edit_position_pop">
        <div className="close" onClick={() => setEditPosition(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3 className="pop_tit">내 정보 변경</h3>
        <div className="edit_position_info">
          <h4 style={{ textAlign: "left", margin: "0" }}>직급</h4>
          <select onChange={handleRankChange}>
            <option value="" selected disabled hidden>
              직급을 선택해주세요.
            </option>
            <option value="사원">사원</option>
            <option value="대리">대리</option>
            <option value="과장">과장</option>
            <option value="부장">부장</option>
            <option value="대표">대표</option>
            <option>직접입력</option>
          </select>
          {uRank === "직접입력" && (
            <input
              style={{ marginTop: "5px" }}
              type="text"
              placeholder="직급을 입력해주세요."
              // value={customRank}
              onChange={(e) => setCustomRank(e.target.value)}
            />
          )}
          <div className="edit_position_btn btn_box">
            <div className="cancel" onClick={() => setEditPosition(false)}>
              <p>취소</p>
            </div>
            <div className="ok" onClick={OnClick_mypage_UpdateRank}>
              <p>확인</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
