import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//api
import { findIdApi } from "../../api/auth";
// css
import "../../asset/css/find.css";

export default function FindId() {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const userNo = location?.state?.userNo;
  const [userId, setUserId] = useState("");

  useEffect(() => {
    // 들어올 때 값 매핑
    findIdFnc();
  }, [location]);

  const findIdFnc = async () => {
    const response = await findIdApi(userNo);
    console.log(response);
    if (response.status === "success") {
      setUserId(response.data.id);
    }
  };

  return (
    <div className="completed_wrap joincomplete_wrap">
      <div className="completed_container joincomplete_container">
        <div className="completed_info">
          <div className="join_top">
            <h2>아이디 찾기 완료</h2>
            <div className="join_pagenation">
              <p>1</p>
              <p className="active">2</p>
            </div>
          </div>

          <p className="complete_text">
            회원님의 아이디는
            <br />
            <span>{userId}</span>입니다.
          </p>
        </div>
        <div className="btn_wrap find_btn_wrap">
          <div
            onClick={() => {
              navigate("/find", {
                state: {
                  goPwTab: true,
                },
              });
            }}
          >
            비밀번호 찾기
          </div>
          <div
            onClick={() => {
              navigate("/login");
            }}
          >
            로그인
          </div>
        </div>
      </div>
    </div>
  );
}
