import React from "react";
import i_close from "../../../asset/images/w-cancel-icon.png";
import i_download from '../../../asset/images/download_icon.png';
import { useLocation } from "react-router-dom";

import { estimateContractApi } from "../../../api/auth";
import Loading from "../../etc/Loading";
import { useMediaQuery } from "react-responsive";

import { isMobile, isIOS } from "react-device-detect";
import { orderCheckApi, orderDetailApi } from "../../../api/auth";

export default function GenerateCheckPop({ setGeneratePop, filePath, orNo }) {
  const location = useLocation();
  const PageName = '주문 확인서';
  


  const downloadPdf = async () => {
    const link = document.createElement('a');
    link.href = filePath;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="pop">
        <div className="pop_dim" onClick={() => setGeneratePop(false)}></div>
        <div className="contract_terms_pop">
          <div className="close" onClick={() => setGeneratePop(false)}>
            <img src={i_close} alt="" />
          </div>
          <h3 className="pop_tit">{PageName}</h3>
          <div>
            {!isMobile ? (
              <>
              <iframe
                style={{ width: "100%", height: "500px" }}
                src={filePath}
              ></iframe>
              <div className="pop_download_container">
                <span>보안 프로그램의 문제로 인해 미리보기가 표시되지 않는 경우, 다운로드하여 {PageName}를 확인해주세요.</span>
                <div className="pop_download_box" onClick={()=>{downloadPdf()}}>
                    <span>다운로드</span>
                    <img src={i_download} alt=""/>
                </div>
              </div>
              </>
            ) : isMobile && isIOS ? ( //아이폰 웹에서 계약서보기로 띄워줌
              <div>
                <span className="contract_terms_info">
                  모바일 환경에선 미리보기가 불가합니다.
                  <br />
                  아래 계약서를 확인한 후 동의를 눌러주세요.
                </span>
                <a className="go_contract_terms" href={filePath}>
                  계약서 보기
                </a>
              </div>
            ) : (
              <div>
                <iframe
                  style={{ width: "100%", height: "200px" }}
                  src={filePath}
                ></iframe>
                <span className="contract_terms_info">
                  모바일 환경에선 미리보기가 불가합니다.
                  <br />
                  다운로드 받은 계약서를 보고 계약해주세요!
                </span>
                <div className="pop_download_container">
                <span>보안 프로그램의 문제로 인해 미리보기가 표시되지 않는 경우, 다운로드하여 {PageName}를 확인해주세요.</span>
                <div className="pop_download_box" onClick={()=>{downloadPdf()}}>
                    <span>다운로드</span>
                    <img src={i_download} alt=""/>
                </div>
              </div>
              </div>
            )}
          </div>

          <div className="terms_pop_btn btn_box">
            <div className="agree">
              <a onClick={() => setGeneratePop(false)}>확인</a>
            </div>
          </div>
        </div>
      </div>

      {/* {generating && <Loading msg={'계약 날인중입니다.'} />} */}
    </>
  );
}
