import React, { useEffect, useState } from "react";
//library
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
//component
import Mnavi from "./Mnavi.js";
//css
import "../../asset/css/common.css";
//etc
import menu_img from "../../asset/images/m-menu-icon.png";
import { menuList } from "../../constant/Constant";
import gong_chatting from "../../asset/images/gong_chatting.png";
import channel_talk_web_icon from "../../asset/images/channel_talk_web_icon.png";
import look_icon from "../../asset/images/look_header_icon.png";
import ChannelService from "../../component/etc/ChannelService";
import my_page_icon from "../../asset/images/icon_my_page.png";
import x_icon from "../../asset/images/icon_close_x.svg";
import history_icon from "../../asset/images/icon-history.png";

//redux
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../redux/action";
import { userDefault } from "../../redux/reducer/default.js";

//sweet alert
import swal from "sweetalert";
import { AnimatePresence, motion } from "framer-motion";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { signToken } = useSelector((state) => ({
    signToken: state.token,
  }));

  const { u_name, c_name } = useSelector((state) => ({
    u_name: state.user.u_name,
    u_rank: state.user.u_rank,
    c_name: state.user.c_name,
  }));

  const recentSearchList = useSelector((state) => state.activity.activity_list);
  const isResponsive = useMediaQuery({
    query: "(max-width : 1080px)",
  });
  const [navList, setNavList] = useState(menuList);
  const [naviSwitch, setNaviSwitch] = useState(false);
  const [comeList, setComeList] = useState(menuList);
  const [keyword, setKeyword] = useState("");
  const [headerFocus, setHeaderFocus] = useState(false);

  const [recentKeyword, setRecentKeyword] = useState([]);
  const [lookInfoPopup, setLookInfoPopup] = useState(false);

  //클릭한 메뉴
  const [activeMenu, setActiveMenu] = useState({ id: 0, active: false });

  //로그아웃
  const logoutFnc = () => {
    swal({
      title: "LOGOUT",
      text: "로그아웃 하시겠습니까?",
      icon: "info",
      buttons: true,
      // dangerMode: false,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(actions.setToken(false));
        dispatch(actions.setUser(userDefault));

        navigate("/", { replace: true });
      } else {
        swal("취소하셨습니다.");
      }
    });
  };

  useEffect(() => {
    if (recentSearchList) {
      //처음들어왔을때, 배열 순서 뒤로 뒤집고, 5개까지 자르기, 최근키워드에 저장, 중복 제거..ㅠㅠ

      const filterArr = recentSearchList.filter((elem, i) => {
        return recentSearchList.indexOf(elem) === i;
      });
      const realRecentSearchList = [...filterArr].slice(0, 5);
      const total = {
        activity_list: realRecentSearchList,
      };
      dispatch(actions.setActivity(total));
      setRecentKeyword(realRecentSearchList);
    }
  }, [headerFocus]); //최근검색어 나올 때마다 잘라주기

  //nav 이벤트
  const activeFunction = (location) => {
    const activeList = menuList.map((item) =>
      item.uri.indexOf(location.split("/")[1]) > 0
        ? { ...item, active: true }
        : { ...item }
    );
    setNavList(activeList);
    const filterList = activeList.filter((item) => item.active);
    if (filterList.length > 0) {
      setActiveMenu(filterList[0]);
    }
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveMenu({ ...activeMenu, id: 0 });
    }
    activeFunction(location.pathname);
    setNaviSwitch(false);
  }, [location]);

  const searchFnc = (headerKey) => {
    if (keyword === "" && headerKey === undefined) {
      return swal("검색어를 입력해주세요.", "", "warning");
    }
    //focus 닫기
    setHeaderFocus(false);
    let copy = recentKeyword; //여기서 분기해주기
    copy.unshift(keyword); //push는 뒤에 추가, unshift는 앞에 추가
    setRecentKeyword(copy);

    let total = {
      activity_list: recentKeyword,
    };

    dispatch(actions.setActivity(total));

    let totalKeyword = headerKey ? headerKey : keyword;

    //keyword갖고 이동
    navigate("/my_page/my_info/product_registration", {
      state: { headerKeyword: totalKeyword },
    });
  };

  const _onKeyPress = (e) => {
    if (e.key == "Enter") {
      searchFnc();
    }
  };

  useEffect(() => {
    ChannelService.boot({
      pluginKey: "20a812ff-1572-4c34-8c82-814edbd61f81", //please fill with your plugin key
      customLauncherSelector: ".custom_alarm",
      hideChannelButtonOnBoot: true,
    });
  }, []);

  return signToken !== false ? (
    <>
      <div
        className={
          c_name !== "" && location.pathname === "/"
            ? "header_wrap2"
            : "header_wrap_else2"
        }
      >
        <div className="header_container">
          {isResponsive ? (
            <>
              <div
                className="m_header_right"
                onClick={() => {
                  setNaviSwitch(true);
                }}
              >
                <img src={menu_img} alt="" className="menu_icon" />
              </div>
              <Mnavi
                naviSwitch={naviSwitch}
                setNaviSwitch={setNaviSwitch}
                comeList={comeList}
                setComeList={setComeList}
                userName={u_name}
                selectedSite={c_name}
                logoutFnc={logoutFnc}
              />
              {/* {comeList.uri === '/' ? "asdfasdf":"asdfasdfasdf"} */}
            </>
          ) : (
            <>
              {/* <nav className='main_nav'>
                {navList.map((data, i) => (
                  <Link
                    to={data.uri}
                    onClick={() => {
                      setActiveMenu({ ...data, active: true });
                    }}
                    className={data.active ? 'active_menu' : 'unactive_menu'}
                    key={i.toString()}
                  >
                    {data.mainNav}
                  </Link>
                ))}
              </nav> */}
              <div className="main_search_container">
                <div className="main_look_container">
                  <img
                    src={look_icon}
                    alt=""
                    className="main_search_icon"
                    onClick={() => {
                      navigate("/look");
                      // dispatch(actions.setActivity(activityDefault));
                    }}
                    onMouseOver={() => {
                      setLookInfoPopup(true);
                    }}
                    onMouseLeave={() => {
                      setLookInfoPopup(false);
                    }}
                  />
                  <AnimatePresence>
                    {lookInfoPopup ? (
                      <motion.p
                        className="look_desc_tooltip"
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          scale: [1, 1.1, 1.1, 1.1, 1],
                        }}
                        exit={{ opacity: 0 }}
                      >
                        인근현장찾기
                      </motion.p>
                    ) : null}
                  </AnimatePresence>
                </div>
                <div className="search_input_container">
                  <input
                    type="text"
                    className="main_search_input"
                    placeholder="작업명 및 등록할 품목을 입력해주세요."
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setHeaderFocus(false);
                      } else {
                        setKeyword(e.target.value.trim()); //공백제거
                        setHeaderFocus(true);
                      }
                    }}
                    onKeyPress={_onKeyPress}
                    onClick={() => {
                      setHeaderFocus(true);
                    }}
                    // onBlur={()=>{
                    //   setHeaderFocus(false);
                    // }}
                  />
                  {headerFocus && (
                    <div
                      className="dropdown_menu"
                      onMouseLeave={() => {
                        setHeaderFocus(false);
                      }}
                    >
                      <div className="dropdown_recent">
                        <p className="recent_keyword_title">최근검색어</p>
                        {/* useselector로 가져오기 */}
                        <div className="recent_keyword_container">
                          {recentSearchList?.map((a, i) => {
                            return (
                              <div
                                className="recent_search_list"
                                onClick={() => {
                                  searchFnc(a);
                                  setHeaderFocus(false);
                                }}
                              >
                                <span>{a}</span>
                                <img
                                  src={x_icon}
                                  alt=""
                                  className="x_icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    const filtering = recentSearchList.filter(
                                      (all) => all !== a
                                    );
                                    //filter
                                    let total = { activity_list: filtering };
                                    dispatch(actions.setActivity(total));
                                  }}
                                />
                              </div>
                            );
                          })}
                          {recentSearchList.length == 0 && (
                            <p className="no_recent_keyword">
                              최근 검색어가 없습니다.
                            </p>
                          )}
                        </div>
                        {/* <p className="recommend_keyword_title">추천 키워드</p>
                        <div className="recommend_keyword_container">
                           <span onClick={()=>{
                            history.push({
                              pathname:'/management/estimate/request_choice',
                              state:{keyKind:'7'}
                            });
                           }}>공통공사</span>
                           <span onClick={()=>{
                            history.push({
                              pathname:'/management/estimate/request_choice',
                              state:{keyKind:'8'}
                            });
                           }}>POSCO 이노빌트</span>
                           <span onClick={()=>{
                            history.push({
                              pathname:'/management/estimate/request_choice',
                              state:{keyKind:'9'}
                            });
                           }}>안전용품</span>
                           <span onClick={()=>{
                            history.push({
                              pathname:'/management/estimate/request_choice',
                              state:{keyKind:'10'}
                            });
                           }}>폐기물</span>
                        </div>  */}
                      </div>
                    </div>
                  )}
                </div>
                <span
                  className="main_search_btn"
                  onClick={() => {
                    // dispatch 추가필요
                    searchFnc();
                  }}
                >
                  검색
                </span>
              </div>
              <div className="header_right_on">
                <div className="header_info">
                  <p>{c_name}</p>
                  <p>{u_name}</p>
                </div>
                {/* <p onClick={logoutFnc}>로그아웃</p> */}

                <div
                  className="header_right_btn"
                  onClick={() => {
                    navigate("/my_page/history");
                  }}
                >
                  <img
                    src={history_icon}
                    className="header_my_page_icon"
                    alt=""
                  />
                  <p>히스토리</p>
                </div>

                <div
                  className="header_right_btn"
                  onClick={() => {
                    navigate("/my_page/my_info");
                  }}
                >
                  <img
                    src={my_page_icon}
                    className="header_my_page_icon"
                    alt=""
                  />
                  <p>마이페이지</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* {activeMenu.id === 3 || activeMenu.id === 6 ? (
        <div className='sub' style={{ top: 100 }}>
          <div className='sub_title'>
            {navList[activeMenu.id - 1].subNav.map((data, index) => (
              <Link
                to={data.to}
                onClick={() => setNowUrl(data.to)}
                key={index.toString()}
                className={
                  nowUrl.includes(data.to)
                    ? 'sub_on subtest1'
                    : 'sub_off subtest2'
                }
              >
                {data.title}
              </Link>
            ))}
          </div>
        </div>
      ) : null} */}
      {/* 질의 응답에서는 알림톡 지우기! */}
      <div
        className={
          location.pathname === "/qna" && isResponsive
            ? "displayNone"
            : "custom_alarm"
        }
      >
        <img src={channel_talk_web_icon} alt="" />
      </div>
    </>
  ) : (
    <div
      className={location.pathname === "/" ? "header_wrap" : "header_wrap_else"}
    >
      <div className="header_container">
        <div
          className="header_left"
          onClick={() => {
            navigate("/");
          }}
        >
          <h1>{/* 로고이미지 */}</h1>
          <p>공급사</p>
        </div>
        {isResponsive ? null : (
          <div className="header_right">
            <Link to="/login">로그인</Link>
            <p
              className="top-join-button"
              onClick={() => {
                navigate(`/join`, {
                  state: { socialType: "", socialValue: "" },
                });
              }}
            >
              회원가입
            </p>
          </div>
        )}
      </div>
      <div class="custom_alarm">
        <img src={channel_talk_web_icon} alt="" />
      </div>
    </div>
  );
}

export default Header;
