import * as S from "../../../asset/css/allHistory.styles";
import { PLACEHOLDER } from "../../../constant/Placeholder.js";
import { allTradeHistoryListApi } from "../../../api/auth.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Mheader from "../../../component/common/Mheader.js";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import arrowClose from "../../../asset/images/arrow-close-icon.png";

export default function AllHistory() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [defaultHistoryList, setDefaultHistoryList] = useState({
    keyword: "",
  });
  const [fetchedHistoryList, setFetchedHistoryList] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("전체");
  const [enterSearchKeyword, setEnterSearchKeyword] = useState("");
  const [maxNum, setMaxNum] = useState(0);
  const [tempVar, setTempVar] = useState(0);
  const [selectTab, setSelectTab] = useState(false);
  const [keyword, setKeyword] = useState("");

  const fetchHistoryList = async () => {
    const response = await allTradeHistoryListApi(keyword);
    if (response.status === "success") {
      setEnterSearchKeyword(keyword);
      setFetchedHistoryList(response.data.itemList);
      console.log(response.data);
      setMaxNum(response.data.count);
    }
  };

  const onKeyPressFetchHistoryList = (e) => {
    if (e.key === "Enter") {
      fetchHistoryList();
      setSelectValue(keyword);
    }
  };

  const onChangeSearchKeyword = (e) => {
    setKeyword(e.target.value);
  };

  // useEffect

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setDefaultHistoryList({
      ...defaultHistoryList,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
      nowPage: 1,
    });
  };

  useEffect(() => {
    fetchHistoryList();
  }, [tempVar]);

  const navigateFnc = async (type, typeNo) => {
    let path;

    if (type === "BID") {
      path = `/management/estimate/document/${typeNo}`;
    } else if (type === "CONTRACT") {
      path = `/management/contract/document/${typeNo}`;
    } else if (type === "ORDERS") {
      path = `/management/order/document/${typeNo}`;
    }

    navigate(path);
  };

  const navigatePageFnc = (type, no) => {
    console.log(type, no);
    if (type === "bid") {
      navigate("/management/estimate/document/" + no);
    }
    if (type === "orders") {
      navigate("/management/order/document/" + no);
    }
    if (type === "") {
    }
  };

  return (
    <S.Wrap>
      <Mheader title="품목별 거래이력" />
      <S.Header>
        <S.Title>품목별 거래이력</S.Title>
        <S.SearchBox>
          <S.SearchInput
            placeholder={PLACEHOLDER.HISTORY_SEARCH_INPUT}
            onChange={onChangeSearchKeyword}
            onKeyPress={onKeyPressFetchHistoryList}
          />

          <S.SearchBtn onClick={fetchHistoryList}>검색</S.SearchBtn>
        </S.SearchBox>
      </S.Header>

      <S.SearchResult>
        <span>{enterSearchKeyword || "전체"}</span> &nbsp;검색결과 :
        <span>{maxNum}</span>건
      </S.SearchResult>

      {isDesktop && (
        <S.HistoryListWrap>
          <S.ListTitle>
            <span>현장 및 단가 작성일</span>
            <span>품목명</span>
            <span>고객사 납품 단가</span>
            <span>주문금액</span>
            <span>비고</span>
            <span>상태분류</span>
            <span></span>
          </S.ListTitle>

          {fetchedHistoryList && fetchedHistoryList.length > 0 ? (
            fetchedHistoryList.map((data, idx) => (
              <S.ListContent key={idx}>
                <span title={data.siteName}>
                  <S.RegionSection>
                    <span>{data.siteName || "-"}</span>
                    <span>{data.createdDate?.substring(0, 16)}</span>
                  </S.RegionSection>
                </span>

                <span>
                  <S.NameSection>
                    <div>
                      <span title={data.productName || "-"}>
                        {data.productName || "-"}
                      </span>
                    </div>
                    <span title={data.standard || "-"}>
                      {data.standard || "-"}
                    </span>
                  </S.NameSection>
                </span>
                <span title={data.price.toLocaleString("ko") + "원"}>
                  <S.PriceSection>
                    {data.price.toLocaleString("ko")}원
                  </S.PriceSection>
                </span>
                {/* <span title={data.totalPrice.toLocaleString("ko") + "원"}>
          <S.PriceSection>
            {data.totalPrice.toLocaleString("ko")}원
          </S.PriceSection>
        </span> */}
                <span>
                  <S.AllPriceSection>
                    <span>총 {data.totalPrice.toLocaleString("ko")}원</span>
                    <span
                      title={
                        data.price.toLocaleString("ko") +
                        "원 * " +
                        data.count +
                        "개 [단위 :" +
                        data.unit +
                        "]"
                      }
                    >
                      ({data.price.toLocaleString("ko") + "원"} *{" "}
                      {data.count + "개"}&nbsp;[단위 : {data.unit}])
                    </span>
                  </S.AllPriceSection>
                </span>
                <span>
                  <S.EtcSection title={data.description || "-"}>
                    {data.description || "-"}
                  </S.EtcSection>
                </span>
                <span>
                  <S.NameSection title={data.state || "-"}>
                    {data.state}
                  </S.NameSection>
                </span>
                <span>
                  <S.ViewPageBtn
                    title={"상세페이지로 이동합니다."}
                    onClick={() =>
                      navigatePageFnc(data.targetType, data.targetNo)
                    }
                  >
                    상세보기
                  </S.ViewPageBtn>
                </span>
              </S.ListContent>
            ))
          ) : (
            <S.ListContent>
              <S.NoResultList>
                <span>{enterSearchKeyword}</span>에 대한 검색 결과가 없습니다.
              </S.NoResultList>
            </S.ListContent>
          )}
        </S.HistoryListWrap>
      )}

      {isMobile && (
        <S.M_HistoryListContainer>
          {fetchedHistoryList?.map((data, idx) => (
            <S.M_HistoryListWrap key={idx}>
              <S.M_HistoryListHeader>
                {data.siteName || "-"}&nbsp; | &nbsp;
                {data.createdDate?.substring(0, 16)}
              </S.M_HistoryListHeader>
              <S.M_HistoryListBody>
                <S.M_HistoryListNameSection>
                  <span>품목명 : {data.productName || "-"}</span>
                </S.M_HistoryListNameSection>
                <div>
                  단가 : {data.price.toLocaleString("ko") + "원" || "-"}
                </div>
                <S.M_HistoryListPriceSection>
                  주문금액 :
                  {"총 " + data.totalPrice.toLocaleString("ko") + "원" || "-"}
                  <span>
                    ({data.price.toLocaleString("ko") + "원"} *{" "}
                    {data.count + "개"}&nbsp;[단위 : {data.unit}])
                  </span>
                </S.M_HistoryListPriceSection>
                <S.M_HistoryListEtcSection
                  // data-tip="React-tooltip"
                  onClick={() => setSelectTab(idx)}
                  selectTab={selectTab}
                  index={idx}
                >
                  <img src={arrowClose} />
                  비고 : {data.description || "-"}
                </S.M_HistoryListEtcSection>
                <S.M_HistoryAllListFooter>
                  <S.M_HistoryDetailBtn
                    onClick={() =>
                      navigatePageFnc(data.targetType, data.targetNo)
                    }
                  >
                    상세보기
                  </S.M_HistoryDetailBtn>
                </S.M_HistoryAllListFooter>
              </S.M_HistoryListBody>
            </S.M_HistoryListWrap>
          ))}
        </S.M_HistoryListContainer>
      )}
    </S.Wrap>
  );
}
