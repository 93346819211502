import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import i_close from '../../../asset/images/w-cancel-icon.png';
import '../../../asset/css/pop.css';
//css
import '../../../asset/css/myInfoEditPwPop.css';
import { mypage_UpdateLogoApi, imgUpApi } from '../../../api/auth';
import i_remove from '../../../asset/images/w-cancle-bt.png';

//sweet alert
import swal from 'sweetalert';


export default function MyInfoEditLogo({ setEditLogo, company }) {
  //사용자 프사 업로드
  const [logoImg, setLogoImage] = useState(null);
  const [selectFile, setSelectFile] = useState('');
  const [uploadImg, setUploadImg] = useState(''); //img src
  const [uploadImgNo, setUploadImgNo] = useState(''); //img no

  //변경하기
  const mypage_UpdateLogoChgFnc = async () => {
    const response = await mypage_UpdateLogoApi(uploadImgNo);

    if (response.status === 'success') {
      // swal('사업자 프로필 이미지 변경에 성공하였습니다.');
      setEditLogo(false);
      window.location.replace('/my_page/my_info');
    }
  };

  const handleLogoChg = async (e) => {
    setSelectFile(e.target.files[0].name);
    setLogoImage(e.target.files[0]);
  };
  const handleLogoIgForm = async () => {
    let form = new FormData();
    form.append('file', logoImg);
    const response = await imgUpApi(form);

    if (response.status === 'success') {
      setUploadImg(response.data.f_path); //f_no와 f_path를 넣어줌.
      setUploadImgNo(response.data.f_no);
      //  성공했을 때
    } else if (response.status === 'NoData' || 'Error') {
      swal('파일이 업로드가 되지 않았습니다.','','warning');
    }
  };
  useEffect(() => {
    if (logoImg) {
      handleLogoIgForm();
    }
  }, [logoImg]);

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setEditLogo(false)}></div>
      <div className='contract_terms_pop my_info_edit_pw_pop'>
        <div className='close' onClick={() => setEditLogo(false)}>
          <img src={i_close} alt='' />
        </div>
        <h3 className='pop_tit'>사업자 프로필 이미지 변경</h3>
        <div className='detail_top_img'>
          {uploadImg !== '' ? (
            <img src={uploadImg} alt='사업자 로고입니다.' />
          ) : (
            <img src={company.f_root} alt='사업자 로고입니다.' />
          )}
        </div>

        <div className='img_upload'>
          <input
            type='file'
            id='upload'
            accept='image/*'
            onChange={handleLogoChg}
          />
          <input type='text' value={selectFile} readOnly />
          <label htmlFor='upload'>찾아보기</label>
        </div>
        <div className='terms_pop_btn btn_box'>
          <div className='cancel'>
            <p onClick={() => setEditLogo(false)}>취소</p>
          </div>
          <div className='ok' onClick={mypage_UpdateLogoChgFnc}>
            <p>확인</p>
          </div>
        </div>
      </div>
    </div>
  );
}
