import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

//menu
import { myInfoMenu } from "../../../asset/js/dummy.js";

//component
import Mheader from "../../../component/common/Mheader";
import ItemInfo from "../../../component/etc/ItemInfo";
import MyCart from "../../../component/etc/MyCart";

//css
import "../../../asset/css/productRegistration.css";

//api
import {
  main_ProductAddApi,
  bidSearchProcess,
  bidSearchFirstCategory,
  bidSearchSecondCategory,
  bidSearchThirdCategory,
  productFindApi,
} from "../../../api/auth";

//redux
import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";
import useDidMountEffect from "../../../component/etc/useDidMountEffect.js";
import Pagination from "../../../component/ui/pagination/Pagination.js";

export default function ProductRegistration() {
  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const [sortPageList, setSortPageList] = useState({
    pageSort: "ALL",
    nowPage: 1,
  });

  const headerKeyword = location.state?.headerKeyword;
  const headerKeywordKind = location.state?.keyKind;

  const [cart, setCart] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  const [cartItem, setCartItem] = useState([]);

  const [itemList, setItemList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [, setProcessList] = useState([]);
  const [firstList, setFirstList] = useState([]);
  const [secondList, setSecondList] = useState([]);
  const [thirdList, setThirdList] = useState([]);

  const [selectedProcess, setSelectedProcess] = useState(null);
  const [selectedFirst, setSelectedFirst] = useState(null);
  const [selectedSecond, setSelectedSecond] = useState(null);
  const [selectedThird, setSelectedThird] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [enteredKeyword, setEnteredKeyword] = useState(1);
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [nowUrl, setNowUrl] = useState("");
  const [p_no, setP_no] = useState({
    p_no: "",
  });

  const _bidSearchProcess = async () => {
    const response = await bidSearchProcess();
    if (response.status === "success") setProcessList(response.data);
  };
  const _bidSearchFirstCategory = async () => {
    const response = await bidSearchFirstCategory(selectedProcess);
    if (response.status === "success") setFirstList(response.data);
  };
  const _bidSearchSecondCategory = async () => {
    const response = await bidSearchSecondCategory(selectedFirst);
    if (response.status === "success") setSecondList(response.data);
  };
  const _bidSearchThirdCategory = async () => {
    const response = await bidSearchThirdCategory(selectedSecond);
    if (response.status === "success") setThirdList(response.data);
  };

  const searchProduct = async () => {
    const searchData = {
      processNo: selectedProcess ? selectedProcess : null,
      lcNo: selectedFirst ? selectedFirst : null,
      mcNo: selectedSecond ? selectedSecond : null,
      scNo: selectedThird ? selectedThird : null,
      keyword: keyword ? keyword : "",
      pageNum: sortPageList.nowPage === 0 ? 1 : sortPageList.nowPage,
      pageCount: 32,
    };

    const response = await productFindApi(searchData);

    if (response.status === "success") {
      setItemList(response.data);
      setTotalCount(response.data.totalCount);
    }
  };

  const headerSearchProduct = async (headerKeyword) => {
    const searchData = {
      processNo: null,
      lcNo: null,
      mcNo: null,
      scNo: null,
      keyword: headerKeyword ? headerKeyword : "",
      pageNum: sortPageList.nowPage,
      pageCount: 32,
    };

    setKeyword(headerKeyword);
    setEnteredKeyword(headerKeyword);

    const response = await productFindApi(searchData);

    if (response.status === "success") {
      setItemList(response.data);
      setTotalCount(response.data.totalCount);
    }
  };

  const _editCartItemAmount = (item) => {
    setCartList(item);
  };

  //cart state
  const [cartList, setCartList] = useState([]);
  const c_no = userData.c_no;

  const onClick_main_ProductAdd = async () => {
    const pList = cartList.map((item) => {
      const pListData = item.p_no;
      return { p_no: pListData };
    });

    const res = await main_ProductAddApi(c_no, pList);
    if (res.status === "success") {
      navigate("/my_page/my_info/product_registration/completed");
    } else {
      swal("주요품목등록이 실패하였습니다.", "", "error");
    }
  };

  useEffect(() => {
    _bidSearchProcess();
  }, []);

  useEffect(() => {
    if (selectedProcess !== null && selectedProcess !== "null") {
      setSelectedFirst(null);
      setSelectedSecond(null);
      setSelectedThird(null);

      setFirstList([]);
      setSecondList([]);
      setThirdList([]);
      _bidSearchFirstCategory();
    }
  }, [selectedProcess]);

  useEffect(() => {
    if (selectedFirst !== null && selectedFirst !== "null") {
      setSelectedSecond(null);
      setSelectedThird(null);

      setSecondList([]);
      setThirdList([]);
      _bidSearchSecondCategory();
    }
  }, [selectedFirst]);

  useEffect(() => {
    if (selectedSecond !== null && selectedSecond !== "null") {
      setSelectedThird(null);

      setThirdList([]);
      _bidSearchThirdCategory();
    }
  }, [selectedSecond]);

  const majorGroupList = [
    { no: "7", process: "공통공사" },
    { no: "8", process: "POSCO 이노빌트" },
    { no: "9", process: "안전용품" },
    { no: "10", process: "폐기물" },
  ];

  useEffect(() => {
    if (headerKeywordKind) {
      const keyword = majorGroupList.filter(
        (el) => el.no === headerKeywordKind
      );
      setKeyword("");
      setEnteredKeyword("");
      setSelectedKeyword(keyword[0].process);
      setSelectedProcess(headerKeywordKind);
    } else {
      headerSearchProduct(headerKeyword);
    }
  }, [headerKeyword, headerKeywordKind]);

  useDidMountEffect(() => {
    searchProduct();
  }, [sortPageList.nowPage]);

  const wonComma = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handlePageChange = (page) => {
    setSortPageList((prev) => ({ ...prev, nowPage: page }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="prd_register_wrap">
      <Mheader title="주요 공급품목 등록" />
      <div className="sub">
        <div className="sub_title">
          {myInfoMenu.map((data, index) => (
            <Link
              to={data.to}
              onClick={() => setNowUrl(data.to)}
              className={nowUrl?.includes(data.to) ? "sub_on" : "sub_off"}
            >
              {data.title}
            </Link>
          ))}
        </div>
      </div>
      <div className="prd_register_container wd">
        <div className="top_pagenation" style={{ paddingTop: "70px" }}>
          <h2>주요 공급품목 등록</h2>
          <div className="top_pagenation_num">
            <p className="active">1</p>
            <p>2</p>
          </div>
        </div>
        <div className="prd_register_top">
          <div className="prd_choice_btn">
            <p onClick={() => setCart(!cart)}>
              선택 공급품목 (<span>{cartList.length}</span>)
            </p>
          </div>
          <div className="prd_add_btn">
            <p onClick={onClick_main_ProductAdd}>등록하기</p>
          </div>
        </div>
        <div className="prd_register_mid">
          <div className="prd_register_mid_top">
            <div className="input_box">
              <p>대표공종</p>
              <select
                onChange={(e) => {
                  setSelectedProcess(e.target.value);
                }}
              >
                {/* <option value={'null'} selected>
                  공종
                </option> */}
                {majorGroupList.map((el) => (
                  <option value={el.no}>{el.process}</option>
                ))}
              </select>
            </div>
            <div className="input_box">
              <p>대분류</p>
              <select
                onChange={(e) => {
                  setSelectedFirst(e.target.value);
                  setSelectedSecond(null);
                  setSelectedThird(null);
                }}
              >
                <option value={"null"}>대분류</option>
                {firstList.map((el, idx) => (
                  <option value={el.lc_no} key={idx}>
                    {el.lc_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_box">
              <p>중분류</p>
              <select
                onChange={(e) => {
                  setSelectedSecond(e.target.value);
                  setSelectedThird(null);
                }}
              >
                <option value={"null"}>중분류</option>
                {secondList.map((el, idx) => (
                  <option value={el.mc_no} key={idx}>
                    {el.mc_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_box">
              <p>소분류</p>
              <select
                onChange={(e) => {
                  setSelectedThird(e.target.value);
                }}
              >
                <option value={"null"}>소분류</option>
                {thirdList.map((el, idx) => (
                  <option value={el.sc_no} key={idx}>
                    {el.sc_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="prd_register_mid_bot">
            <div className="input_box">
              <p>품목명</p>
              <input
                type="text"
                value={keyword}
                placeholder="품목명을 입력하세요."
                spellCheck="false"
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setKeyword(e.target.value);
                    setEnteredKeyword(e.target.value);
                    searchProduct();
                    setSortPageList({ ...sortPageList, nowPage: 1 });
                  }
                }}
              />
            </div>
            <div
              className="btn_search"
              onClick={() => {
                setEnteredKeyword(keyword);
                searchProduct();
                setSortPageList({ ...sortPageList, nowPage: 1 });
              }}
            >
              <p className="pc_on">검색</p>
              <p className="mo_on">검색하기</p>
            </div>
          </div>
        </div>
        <div className="prd_register_finded">
          <div>
            <h3 className="s_tit">
              <p>
                검색결과{" "}
                <span className="prd_register_word">{enteredKeyword}</span>{" "}
                <span className="prd_register_num">{wonComma(totalCount)}</span>
                건
              </p>
            </h3>
          </div>
          <ul className="prd_register_result">
            {itemList?.productList?.map((data, i) => (
              <li
                onClick={() => {
                  setOpenDetail(true);
                  setP_no({ ...p_no, p_no: data.product.no });
                }}
                key={i}
              >
                <div className="top_wrap">
                  <div className="prd_register_result_top">
                    <img
                      className="prd_register_product_img"
                      src={data.file}
                      alt=""
                    />
                  </div>
                  <div className="prd_register_result_mid">
                    <div className="prd_register_product_info">
                      <p className="name0">{data.product.brand}</p>
                      <p className="name1">{data.product.name}</p>
                      <p className="name2">{data.product.standard}</p>
                    </div>
                    <p className="prd_register_product_option">
                      {data.product.etc}
                    </p>
                    <div className="prd_register_product_price"></div>
                  </div>
                </div>
                <div className="prd_register_result_bot">상세보기　+</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="product_registration_pagination_container">
          <Pagination
            current={sortPageList.nowPage}
            total={totalCount}
            pageSize={32}
            onChange={handlePageChange}
          />
        </div>

        {cart ? (
          <MyCart
            setCart={setCart}
            cartItem={cartItem}
            cartList={cartList}
            setCartList={setCartList}
          />
        ) : (
          ""
        )}
      </div>

      {cart && <div className="cart_dim" onClick={() => setCart(false)}></div>}
      {openDetail && (
        <ItemInfo
          openDetail={openDetail}
          setOpenDetail={setOpenDetail}
          p_no={p_no}
          cartList={cartList}
          setCartList={_editCartItemAmount}
        />
      )}
    </div>
  );
}
