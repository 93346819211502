import React, { useEffect, useState } from "react";

//library
import { useLocation, useNavigate } from "react-router";

//component
import Mheader from "../../component/common/Mheader";
import DaumPostApi from "../../component/etc/DaumPostApi";

// img
import i_cancel from "../../asset/images/address-cancel.png";

//css
import "../../asset/css/newinfo2.css";
import "../../asset/css/newinfo3.css";

//api
import {
  existingConSignUpApi,
  imgUpApi,
  joinApiStep2,
  getConInfoApi,
  checkCompanyCode,
} from "../../api/auth";

//constant
import { areaGuList } from "../../constant/Constant";

import Loading from "../../component/etc/Loading";

//sweet alert
import swal from "sweetalert";

export default function NewInfo2() {
  const navigate = useNavigate();
  const location = useLocation();

  //Location before props
  const user_no = location.state.u_no;

  //state
  const [tabSwitch, setTabSwitch] = useState(false);
  const [mapSwitch, setMapSwitch] = useState(false);
  const [addrPopup, setAddrPopup] = useState(false);
  const [bankName, setBankName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [generating, setGenerating] = useState(false);

  //신규공급사 등록
  const [conInfo, setConInfo] = useState({
    u_no: user_no,
    c_name: "",
    c_bsname: "",
    c_div: "C",
    c_num: "",
    c_ceo: "",
    c_sec: "",
    c_bc: "",
    c_zip: "",
    c_addr1: "",
    c_addr2: "",
    c_lat: "",
    c_lng: "",
    c_f_logo: "",
    c_f_license: "",
    // c_f_stamp: '',
    c_phone: "",
    c_account: "",
    c_f_bankbook: "",
    c_f_credit: "",
    c_f_workspace: "",
    c_f_intro: "",
    c_type: "",
    addrList: "",
  });

  //기존 공급사 등록
  const [existingConInfo, setExistingConInfo] = useState({
    u_no: user_no,
    c_no: "",
    c_addr1: "",
    c_zip: "",
    c_lat: "",
    c_lng: "",
    code: "",
  });
  const [companyList, setCompanyList] = useState([]);

  //공급사 회원가입 클릭
  const formData = {
    u_no: conInfo.u_no,
    c_name: conInfo.c_bsname, // c_name -> c_bsname으로 통일. view는 보여주지 않고 필드 저장은 함.
    c_bsname: conInfo.c_bsname,
    c_div: conInfo.c_div,
    c_num: conInfo.c_num,
    c_ceo: conInfo.c_ceo,
    c_sec: conInfo.c_sec,
    c_bc: conInfo.c_bc,
    c_zip: conInfo.c_zip,
    c_addr1: conInfo.c_addr1,
    c_addr2: conInfo.c_addr2,
    c_lat: conInfo.c_lat,
    c_lng: conInfo.c_lng,
    c_f_logo: conInfo.c_f_logo,
    c_f_license: conInfo.c_f_license,
    // c_f_stamp: conInfo.c_f_stamp,
    c_account: conInfo.c_account,
    c_f_bankbook: conInfo.c_f_bankbook,
    c_f_workspace: conInfo.c_f_workspace, //사업장 및 대지사진
    c_f_intro: conInfo.c_f_intro, //회사소개서
    c_f_credit: conInfo.c_f_credit,
    c_type: conInfo.c_type,
    c_phone: conInfo.c_phone,
    addrList: conInfo.addrList,
  };

  const bankList = [
    "NH농협",
    "KB국민은행",
    "카카오뱅크",
    "신한은행",
    "우리은행",
    "IBK기업은행",
    "하나은행",
    "새마을금고",
    "대구은행",
    "부산은행",
    "케이뱅크",
    "우체국",
    "신협",
    "SC제일은행",
    "경남은행",
    "수협은행",
    "광주은행",
    "전북은행",
    "토스뱅크",
    "상호저축은행",
    "중국공상은행",
    "JP모간체이스은행",
    "BNP파리바",
    "씨티은행",
    "제주은행",
    "KDB산업은행",
    "SBI저축은행",
    "산림조합중앙회",
    "BOA",
    "HSBC은행",
    "중국은행",
    "도이치은행",
    "중국건설은행",
  ];

  const _getConInfoApi = async () => {
    const response = await getConInfoApi(
      existingConInfo.c_lat,
      existingConInfo.c_lng
    );
    if (response.status === "success") {
      setCompanyList(response.data.ecList);
    } else {
      setCompanyList([]);
    }
  };
  //공급사 신규회원가입
  const onClickCtAccount = async () => {
    // if (formData.c_name === "") {
    //   swal("업체명을 입력해 주세요", "", "warning");
    //   return;
    // }
    if (formData.c_bsname === "") {
      swal("사업자명을 입력해 주세요", "", "warning");
      return;
    } else if (formData.c_div === "") {
      swal("사업자구분을 선택해주세요.", "", "warning");
      return;
    } else if (formData.c_num === "") {
      swal("사업자 등록번호를 입력해주세요", "", "warning");
      return;
    } else if (formData.c_ceo === "") {
      swal("대표자명을 입력해주세요", "", "warning");
      return;
    } else if (formData.c_phone === "") {
      swal("대표번호를 입력해 주세요.", "", "warning");
      return;
    } else if (formData.c_sec === "") {
      swal("업종을 입력해주세요", "", "warning");
      return;
    } else if (formData.c_bc === "") {
      swal("업태를 입력해주세요", "", "warning");
      return;
    } else if (formData.c_zip === "" || formData.c_addr1 === "") {
      swal("주소를 입력해주세요", "", "warning");
      return;
    } else if (formData.c_f_license === "") {
      swal("사업자 등록증 이미지를 첨부해주세요", "", "warning");
      return;
    } else if (formData.c_f_intro === "") {
      swal("회사소개서 이미지를 첨부해주세요", "", "warning");
      return;
    } else if (
      formData.c_account === "" ||
      bankName === undefined ||
      accountNumber === undefined
    ) {
      swal("정산받을 계좌 정보를 입력해주세요.", "", "warning");
      return;
    } else if (formData.c_f_bankbook === "") {
      swal("정산받을 통장 이미지를 첨부해주세요.", "", "warning");
      return;
    } else if (formData.c_type === "") {
      swal("공급업체 분류를 선택해주세요", "", "warning");
      return;
    } else if (formData.addrList === "") {
      swal("납품가능지역을 선택해 주세요.", "", "warning");
      return;
    }

    setGenerating(true);

    const response = await joinApiStep2(formData);

    if (response.status === "success") {
      navigate("/join/newinfo3", { replace: true });
    }
    setGenerating(false);
    /**
     * con_f_logo
     * c_f_credit
     */
  };

  //로고 이미지 업로드
  const [logoImg, setLogoImage] = useState(null);
  const [selectFile, setSelectFile] = useState("");
  const handleLogoChg = async (e) => {
    setSelectFile(e.target.files[0].name);
    setLogoImage(e.target.files[0]);
  };
  const handleLogoIgForm = async () => {
    let form = new FormData();
    form.append("file", logoImg);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_logo: response.data.f_no });
    } else {
      swal(
        "파일이 업로드되지 않았습니다. 잠시 후 다시 시도해주세요.",
        "",
        "warning"
      );
    }
  };
  useEffect(() => {
    if (logoImg) {
      handleLogoIgForm();
    }
  }, [logoImg]);

  //사업자등록증 첨부
  const [selectFile2, setSelectFile2] = useState("");
  const [licenseImg, setLicenseImag] = useState(null);
  const handleLicenseChg = (e) => {
    setSelectFile2(e.target.files[0].name);
    setLicenseImag(e.target.files[0]);
  };

  const handleLicenseForm = async () => {
    let form = new FormData();
    form.append("file", licenseImg);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_license: response.data.f_no });
    } else {
      swal(
        "파일이 업로드되지 않았습니다. 잠시 후 다시 시도해주세요.",
        "",
        "warning"
      );
    }
  };

  useEffect(() => {
    if (licenseImg) {
      handleLicenseForm();
    }
  }, [licenseImg]);

  //사업자 대지 Or 사업장 사진 첨부
  const [selectFile3, setSelectFile3] = useState("");
  const [workspaceImg, setWorkspaceImg] = useState(null);
  const handleWorkspaceChg = (e) => {
    setSelectFile3(e.target.files[0].name);
    setWorkspaceImg(e.target.files[0]);
  };

  const handleWorkspaceForm = async () => {
    let form = new FormData();
    form.append("file", workspaceImg);
    const response = await imgUpApi(form);

    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_workspace: response.data.f_no });
    } else {
      swal(
        "파일이 업로드되지 않았습니다. 잠시 후 다시 시도해주세요.",
        "",
        "warning"
      );
    }
  };

  useEffect(() => {
    if (workspaceImg) {
      handleWorkspaceForm();
    }
  }, [workspaceImg]);

  //통장사본파일번호 첨부
  const [selectFile4, setSelectFile4] = useState("");
  const [bankbookImg, setbankbookImg] = useState(null);
  const handleBankbookChg = (e) => {
    setSelectFile4(e.target.files[0].name);
    setbankbookImg(e.target.files[0]);
  };
  const handleBankbookForm = async () => {
    let form = new FormData();
    form.append("file", bankbookImg);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_bankbook: response.data.f_no });
    } else {
      swal(
        "파일이 업로드되지 않았습니다. 잠시 후 다시 시도해주세요.",
        "",
        "warning"
      );
    }
  };

  useEffect(() => {
    if (bankbookImg) {
      handleBankbookForm();
    }
  }, [bankbookImg]);

  //신용평가서 첨부
  const [selectFile5, setSelectFile5] = useState("");
  const [creditImg, setCreditImg] = useState(null);
  const handlecreditChg = (e) => {
    setSelectFile5(e.target.files[0].name);
    setCreditImg(e.target.files[0]);
  };
  const handleCreditImgForm = async () => {
    let form = new FormData();
    form.append("file", creditImg);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_credit: response.data.f_no });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };
  useEffect(() => {
    if (creditImg) {
      handleCreditImgForm();
    }
  }, [creditImg]);

  //회사소개서 첨부
  const [selectFile6, setSelectFile6] = useState("");
  const [introFile, setIntroFile] = useState(null);
  const handleIntroChg = (e) => {
    setSelectFile6(e.target.files[0].name);
    setIntroFile(e.target.files[0]);
  };
  const handleIntroFileForm = async () => {
    let form = new FormData();
    form.append("file", introFile);
    const response = await imgUpApi(form);
    if (response.status === "success") {
      setConInfo({ ...conInfo, c_f_intro: response.data.f_no });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };
  useEffect(() => {
    if (introFile) {
      handleIntroFileForm();
    }
  }, [introFile]);

  //납품가능한 지역

  const [selectedAreaSi, setSelectedAreaSi] = useState("");
  const handleSelectSi = (e) => {
    setSelectedAreaSi(e.target.value);
  };
  //구
  const [selectedAreaGu, setSelectedAreaGu] = useState("");
  const [stateArea, setStateArea] = useState([]);
  const [cityArea, setCityArea] = useState([]);
  const [cityOpen, setCityOpen] = useState(true);
  const handleSelectGu = (e) => {
    setSelectedAreaGu(e.target.value);
  };
  //구 주소받기
  const cityMap = () => {
    areaGuList.map((item) => {
      const stateKeys = Object.keys(item);
      setStateArea(stateKeys);
      if (selectedAreaSi) {
        const cityKeys = Object.values(item[selectedAreaSi]);
        cityKeys.map((itm) => {
          setCityArea(itm);
        });
        setCityOpen(false);
        return cityKeys;
      } else {
        setCityOpen(true);
      }
    });
  };
  useEffect(() => {
    cityMap();
  }, [selectedAreaSi]);

  //납품 가능한 지역 어레이 추가
  const [showArea, setShowArea] = useState([]);
  const [sendArea, setSendArea] = useState([]);
  const onClinkAddArea = () => {
    const areaData = selectedAreaSi + " " + selectedAreaGu;
    setShowArea([...showArea, { area: areaData }]);
    setSendArea([...sendArea, areaData]);
    //중복 validation

    showArea.map((itm) => {
      if (itm.area !== areaData) {
        setShowArea([...showArea, { area: areaData }]);
        setSendArea([...sendArea, areaData]);
      }
      if (itm.area === areaData) {
        swal("납품 가능한 지역이 중복 되었습니다.", "", "warning");
        setShowArea(showArea);
        setSendArea(sendArea);
      }
    });
  };

  // 납품가능지역 변경
  const chgAreaList = () => {
    const str = sendArea.join();
    setConInfo({ ...conInfo, addrList: str });
  };
  useEffect(() => {
    chgAreaList();
  }, [sendArea]);

  // 초대번호 에러
  const [error, setError] = useState(false);

  //기존 공급사 등록
  const onClickexistingConSignUp = async () => {
    if (existingConInfo.code === "") {
      swal("초대번호를 입력해주세요.", "", "warning");
      return;
    }

    const response = await existingConSignUpApi(
      existingConInfo.u_no,
      existingConInfo.code
    );
    if (response.status === "success")
      navigate("/join/newinfo3", { replace: true });
    if (response.status === "fail")
      return swal("존재하지 않는 초대번호입니다.", "", "warning");
  };

  // const _checkCompanyCode = async () => {
  //   const data = {
  //     c_no: existingConInfo.c_no,
  //     code: existingConInfo.code,
  //   };
  //   const response = await checkCompanyCode(data);

  //   if (response.status === "success") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  useEffect(() => {
    if (
      existingConInfo &&
      existingConInfo.c_lat !== "" &&
      existingConInfo.c_lng !== ""
    ) {
      _getConInfoApi();
    }
  }, [existingConInfo]);

  return (
    <>
      <div className="newinfo_wrap">
        <Mheader title="회원가입" />
        <div className="newinfo_container">
          <div className="join_top">
            <h2>회원정보 입력</h2>
            <div className="join_pagenation">
              <p>1</p>
              <p>2</p>
              <p className="active">3</p>
              <p>4</p>
            </div>
          </div>
          <div className="construction_info">
            <h3>회원정보 입력</h3>
            <div className="construction_tab tab_title">
              <p
                className={!tabSwitch ? "active_tab" : "unactivate_tab"}
                onClick={() => {
                  setTabSwitch(false);
                }}
              >
                신규 공급사
              </p>
              <p
                className={tabSwitch ? "active_tab" : "unactivate_tab"}
                onClick={() => {
                  setTabSwitch(true);
                }}
              >
                기존 공급사
              </p>
            </div>
            {!tabSwitch && (
              <p className="tab_p" style={{ marginBottom: "0px" }}>
                * 신규 공급사의 경우 승인절차가 진행된 후 활동이 가능합니다.
              </p>
            )}
            <p className="tab_p">
              * 가입 중 문의사항이 있으시다면 아래 채널톡을 통해 공새로에
              문의해주세요!
            </p>
            <div className="construction_content">
              <div className={!tabSwitch ? "content1_on" : "content1_off"}>
                <dl className="construction_tab2">
                  {/* <dt>공급사명</dt>
                  <dd>
                    <input
                      type="text"
                      placeholder="공급사명을 입력해주세요."
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_name: e.target.value });
                      }}
                    />
                  </dd> */}
                  <dt>사업자명</dt>
                  <dd>
                    <input
                      type="text"
                      placeholder="사업자명을 입력해주세요."
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_bsname: e.target.value });
                      }}
                    />
                  </dd>
                  <dt>사업자구분</dt>
                  <dd>
                    <select
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_div: e.target.value });
                      }}
                    >
                      <option value="" selected disabled hidden>
                        사업자 구분
                      </option>
                      <option value="P">개인</option>
                      <option value="C">법인</option>
                    </select>
                  </dd>
                  <dt>사업자등록번호</dt>
                  <dd>
                    <input
                      type="text"
                      placeholder="사업자등록번호를 입력해주세요."
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_num: e.target.value });
                      }}
                    />
                  </dd>
                  <dt>대표자명</dt>
                  <dd>
                    <input
                      type="text"
                      placeholder="대표자명을 입력해주세요."
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_ceo: e.target.value });
                      }}
                    />
                  </dd>
                  <dt>대표번호</dt>
                  <dd>
                    <input
                      type="text"
                      maxLength={11}
                      placeholder="연락 가능한 대표번호를 입력해주세요.(-을 제외하고 입력)"
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_phone: e.target.value });
                      }}
                    />
                  </dd>
                  <dt>업종</dt>
                  <dd>
                    <input
                      type="text"
                      placeholder="업종을 입력해주세요"
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_sec: e.target.value });
                      }}
                    />
                  </dd>
                  <dt>업태</dt>
                  <dd>
                    <input
                      type="text"
                      placeholder="업태를 입력해주세요"
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_bc: e.target.value });
                      }}
                    />
                  </dd>
                  <dt>주소</dt>
                  <dd className="address_search">
                    <input
                      type="text"
                      readOnly
                      id="my_address"
                      value={conInfo.c_addr1}
                    />

                    <label
                      htmlFor="my_address"
                      onClick={() => {
                        setAddrPopup(!addrPopup);
                      }}
                    >
                      검색
                    </label>

                    <input
                      type="text"
                      placeholder="상세주소를 입력해주세요"
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_addr2: e.target.value });
                      }}
                    />

                    {addrPopup && (
                      <DaumPostApi
                        conInfo={conInfo}
                        setConInfo={setConInfo}
                        setAddrPopup={setAddrPopup}
                        addrPopup={addrPopup}
                      />
                    )}
                  </dd>
                  <dt>
                    로고이미지
                    <span>
                      {" "}
                      *가입 후 마이페이지에서 추가할 수 있습니다. (선택사항)
                    </span>
                  </dt>
                  <dd className="img_upload">
                    <input
                      type="file"
                      id="logo_upload"
                      accept="image/*"
                      onChange={handleLogoChg}
                    />
                    <p>{selectFile}</p>
                    <label htmlFor="logo_upload">찾아보기</label>
                  </dd>
                  {/* <dt>사업자 도장 이미지<span> *가입 후 마이페이지에서 추가할 수 있습니다. (선택사항)</span></dt>
                  <dd className='img_upload upload3'>
                    <input
                      type='file'
                      id='logo_upload3'
                      accept='image/*'
                      onChange={handleStampChg}
                    />
                    <p>{selectFile3}</p>
                    <label htmlFor='logo_upload3'>찾아보기</label>
                  </dd> */}
                  <dt>
                    신용평가서 첨부
                    <span>
                      {" "}
                      *가입 후 마이페이지에서 추가할 수 있습니다. (선택사항)
                    </span>
                  </dt>
                  <dd className="img_upload upload5">
                    <input
                      type="file"
                      id="logo_upload5"
                      onChange={handlecreditChg}
                    />
                    <p>{selectFile5}</p>
                    <label htmlFor="logo_upload5">찾아보기</label>
                  </dd>
                  {/* 사업장 및 대지 추가 */}
                  <dt>
                    사업장 및 대지사진
                    <span>
                      {" "}
                      *가입 후 마이페이지에서 추가할 수 있습니다. (선택사항)
                    </span>
                  </dt>
                  <dd className="img_upload upload3">
                    <input
                      type="file"
                      id="logo_upload3"
                      accept="image/*"
                      onChange={handleWorkspaceChg}
                    />
                    <p>{selectFile3}</p>
                    <label htmlFor="logo_upload3">찾아보기</label>
                  </dd>

                  <dt>사업자등록증</dt>
                  <dd className="img_upload upload2">
                    <input
                      type="file"
                      id="logo_upload2"
                      accept="image/*"
                      onChange={handleLicenseChg}
                    />
                    <p>{selectFile2}</p>
                    <label htmlFor="logo_upload2">찾아보기</label>
                  </dd>

                  {/* 회사소개서 추가 */}
                  <dt>회사소개서</dt>
                  <dd className="img_upload upload6">
                    <input
                      type="file"
                      id="logo_upload6"
                      // accept='image/*'
                      onChange={handleIntroChg}
                    />
                    <p>{selectFile6}</p>
                    <label htmlFor="logo_upload6">찾아보기</label>
                  </dd>

                  <dt>정산받을 통장 사본 이미지</dt>
                  <dd className="img_upload upload4">
                    <input
                      type="file"
                      id="logo_upload4"
                      accept="image/*"
                      onChange={handleBankbookChg}
                    />
                    <p>{selectFile4}</p>
                    <label htmlFor="logo_upload4">찾아보기</label>
                  </dd>
                  <dt>정산받을 통장 계좌</dt>
                  <dd>
                    <div className="newinfo_bank">
                      <select
                        onChange={(e) => {
                          setConInfo({
                            ...conInfo,
                            c_account: e.target.value + ":" + accountNumber,
                          });
                          setBankName(e.target.value);
                        }}
                      >
                        <option value="" selected disabled hidden>
                          은행선택
                        </option>
                        {bankList.map((data, i) => {
                          return <option value={data}>{data}</option>;
                        })}
                      </select>
                      <input
                        type="text"
                        placeholder="계좌번호를 입력해주세요( - 제외)"
                        onChange={(e) => {
                          setConInfo({
                            ...conInfo,
                            c_account: bankName + ":" + e.target.value,
                          });
                          setAccountNumber(e.target.value);
                        }}
                      />
                    </div>
                  </dd>
                  <dt>납품 가능한 지역</dt>
                  <dd>
                    <div className="newinfo_address flex">
                      <select
                        placeholder="시"
                        onChange={handleSelectSi}
                        value={selectedAreaSi}
                      >
                        {stateArea.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </select>

                      {cityOpen ? (
                        <select placeholder="구">
                          <option>구</option>
                        </select>
                      ) : (
                        <select
                          placeholder="시"
                          onChange={handleSelectGu}
                          value={selectedAreaGu}
                        >
                          {cityArea.map((item, index) => (
                            <option value={item} key={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                      )}

                      <div className="newinfo_address_add_btn">
                        <p onClick={onClinkAddArea}>추가</p>
                      </div>
                    </div>
                    <div className="newinfo_area">
                      {showArea.map((item, index) => (
                        <p key={index} value={item}>
                          {item.area}
                          <span>
                            <img
                              onClick={() => {
                                const delData = showArea.filter(
                                  (data) => data.area !== item.area
                                );
                                const delSendData = sendArea.filter(
                                  (data) => data !== item.area
                                );
                                setShowArea(delData);
                                setSendArea(delSendData);
                              }}
                              src={i_cancel}
                              alt=""
                            />
                          </span>
                        </p>
                      ))}
                    </div>
                  </dd>
                  <dt>공급업체 분류</dt>
                  <dd>
                    <select
                      onChange={(e) => {
                        setConInfo({ ...conInfo, c_type: e.target.value });
                      }}
                    >
                      {/* TODO 
                        공급업체 분류 추가
                      */}
                      <option value="" selected disabled hidden>
                        공급업체 분류를 선택해주세요.
                      </option>
                      <option value="건설">건설</option>
                      <option value="토목">토목</option>
                      <option value="기타">기타</option>
                    </select>
                  </dd>
                </dl>
              </div>
              <div className={tabSwitch ? "content2_on" : "content2_off"}>
                {/* <button
                  style={{ marginTop: "20px" }}
                  className="adress_chi"
                  onClick={() => {
                    setMapSwitch(true);
                  }}
                >
                  주소 검색하기
                </button>
                {mapSwitch && (
                  <DaumPostApi
                    conInfo={existingConInfo}
                    setConInfo={setExistingConInfo}
                    setAddrPopup={setMapSwitch}
                    addrPopup={mapSwitch}
                  />
                )}
                <div className="search_adress">
                  {companyList.length === 0 ? (
                    <div className="adress_result">
                      <p style={{ lineHeight: 1.5 }}>
                        검색 결과가 없습니다.
                        <br />
                        주소 검색을 눌러 주소를 검색해주세요.
                      </p>
                    </div>
                  ) : (
                    <>
                      {companyList.map((item, index) => {
                        return (
                          <div className="adress_result" key={index}>
                            <input
                              type="radio"
                              id={`company_${item.c_no}`}
                              name="company"
                              value={item.c_no}
                              onClick={() => {
                                setExistingConInfo({
                                  ...existingConInfo,
                                  c_no: item.c_no,
                                });
                              }}
                            />
                            <label htmlFor={`company_${item.c_no}`}> */}
                {/* <span style={{ lineHeight: 1.4 }}>
                                {item.c_bsname} /{' '}
                                {item.c_div === 'C'
                                  ? '법인사업자'
                                  : '개인사업자'}{' '}
                                / {item.c_num} / <br />
                                {item.c_addr1}
                              </span> */}
                {/* <div className="company_result_list">
                                <span>{item.c_name}</span>
                                <span>{"(" + item.c_bsname + ")"}</span>
                                <span>
                                  {item.c_div === "C" ? "법인-" : "개인-"}
                                </span>
                                <span>{item.c_num}</span>
                                <br />
                                <span>{item.c_addr1}</span>
                              </div>
                            </label>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div> */}
                <dl className={error ? "invite_code error" : "invite_code"}>
                  <dt>초대번호</dt>
                  <dd className="code_input">
                    <input
                      type="text"
                      placeholder="초대번호를 입력하세요."
                      onChange={(e) => {
                        setExistingConInfo({
                          ...existingConInfo,
                          code: e.target.value,
                        });
                      }}
                    />
                  </dd>
                  {error && (
                    <dd className="code_alert">
                      초대번호가 일치하지 않습니다.
                    </dd>
                  )}
                </dl>
              </div>
            </div>
          </div>
          <div className="newinfo_btn btn_box">
            <div className="prev">
              <p
                onClick={() => {
                  navigate("/");
                }}
              >
                이전
              </p>
            </div>
            <div className="next">
              {tabSwitch ? (
                <p onClick={onClickexistingConSignUp}>공급사 회원가입</p>
              ) : (
                <p onClick={onClickCtAccount}>공급사 회원가입</p>
              )}
            </div>
          </div>
        </div>
        {generating && <Loading msg={"로딩 중입니다."} />}
      </div>
    </>
  );
}
