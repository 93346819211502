import React, { useState, useEffect } from "react";
//component
import { useLocation, useNavigate } from "react-router";
import { impApi } from "../../api/auth";
//css
import "../../asset/css/join.css";
import Mheader from "../../component/common/Mheader";
import JoinDescPop from "../../component/common/Pop/JoinDescPop";
//img
import rightArrow from "../../asset/images/arrow-right-icon.png";

import { IMP_CODE } from "../../constant/Constant";

//redux
import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";

export default function Join() {
  const navigate = useNavigate();
  // const location = useLocation();

  const signToken = useSelector((state) => state.token);

  //social check
  // const { socialType, socialValue } = location.state;

  const [joinDescPop, setJoinDescPop] = useState(false);

  //checkbox
  const [checkStatus, setCheckStatus] = useState({
    service: false,
    privacy: false,
    privacyAgree: false,
    noteen: false,
  });

  //certification
  const [certUid, setCertUid] = useState(false);

  //constant
  const authData = {
    merchant_uid: `gong_auth_${new Date().getTime()}`,
  };

  const entireAgreeFnc = () => {
    if (checkStatus.service && checkStatus.privacy) {
      setCheckStatus({
        service: false,
        privacy: false,
        privacyAgree: false,
        noteen: false,
      });
    } else {
      setCheckStatus({
        service: true,
        privacy: true,
        privacyAgree: true,
        noteen: true,
      });
    }
  };

  const onClickCertification = () => {
    const { IMP } = window;
    IMP.init(IMP_CODE);
    IMP.certification(authData, onAuthCertificate);
  };

  const onAuthCertificate = (response) => {
    const { success, merchant_uid, error_msg, imp_uid } = response;
    if (success) {
      if (merchant_uid !== authData.merchant_uid) {
        swal(`데이터가 변조되었습니다. 다시 시도해주세요.`, "", "error");
        return;
      }
      setCertUid(imp_uid);
    } else {
      swal(`${error_msg}`);
      return;
    }
  };

  const nextStep = async () => {
    if (checkStatus.service && checkStatus.privacy && certUid) {
      const response = await impApi(certUid);
      if (response.status === "success") {
        navigate("/join/newinfo", {
          state: {
            authCode: certUid,
            u_no: response.data.u_no,
            u_name: response.data.u_name,
            // socialType: false,
            // socialValue: false,
          },
        });
      } else if (response.message === "samePhone") {
        swal("이미 사용중인 휴대폰 번호입니다.", "", "warning");
        return;
      }
    } else {
      swal("약관 동의 및 본인인증을 해주세요.", "", "warning");
      return;
    }
  };

  useEffect(() => {
    if (signToken !== false) {
      navigate("/");
    }
  }, []);

  return (
    <div className="join_wrap">
      <Mheader title="회원가입" />
      <div className="join_container">
        <div className="join_top">
          <h2>회원가입</h2>
          <div className="join_pagenation">
            <p className="active">1</p>
            <p>2</p>
            <p>3</p>
            <p>4</p>
          </div>
        </div>

        <div className="join_desc_wrap">
          <div
            className="join_manual_btn"
            onClick={() =>
              window.open("https://www.youtube.com/watch?v=435MQaEXclA")
            }
          >
            <span>공급사 매뉴얼 바로가기</span>
          </div>

          <div
            className="join_desc_btn"
            onClick={() => {
              setJoinDescPop(true);
            }}
          >
            <span>가입절차 알아보기</span>
            <img src={rightArrow} alt="" />
          </div>
        </div>

        <div className="agree_wrap">
          <h3>약관동의</h3>
          <div className="agree_container">
            <div className="agree_entire">
              <input
                type="checkbox"
                id="entire_a"
                name="전체동의"
                onChange={entireAgreeFnc}
                checked={
                  checkStatus.service &&
                  checkStatus.privacy &&
                  checkStatus.privacyAgree &&
                  checkStatus.noteen
                }
              />
              <label
                className={
                  checkStatus.service === true &&
                  checkStatus.privacy === true &&
                  checkStatus.privacyAgree === true &&
                  checkStatus.noteen === true
                    ? "check_on"
                    : "check_off"
                }
                htmlFor="entire_a"
              >
                <span>전체약관동의</span>
              </label>
            </div>
            <div className="agree_section">
              <div className="section">
                <div className="section_left">
                  <input
                    type="checkbox"
                    id="service"
                    name="service"
                    onChange={() => {
                      setCheckStatus({
                        ...checkStatus,
                        service: !checkStatus.service,
                      });
                    }}
                    checked={checkStatus.service}
                  />
                  <label
                    className={
                      checkStatus.service ? "check_sub_on" : "check_sub_off"
                    }
                    htmlFor="service"
                  >
                    <span>&#91;필수&#93; 이용약관동의</span>
                  </label>
                </div>
                <a href="/terms" target={"_blank"}>
                  <p>자세히보기&nbsp;&nbsp;&nbsp;&#62;</p>
                </a>
              </div>
              <div className="section">
                <div className="section_left">
                  <input
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    onChange={() => {
                      setCheckStatus({
                        ...checkStatus,
                        privacy: !checkStatus.privacy,
                      });
                    }}
                    checked={checkStatus.privacy}
                  />
                  <label
                    className={
                      checkStatus.privacy ? "check_sub_on" : "check_sub_off"
                    }
                    htmlFor="privacy"
                  >
                    <span>&#91;필수&#93; 개인정보처리방침 약관동의</span>
                  </label>
                </div>
                <a href="/privacy" target={"_blank"}>
                  <p>자세히보기&nbsp;&nbsp;&nbsp;&#62;</p>
                </a>
              </div>

              <div className="section">
                <div className="section_left">
                  <input
                    type="checkbox"
                    id="privacyAgree"
                    name="privacyAgree"
                    onChange={() => {
                      setCheckStatus({
                        ...checkStatus,
                        privacyAgree: !checkStatus.privacyAgree,
                      });
                    }}
                    checked={checkStatus.privacyAgree}
                  />
                  <label
                    className={
                      checkStatus.privacyAgree
                        ? "check_sub_on"
                        : "check_sub_off"
                    }
                    htmlFor="privacyAgree"
                  >
                    <span>&#91;필수&#93; 개인정보수집 이용동의</span>
                  </label>
                </div>
                <a href="/privacyAgree" target={"_blank"}>
                  <p>자세히보기&nbsp;&nbsp;&nbsp;&#62;</p>
                </a>
              </div>

              <div className="section">
                <div className="section_left">
                  <input
                    type="checkbox"
                    id="noteen"
                    name="noteen"
                    onChange={() => {
                      setCheckStatus({
                        ...checkStatus,
                        noteen: !checkStatus.noteen,
                      });
                    }}
                    checked={checkStatus.noteen}
                  />
                  <label
                    className={
                      checkStatus.noteen ? "check_sub_on" : "check_sub_off"
                    }
                    htmlFor="noteen"
                  >
                    <span>&#91;필수&#93; 만 14세 이상입니다.</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="certification_wrap">
          <h3>휴대폰 본인인증</h3>
          <button
            onClick={certUid === false && onClickCertification}
            style={certUid !== false ? { background: "#ececec" } : {}}
          >
            {certUid === false ? "본인인증" : "본인인증 완료"}
          </button>
          <p>* 휴대폰 본인인증 완료 후 다음페이지로 이동해주세요.</p>
        </div>
        <div className="move_btn btn_box">
          <div className="cancel">
            <p
              onClick={() => {
                navigate("/");
              }}
            >
              취소
            </p>
          </div>
          <div className="next">
            <p onClick={nextStep}>다음</p>
          </div>
        </div>
      </div>
      {joinDescPop && <JoinDescPop setJoinDescPop={setJoinDescPop} />}
    </div>
  );
}
