import React, { useEffect, useState } from 'react';
import { getBoard_InfoApi } from '../../../api/auth';
import { Link, useLocation,useHistory } from 'react-router-dom';
//component
//css
import '../../../asset/css/notice.css';
import Mheader from '../../../component/common/Mheader';

//menu
import { myInfoMenu }  from '../../../asset/js/dummy.js';

export default function NoticeDetail(props) {
  const location = useLocation()
  const data = location.state.boradInfo;

  const [boradInfo, setBoradInfo] = useState([]);

  const getBoard_Info = async () => {
    const res = await getBoard_InfoApi(data.bd_no);

    if (res.status === 'success') {
      setBoradInfo(res.data);
    }
  };

  useEffect(() => {
    getBoard_Info();
  }, []);

  const [nowUrl, setNowUrl] = useState('/my_page/notice');

  return (
    <div className='noticeDetail_wrap'>
      <Mheader title='공지사항' />
      <div className='sub'>
          <div className='sub_title'>
            {myInfoMenu.map((data, index) => (
              <Link
                to={data.to}
                onClick={() => setNowUrl(data.to)}
                className={
                  nowUrl.includes(data.to)
                    ? 'sub_on'
                    : 'sub_off'
                }
              >
               {data.title}
              </Link>
            ))}
          </div>
        </div>
      <div className='noticeDetail_container '>
        <div className='top_pagenation notice_h2'>
          <h2>공지사항</h2>
        </div>
        <div className='noticeDetail_content'>
          <div className='no_content_top'>
            <h3>{boradInfo.bd_title}</h3>
            <div className='no_content_right'>
              <p>
                {' '}
                {data.bd_regdate.substring(0, 4)}.
                {data.bd_regdate.substring(5, 7)}.
                {data.bd_regdate.substring(8, 10)}
              </p>
              <p>공새로</p>
            </div>
          </div>
          {/* <p>{boradInfo.bd_contents}</p> */}
          <div
            dangerouslySetInnerHTML={{ __html: boradInfo.bd_contents }}
            className='notice_content_div'
          ></div>
        </div>
      </div>
    </div>
  );
}
