import React, { useEffect, useState } from "react";
import i_close from "../../../asset/images/w-cancel-icon.png";
import i_star from "../../../asset/images/w-star-icon.png";
import i_vs from "../../../asset/images/versus_icon.png";
import "../../../asset/css/contractComparePop.css";
import { wonComma } from "../../../util/Util";
import { getMy_ContractInfoApi } from "../../../api/auth";
import swal from "sweetalert";

export default function CompanyPop({
  setComparePop,
  conCompareInfo,
  conCurrentInfo,
  conActive,
}) {
  //공급계약내역 정보받기
  const [pList, setPlist] = useState(conCurrentInfo?.productList);
  const [conInfo, setConInfo] = useState(conCurrentInfo);

  const [pList2, setPlist2] = useState([]);
  const [conInfo2, setConInfo2] = useState([]);

  return (
    <div className="compare_info_pop">
      <div className="pop_dim" onClick={() => setComparePop(false)}></div>
      <div className="compare_info_pop_box">
        <div className="look_close" onClick={() => setComparePop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3>공급 내역 비교</h3>

        <div className="compare_box">
          <div className="compare_target_box">
            <span>
              {/* {conActive?.idx===0?"최초계약":
            conActive.idx===(conActive?.length-1) ? 
            `${conActive?.idx}차 변경계약(최종)` : 
            `${conActive?.idx}차 변경계약`} */}
              {conCurrentInfo.ct_code}
            </span>

            <div className="compare_table">
              <div className="table_container">
                <table>
                  <thead>
                    <tr>
                      <th className="table_name">품목명</th>
                      <th className="table_amount">단가 / 수량</th>
                      <th className="table_amount">금액</th>
                    </tr>
                  </thead>

                  <tbody>
                    {pList.map((a, i) => {
                      if (a.od_price !== "0" || a.od_count !== "0") {
                        return (
                          <tr>
                            <td>{a.bp_name}</td>
                            <td>
                              {wonComma(a.od_price)}원 &nbsp;/&nbsp;&nbsp;
                              {a.bp_count}(개)
                            </td>
                            <td>{wonComma(a.bp_count * a.od_price)}원</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>합계</td>
                      <td colSpan="2">
                        {wonComma(
                          pList
                            .map((data) => data.bp_count * data.od_price)
                            .reduce((sum, current) => sum + current, 0)
                        )}
                        원
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div class="compare_date">
                <div class="compare_date_tit">
                  <p>계약일시</p>
                </div>
                <div class="compare_date_content">
                  <p>
                    {conInfo.ct_sdate}&nbsp;~&nbsp;
                    {conInfo.ct_edate}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="compare_target_box">
            <select
              className="compare_select"
              onChange={(e) => {
                let val = JSON.parse(e.target.value);
                setConInfo2(val);
                setPlist2(val?.productList);
              }}
            >
              <option selected disabled>
                비교할 계약을 선택해주세요.
              </option>
              {conCompareInfo.map((data, i, all) => {
                //
                if (
                  data.ct_code !== conCurrentInfo.ct_code &&
                  data.ct_count * 1 >= i
                ) {
                  return (
                    <option value={JSON.stringify(data)}>
                      {/* {
                        data.idx===0 ? "최초계약" :
                        data.idx===(all.length-1) ? 
                        `${i}차 변경계약(최종)` : 
                        `${i}차 변경계약`
                        } */}
                      {data.ct_code}
                    </option>
                  );
                }
              })}
            </select>

            <div className="compare_table">
              <div className="table_container">
                <table>
                  <thead>
                    <tr>
                      <th className="table_name">품목명</th>
                      <th className="table_amount">단가 / 수량</th>
                      <th className="table_amount">금액</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pList2.map((data, i) => {
                      if (data.od_price !== "0" || data.od_count !== "0") {
                        return (
                          <tr>
                            <td>{data.bp_name}</td>
                            <td>
                              {wonComma(data.od_price)}원 &nbsp;/&nbsp;&nbsp;
                              {data.bp_count}(개)
                            </td>
                            <td>{wonComma(data.bp_count * data.od_price)}원</td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>합계</td>
                      <td colSpan="2">
                        {wonComma(
                          pList2
                            .map((data) => data.bp_count * data.od_price)
                            .reduce((sum, current) => sum + current, 0)
                        )}
                        원
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div class="compare_date">
                <div class="compare_date_tit">
                  <p>계약일시</p>
                </div>
                <div class="compare_date_content">
                  <p>
                    {conInfo2.ct_sdate}&nbsp;~&nbsp;
                    {conInfo2.ct_edate}
                  </p>
                </div>
              </div>

              {/* <div class="compare_date">
                  <div class="compare_date_tit">
                    <p>총액</p>
                  </div>
                  <div class="compare_date_content">
                    <p>350,000원</p>
                  </div>
                </div> */}
            </div>
          </div>
          <img src={i_vs} className="versus_icon" />
        </div>
        <div className="edit_position_btn btn_box">
          <div className="ok" onClick={() => setComparePop(false)}>
            <p>확인</p>
          </div>
        </div>
      </div>
    </div>
  );
}
