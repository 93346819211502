import * as S from "../../asset/css/invoiceUpload.styles";
import circle_plus from "../../asset/images/circle-plus.png";
import circle_minus from "../../asset/images/circle-minus.png";
import default_invoice_preview from "../../asset/images/default-invoice-preview.png";
import swal from "sweetalert";
import useInvoiceUpload from "./customHooks/useInvoiceUpload";
import { PLACEHOLDER } from "../../constant/Placeholder";
import { checkValidationFile } from "../../util/uploadValidation";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

export default function InvoiceUpload(props) {
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });

  const {
    onClickCloseUploadModal,
    invoice,
    fetchInvoiceList,
    fetchInvoiceCountByState,
  } = props;

  const {
    uploadedFile,
    setUploadedFile,
    setUploadedFileImg,
    fileNo,
    filePath,
    uploadInvoice,
  } = useInvoiceUpload();

  const onChangeUploadFile = (e) => {
    const file = checkValidationFile(e.target.files[0]);
    if (!file) return;

    setUploadedFile(e.target.files[0]?.name);
    setUploadedFileImg(e.target.files[0]);
  };

  const onClickUploadInvoice = () => {
    if (!uploadedFile) {
      swal("세금계산서가 선택되지 않았습니다.", "", "error");
      return;
    }

    swal({
      title: "전송을 진행하시겠습니까?",
      icon: "info",
      buttons: ["취소", "전송"],
      closeOnClickOutside: false,
    }).then((value) => {
      if (value) {
        uploadInvoice(invoice.companyInvoice.no, fileNo);
        swal({
          title: "세금계산서 전송이 완료되었습니다.",
          text: `해당 건은 "지급처리중" 상태로 이동됩니다.`,
          icon: "success",
          closeOnClickOutside: false,
        }).then((value) => {
          if (value) {
            fetchInvoiceList("process");
            fetchInvoiceCountByState();
            onClickCloseUploadModal();
          }
          if (!value) swal("취소되었습니다.", "", "error");
        });
      }
      if (!value) swal("취소되었습니다.", "", "error");
    });
  };

  const charge =
    (
      invoice.companyInvoice.totalAmount - invoice.companyInvoice.realAmount
    ).toLocaleString("ko") || 0;

  useEffect(() => {
    if (isMobile) {
      document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;`;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [isMobile]);

  return (
    <>
      <S.ModalBackground>
        <S.Wrapper>
          <S.Title>세금계산서 업로드</S.Title>

          <S.UploadWrap>
            <input
              type="file"
              id="upload"
              // accept="image/*"
              onChange={onChangeUploadFile}
            />
            <S.UploadedFile
              type="text"
              value={uploadedFile}
              placeholder={PLACEHOLDER.INVOICE_FILE_UPLOAD}
              disabled
            />
            <S.UploadBtn htmlFor="upload">업로드</S.UploadBtn>
          </S.UploadWrap>

          <S.ContentWrap>
            <S.TotalAmountContainer>
              <S.TotalAmountTitle>합계현황</S.TotalAmountTitle>
              <S.DivideLine></S.DivideLine>
              <S.TotalAmount>
                <div>납품금액</div>
                <div>
                  {invoice.companyInvoice.totalAmount?.toLocaleString("ko")}원
                </div>
              </S.TotalAmount>
              <S.Fee>
                <div>수수료</div>
                <S.AlignWrap>
                  <img src={circle_minus} alt="" />
                  <div>{charge}원</div>
                </S.AlignWrap>
              </S.Fee>
              <S.RealAmount>
                <div>정산금액</div>
                <div>
                  {invoice.companyInvoice.realAmount?.toLocaleString("ko")}원
                </div>
              </S.RealAmount>
              <S.DivideLine></S.DivideLine>
              <S.Vat>
                <div>부가세</div>
                <S.AlignWrap>
                  <img src={circle_plus} alt="" />
                  <div>
                    {(invoice.companyInvoice.realAmount * 0.1).toLocaleString(
                      "ko"
                    )}
                    원
                  </div>
                </S.AlignWrap>
              </S.Vat>
              <S.DivideLine></S.DivideLine>
              <S.RealVatAmount>
                <div>합계</div>
                <div>
                  <span>
                    {(
                      invoice.companyInvoice.realAmount +
                      invoice.companyInvoice.realAmount * 0.1
                    ).toLocaleString("ko")}
                  </span>
                  원
                </div>
              </S.RealVatAmount>
              <S.Configure>
                <div>(정산금액 + 부가세)</div>
              </S.Configure>
            </S.TotalAmountContainer>

            <S.PreviewContainer>
              <S.PreviewTitle>세금계산서 미리보기</S.PreviewTitle>
              {uploadedFile && (
                <S.InvoiceFileImgWrap>
                  <img src={filePath} alt="" />
                </S.InvoiceFileImgWrap>
              )}
              {!uploadedFile && (
                <S.DefaultFileImgWrap>
                  <img src={default_invoice_preview} alt="" />
                </S.DefaultFileImgWrap>
              )}
            </S.PreviewContainer>
          </S.ContentWrap>

          <S.BtnWrap>
            <S.CancelBtn
              onClick={() => {
                onClickCloseUploadModal();
                swal("취소되었습니다.", "", "error");
              }}
            >
              취소
            </S.CancelBtn>
            <S.SendBtn onClick={onClickUploadInvoice}>전송</S.SendBtn>
          </S.BtnWrap>
        </S.Wrapper>
      </S.ModalBackground>
    </>
  );
}
