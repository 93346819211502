import { checkSocialRegistered, socialLogin, logError } from '../api/auth';
import { actions } from '../redux/action';
//sweet alert
import swal from 'sweetalert';


export const errorLog = (key, value) => {
  let userAgent = navigator.userAgent;
  userAgent = userAgent.replace(';', '');
  const reqData = {
    userAgent: userAgent,
    key: key,
    value: value,
  };
  logError(reqData);
};

export const wonComma = (price) => {
  if(price !== undefined){
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

export const generateRandomKey = (length) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const _checkSocialRegistered = async (data) => {
  const response = await checkSocialRegistered(data);

  if (response.status === 'success') {
    return false;
  } else if (response.message === 'AlreadyRegistered') {
    return true;
  } else {
    //error
    return -1;
  }
};

export const processSocialLogin = async (
  type,
  value,
  history,
  dispatch,
  pushToken
) => {
  const reqData = {
    type: type,
    value: value,
  };

  const response = await _checkSocialRegistered(reqData);

  if (response === false) {
    //가입처리
    swal(
      '가입되지 않은 소셜 계정입니다.\n가입 후 공새로만의 특별한 서비스를 이용해보세요.','','info'
    );
    history.replace({
      pathname: `/join`,
      state: { socialType: type, socialValue: value },
    });
  } else if (response === true) {
    //로그인처리
    const response = await socialLogin(type, value, pushToken);

    if (response.status === 'success') {
      if (response.data.u_type !== 'C') {
        swal(
          '공급사 관리자만 로그인이 가능합니다. 현장 관계자께서는 현장 페이지를 이용해주세요.','','info'
        );
        return;
      }

      let newUserData = {
        u_no: response.data.u_no,
        u_name: response.data.u_name,
        u_rank: response.data.u_rank,

        uc_no: response.data.uc_no,
        c_no: response.data.c_no,
        c_name: response.data.c_name,
        c_bsname: response.data.c_bsname,
      };

      dispatch(actions.setUser(newUserData));
      dispatch(actions.setToken(response.data.signToken));
      history.replace('/');
    } else {
      if (response.message === 'HOLD') {
        swal('현재 승인 대기중입니다. 승인 후 이용 가능합니다.','','info');
        return;
      }
      swal('회원 정보가 일치하지 않습니다.','','warning');
    }
  } else {
    swal('알 수 없는 오류가 발생하였습니다.(2)','','error');
    history.replace('/');
  }
};
