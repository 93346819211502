import styled from "@emotion/styled";

export const Wrapper = styled.div`
  margin-bottom: 150px;
`;

// =========
// DashBoard
// =========
export const DashboardContainer = styled.div`
  width: 1440px;
  margin: 0 auto;
  padding: 60px 30px 0 230px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5rem 5.5rem 15rem;
  }
`;

export const DashBoardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  margin-bottom: 130px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-bottom: 0;
  }
`;

// ==================
// DashBoard - header
// ==================
export const ContentHeader = styled.div`
  width: 100%;
  height: 50px;
  background: #f2f5f9;
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  @media (max-width: 1080px) {
    height: 10rem;
    padding: 0 2.5rem;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  padding-right: 15px;
  @media (max-width: 1080px) {
    font-size: 4.5rem;
  }
`;

// ================
// Dashboard - body
// ================
export const ContentBody = styled.div`
  width: 100%;
  height: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1080px) {
    width: 100%;
    height: 30rem;
    justify-content: space-around;
  }

  button {
    width: 130px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 4px 3px rgba(67, 67, 87, 0.31);
    border: 2px solid rgba(0, 40, 99, 0.8);
    border-radius: 4px;
    font-weight: 700;
    font-size: 18px;
    color: #254574;
    transition: all 0.2s;
    &:hover {
      background: #ff6600;
      border: 2px solid #ff6600;
      color: #ffffff;
    }
    @media (max-width: 1080px) {
      width: 80%;
      height: 10rem;
      font-size: 4.5rem;
    }
  }
`;

export const DivideLine = styled.div`
  opacity: 0.3;
  border: 1px solid #000000;
  height: 80px;
  margin-left: 60px;
`;

export const PaymentStateWrap = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 60px;
  @media (max-width: 1080px) {
    width: 80%;
    margin-left: 0;
  }
`;

export const RequestState = styled.div`
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  transition: 0.2s all;
  color: ${(props) => props.state === "request" && "#ff6600"} !important;
  @media (max-width: 1080px) {
    height: 10rem;
    font-size: 4rem;
  }
`;

export const ProcessState = styled.div`
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  transition: 0.2s all;
  color: ${(props) => props.state === "process" && "#ff6600"} !important;
  @media (max-width: 1080px) {
    height: 10rem;
    font-size: 4rem;
  }
`;

export const CompleteState = styled.div`
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  transition: 0.2s all;
  color: ${(props) => props.state === "complete" && "#ff6600"} !important;
  @media (max-width: 1080px) {
    height: 10rem;
    font-size: 4rem;
  }
`;

export const AllState = styled.div`
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  transition: 0.2s all;
  color: ${(props) => props.state === "all" && "#ff6600"} !important;
  @media (max-width: 1080px) {
    height: 10rem;
    font-size: 4rem;
  }
`;

export const CheckPaymentRequestListBtn = styled.button`
  background: ${(props) => props.state === "request" && "#ff6600"} !important;
  color: ${(props) => props.state === "request" && "#ffffff"} !important;
  border: ${(props) => props.state === "request" && "none"} !important;
`;

export const PaymentProcessingBtn = styled.button`
  background: ${(props) => props.state === "process" && "#ff6600"} !important;
  color: ${(props) => props.state === "process" && "#ffffff"} !important;
  border: ${(props) => props.state === "process" && "none"} !important;
`;

export const PaymentCompletedBtn = styled.button`
  background: ${(props) => props.state === "complete" && "#ff6600"} !important;
  color: ${(props) => props.state === "complete" && "#ffffff"} !important;
  border: ${(props) => props.state === "complete" && "none"} !important;
`;

export const PaymentAllBtn = styled.button`
  background: ${(props) => props.state === "all" && "#ff6600"} !important;
  color: ${(props) => props.state === "all" && "#ffffff"} !important;
  border: ${(props) => props.state === "all" && "none"} !important;
`;

// ==================
// Dashboard - footer
// ==================
export const ContentFooter = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f2f5f9;
  border-top: 1px solid #dddddd;
  padding: 0 20px;
  @media (max-width: 1080px) {
    height: 10rem;
    padding: 0 2.5rem;
  }
`;

export const EntireTotalAmount = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #222222;
  padding-right: 15px;
  @media (max-width: 1080px) {
    font-size: 4rem;
  }
`;

export const NoInvoiceAlert = styled.div`
  width: 1440px;
  margin: 0 auto;
  padding: 10px 30px 0 230px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5rem 5.5rem 15rem;
  }
`;
export const NoInvoiceAlertText = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  letter-spacing: -1px;
  color: #ddd;
  @media (max-width: 1080px) {
    font-size: 5rem;
  }
`;
