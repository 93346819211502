import React from "react";
import i_close from "../../../asset/images/w-cancel-icon.png";
import "../../../asset/css/pop.css";
import { offerUpdateApi } from "../../../api/auth";
import { useNavigate } from "react-router";

//sweet alert
import swal from "sweetalert";

export default function EstimateModifyPop({
  setSubmitPop,
  comment,
  od,
  pList,
  setModify,
  fileNo,
}) {
  const odData = pList.map((item, index) => {
    return {
      offerDetailNo: item.offerDetail.no, // offerDetailNo
      price: item.offerDetail.price,
    };
  });

  //견적수정 폼
  const formData = {
    offerNo: pList[0].offerDetail.offerNo, // offerNo
    offerComment: comment,
    offerUpdateInfoList: odData,
    offerFileNo: fileNo,
  };
  console.log(formData);

  const onClick_req_modify = async () => {
    for (let i = 0; i < od.length; i++) {
      if (od[i].price === "" || od[i].price < 1 || od[i].price === "0") {
        swal("단가를 입력해주세요.", "", "warning");
        return setSubmitPop(false);
      }
      if (od[i].price === 1 || od[i].price === "1") {
        swal("고객사 납품단가는 2원 이상으로 입력해주세요.", "", "warning");
        return setSubmitPop(false);
      }
    }

    const res = await offerUpdateApi(formData);

    if (res.status === "success") {
      // navigate("/management/estimate/1");
      setModify(true);
      setSubmitPop(false);
      swal("수정이 완료되었습니다.", "", "success");
    } else if (res.message === "already_choose") {
      swal(
        "투찰시간이 종료되었습니다. 투찰을 수정할 수 없습니다.",
        "",
        "error"
      );
    } else {
      swal("투찰수정에 오류가 발생하였습니다.", "", "error");
    }
  };

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setSubmitPop(false)}></div>
      <div className="est_submit_pop">
        <div className="close" onClick={() => setSubmitPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <div className="contract_pop_tit">
          <h3>최종 수정하시겠습니까?</h3>
          <p>입력한 투찰서를 최종 수정하시겠습니까?</p>
        </div>
        <div className="est_submit_pop_info">
          <div className="btn_box">
            <div className="cancel">
              <p onClick={() => setSubmitPop(false)}>취소</p>
            </div>
            <div className="ok" onClick={onClick_req_modify}>
              확인
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
