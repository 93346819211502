import React, { useState } from "react";
//library
import { Link } from "react-router-dom";
//component
import Mheader from "../../component/common/Mheader";
//css
// import "../../asset/css/payment2.css";

export default function Payment2({ }) {
  return (
    <div className="payment_wrap">
      <Mheader title="유료 서비스 결제" />
      <div className="payment2_container">
        <div className="payment_top">
          <h2>유료 서비스 결제하기</h2>
          <div className="payment_pagenation">
            <p>1</p>
            <p className="active">2</p>
          </div>
        </div>
        <div className="payment_complete">
          <p>결제가 완료되었습니다.</p>
          <div>
            <p className="pay_date">
              결제일시 : <span>2021.08.02 14:22</span>
            </p>
            <p className="pay_service">
              상품정보 : <span>골드등급 6,600원</span>
            </p>
          </div>
        </div>
        <Link to="/look">
          <div className="blue_btn">확인</div>
        </Link>
      </div>
    </div>
  );
}
