import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//component
import Mheader from "../../../component/common/Mheader";
import ContractTermsPop from "../../../component/common/Pop/ContractTermsPop";
import ContractTermsStopPop from "../../../component/common/Pop/ContractTermsStopPop";
import ContractComparePop from "../../../component/common/Pop/ContractComparePop";
import ContractChangeSentReqPop from "../../../component/common/Pop/ContractChangeSentReqPop";

import { getMy_ContractAllInfoApi, contractDetailApi } from "../../../api/auth";
import { wonComma } from "../../../util/Util";
import Loading from "../../../component/etc/Loading";

//sweet alert
import swal from "sweetalert";

//css
import * as S from "../../../asset/css/contract.styles";
import { useMediaQuery } from "react-responsive";
import useFee from "../../main/customHooks/useFee";
import TableSummaryFooter from "../../../component/etc/TableFooter";

export default function ContractDetail(props) {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const params = useParams();
  const ct_no = params.id;

  // 팝업
  const [termsPop, setTermsPop] = useState(false);
  const [termsStopPop, setTermsStopPop] = useState(false);
  const [sentPop, setSentPop] = useState(false);

  //공급계약내역 정보받기
  const [conInfo, setConInfo] = useState([]);

  //통장사본보기
  const [imgList, setImgList] = useState([]);

  //세금계산서 발행정보

  const [taxInfo, setTaxInfo] = useState(false);
  //변경 계약
  const [comparePop, setComparePop] = useState(false);
  const [contractActive, setContractActive] = useState({
    idx: 0,
    ct_no: ct_no,
    // ct_parent : countList[4]?.ct_parent,
    // ct_count : countList[4]?.ct_count
  });
  const [isClick, setIsClick] = useState(false);

  const fee = useFee();

  //loading
  const [generating, setGenerating] = useState(false);

  const [firstConInfo, setFirstConInfo] = useState([]);
  const [pListT, setpListT] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [countListT, setCountListT] = useState([]);

  const [detailInfo, setDetailInfo] = useState({});

  const contractDetailFnc = async () => {
    const response = await contractDetailApi(ct_no);
    if (response.message === "success") {
      setDetailInfo(response.data.contractList[0]);
    }
  };

  const getMy_ContractAllInfo = async () => {
    const res = await getMy_ContractAllInfoApi(ct_no);

    if (res.message === "success") {
      setConInfo(res.data.contractList);
      setCountListT(res.data.contractMap);
      setFirstConInfo(res.data.contractList[0]);

      setCurrentList(res.data.contractList[res.data.contractMap.ct_count]);
      setpListT(
        res.data.contractList[res.data.contractMap.ct_count].productList
      );
    }
  };

  useEffect(() => {
    getMy_ContractAllInfo();
    contractDetailFnc();
  }, []);

  useEffect(() => {
    setIsClick(true);
  }, [contractActive]);

  const _move2Success = () => {
    getMy_ContractAllInfo();
    setTermsPop(false);
    // reload?
  };

  const hyphen = (phoneNum) => {
    if (phoneNum != undefined) {
      return phoneNum.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    }
    return phoneNum;
  };

  return (
    <>
      <div className="contract_ok_wrap">
        <Mheader title="공급계약 내역 확인" />
        <div className="contract_ok_container">
          <div className="top_pagenation">
            <h2>공급계약 내역 확인</h2>
            <ul className="top_btn_box">
              <li
                onClick={() => {
                  window.open(currentList.contract_file);
                  // 변경계약 file path가 떠야함
                }}
              >
                <p>계약서 확인</p>
              </li>
              {firstConInfo?.ct_state === "START" && (
                <li className="w180">
                  <p onClick={() => setTermsPop(true)}>계약체결 동의</p>
                </li>
              )}
              {firstConInfo.ct_state === "HOLD" && (
                <li
                  onClick={() => {
                    setTermsStopPop(true);
                  }}
                >
                  <p>계약 중지 동의</p>
                </li>
              )}
            </ul>
          </div>
          <div className="contract_ok_info">
            {firstConInfo.ct_check === "Y" && (
              <div className="change_contract_caution">
                <p className="caution_txt_orange">
                  고객사에서 변경계약에 동의하지않았습니다.
                </p>
                <div
                  className="change_contract_agree"
                  onClick={() => {
                    // breakDownFnc();
                    setSentPop(true);
                  }}
                >
                  확인하기
                </div>
              </div>
            )}
            <div className="change_contract_box">
              <div className="change_contract_tab">
                {conInfo.map((a, i, all) => {
                  // countList[0].ct_count는 동의된 차수인데, 동의 안한 차수 전까지만 뿌리기위해 조건절
                  if (firstConInfo.ct_count * 1 >= i) {
                    return (
                      <span
                        className={currentList === a && "active"}
                        onClick={() => {
                          setCurrentList(a);
                          setpListT(a.productList);
                          // getMy_ContractInfo();
                          //여기서 api호출해야함
                          //바로 호출하게 하지말고 useEffect를 사용해서 값이 바뀔 때마다 호출하는 방식으로 쓰는게 제일 좋아보임.
                        }}
                      >
                        {i === 0
                          ? "최초계약"
                          : i === all.length - 1
                          ? `${i}차 변경계약(최종)`
                          : `${i}차 변경계약`}
                      </span>
                    );
                  }
                })}
              </div>
              {firstConInfo.ct_count !== "0" && (
                <div className="change_contract_compare">
                  <div
                    className="change_contract_compare_btn"
                    onClick={() => {
                      setComparePop(!comparePop);
                    }}
                  >
                    계약 비교하기
                  </div>
                </div>
              )}
            </div>
            <div
              className="contract_content"
              style={{ marginTop: "0px", borderTop: "none" }}
            >
              <div className="content_top">
                <span>계약서 번호 :&nbsp;</span>
                <span className="public_code">{currentList.ct_code}</span>
              </div>
              <div className="content_box">
                <div className="content_btm">
                  <S.Badge el={detailInfo}>
                    {detailInfo.ct_state === "START" && "계약 전"}
                    {detailInfo.ct_state === "ALIVE" && "계약완료"}
                    {detailInfo.ct_state === "CANCEL" && "계약취소"}
                    {detailInfo.ct_state === "HOLD" && "중지요청"}
                    {detailInfo.ct_state === "STOP" && "계약중지"}
                    {detailInfo.ct_state === "GRACE" && "연장가능계약"}
                    {detailInfo.ct_state === "END" && "계약종료"}
                  </S.Badge>
                  <p className="contractor">
                    <span>
                      {detailInfo?.bid_type === "T" ||
                      detailInfo?.bid_type === "C"
                        ? `지정가 단가계약 (${detailInfo?.bid_term}개월)`
                        : detailInfo.bid_type === "S"
                        ? "단일건 계약"
                        : null}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="quote_content">
              <h3 className="s_tit">
                <p>계약 정보</p>
              </h3>
              <div className="quote_list_box">
                <ul className="quote_list">
                  {/* {(conInfo.ct_state === 'ALIVE')  && ( */}
                  <li>
                    <p>고객사</p>
                    <span>
                      [{firstConInfo.con_name}] {firstConInfo.s_name}
                    </span>
                  </li>
                  {/* )} */}
                  <li>
                    <p>현장주소</p>
                    <span>
                      {firstConInfo.bid_addr1}, {firstConInfo.bid_addr2}
                    </span>
                  </li>
                  <li>
                    <p>공급사</p>
                    <span>{firstConInfo.company_name}</span>
                  </li>
                  {firstConInfo.bid_type === "T" ||
                  firstConInfo.bid_type === "C" ? (
                    <li>
                      <p className="company_info">계약기간</p>
                      <span>
                        {currentList.ct_sdate?.slice(0, 10)} ~{" "}
                        {currentList.ct_edate?.slice(0, 10)}
                      </span>
                    </li>
                  ) : null}
                  {firstConInfo.bid_type === "S" && (
                    <li>
                      <p>납품기한</p>
                      <span>{conInfo.bid_deldate}</span>
                    </li>
                  )}
                </ul>
                <ul className="quote_list">
                  <li>
                    <p>배송방법</p>
                    <span>
                      {firstConInfo.bid_drop === "L"
                        ? "하차 역무 있음 ⭕️"
                        : firstConInfo.bid_drop === "U"
                        ? "하차 역무 없음 ❌"
                        : null}
                    </span>
                  </li>
                  <li>
                    <p>결제방법</p>
                    <span>{firstConInfo.bid_refund}</span>
                  </li>

                  <li>
                    <p>계약생성일시</p>
                    <span className="company_info">
                      {detailInfo.ct_regdate}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            {(firstConInfo.ct_state === "ALIVE" ||
              firstConInfo.ct_state === "HOLD") && (
              <div className="contract_manager">
                <div className="manager">
                  <h3 className="s_tit">
                    <p>고객사 담당자</p>
                  </h3>
                  <ul className="manager_info">
                    <li>
                      <p>담당자</p>
                      <span>{currentList.s_u_name}</span>
                    </li>
                    <li>
                      <p>전화번호</p>
                      <span>{hyphen(currentList.s_u_phone)}</span>
                    </li>
                  </ul>
                </div>
                <div className="manager">
                  <h3 className="s_tit">
                    <p>공급사 담당자</p>
                  </h3>
                  <ul className="manager_info">
                    <li>
                      <p>담당자</p>
                      <span>{currentList.c_u_name}</span>
                    </li>
                    <li>
                      <p>전화번호</p>
                      <span>{hyphen(currentList.c_u_phone)}</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {firstConInfo.bid_type === "T" ||
              (firstConInfo.bid_type === "C" && (
                <div className="contract_date">
                  <h3 className="s_tit">
                    <p>계약기간</p>
                  </h3>
                  <ul className="contract_date_ul">
                    <li>
                      <input
                        type="text"
                        value={firstConInfo.ct_sdate?.slice(0, 10)}
                        disabled
                      />
                    </li>
                    {firstConInfo.bid_type === "T" ||
                    firstConInfo.bid_type === "C" ? (
                      <>
                        <li>
                          <input
                            type="text"
                            value={currentList.ct_edate?.slice(0, 10)}
                            disabled
                          />
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              ))}

            <div className="conclude_est">
              <h3 className="s_tit">
                <p>확정 내역</p>
              </h3>
              <div className="table_container">
                <table>
                  <thead>
                    <tr>
                      <th className="table_index"></th>
                      <th className="table_name">품목명</th>
                      <th className="table_standard">규격</th>
                      <th className="table_brand">
                        제조국
                        <p className="table_sub_desc">(브랜드)</p>
                      </th>
                      <th className="table_etc">비고</th>
                      <th className="table_unit">단위</th>
                      <th className="table_count">수량</th>
                      <th className="table_total_amount">
                        고객사 납품단가
                        <p className="table_sub_desc">(수수료 포함 단가)</p>
                      </th>
                      <th className="table_total_amount">
                        최종 합계 금액
                        <p className="table_sub_desc">(고객사 납품 단가)</p>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {pListT.map((data, i) => {
                      if (data.od_price !== "0" || data.od_count !== "0") {
                        return (
                          <tr value={data} key={i}>
                            <th>{i + 1}</th>
                            <td>{data.bp_name}</td>
                            <td>
                              <p className="table_overflow_text">
                                {data.bp_standard.replace(/, /gi, "\n")}
                              </p>
                            </td>
                            <td>{data.bp_brand}</td>
                            <td>
                              <p className="table_overflow_text">
                                {data.bp_etc}
                              </p>
                            </td>
                            <td>{data.bp_unit}</td>
                            <td>{data.bp_count}</td>
                            <td>{wonComma(data?.od_price)}</td>
                            <td>{wonComma(data?.bp_count * data?.od_price)}</td>
                          </tr>
                        );
                      }

                      return null;
                    })}
                  </tbody>

                  <TableSummaryFooter
                    list={pListT}
                    priceKey="od_price"
                    countKey="bp_count"
                    rowSpan="3"
                    colSpan="7"
                  />
                </table>
              </div>
            </div>
            <div className="uniqueness_box">
              <div className="uniqueness">
                <div className="uniqueness_tit">
                  <p>
                    <span>{firstConInfo.c_name}</span>
                    <span>공급사</span>
                    특이사항
                  </p>
                </div>
                <div className="uniqueness_content">
                  <p>{currentList.ct_comment}</p>
                </div>
              </div>
              <div className="uniqueness">
                <div className="uniqueness_tit">
                  <p>
                    <span>계약 관련</span>
                    <span>추가 내용</span>
                  </p>
                </div>
                <div className="uniqueness_content contract_additions">
                  <p>{firstConInfo.o_comment}</p>
                </div>
              </div>
            </div>
            <div className="contract_ok_pay">
              <h3 className="s_tit">
                <p>지급방법</p>
              </h3>
              <p>{firstConInfo.bid_refund}</p>
            </div>
            {/* <div className="look_info payment_account">
              <h3 className="s_tit">
                <p>지급계좌 정보</p>
              </h3>
              <p onClick={() => window.open(imgList.f_root, "_blank")}>
                통장사본
              </p>
            </div> */}
            {detailInfo.ct_state === "ALIVE" && (
              //  || data.e_state === "CS"
              <>
                <div className="look_info tax_bill">
                  <h3 className="s_tit">
                    <p>세금계산서 발행 정보</p>
                  </h3>
                  {taxInfo && (
                    <p onClick={() => window.open(taxInfo.f_root, "_blank")}>
                      사업자정보 보기
                    </p>
                  )}
                </div>
                <p className="caution_txt">{conInfo.bid_comment}</p>
              </>
            )}
            <p className="caution_txt">
              *고객사 및 공급사의 일방적인 계약중지에 따른 패널티가 발생할 수
              있습니다. 계약중지 시 상호 간 사전협의 후 진행해주세요.
            </p>
          </div>
        </div>
        {termsPop && (
          <ContractTermsPop
            setTermsPop={setTermsPop}
            formCt_no={ct_no}
            contract_file={currentList.contract_file}
            move2Success={_move2Success}
          />
        )}
        {termsStopPop && (
          <ContractTermsStopPop
            move2Success={_move2Success}
            setTermsStopPop={setTermsStopPop}
            formCt_no={ct_no}
          />
        )}
        {comparePop && (
          <ContractComparePop
            setComparePop={setComparePop}
            conCompareInfo={conInfo}
            conCurrentInfo={currentList}
            conActive={contractActive}
            // partInfo={partInfo}
          />
        )}
        {sentPop && (
          <ContractChangeSentReqPop
            setSentPop={setSentPop}
            conInfo={conInfo.at(-1)}
            move2Success={_move2Success}
          />
        )}
        {generating && <Loading msg={"로딩중입니다."} />}
      </div>
    </>
  );
}
