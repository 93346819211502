import styled from "@emotion/styled";

export const Wrap = styled.div`
  width: 1440px;
  margin: 0 auto;
  padding: 30px 30px 150px 230px;
  @media (max-width: 1080px) {
    width: 100%;
    padding: 5rem 5.5rem 15rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  margin-right: 30px;
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const DateRangeSelect = styled.select`
  width: 140px;
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  @media (max-width: 1080px) {
    width: 100%;
    height: 13rem;
    margin-bottom: 3rem;
    margin-right: 0;
    font-size: 4.6rem;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1080px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3rem;
  }
`;
export const DateWrap = styled.div`
  .react-datepicker__input-container input {
    height: 35px !important;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    height: 30px !important;
    line-height: 30px !important;
    background-color: #fff !important;
    margin-top: 10px !important;
  }
  margin-right: 10px;
  @media (max-width: 1080px) {
    width: 48% !important;
    margin-right: 0;
    .react-datepicker {
      border-radius: 1rem !important;
    }
    .react-datepicker__day {
      width: 9rem !important;
      height: 9rem !important;
      line-height: 9rem !important;
      border-radius: 1rem;
    }
    .react-datepicker__input-container input {
      height: 13rem !important;
      font-size: 4.6rem;
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select {
      height: 8rem !important;
      line-height: 8rem !important;
      margin-top: 2rem;
    }
  }
`;

export const SearchInput = styled.input`
  width: 300px;
  height: 35px;
  line-height: 35px;
  margin-left: 20px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 3rem;
    padding: 6.4rem 0;
    font-size: 4.6rem;
  }
`;

export const SearchBtn = styled.button`
  width: 100px;
  height: 35px;
  font-weight: 500;
  font-size: 18px;
  background: #dbe4f0;
  border-radius: 3px;
  color: #002863;
  font-weight: 500;
  font-size: 18px;
  margin-left: 10px;
  transition: all 0.2s;
  &:hover {
    background: #002863;
    color: #dbe4f0;
  }
  @media (max-width: 1080px) {
    width: 100%;
    margin-left: 0;
    height: 13rem;
    font-size: 4.6rem;
    transition: none;
  }
`;

export const SearchResult = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;
  font-weight: 700;
  span {
    margin-left: 5px;
    color: #ff6600;
  }
  @media (max-width: 1080px) {
    font-size: 4rem;
  }
`;

export const HistoryListWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background: #012964;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  padding: 0 10px;
  span:nth-of-type(1) {
    width: 16%;
  }
  span:nth-of-type(2) {
    width: 10%;
  }
  span:nth-of-type(3) {
    width: 18%;
  }
  span:nth-of-type(4) {
    width: 18%;
  }
  span:nth-of-type(5) {
    width: 20%;
  }
  span:nth-of-type(6) {
    width: 18%;
  }
  span:nth-of-type(7) {
    width: 10%;
  }
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid #ddd;
  border-top: none;
  background: #fff;
  color: #222;
  font-size: 16px;
  padding: 0 10px;
  span {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span:nth-of-type(1) {
    width: 16%;
  }
  span:nth-of-type(2) {
    width: 10%;
  }
  span:nth-of-type(3) {
    width: 18%;
  }
  span:nth-of-type(4) {
    width: 18%;
  }
  span:nth-of-type(5) {
    width: 20%;
  }
  span:nth-of-type(6) {
    width: 18%;
  }
  button {
    width: 10%;
    height: 35px;
    background: #dbe4f0;
    border-radius: 3px;
    font-weight: 500;
    color: #002863;
    transition: all 0.2s;
    &:hover {
      background: #002863;
      color: #dbe4f0;
    }
  }
`;

export const M_HistoryListContainer = styled.div``;
export const M_HistoryListWrap = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 4rem;
`;

export const M_HistoryListHeader = styled.div`
  font-size: 3.5rem;
  font-weight: 700;
  background: #f7f7f7;
  padding: 4rem;
`;

export const M_HistoryListBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem;
  div {
    margin-bottom: 3rem;
    font-size: 4rem;
  }
  div:nth-of-type(1) {
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 4rem;
  }
  div:nth-of-type(4) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div:nth-of-type(5) {
    margin-bottom: 0;
  }
`;

export const M_HistoryListFooter = styled.div`
  width: 100%;
  padding: 0 4rem 4rem 4rem;
`;

export const M_HistoryDetailBtn = styled.button`
  width: 100%;
  height: 12rem;
  background: #dbe4f0;
  border-radius: 3px;
  font-size: 4rem;
  font-weight: 500;
  color: #002863;
  /* transition: all 0.2s; */
  /* cursor: not-allowed; */
  /* &:hover {
      background: #002863;
      color: #dbe4f0;
    } */
`;
