import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import s from "../../../asset/css/requestConfirm.module.css";
import { useDispatch, useSelector } from "react-redux";
import { bidCreateApi } from "../../../api/auth";
import { estimateDefault } from "../../../redux/reducer/default";
import Loading from "../../../component/etc/Loading";
import { actions } from "../../../redux/action";
import TableSummaryFooter from "../../../component/etc/TableFooter";
import Mheader from "../../../component/common/Mheader";
import PreventRefresh from "../../main/customHooks/usePreventRefresh";

export default function RequestConfirm() {
  const location = useLocation();
  const data = location.state.data;
  const orderInfoData = location.state.onlyViewData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(data);
  const [generating, setGenerating] = useState(false);

  const userData = useSelector((state) => state.user);

  const goBack = () => {
    navigate(-1);
  };

  const onClickCreateBid = async () => {
    setGenerating(true);
    const res = await bidCreateApi(data);

    if (res.status === "success") {
      dispatch(actions.setEstimate(estimateDefault));
      navigate("/management/order/request_complete", {
        state: { no: res.data.no },
      });
    }
    setGenerating(false);
  };

  return (
    <div className={s.container}>
      <PreventRefresh />
      <Mheader title="상세정보 확인" />
      <div className="top_progress_bar">
        <div className="menus">주문서 작성하기</div>
        <div className="menus">상세정보 입력</div>
        <div className="active_menus">상세정보 확인</div>
        <div className="menus not_allowed">주문서 작성 완료</div>
      </div>

      <div className={s.inner}>
        <div className={s.content}>
          <h3 className="s_tit">
            <p>주문자</p>
          </h3>
          <div className="quote_list_box">
            <ul className="quote_list">
              <li>
                <p>고객사명</p>
                <span>{orderInfoData.constructionName}</span>
              </li>
              <li>
                <p>담당자명</p>
                <span>{orderInfoData.siteUserName}</span>
              </li>
            </ul>
            <ul className="quote_list">
              <li>
                <p>현장명</p>
                <span>{orderInfoData.siteName}</span>
              </li>
              {/* <li>
                <p>주문자 연락처</p>
                <span>-</span>
              </li> */}
            </ul>
          </div>
        </div>
        <div className={s.content}>
          <h3 className="s_tit">
            <p>주문 정보</p>
          </h3>
          <div className="quote_list_box">
            <ul className="quote_list">
              <li>
                <p>주문 / 입찰 방식</p>
                <span>주문서 역발행</span>
              </li>
              <li>
                <p>공급사명</p>
                <span>{userData.c_name}</span>
              </li>
              <li>
                <p>공급사 담당자</p>
                <span>{userData.u_name}</span>
              </li>

              <li>
                <p>배송비</p>
                <span>배송비 포함</span>
              </li>
              <li>
                <p>비용정산</p>
                <span>공새로 위임 정산</span>
              </li>
            </ul>
            <ul className="quote_list">
              <li>
                <p>배송 상태</p>
                <span>{data.ordersState}</span>
              </li>
              <li>
                <p>주문 일자</p>
                <span>{data.ordersDate?.substring(0, 10)}</span>
              </li>
              <li>
                <p>납품 완료 일자</p>
                <span>
                  {data.ordersState === "배송완료 - 납품완료"
                    ? data.endDate?.substring(0, 10)
                    : "-"}
                </span>
              </li>
              {/* <li>
                <p>수령인 연락처</p>
                <span>-</span>
              </li>
              <li>
                <p>납품 주소</p>
                <span>-</span>
              </li> */}
              <li>
                <p>하차 역무</p>
                <span>하차 역무 포함</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={s.content}>
          <h3 className="s_tit">
            <p>품목 내역</p>
          </h3>
          <div className="table_container">
            <table>
              <thead>
                <tr>
                  <th className="table_index"></th>
                  <th className="table_name">품목명</th>
                  <th className="table_standard">규격</th>
                  <th className="table_brand">
                    제조국
                    <p className="table_sub_desc">(브랜드)</p>
                  </th>
                  <th className="table_etc">비고</th>
                  <th className="table_unit">단위</th>
                  <th className="table_count">수량</th>

                  <th className="table_total_amount">
                    고객사 납품 단가
                    <p className="table_sub_desc">(수수료 포함 단가)</p>
                  </th>
                  <th className="table_total_amount">
                    최종 합계 금액
                    <p className="table_sub_desc">(고객사 납품 단가)</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.productInfoList?.map((elem, idx) => {
                  return (
                    <tr value={elem} key={idx}>
                      <th>{idx + 1}</th>
                      <td>{elem.bidProduct.name}</td>
                      <td>{elem.bidProduct.standard}</td>
                      <td>{elem.bidProduct.brand}</td>
                      <td>{elem.bidProduct.etc}</td>
                      <td>{elem.bidProduct.unit}</td>
                      <td>{elem.bidProduct.count.toLocaleString("ko")}</td>
                      <td>{elem.price.toLocaleString("ko")}원</td>
                      <td>
                        {(elem.price * elem.bidProduct.count).toLocaleString(
                          "ko"
                        )}
                        원
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              <TableSummaryFooter
                list={data.productInfoList}
                priceKey="price"
                countKey="bidProduct.count"
                rowSpan="3"
                colSpan="7"
              />
            </table>
          </div>
          <div className="uniqueness_box">
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>특이사항</p>
              </div>
              <div className="uniqueness_content">
                <pre>{data.comment}</pre>
              </div>
            </div>
          </div>
          {data?.bidImgList?.length === 0 ? null : (
            <div className="look_info payment_account">
              <h3 className="s_tit">
                <p>첨부파일 정보</p>
              </h3>
              {data?.bidImgList?.map((a, index) => {
                return (
                  <div>
                    <p
                      onClick={() => {
                        window.open(a.fileRoot);
                      }}
                    >
                      첨부파일 {index + 1} 보기
                    </p>
                    <span> {a.fileName} </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className={s.submit_wrap}>
        <div className="btn_box">
          <div className="estimate_preview_btn" onClick={goBack}>
            이전으로
          </div>
          <div className="estimate_complete_btn" onClick={onClickCreateBid}>
            주문서 발송
          </div>
        </div>
      </div>
      <>
        {generating && (
          <Loading msg={"주문 확인서 생성중입니다."} color="#000" />
        )}
      </>
    </div>
  );
}
