import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import { Checkbox, ConfigProvider } from "antd";

//component
import Mheader from "../../../component/common/Mheader";
// css
import "../../../asset/css/orderIssuance.css";
import * as S from "../../../asset/css/mobileOrderReturnDetailItem.styles.js";

//api
import { createOrderReturnApi, ordersDetailApi } from "../../../api/auth";

//redux
import default_invoice_preview from "../../../asset/images/default-invoice-preview.png";
import { useMediaQuery } from "react-responsive";
import swal from "sweetalert";
import Loading from "../../../component/etc/Loading.js";
import TableSummaryFooter from "../../../component/etc/TableFooter";

export default function OrderReturnDetail() {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1080px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 1080px)",
  });
  const navigate = useNavigate();
  const { id: orderNo } = useParams();
  const [loading, setLoading] = useState(false);

  const [attachedFileList, setAttachedFileList] = useState([]);
  const [orderState, setOrderState] = useState("");
  const [orderDetail, setOrderDetail] = useState({});
  const [companyComment, setCompanyComment] = useState("");

  // 기존 주문서 정보
  const [orderProductList, setOrderProductList] = useState([]);
  // 반품 주문서 정보
  const [orderReturnProductList, setOrderReturnProductList] = useState([]);
  // 체크박스 로직
  const checkAll = orderProductList?.length === orderReturnProductList?.length;
  const checkSome =
    orderReturnProductList?.length > 0 &&
    orderReturnProductList.length < orderProductList.length;
  const onCheckAll = (e) => {
    setOrderReturnProductList(e.target.checked ? orderProductList : []);
  };
  const onCheckSome = (product, isChecked) => {
    if (isChecked) {
      setOrderReturnProductList((prev) => [...prev, product]);
    } else {
      setOrderReturnProductList((prev) =>
        prev.filter((item) => item.bidProduct.no !== product.bidProduct.no)
      );
    }
  };
  // 반품 수량 input handler
  const handleReturnCountChange = (e, orderProduct) => {
    const returnCount = parseInt(e.target.value);

    setOrderReturnProductList((prev) =>
      prev.map((product) =>
        product.bidProduct.no === orderProduct.bidProduct.no
          ? {
              ...product,
              bidProduct: {
                ...product.bidProduct,
                returnCount: -Math.abs(returnCount),
              },
            }
          : product
      )
    );
  };
  // 반품 주문서 validation
  const isCheckedOrder = !orderReturnProductList.length;
  const isExistReturnCount = orderReturnProductList.some(
    (product) =>
      product.bidProduct.returnCount >= 0 ||
      isNaN(product.bidProduct.returnCount)
  );
  const isValidReturnCount = orderReturnProductList.some(
    (product) => product.bidProduct.count < -product.bidProduct.returnCount
  );
  const isValidRemainCount = orderReturnProductList.some(
    (product) => product.remainCount === 0
  );
  // 반품 주문서 parameter
  const orderReturnParameter = {
    ordersNo: orderNo,
    companyComment: companyComment,
    productInfoList: orderReturnProductList,
  };
  // 반품 주문서 생성 api
  const createOrderReturn = async () => {
    setLoading(true);

    try {
      const response = await createOrderReturnApi(orderReturnParameter);
      navigate(`/management/order/document/${response.data.no}`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // 완료 버튼 클릭
  const onClickComplete = () => {
    if (isCheckedOrder) {
      swal("반품할 품목을 선택해주세요", "", "info");
      return;
    }
    if (isExistReturnCount) {
      swal("선택한 품목의 반품 수량을 입력해주세요", "", "info");
      return;
    }
    if (isValidReturnCount) {
      swal("반품 가능 수량보다 큰 수량은 입력할 수 없습니다", "", "info");
      return;
    }
    if (isValidRemainCount) {
      swal("반품 가능 수량이 0인 품목은 선택할 수 없습니다.", "", "info");
      return;
    }

    swal({
      title: "반품 주문서 생성을 완료하시겠습니까?",
      icon: "info",
      buttons: {
        cancel: {
          text: "취소",
          value: null,
          visible: true,
        },
        confirm: {
          text: "완료",
          value: true,
          visible: true,
        },
      },
    }).then((value) => {
      if (value) createOrderReturn();
      if (!value) swal("취소되었습니다", "", "error");
    });
  };

  const fetchOrderDetail = useCallback(async () => {
    const response = await ordersDetailApi(orderNo);
    if (response.status === "success") {
      response.data.productInfoList.map(
        (product) => (product.bidProduct.returnCount = 0)
      );

      setOrderDetail(response.data);
      setOrderState(response.data.orders.state);
      setAttachedFileList(response.data.bidImgInfoList);
      setOrderProductList(response.data.productInfoList);
    }
  }, [orderNo]);

  useEffect(() => {
    fetchOrderDetail();
  }, [fetchOrderDetail]);

  const formatPhoneNumber = (phoneNumber) => {
    const pattern = /^(\d{3})(\d{4})(\d{4})$/;

    if (phoneNumber !== undefined || phoneNumber !== "") {
      return phoneNumber?.replace(pattern, "$1-$2-$3");
    }
  };

  return (
    <div className="order_wrap">
      <Mheader title="반품 주문서 생성" />
      <div className="order_ok_container wd">
        <div className="top_pagenation">
          <h2>반품 주문서 생성</h2>
          <ul className="top_btn_box">
            <li onClick={() => navigate(-1)}>
              <p>취소</p>
            </li>
            <li>
              <p onClick={onClickComplete}>완료</p>
            </li>
          </ul>
        </div>
        <div className="order_ok_info">
          <div className="contract_content" style={{ marginBottom: "30px" }}>
            <div className="content_top">
              <span>주문서 번호 :&nbsp;</span>
              <span className="public_code">{orderDetail.orders?.code}</span>
            </div>
            <div className="content_btm">
              <div className="content_box">
                {orderDetail.bidType === "Q" ? (
                  <img
                    className="company_img"
                    src={orderDetail.constructionLogo}
                    title={orderDetail.constructionName}
                    alt=""
                  />
                ) : (
                  <img
                    className="company_img"
                    src={orderDetail.constructionLogo}
                    title={orderDetail.constructionName}
                    alt=""
                  />
                )}
                <div className="contract_info">
                  <S.BadgeWrap>
                    <S.Badge state={orderState}>
                      {orderState === "START" && "발행완료"}
                      {orderState === "CHECK" && "주문확인"}
                      {orderState === "DONE" && "배송완료"}
                      {orderState === "TESTED" && "검수완료"}
                      {orderState === "CANCEL" && "주문취소"}
                      {orderState === "REJECT" && "반려"}
                      {orderState === "END" && "정산"}
                    </S.Badge>
                    {orderDetail.bidType === "A" && (
                      <S.TypeBadge type={orderDetail.bidType}>
                        주문전용 앱
                      </S.TypeBadge>
                    )}
                    {orderDetail.bidType === "D" && (
                      <S.TypeBadge type={orderDetail.bidType}>
                        PB품목
                      </S.TypeBadge>
                    )}
                    {orderDetail.bidType === "S" && (
                      <S.TypeBadge type={orderDetail.bidType}>
                        일회성입찰
                      </S.TypeBadge>
                    )}
                    {orderDetail.bidType === "T" && (
                      <S.TypeBadge type={orderDetail.bidType}>
                        단가계약
                      </S.TypeBadge>
                    )}
                    {orderDetail.bidType === "P" && (
                      <S.TypeBadge type={orderDetail.bidType}>
                        주문서역발행
                      </S.TypeBadge>
                    )}
                    {orderDetail.bidType === "Q" && (
                      <S.TypeBadge type={orderDetail.bidType}>
                        업체지정
                      </S.TypeBadge>
                    )}
                    {(orderDetail.bidType === "B" ||
                      orderDetail.bidType === "D") && (
                      <S.TypeBadge type={orderDetail.bidType}>
                        PB품목
                      </S.TypeBadge>
                    )}
                  </S.BadgeWrap>

                  <p className="contractor">
                    <span>
                      [{orderDetail.constructionName}]&nbsp;
                      {orderDetail?.siteName}
                    </span>
                    <span>{orderDetail.orders?.createdDate} 발행</span>
                  </p>

                  <div className="contract_text">
                    <div>
                      <p>
                        담당자 : {orderDetail?.siteUser?.name}&nbsp;
                        {orderDetail?.siteUser?.rank}
                      </p>
                      <p>
                        전화번호 :{" "}
                        {formatPhoneNumber(orderDetail?.siteUser?.phone)}
                      </p>
                    </div>
                    <div>
                      {/* <p>발행일시 : </p> */}
                      <p>
                        납품주소 :&nbsp;
                        <span>
                          {orderDetail.orders?.addr},{" "}
                          {orderDetail.orders?.detail}
                        </span>
                      </p>
                      <p>납품기한 : {orderDetail.orders?.delDate || "-"}</p>
                      {orderDetail.orders?.state === "cancel" && (
                        <p>
                          취소사유 :&nbsp;
                          <span>{orderDetail.orders?.cancel}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table_container">
            <table>
              <thead>
                <tr>
                  <th className="table_index">
                    <ConfigProvider
                      theme={{
                        components: {
                          Checkbox: {
                            colorPrimary: "#11336B",
                            colorPrimaryBorder: "#11336B",
                            colorPrimaryHover: "#11336B",
                          },
                        },
                      }}
                    >
                      <Checkbox
                        indeterminate={checkSome}
                        onChange={onCheckAll}
                        checked={checkAll}
                      />
                    </ConfigProvider>
                  </th>
                  <th className="table_name">품목명</th>
                  <th className="table_etc">규격</th>
                  <th className="table_brand">
                    제조국
                    <p className="table_sub_desc">(브랜드)</p>
                  </th>
                  <th className="table_etc">비고</th>
                  <th className="table_unit">단위</th>
                  <th className="table_count">
                    반품
                    <br />
                    가능수량
                  </th>
                  <th className="table_count">반품수량</th>
                  <th className="table_total_amount">
                    최종단가
                    <p className="table_sub_desc">(수수료 포함)</p>
                  </th>
                  <th className="table_total_amount">
                    금액
                    <p className="table_sub_desc">(반품수량 X 최종단가)</p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {orderProductList?.map((data, i) => (
                  <tr value={data} key={i}>
                    <td>
                      <ConfigProvider
                        theme={{
                          components: {
                            Checkbox: {
                              colorPrimary: "#11336B",
                              colorPrimaryBorder: "#11336B",
                              colorPrimaryHover: "#11336B",
                            },
                          },
                        }}
                      >
                        <Checkbox
                          onChange={(e) => onCheckSome(data, e.target.checked)}
                          checked={orderReturnProductList?.some(
                            (product) =>
                              product.bidProduct.no === data.bidProduct.no
                          )}
                        />
                      </ConfigProvider>
                    </td>
                    <S.ProductNameWrap>
                      {/* {data.productImageUrl && (
                        <S.ProductImage
                          src={data.productImageUrl}
                          alt=""
                          onClick={() =>
                            window.open(data.productImageUrl, "_blank")
                          }
                        />
                      )}
                      {!data.productImageUrl && (
                        <S.DefaultFileImgWrap
                          src={default_invoice_preview}
                          alt=""
                        />
                      )} */}
                      <span>{data?.bidProduct.name}</span>
                    </S.ProductNameWrap>
                    <td>
                      <p className="table_overflow_text">
                        {data?.bidProduct.standard.replace(/, /gi, "\n")}
                      </p>
                    </td>

                    <td>{data?.bidProduct.brand}</td>
                    <td>
                      <p className="table_overflow_text">
                        {data.bidProduct.etc}
                      </p>
                    </td>
                    <td>{data?.bidProduct.unit}</td>
                    <td>{data?.remainCount}</td>
                    <td>
                      <input
                        className="order_return_count_input"
                        defaultValue={0}
                        value={
                          !orderReturnProductList?.some(
                            (product) =>
                              product.bidProduct.no === data.bidProduct.no
                          )
                            ? 0
                            : orderReturnProductList?.filter(
                                (product) =>
                                  product.bidProduct.no === data.bidProduct.no
                              )?.[0].bidProduct.returnCount
                        }
                        type="number"
                        onChange={(e) => {
                          handleReturnCountChange(e, data);
                        }}
                        disabled={
                          !orderReturnProductList?.some(
                            (product) =>
                              product.bidProduct.no === data.bidProduct.no
                          )
                        }
                      />
                    </td>
                    <td>{(data?.price).toLocaleString("ko")}</td>
                    <td>
                      {(
                        (orderReturnProductList?.filter(
                          (product) =>
                            product.bidProduct.no === data.bidProduct.no
                        )?.[0]?.bidProduct.returnCount || 0) * data?.price
                      ).toLocaleString("ko")}
                    </td>
                  </tr>
                ))}
              </tbody>

              <TableSummaryFooter
                list={orderReturnProductList}
                priceKey="price"
                countKey="bidProduct.returnCount"
                rowSpan={3}
                colSpan={8}
                inOrderFee={orderDetail?.orders?.fee}
              />
            </table>
          </div>
          <div className="uniqueness_box">
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>특이사항</p>
              </div>
              <div className="uniqueness_content">
                <pre>{orderDetail.orders?.uniqueness}</pre>
              </div>
            </div>
          </div>
          <div className="uniqueness_box">
            <div className="uniqueness">
              <div className="uniqueness_tit">
                <p>공급사 비고 발신란</p>
              </div>
              <div className="uniqueness_content_mobile">
                <textarea
                  onChange={(e) => setCompanyComment(e.target.value)}
                  placeholder="반품 관련 비고 사항을 입력해 주세요"
                />
              </div>
            </div>
          </div>
          {attachedFileList?.length === 0 ? null : (
            <div className="look_info payment_account">
              <h3 className="s_tit">
                <p>첨부파일 정보</p>
              </h3>
              {attachedFileList?.map((a, index) => {
                return (
                  <div>
                    <p
                      onClick={() => {
                        window.open(a.fileRoot);
                      }}
                    >
                      첨부파일 {index + 1} 보기
                    </p>
                    <span> {a.fileName} </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {loading && <Loading msg={"반품 주문서 생성 중입니다"} />}
    </div>
  );
}
