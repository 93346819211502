import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../asset/css/notice.css";
import Mheader from "../../../component/common/Mheader";
import { getBoard_ListApi } from "../../../api/auth";
import { myInfoMenu } from "../../../asset/js/dummy.js";
import { Pagination } from "antd";

export default function Notice() {
  const navigate = useNavigate();
  const [sortPageList, setSortPageList] = useState({
    pageSort: "ALL",
    nowPage: 1,
    s_no: 1,
  });
  const [maxNum, setMaxNum] = useState(0);
  const [boradInfo, setBoardInfo] = useState([]);
  const [nowUrl, setNowUrl] = useState("/my_page/notice");

  const getBoard_List = async () => {
    const res = await getBoard_ListApi(sortPageList.s_no);
    if (res.status === "success") {
      setBoardInfo(res.data.bList);
      setMaxNum(res.data.maxNum);
    }
  };

  useEffect(() => {
    getBoard_List();
  }, [sortPageList]);

  const handlePageChange = (page) => {
    setSortPageList((prev) => ({ ...prev, nowPage: page }));
  };

  return (
    <div className="notice_wrap">
      <Mheader title="공지사항" />
      <div className="sub">
        <div className="sub_title">
          {myInfoMenu.map((data, index) => (
            <Link
              to={data.to}
              onClick={() => setNowUrl(data.to)}
              className={nowUrl.includes(data.to) ? "sub_on" : "sub_off"}
            >
              {data.title}
            </Link>
          ))}
        </div>
      </div>
      <div className="notice_container wd">
        <div
          className="top_pagenation notice_h2"
          style={{ paddingTop: "70px" }}
        >
          <h2>공지사항</h2>
        </div>
        <div className="table_container">
          <table className="notice_content">
            {boradInfo.map((data, i) => {
              return (
                <tr
                  onClick={() => {
                    navigate(`/my_page/notice/${data.bd_no}`, {
                      state: { boradInfo: boradInfo[i] },
                    });
                  }}
                  key={data.date}
                >
                  <td className="notice_title">{data.bd_title}</td>
                  <td className="notice_info">
                    <p>
                      {" "}
                      {data.bd_regdate.substring(0, 4)}.
                      {data.bd_regdate.substring(5, 7)}.
                      {data.bd_regdate.substring(8, 10)}
                    </p>
                    <p>공새로</p>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
        <div className="notice_pagination_container">
          <Pagination
            current={sortPageList.nowPage}
            total={maxNum}
            onChange={handlePageChange}
            pageSize={10}
          />
        </div>
      </div>
    </div>
  );
}
