import React, { useState } from "react";
import i_close from "../../../asset/images/w-cancel-icon.png";
import { getWinning_bidApi, getTemporaryBidApi } from "../../../api/auth";
import { useEffect } from "react";
import { wonComma } from "../../../util/Util";

export default function TemporaryStoragePop({
  setTemporaryStoragePop,
  pList,
  setPlist,
}) {
  console.log(pList);
  const [bidInfo, setBidInfo] = useState([]);

  const [temporaryList, setTemporaryList] = useState([]);

  const getTemporaryBidFnc = async () => {
    const response = await getTemporaryBidApi(pList[0].bidProduct.bidNo);
    console.log(response);
    if (response.status === "success") {
      setTemporaryList(response.data.offerDetailList);
    }
  };

  useEffect(() => {
    getTemporaryBidFnc();
  }, []);

  const TemporaryStorageApi = async () => {
    setTemporaryStoragePop(false);

    //pList에 들어가야할듯?
    setPlist(
      pList.map((item, i) => ({
        ...item,
        offerDetail: {
          no: temporaryList[i]?.no,
          offerNo: temporaryList[i]?.offerNo,
          price: temporaryList[i]?.price,
          displayPrice: String(temporaryList[i]?.price).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          ),
        },
      }))
    );
  };

  const emptyCheck = () => {
    const isEmpty = isNaN(
      pList
        .map((data, i) => data.bidProduct.count * temporaryList[i]?.price)
        .reduce((sum, current) => sum + current, 0)
    );

    return isEmpty;
  };

  return (
    <div className="pop">
      <div
        className="pop_dim"
        onClick={() => setTemporaryStoragePop(false)}
      ></div>
      <div className="winning_bid_pop">
        <div className="close" onClick={() => setTemporaryStoragePop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3 className="pop_tit">임시저장 내역 확인</h3>
        <div className="winning_bid_pop_info">
          <div className="table_container">
            <table>
              <thead>
                <tr>
                  <th className="table_name">품목명</th>
                  <th className="table_standard">규격</th>
                  <th className="table_brand">
                    제조국
                    <p className="table_sub_desc">(브랜드)</p>
                  </th>
                  <th className="table_etc">비고</th>
                  <th className="table_unit">단위</th>
                  <th className="table_count">수량</th>
                  <th className="table_total_amount">
                    고객사 납품단가
                    <p className="table_sub_desc">(수수료 포함 단가)</p>
                  </th>
                  <th className="table_total_amount">
                    최종 합계 금액
                    <p className="table_sub_desc">(수수료 포함 단가)</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pList.map((data, i) => {
                  //비드인포, 저장시간 등 가져오기
                  return (
                    <tr key={i}>
                      <td>{data.bidProduct.name}</td>
                      <td>
                        <p className="table_overflow_text">
                          {data.bidProduct.standard.replace(/, /gi, "\n")}
                        </p>
                      </td>
                      <td>{data.bidProduct.brand}</td>
                      <td>{data.bidProduct.etc}</td>
                      <td>{data.bidProduct.unit}</td>
                      <td>{data.bidProduct.count}</td>
                      <td>
                        {data.bidProduct.no ===
                        temporaryList[i]?.bidProductNo ? (
                          wonComma(temporaryList[i]?.price) + "원"
                        ) : (
                          <span>저장된 정보가 없습니다.</span>
                        )}
                      </td>
                      <td>
                        {data.bidProduct.no ===
                        temporaryList[i]?.bidProductNo ? (
                          wonComma(
                            data.bidProduct.count * temporaryList[i]?.price
                          ) + "원"
                        ) : (
                          <span>저장된 정보가 없습니다.</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan="7">
                    <div className="tfoot_total_price">
                      최종 합계 금액
                      <br />
                      (고객사 납품 단가)
                    </div>
                  </th>
                  <td>
                    {emptyCheck() ? (
                      <span>저장된 정보가 없습니다.</span>
                    ) : (
                      wonComma(
                        pList
                          .map(
                            (data, i) =>
                              data.bidProduct.count * temporaryList[i]?.price
                          )
                          .reduce((sum, current) => sum + current, 0)
                      ) + "원"
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
            {emptyCheck() ? null : (
              <p
                className="temporary_btn btn"
                onClick={() => {
                  TemporaryStorageApi();
                }}
              >
                임시저장 불러오기
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
