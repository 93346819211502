import * as S from "../../asset/css/invoiceListItemBtn.styles";

export default function InvoiceListItemBtn(props) {
  const {
    invoice,
    Reject,
    Complete,
    onClickOpenUploadModal,
    onClickRejectComment,
  } = props;

  return (
    <S.BtnWrap>
      {invoice.companyInvoice.state === "REQUEST" && (
        <S.CompanionBtn onClick={Reject}>정산 반려</S.CompanionBtn>
      )}
      {invoice.companyInvoice.state === "REQUEST" && (
        <S.UploadBtn onClick={onClickOpenUploadModal}>
          세금계산서 업로드
        </S.UploadBtn>
      )}
      {invoice.companyInvoice.state === "REJECT_TAXINVOICE" && (
        <>
          <S.CompanionBtn onClick={(e) => onClickRejectComment(e)}>
            반려 사유
          </S.CompanionBtn>
          <S.UploadBtn onClick={onClickOpenUploadModal}>
            세금계산서 업로드
          </S.UploadBtn>
        </>
      )}
      {invoice.companyInvoice.state === "PAYMENT" && (
        <S.CompleteBtn onClick={Complete}>대금지급 확인</S.CompleteBtn>
      )}
    </S.BtnWrap>
  );
}
