import React, { useState, useEffect } from "react";
import { useLocation, useHistory, useNavigate } from "react-router";

//redux
import { useDispatch, useSelector } from "react-redux";

import { getNaverInfoByToken } from "../../api/auth";
import { processSocialLogin } from "../../util/Util";

//sweet alert
import swal from "sweetalert";

export default function Naver() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pushToken = useSelector((state) => state.push);

  const [accessToken, setAccessToken] = useState(false);

  const getNaverToken = () => {
    if (!location.hash) {
      navigate("/", { replace: true });
      return;
    }
    const token = location.hash.split("=")[1].split("&")[0];
    setAccessToken(token);
  };

  const _getNaverInfoByToken = async () => {
    const reqData = {
      accessToken: accessToken,
    };

    const response = await getNaverInfoByToken(reqData);
    if (response.status === "success") {
      const id = response.message;
      processSocialLogin("NAVER", id, navigate, dispatch, pushToken);
    } else {
      swal("알 수 없는 오류가 발생하였습니다.", "", "error");
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    getNaverToken();
  }, []);

  useEffect(() => {
    if (accessToken !== false) {
      //정보 체크
      _getNaverInfoByToken();
    }
  }, [accessToken]);

  return (
    <>
      <div className="login_wrap">
        <div className="login_container">
          <h2>네이버 아이디로 로그인중입니다.</h2>
          <p>잠시만 기다려주세요...</p>
        </div>
      </div>
    </>
  );
}
