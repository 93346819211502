import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import i_close from "../../../asset/images/w-cancel-icon.png";
import "../../../asset/css/pop.css";
//css
import "../../../asset/css/myInfoEditPwPop.css";
import { mypage_WithdrawalApi } from "../../../api/auth";
import Cookies from "js-cookie";
//redux
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/action";
import { userDefault } from "../../../redux/reducer/default.js";

//sweet alert
import swal from "sweetalert";

export default function MyInfoWithdrawalPop({ withdrawal, setWithdrawal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //비밀번호스테이트
  const [pwd, setPwd] = useState({
    u_pw: "",
  });

  //비밀번호 영문+숫자+특수문자 8자리 이상
  const patternPwd = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,16}$/;

  const onClick_mypage_UpdatePw = async () => {
    const res = await mypage_WithdrawalApi(pwd.u_pw);
    if (!patternPwd.test(pwd.u_pw)) {
      swal("비밀번호의 비밀번호 형식을 맞춰주세요", "", "warning");
    }
    if (res.message === "pw-error") {
      swal("기존비밀번호가 틀립니다.", "", "warning");
    }
    if (res.status === "success") {
      // Cookies.remove("user");
      // Cookies.remove("rep_site");
      // Cookies.remove("site_num");
      dispatch(actions.setToken(false));
      dispatch(actions.setUser(userDefault));
      swal("회원탈퇴가 완료되었습니다.", "", "success");
      setWithdrawal(false);
      navigate("/");
    }
  };

  return (
    <div className="pop">
      <div className="pop_dim" onClick={() => setWithdrawal(false)}></div>
      <div className="contract_terms_pop my_info_edit_pw_pop my_info_withdrawal_pop">
        <div className="close" onClick={() => setWithdrawal(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3 className="pop_tit">회원탈퇴</h3>
        <form className="edit_pw_input_form">
          <div>
            <h4>비밀번호</h4>
            <input
              type="password"
              placeholder="비밀번호를 입력해주세요."
              onChange={(e) => {
                setPwd({ ...pwd, u_pw: e.target.value });
              }}
            />
          </div>
        </form>
        <p className="withdrawal_text">
          <span>* </span>진행중인 계약 및 미정산 내역이 있다면 회원탈퇴 하실 수
          없습니다.
          <br />
          계약종료 및 정산을 모두 완료한 후 회원탈퇴를 진행해주세요.
        </p>
        <div className="terms_pop_btn btn_box">
          <div className="cancel">
            <p onClick={() => setWithdrawal(false)}>취소</p>
          </div>
          <div className="ok" onClick={onClick_mypage_UpdatePw}>
            <p>확인</p>
          </div>
        </div>
      </div>
    </div>
  );
}
