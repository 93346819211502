import React, { useState } from "react";
import i_close from "../../../asset/images/w-cancel-icon.png";
import { getWinning_bidApi } from "../../../api/auth";
import { useEffect } from "react";
import { wonComma } from "../../../util/Util";

export default function OriginContractPop({ setOriginContractPop, pList }) {
  return (
    <div className="pop">
      <div
        className="pop_dim"
        onClick={() => setOriginContractPop(false)}
      ></div>
      <div className="winning_bid_pop">
        <div className="close" onClick={() => setOriginContractPop(false)}>
          <img src={i_close} alt="" />
        </div>
        <h3 className="pop_tit">기존 계약 확인</h3>
        <div className="winning_bid_pop_info">
          <div className="table_container">
            <table>
              <thead>
                <tr>
                  <th className="table_name">품목명</th>
                  <th className="table_standard">규격</th>
                  <th className="table_count">수량</th>
                  <th className="table_etc">비고</th>
                  <th className="table_amount">단가</th>
                  <th className="table_amount">
                    최종단가
                    <p className="table_sub_desc">(수수료 포함)</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pList.map((data, i) => {
                  return (
                    <tr>
                      <td>{data?.bp_name}</td>
                      <td>
                        <p className="table_overflow_text">
                          {data.bp_standard.replace(/, /gi, "\n")}
                        </p>
                      </td>
                      <td>{data?.bp_unit}</td>
                      <td>{data.od_count}</td>
                      <td>{data.od_price}</td>
                      <td>{wonComma(data?.od_count * data?.od_price)}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5">합계</td>
                  <td>
                    {wonComma(
                      pList
                        .map((data) => data.od_count * data.od_price)
                        .reduce((sum, current) => sum + current, 0)
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
