import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import i_close from '../../../asset/images/w-cancel-icon.png';
import '../../../asset/css/pop.css';
import { mypage_send_smsApi } from '../../../api/auth';

//sweet alert
import swal from 'sweetalert';


export default function InviteMsgPop({
  inviteMsg,
  setInviteMsg,
  company,
  user,
}) {
  const [receiver, setReceiver] = useState('');

  const onClickMypage_send_sms = async () => {
    const res = await mypage_send_smsApi(
      company.c_code,
      receiver,
      user.u_phone
    );

    if (receiver === '') {
      swal('핸드폰번호를 입력해주세요','','warning');
    } else if (res.status === 'success') {
      swal('초대문자발송이 완료되었습니다.','','success');
      setInviteMsg(false);
    }
  };

  return (
    <div className='pop'>
      <div className='pop_dim' onClick={() => setInviteMsg(false)}></div>
      <div className='invite_msg_pop'>
        <div className='close' onClick={() => setInviteMsg(false)}>
          <img src={i_close} alt='' />
        </div>
        <div className='invite_pop_tit'>
          <h3>초대문자 발송</h3>
          <p>현장 관계자 핸드폰번호를 입력해주세요</p>
        </div>
        <form>
          <input
            type='text'
            placeholder='-제외 숫자만 
          입력'
            onChange={(e) => {
              setReceiver(e.target.value);
            }}
          />
        </form>
        <div className='invite_btn_box btn_box'>
          <div className='cancel'>
            <p onClick={() => setInviteMsg(false)}>취소</p>
          </div>
          <div className='next'>
            <p onClick={onClickMypage_send_sms}>발송</p>
          </div>
        </div>
      </div>
    </div>
  );
}
