import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//component
import Mheader from "../../../component/common/Mheader";
import x_icon from "../../../asset/images/icon_close_x.svg";

import { tagAddApi, tagFindApi, tagListApi, tagRemoveApi } from "../../../api/auth";
import swal from "sweetalert";
import Loading from "../../../component/etc/Loading";

export default function MyInfoHashTag() {
  const [tagName, setTagName] = useState("");
  const [tagList, setTagList] = useState([]);
  const [done, setDone] = useState(false);
  const [findList, setFindList] = useState([]);

  const [generate, setGenerate] = useState(false);


  const tagAddFnc = async () => {
    if (tagList.length >= 10) {
      return swal("주요 키워드는 10개까지 등록이 가능합니다.", "", "warning");
    }
    if (tagName.indexOf(" ") !== -1) {
      return swal(
        "공백이 있습니다. 공백을 제거하고 다시 입력해주세요.",
        "",
        "warning"
      );
    }
    if (tagName.length === 0 || tagName.length >= 10) {
      return swal("주요 키워드는 1~10자 사이로 입력해주세요.", "", "warning");
    }
    if (tagList.includes(tagName)) {
      return swal("이미 등록된 키워드입니다.", "", "warning");
    }
    if (/^[가-힣0-9]+$/.test(tagName) === false) {
      return swal("한글 및 숫자만 입력 가능합니다.", "", "warning");
    }
    tagAddApiFnc();

  };

  //태그 추가하는 api (2way:입력하여 추가,클릭하여 추가)
  const tagAddApiFnc = async(selectName) =>{
    setGenerate(true);
    const response = await tagAddApi(selectName ? selectName : tagName);
    if (response.status === "success") {
      
      swal("주요 키워드가 추가되었습니다.", "", "success");
      setDone(!done);
      setTagName('');
      setGenerate(false);
    }
  }

  //적용된 list 불러오는 api 
  const tagListFnc = async () => {
    const response = await tagListApi();
    if (response.status === "success") {
      setTagList(response.data);
    } else {
      swal("리스트를 불러오지 못했습니다.", "", "warning");
    }
  };

  //적용된 tag 삭제하는 api
  const tagRemoveFnc = async (a) => {
    const response = await tagRemoveApi(a);
    if (response.status === "success") {
      swal("주요 키워드가 삭제되었습니다.", "", "info");
      setDone(!done);
    } else {
      swal("주요 키워드 삭제에 실패했습니다. 다시 시도해주세요.", "", "warning");
    }
  };

  const chooseTagClick = async(a) => {
    // if (tagList.includes(a)) {
    //     tagRemoveFnc(a);
    //     // return swal("이미 등록된 키워드입니다.", "", "warning");
    // } else {
    if (tagList.length >= 10) {
        return swal(
        "주요 키워드는 10개까지 등록이 가능합니다.",
        "",
        "warning"
        );
    }
    tagAddApiFnc(a);
    // }
  }

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
        tagAddFnc();
    }
  };

  const tagFindFnc = async(event) => {
    const value = event.target.value;
    
    const response = await tagFindApi(value);

    if(response.status === 'success'){
        setFindList(response.data);
    }
  }

  const examList2 = [
    { sort: "특징", category: ["적시배송", "친절", "전화주문", "신속배송"] },
    {
      sort: "품목",
      category: [
        "고철수거전문",
        "안전용품전문",
        "마루전문",
        "기능성도료전문",
        "수중펌프전문",
        "소형건설기계전문",
      ],
    },
    {
      sort: "규모",
      category: [
        "매출100억이상",
        "업력30년이상",
        "배송기사10명이상",
        "창고500평이상",
        "직원30명이상",
        "품목5만여개이상",
      ],
    },
    { sort: "장비", category: ["1톤트럭", "5톤트럭", "지게차", "굴착기"] },
    {
      sort: "배송",
      category: [
        "1일2회배송",
        "일요일배송가능",
        "토요일배송가능",
        "일요일격주휴무",
        "1일1회배송",
      ],
    },
    {
      sort: "배송지역",
      category: [
        "경기도",
        "평택",
        "안산",
        "시흥",
        "부천",
        "안양",
        "수원",
        "화성",
        "용인",
      ],
    },
  ];

  const examTagList = [
    '적시배송',
'친절',
'전화주문',
'신속배송',
'고철수거전문',
'안전용품전문',
'마루전문',
'기능성도료전문',
'수중펌프전문',
'소형건설기계전문',
'매출100억이상',
'업력30년이상',
'배송기사10명이상',
'창고500평이상',
'직원30명이상',
'품목5만여개이상',
'1톤트럭',
'5톤트럭',
'지게차',
'굴착기',
'1일2회배송',
'일요일배송가능',
'토요일배송가능',
'일요일격주휴무',
'1일1회배송',
'경기도',
'평택',
'안산',
'시흥',
'부천',
'안양',
'수원',
'화성',
'용인'
  ]

  useEffect(() => {
    tagListFnc();
  }, [done]);

  return (
    <div className="registration_completed_wrap completed_wrap">
      <Mheader title="주요 키워드" />
      <div className="completed_container wd">
        <div className="top_pagenation">
          <h2>주요 키워드</h2>
        </div>
        {/* <div className='hashtag_pagenation'>
            <h3>해시태그 추가하기</h3>
        </div> */}
        <div className="hashtag_input_container">
          <input
            type="text"
            placeholder="주요 키워드입력(ex. 1일2회배송, 마루전문)"
            onChange={(e) => {
              setTagName(e.target.value);
            }}
            list="tag-options"
            onKeyPress={handleKeyPress}
            onKeyUp={tagFindFnc}
            value={tagName}
          />
          {/* {tagName.length > 0 &&
          <datalist id="tag-options">
            {examTagList.map((data, i)=>(
                <option value={data} key={i}/>
            ))}
          </datalist>
} */}
          <div
            class="newinfo_address_add_btn"
            onClick={() => {
                tagAddFnc();
            }}
          >
            <p>입력</p>
          </div>
        {(findList.length === 0 || tagName.length === 0) ||
        <div className="hashtag_find_container">
            {findList.map((a, i)=>(
                <div onClick={() => {
                    if (tagList.includes(a)) {
                        return swal("이미 등록된 키워드입니다.", "", "warning");
                    } else{
                        chooseTagClick(a);
                    }
                }}>
                    {a}
                </div>
            ))}
        </div>
        }
        </div>
        <div className="hashtag_pagenation">
          <h3>
            적용 주요 키워드{" "}
            <span>
              ( {tagList.length === 0 ? "최대 10개" : tagList.length + " / 10"}{" "}
              )
            </span>
          </h3>
        </div>
        <div className="hashtag_apply_list">
          {tagList.length !== 0 ? (
            tagList.map((a, i) => {
              return (
                <div className="hashtag_apply_elem">
                  <span>
                    <span>#</span>
                    {a}
                  </span>
                  <div
                    onClick={() => {
                      tagRemoveFnc(a);
                    }}
                  >
                    <img src={x_icon} />
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                margin: "30px 0",
                textAlign: "center",
                color: "#d2d2d2",
              }}
            >
              적용된 주요 키워드가 없습니다.
            </div>
          )}
        </div>
        <div className="hashtag_pagenation">
          <h3>주요 키워드 샘플 목록</h3>
        </div>

        <div className="hashtag_sample_list">
          {examList2.map((data, i) => (
            <div key={i} className="hashtag_sample_inner">
              <div className="hashtag_sample_title">{data.sort}</div>
              <div className="hashtag_sample_elem_container">
                {/* 샘플 키워드 */}
                {data.category.map((a, j) => (
                  <div
                    key={j}
                    className={
                      tagList.includes(a)
                        ? "hashtag_active_sample_elem"
                        : "hashtag_sample_elem"
                    }
                    onClick={() => {
                        if (tagList.includes(a)) {
                            tagRemoveFnc(a);
                        } else{
                            chooseTagClick(a);
                        }
                    }}
                  >
                    <span>#</span>
                    {a}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="completed_btn">
          <Link to="/my_page/my_info">뒤로가기</Link>
        </div>
      </div>
      {generate && <Loading />}
    </div>
  );
}
