import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Mheader from "../../../component/common/Mheader";
import * as S from "../../../asset/css/order.styles.js";
import question from "../../../asset/images/question-icon.png";
import "../../../asset/css/order.css";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { ko } from "date-fns/esm/locale";
import search_icon from "../../../asset/images/search-icon.png";
import reset_icon from "../../../asset/images/reset-icon.png";
import excel_icon from "../../../asset/images/excel.png";
import {
  generateCheckApi,
  getMy_Order_ListApi,
  preInvoiceExcelDownApi,
} from "../../../api/auth";
import { useSelector } from "react-redux";
import GenerateCheckPop from "../../../component/common/Pop/generateCheckPop";
import Loading from "../../../component/etc/Loading";
import DashBoardTabs from "../../../component/etc/DashBoardTabs";
import { useMediaQuery } from "react-responsive";
import swal from "sweetalert";
import Pagination from "../../../component/ui/pagination/Pagination.js";

export default function Order() {
  const { page } = useParams();
  const url = "/management/order/";
  const isDesktop = useMediaQuery({ query: "(min-width: 1080px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1080px)" });

  const userData = useSelector((state) => state.user);
  const navigate = useNavigate();

  const location = useLocation();
  const fromPage = location?.state?.fromPage;

  const getFromPage = () => {
    if (!fromPage) {
      return "ALL";
    }

    if (fromPage === "dashboard_page_order_new") {
      return "S";
    } else if (fromPage === "dashboard_page_order_confirm") {
      return "CH";
    } else if (fromPage === "dashboard_page_reject") {
      return "R";
    } else {
      return "ALL";
    }
  };

  const [generateFile, setGenerateFile] = useState();
  const [generatePop, setGeneratePop] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [sortPageList, setSortPageList] = useState({
    pageSort: getFromPage(),
    startDate: "2021-01-01",
    endDate: moment().format("yyyy-MM-DD"),
  });
  const [orList, setOrList] = useState([]);
  const [siteList, setSiteList] = useState();
  const [stateMap, setStateMap] = useState([]);
  const [maxCount, setMaxCount] = useState();
  const [currentPage, setCurrentPage] = useState(Number(page) || 1);

  const onClick_getMy_Order_List = async () => {
    const uc_no = userData.uc_no;

    if (uc_no !== null) {
      const res = await getMy_Order_ListApi(
        uc_no,
        sortPageList.pageSort,
        currentPage,
        sortPageList.startDate,
        sortPageList.endDate,
        siteValue,
        keyword !== "" ? keyword : "ALL"
      );

      if (res.status === "success") {
        setOrList(res.data.orList);
        setSiteList(res.data.siteList);
        setSiteAmount(res.data.siteAmount);
        setStateMap(res.data.stateMap);
        setMaxCount(res.data.maxCount);
      }
    }
  };

  const generateCheck = async (orNo) => {
    setGenerating(true);
    const response = await generateCheckApi(orNo);

    if (response.status === "success") {
      if (response.data === null) {
        setGenerating(false);
        swal("검수확인서가 존재하지 않습니다.", "", "warning");
        return false;
      }
      setGenerateFile(response.data.f_root);
      setGenerating(false);
      setGeneratePop(true);
    }
  };

  useEffect(() => {
    onClick_getMy_Order_List();
  }, [sortPageList, currentPage, siteValue, startDate, endDate]);

  const [siteValue, setSiteValue] = useState(0);
  const [siteAmount, setSiteAmount] = useState();

  const onChangeSiteValue = (e) => {
    setSiteValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectValue, setSelectValue] = useState("기간 전체");
  const [keyword, setKeyword] = useState("");
  const [enteredKeyword, setEnteredKeyword] = useState("");

  const onChangeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
    setCurrentPage(1);
  };

  const setDate = (subtract, unit) => {
    setStartDate(
      new Date(moment().subtract(subtract, unit).format("yyyy-MM-DD"))
    );
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setSortPageList({
      ...sortPageList,
      startDate: moment().subtract(subtract, unit).format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    });
  };

  const onChangeKeyword = (e) => {
    setKeyword(e.target.value);
  };

  const onKeyPressSearchIcon = (e) => {
    if (e.key === "Enter") {
      setEnteredKeyword(keyword);
      onClick_getMy_Order_List();
      setCurrentPage(1);
    }
  };

  const onClickReset = () => {
    setStartDate(new Date("2021-01-01"));
    setEndDate(new Date(moment().format("yyyy-MM-DD")));
    setSelectValue("기간 전체");
    setSiteValue(0);
    setKeyword("");
    setEnteredKeyword("");
    setSortPageList({
      ...sortPageList,
      pageSort: "ALL",
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectValue === "최근 1개월") setDate(1, "M");
    if (selectValue === "최근 3개월") setDate(3, "M");
    if (selectValue === "최근 6개월") setDate(6, "M");
    if (selectValue === "최근 1년") setDate(1, "y");
    if (selectValue === "기간 전체") {
      setStartDate(new Date("2021-01-01"));
      setEndDate(new Date(moment().format("yyyy-MM-DD")));
      setSortPageList({
        ...sortPageList,
        startDate: "2021-01-01",
        endDate: moment().format("yyyy-MM-DD"),
      });
    }
  }, [selectValue, siteValue]);

  // 예비 정산내역 엑셀 다운로드
  const downloadLinkRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onClickPreInvoiceDownload = async () => {
    setLoading(true);

    try {
      const response = await preInvoiceExcelDownApi(
        userData.c_no,
        sortPageList.pageSort,
        sortPageList.startDate,
        sortPageList.endDate,
        siteValue,
        keyword !== "" ? keyword : "ALL"
      );

      const url = URL.createObjectURL(response.data);
      let filename = "filename.xlsx";
      const contentDisposition = response.headers["content-disposition"];

      if (contentDisposition) {
        const match = contentDisposition.match(/fileName="([^"]+)"/);
        if (match && match[1]) {
          filename = decodeURIComponent(match[1]).replace(/\+/g, " ");
        }
      }

      downloadLinkRef.current.href = url;
      downloadLinkRef.current.download = filename;
      downloadLinkRef.current.click();
    } catch (error) {
      swal("다운로드 오류", "", "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    navigate(`${url}${page}`);
  };

  return (
    <S.Wrapper>
      <Mheader title="주문관리" />
      <S.DashboardContainer>
        {isDesktop && <DashBoardTabs />}
        <S.DashBoardContent>
          <S.ContentHeader>
            <S.TitleWrap>
              <S.Title>나의 주문관리 현황</S.Title>
              {isDesktop && (
                <>
                  <div>|</div>
                  <S.SelectedDate>
                    {sortPageList?.startDate} ~ {sortPageList?.endDate}
                  </S.SelectedDate>
                </>
              )}
            </S.TitleWrap>

            {isDesktop && (
              <S.AlignContainer>
                <S.AlignWrap>
                  <S.SiteSelect onChange={onChangeSiteValue} value={siteValue}>
                    {siteList?.map((site) => (
                      <option value={site.siteNo}>{site?.siteName}</option>
                    ))}
                    <option value={0}>현장 전체</option>
                  </S.SiteSelect>

                  <S.DateRangeSelect
                    onChange={onChangeSelectValue}
                    value={selectValue}
                  >
                    <option>최근 1개월</option>
                    <option>최근 3개월</option>
                    <option>최근 6개월</option>
                    <option>최근 1년</option>
                    <option>기간 전체</option>
                  </S.DateRangeSelect>

                  <S.DateWrap>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        setSortPageList({
                          ...sortPageList,
                          startDate: moment(date).format("yyyy-MM-DD"),
                        });
                        navigate(url + "1");
                        setCurrentPage(1);
                      }}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="yyyy-MM-dd"
                      locale={ko}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </S.DateWrap>

                  <S.DateWrap>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                        setSortPageList({
                          ...sortPageList,
                          endDate: moment(date).format("yyyy-MM-DD"),
                        });
                        navigate(url + "1");
                        setCurrentPage(1);
                      }}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      dateFormat="yyyy-MM-dd"
                      locale={ko}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </S.DateWrap>
                </S.AlignWrap>

                <S.SearchWrap>
                  <S.SearchAlignWrap>
                    <S.SearchInput
                      placeholder="품목명, 주문번호, 현장명을 입력해주세요"
                      onChange={onChangeKeyword}
                      onKeyPress={onKeyPressSearchIcon}
                      value={keyword}
                    />
                    <S.SearchIcon>
                      <img
                        src={search_icon}
                        onClick={() => {
                          setEnteredKeyword(keyword);
                          onClick_getMy_Order_List();
                          setCurrentPage(1);
                        }}
                        alt="search_icon"
                      />
                    </S.SearchIcon>
                  </S.SearchAlignWrap>

                  <S.ResetIcon onClick={onClickReset}>
                    <img src={reset_icon} alt="reset_icon" />
                  </S.ResetIcon>
                </S.SearchWrap>
              </S.AlignContainer>
            )}
          </S.ContentHeader>

          <S.ContentBody>
            <S.OrderStateWrap>
              <S.OrderState>발행완료</S.OrderState>
              <S.OrderStartBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "S",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.start}건
              </S.OrderStartBtn>
            </S.OrderStateWrap>

            <S.OrderStateWrap>
              <S.OrderState>주문확인</S.OrderState>
              <S.OrderCheckBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "CH",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.check}건
              </S.OrderCheckBtn>
            </S.OrderStateWrap>

            <S.OrderStateWrap>
              <S.OrderState>배송완료</S.OrderState>
              <S.OrderDoneBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "D",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.done}건
              </S.OrderDoneBtn>
            </S.OrderStateWrap>

            <S.OrderStateWrap>
              <S.OrderState>검수완료</S.OrderState>
              <S.OrderTestedBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "T",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.tested}건
              </S.OrderTestedBtn>
            </S.OrderStateWrap>

            <S.OrderStateWrap>
              <S.OrderState>정산</S.OrderState>
              <S.OrderEndBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "E",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {stateMap.endCnt}건
              </S.OrderEndBtn>
            </S.OrderStateWrap>

            {isDesktop && <S.DivideLine></S.DivideLine>}

            <S.OrderStateWrap>
              <S.OrderState>전체보기</S.OrderState>
              <S.OrderAllBtn
                sortPageList={sortPageList}
                onClick={() => {
                  setSortPageList({
                    ...sortPageList,
                    pageSort: "ALL",
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
              >
                {Object.values(stateMap).reduce((acc, cur) => +acc + +cur, 0)}건
              </S.OrderAllBtn>
            </S.OrderStateWrap>
          </S.ContentBody>

          <S.ContentFooter>
            <S.CancelNumBtn
              sortPageList={sortPageList}
              onClick={() => {
                setSortPageList({
                  ...sortPageList,
                  pageSort: "CA",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              취소 <span>{stateMap.cancel}</span>건
            </S.CancelNumBtn>

            <S.RejectNumBtn
              sortPageList={sortPageList}
              onClick={() => {
                setSortPageList({
                  ...sortPageList,
                  pageSort: "R",
                });
                navigate(url + "1");
                setCurrentPage(1);
              }}
            >
              반려 <span>{stateMap.reject}</span>건
            </S.RejectNumBtn>
          </S.ContentFooter>
        </S.DashBoardContent>

        {isDesktop && (
          <>
            <S.SearchResultWrap>
              <S.SearchResult>
                <span>{`"${
                  enteredKeyword !== "" ? enteredKeyword : "전체"
                }" `}</span>
                검색 결과 총 <span>{maxCount}</span>
                건이 검색되었습니다. (미정산 금액 :{" "}
                <span>{siteAmount?.toLocaleString("ko") || 0}</span>원)
                <span />
                <a data-tip="React-tooltip">
                  <img alt="" src={question} className="question_img" />
                  <ReactTooltip
                    place="right"
                    type="dark"
                    effect="solid"
                    backgroundColor="#002863"
                  >
                    <span>기 표기된 금액은 수수료 공제 전 금액입니다.</span>
                  </ReactTooltip>
                </a>
              </S.SearchResult>

              <div style={{ display: "flex" }}>
                <S.ExcelDownBtn
                  onClick={onClickPreInvoiceDownload}
                  disabled={!maxCount}
                >
                  <S.ExcelIcon src={excel_icon} />
                  <S.ExcelText>{`주문내역 엑셀 다운 (${maxCount}건)`}</S.ExcelText>
                </S.ExcelDownBtn>
                <a ref={downloadLinkRef} style={{ display: "none" }} />

                <S.OrderCreateBtn
                  onClick={() => {
                    navigate("/management/order/request_excel");
                  }}
                >
                  주문서 생성
                </S.OrderCreateBtn>
              </div>
            </S.SearchResultWrap>

            {orList?.map((el, idx) => (
              <S.OrderListWrapper key={idx}>
                <S.ListHeader>
                  <S.OrderNo>주문번호 : {el.or_code}</S.OrderNo>
                  <S.SiteName>
                    현장명 : {el.or_type === "C" && el.con_bean?.s_name}
                    {el.or_type === "Q" && el.q_bean?.s_name}
                    {el.or_type === "A" && el.con_bean?.s_name}
                    {el.or_type === "R" && el.con_bean?.s_name}
                    {(el.bidType === "B" || el.bidType === "D") &&
                      el.con_bean?.s_name}
                  </S.SiteName>
                  <S.OrderStep el={el}>
                    <S.Step>
                      <p>발행완료</p>
                    </S.Step>
                    <S.Step>
                      <p>주문확인</p>
                    </S.Step>
                    <S.Step>
                      <p>배송완료</p>
                    </S.Step>
                    <S.Step>
                      <p>검수완료</p>
                    </S.Step>
                    <S.Step>
                      <p>정산</p>
                    </S.Step>
                  </S.OrderStep>
                </S.ListHeader>

                <S.ListBody>
                  <S.ListInfo>
                    <S.BadgeWrap>
                      <S.Badge el={el}>
                        {el.or_state === "START" && "발행완료"}
                        {el.or_state === "CHECK" && "주문확인"}
                        {el.or_state === "DONE" && "배송완료"}
                        {el.or_state === "TESTED" && "검수완료"}
                        {el.or_state === "CANCEL" && "주문취소"}
                        {el.or_state === "REJECT" && "반려"}
                        {el.or_state === "END" && "정산"}
                      </S.Badge>
                      {el.bidType === "A" && (
                        <S.TypeBadge el={el}>주문전용 앱</S.TypeBadge>
                      )}
                      {el.bidType === "S" && (
                        <S.TypeBadge el={el}>일회성 입찰</S.TypeBadge>
                      )}
                      {el.bidType === "T" && (
                        <S.TypeBadge el={el}>단가계약</S.TypeBadge>
                      )}
                      {el.bidType === "P" && (
                        <S.TypeBadge el={el}>주문서역발행</S.TypeBadge>
                      )}
                      {el.bidType === "Q" && (
                        <S.TypeBadge el={el}>업체지정</S.TypeBadge>
                      )}
                      {(el.bidType === "B" || el.bidType === "D") && (
                        <S.TypeBadge el={el}>PB품목</S.TypeBadge>
                      )}
                      {el.bidType === "R" && (
                        <S.TypeBadge el={el}>반품</S.TypeBadge>
                      )}
                    </S.BadgeWrap>
                    <S.NameWrapper>
                      <S.CompanyName>
                        {el.or_type === "C" && el.con_bean?.con_name}
                        {el.or_type === "A" && el.con_bean?.con_name}
                        {el.or_type === "R" && el.con_bean?.con_name}
                        {el.or_type === "Q" && el.q_bean?.con_name}
                        {(el.bidType === "B" || el.bidType === "D") &&
                          el.con_bean?.con_name}
                      </S.CompanyName>
                      <div>|</div>
                      <S.ProductName>{el.product_desc}</S.ProductName>
                    </S.NameWrapper>
                    <S.DetailInfo>
                      <S.BidType>
                        주문형태 :
                        {(el.con_bean?.bid_type === "C" ||
                          el.con_bean?.bid_type === "T") &&
                          ` 단가계약 입찰`}
                        {(el.con_bean?.bid_type === "S" ||
                          el.con_bean?.bid_type === "P") &&
                          ` 일회성 입찰`}
                        {el.or_type === "Q" && ` 업체지정 주문`}
                        {el.or_type === "A" && ` 주문전용 앱`}
                        {(el.bidType === "B" || el.bidType === "D") &&
                          ` PB품목 주문`}
                        {el.or_type === "R" && ` 반품`}
                      </S.BidType>
                      <S.TotalAmount>
                        주문금액 : {el.total_amount.toLocaleString("ko")}원
                      </S.TotalAmount>
                      <S.OrderRegdate>
                        발행시간 : {el.or_regdate.slice(0, 16)}
                      </S.OrderRegdate>
                      <S.OrderRegdate>
                        납품기한 :{" "}
                        {el.or_deldate ? el.or_deldate.slice(0, 16) : "-"}
                      </S.OrderRegdate>
                    </S.DetailInfo>
                  </S.ListInfo>
                  <S.BtnWrapper>
                    <S.OrderContentBtn
                      onClick={() => {
                        navigate(`/management/order/document/${el.or_no}`);
                      }}
                    >
                      주문서 상세 내역
                    </S.OrderContentBtn>

                    {(el.or_state === "TESTED" || el.or_state === "END") && (
                      <S.TestedSheetBtn
                        onClick={() => {
                          generateCheck(el.or_no);
                        }}
                      >
                        검수확인서 보기
                      </S.TestedSheetBtn>
                    )}
                  </S.BtnWrapper>
                </S.ListBody>
              </S.OrderListWrapper>
            ))}
            <div className="order_pagination_container">
              <Pagination
                current={currentPage}
                total={maxCount}
                onChange={handlePageChange}
                pageSize={10}
              />
            </div>
          </>
        )}
      </S.DashboardContainer>
      {generatePop ? (
        <GenerateCheckPop
          setGeneratePop={setGeneratePop}
          filePath={generateFile}
        />
      ) : null}
      {generating && <Loading msg={"검수확인서 생성중입니다."} />}
      {loading && <Loading />}

      {isMobile && (
        <div className="order_wrap">
          <S.AlignWrap>
            <S.DateWrap>
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setSortPageList({
                    ...sortPageList,
                    startDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>

            <S.DateWrap>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setSortPageList({
                    ...sortPageList,
                    endDate: moment(date).format("yyyy-MM-DD"),
                  });
                  navigate(url + "1");
                  setCurrentPage(1);
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </S.DateWrap>
          </S.AlignWrap>
          <div className="order_container">
            <div className="contract_list">
              <div className="contract_list_top">
                <div className="contract_list_count_box">
                  <p
                    onClick={() => {
                      navigate("/management/order/request_excel");
                    }}
                    className="request_sheet_btn"
                  >
                    <span>주문서 생성</span>
                    <span></span>
                  </p>
                  <span style={{ color: "#999" }}>
                    (* 기 표기된 금액은 수수료 공제 전 금액입니다.)
                  </span>
                  <span>
                    미정산 금액 :&nbsp;
                    <dd className="contract_list_count">
                      {siteAmount?.toLocaleString("ko") || 0}
                    </dd>
                    원
                  </span>
                  <span>
                    검색결과 :&nbsp;
                    <dd className="contract_list_count">{maxCount}</dd>건
                  </span>
                </div>
                <button
                  className="contract_list_excel_button"
                  onClick={onClickPreInvoiceDownload}
                >
                  {`주문내역 엑셀 다운 (${maxCount}건)`}
                </button>
                <a ref={downloadLinkRef} style={{ display: "none" }} />
                <button
                  className="contract_list_search_button"
                  onClick={onClick_getMy_Order_List}
                >
                  검색
                </button>

                <div className="contract_list_align_wrap">
                  <input
                    className="contract_list_search_input"
                    onChange={onChangeKeyword}
                    placeholder="품목명, 주문번호, 공급사명을 입력해주세요"
                    value={keyword}
                  />
                  <div className="reset_icon_wrap" onClick={onClickReset}>
                    <img src={reset_icon} alt="reset_icon" />
                  </div>
                </div>

                <select
                  className="contract_list_site_select"
                  onChange={onChangeSiteValue}
                  value={siteValue}
                >
                  {siteList?.map((site) => (
                    <option value={site.siteNo}>{site?.siteName}</option>
                  ))}
                  <option value={0}>현장 전체</option>
                </select>
              </div>

              <div className="contract_list_btm">
                {orList.map((el, i) => {
                  return (
                    <div className="contract_content" key={i}>
                      <div
                        className={
                          el.or_state === "CANCEL"
                            ? "content_top"
                            : "content_top content_delivery"
                        }
                      >
                        <div>
                          <span>주문서 번호 :&nbsp;</span>
                          <span className="public_code">{el.or_code}</span>
                        </div>
                        {el.or_state === "CANCEL" ? null : (
                          <div className="progress_bar">
                            <div
                              className={
                                el.or_state === "START"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>발행완료</span>
                            </div>
                            <div
                              className={
                                el.or_state === "CHECK"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>주문확인</span>
                            </div>
                            <div
                              className={
                                el.or_state === "DONE"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>배송완료</span>
                            </div>
                            <div
                              className={
                                el.or_state === "TESTED"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>검수완료</span>
                            </div>
                            <div
                              className={
                                el.or_state === "END"
                                  ? el.or_state + " delivery"
                                  : null
                              }
                            >
                              <span>정산</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="content_btm">
                        <div className="content_box">
                          {el.or_type === "Q" && el.q_bean ? (
                            <img
                              className="company_img"
                              src={el.q_bean.f_root}
                              alt=""
                            />
                          ) : el.or_type === "C" && el.con_bean ? (
                            <img
                              className="company_img"
                              src={el.con_bean.f_root}
                              alt=""
                            />
                          ) : el.or_type === "A" && el.con_bean ? (
                            <img
                              className="company_img"
                              src={el.con_bean.f_root}
                              alt=""
                            />
                          ) : el.bidType === "B" || el.bidType === "D" ? (
                            <img
                              className="company_img"
                              src={el.con_bean.f_root}
                              alt=""
                            />
                          ) : el.or_type === "R" && el.con_bean ? (
                            <img
                              className="company_img"
                              src={el.con_bean.f_root}
                              alt=""
                            />
                          ) : null}
                          <div className="contract_info">
                            <S.BadgeWrap>
                              <S.Badge el={el}>
                                {el.or_state === "START" && "발행완료"}
                                {el.or_state === "CHECK" && "주문확인"}
                                {el.or_state === "DONE" && "배송완료"}
                                {el.or_state === "TESTED" && "검수완료"}
                                {el.or_state === "CANCEL" && "주문취소"}
                                {el.or_state === "REJECT" && "반려"}
                                {el.or_state === "END" && "정산"}
                              </S.Badge>
                              {el.bidType === "A" && (
                                <S.TypeBadge el={el}>주문전용 앱</S.TypeBadge>
                              )}
                              {el.bidType === "S" && (
                                <S.TypeBadge el={el}>일회성 입찰</S.TypeBadge>
                              )}
                              {el.bidType === "T" && (
                                <S.TypeBadge el={el}>지정단가계약</S.TypeBadge>
                              )}
                              {el.bidType === "P" && (
                                <S.TypeBadge el={el}>주문서역발행</S.TypeBadge>
                              )}
                              {el.bidType === "Q" && (
                                <S.TypeBadge el={el}>업체지정</S.TypeBadge>
                              )}
                              {(el.bidType === "B" || el.bidType === "D") && (
                                <S.TypeBadge el={el}>PB품목</S.TypeBadge>
                              )}
                              {el.bidType === "R" && (
                                <S.TypeBadge el={el}>반품</S.TypeBadge>
                              )}
                            </S.BadgeWrap>

                            {el.or_type === "Q" && el.q_bean ? (
                              <p className="contractor">
                                <span>
                                  [{el.q_bean.con_name}]&nbsp;
                                  {el.q_bean.s_name}
                                </span>
                                <span>
                                  {el.q_bean.q_type === "Q"
                                    ? "일반퀵주문"
                                    : "매칭퀵주문"}
                                </span>
                              </p>
                            ) : el.or_type === "C" && el.con_bean ? (
                              <p className="contractor">
                                <span>
                                  [{el.con_bean.con_name}]&nbsp;
                                  {el.con_bean.s_name}
                                </span>
                                <span>
                                  {el.con_bean.bid_type === "T"
                                    ? "단가 계약 입찰"
                                    : "일회성 입찰"}
                                </span>
                              </p>
                            ) : el.or_type === "R" && el.con_bean ? (
                              <p className="contractor">
                                <span>
                                  [{el.con_bean.con_name}]&nbsp;
                                  {el.con_bean.s_name}
                                </span>
                                <span>반품</span>
                              </p>
                            ) : el.or_type === "A" && el.con_bean ? (
                              <p className="contractor">
                                <span>
                                  [{el.con_bean.con_name}]&nbsp;
                                  {el.con_bean.s_name}
                                </span>
                                <span>주문전용 앱</span>
                              </p>
                            ) : el.bidType === "B" || el.bidType === "D" ? (
                              <p className="contractor">
                                <span>
                                  [{el.con_bean.con_name}]&nbsp;
                                  {el.con_bean.s_name}
                                </span>
                                <span>PB품목 주문</span>
                              </p>
                            ) : null}

                            {el.or_type === "Q" && el.q_bean ? (
                              <div className="contract_text">
                                <div>
                                  <p>발행일시 : {el.or_regdate}</p>
                                </div>
                                <div>
                                  <p>납품기한 : {el.or_deldate}</p>
                                  <p>
                                    납품주소 :&nbsp;
                                    <span>
                                      {el.or_addr}, {el.or_detail}
                                    </span>
                                  </p>
                                  {el.or_state === "cancel" && (
                                    <p>
                                      취소사유 :&nbsp;
                                      <span>{el.e_cancel}</span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : el.or_type === "C" && el.con_bean ? (
                              <div className="contract_text">
                                <div>
                                  {el.con_bean.bid_type === "T" ? (
                                    <p>계약기간 : {el.con_bean.bid_term}개월</p>
                                  ) : null}
                                  <p>발행일시 : {el.or_regdate}</p>
                                </div>
                                <div>
                                  <p>납품기한 : {el.or_deldate}</p>
                                  <p>
                                    납품주소 :&nbsp;
                                    <span>
                                      {el.or_addr}, {el.or_detail}
                                    </span>
                                  </p>
                                  {el.or_state === "cancel" && (
                                    <p>
                                      취소사유 :&nbsp;
                                      <span>{el.e_cancel}</span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : el.or_type === "R" && el.con_bean ? (
                              <div className="contract_text">
                                <div>
                                  {el.con_bean.bid_type === "T" ? (
                                    <p>계약기간 : {el.con_bean.bid_term}개월</p>
                                  ) : null}
                                  <p>발행일시 : {el.or_regdate}</p>
                                </div>
                                <div>
                                  <p>납품기한 : {el.or_deldate}</p>
                                  <p>
                                    납품주소 :&nbsp;
                                    <span>
                                      {el.or_addr}, {el.or_detail}
                                    </span>
                                  </p>
                                  {el.or_state === "cancel" && (
                                    <p>
                                      취소사유 :&nbsp;
                                      <span>{el.e_cancel}</span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : el.or_type === "A" && el.con_bean ? (
                              <div className="contract_text">
                                <div>
                                  {el.con_bean.bid_type === "T" ? (
                                    <p>계약기간 : {el.con_bean.bid_term}개월</p>
                                  ) : null}
                                  <p>발행일시 : {el.or_regdate}</p>
                                </div>
                                <div>
                                  <p>납품기한 : {el.or_deldate}</p>
                                  <p>
                                    납품주소 :&nbsp;
                                    <span>
                                      {el.or_addr}, {el.or_detail}
                                    </span>
                                  </p>
                                  {el.or_state === "cancel" && (
                                    <p>
                                      취소사유 :&nbsp;
                                      <span>{el.e_cancel}</span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : el.bidType === "B" || el.bidType === "D" ? (
                              <div className="contract_text">
                                <div>
                                  <p>발행일시 : {el.or_regdate}</p>
                                </div>
                                <div>
                                  <p>
                                    납품주소 :&nbsp;
                                    <span>
                                      {el.or_addr}, {el.or_detail}
                                    </span>
                                  </p>
                                  {el.or_state === "cancel" && (
                                    <p>
                                      취소사유 :&nbsp;
                                      <span>{el.e_cancel}</span>
                                    </p>
                                  )}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="contract_more">
                          <p
                            onClick={() => {
                              navigate(
                                `/management/order/document/${el.or_no}`
                              );
                            }}
                          >
                            주문서 상세 내역
                          </p>
                          {el.or_state === "TESTED" && (
                            <p
                              onClick={() => {
                                generateCheck(el.or_no);
                              }}
                            >
                              검수확인서 보기
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="order_pagination_container">
                <Pagination
                  current={currentPage}
                  total={maxCount}
                  onChange={handlePageChange}
                  pageSize={10}
                />
              </div>
              {generatePop ? (
                <GenerateCheckPop
                  setGeneratePop={setGeneratePop}
                  filePath={generateFile}
                />
              ) : null}
              {generating && <Loading msg={"검수확인서 생성중입니다."} />}
            </div>
          </div>
        </div>
      )}
    </S.Wrapper>
  );
}
