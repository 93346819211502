import { useState, useEffect } from "react";
import { getMyPage_listApi } from "../../../api/auth";

function useFee() {
  const [fee, setFee] = useState(0);

  const getMyPageList = async () => {
    try {
      const res = await getMyPage_listApi();
      if (res.status === "success") {
        setFee(res.data.company.c_fee);
      }
    } catch (error) {
      console.error("Error fetching fee:", error);
    }
  };

  useEffect(() => {
    getMyPageList();
  }, []);

  return fee;
}

export default useFee;
