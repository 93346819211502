import React, { useState, useEffect } from "react";
// img
import i_remove from "../../../asset/images/w-cancle-bt.png";
//component
import Mheader from "../../../component/common/Mheader";
//css
import "../../../asset/css/registrationInput.css";

//api
import {
  getCategory_BiglistApi,
  getCategory_listApi,
  getCategory_SlistApi,
  getProcess_listAPi,
  imgUpApi,
  postCompany_ProductApi,
} from "../../../api/auth";

//redux
import { useSelector } from "react-redux";

//sweet alert
import swal from "sweetalert";

import { Link, useNavigate } from "react-router-dom";

//menu
import { myInfoMenu } from "../../../asset/js/dummy.js";

export default function RegistrationInput(props) {
  const userData = useSelector((state) => state.user);

  // 뒤로가기
  const goBack = () => {
    window.history.back();
  };
  const navigate = useNavigate();

  //formData대표공정보내기
  const [conInfo, setConInfo] = useState({});

  const [nowUrl, setNowUrl] = useState("/my_page/registration");

  //공정관리리스트받아오기
  const [ProcessList, setProcessList] = useState([]);
  const getProcess_list = async () => {
    const res = await getProcess_listAPi();

    if (res.status === "success") {
      setCategory_list([]);
      setBigCategory_list([]);
      setScategory_list([]);
      setProcessList(res.data);
    } else {
      setCategory_list([]);
      setBigCategory_list([]);
      setScategory_list([]);
    }
  };

  useEffect(() => {
    getProcess_list();
  }, []);

  //세부공정리스트 받아오기
  const [category_list, setCategory_list] = useState([]);
  const getCategory_list = async () => {
    const res = await getCategory_listApi(conInfo.pro_no);
    if (res.status === "success") {
      setBigCategory_list([]);
      setScategory_list([]);
      setCategory_list(res.data);
    } else {
      setBigCategory_list([]);
      setScategory_list([]);
    }
  };
  useEffect(() => {
    conInfo.pro_no && getCategory_list();
  }, [conInfo.pro_no]);

  //대분류 리스트 가져오기
  const [bigCategory_list, setBigCategory_list] = useState([]);
  const getCategory_Biglist = async () => {
    const res = await getCategory_BiglistApi(conInfo.lc_no);
    if (res.status === "success") {
      setScategory_list([]);
      setBigCategory_list(res.data);
    } else {
      setScategory_list([]);
    }
  };

  useEffect(() => {
    conInfo.lc_no && getCategory_Biglist();
  }, [conInfo.lc_no]);

  //소분류 리스트 가져오기
  const [sCategory_list, setScategory_list] = useState([]);
  const getCategory_Slist = async () => {
    const res = await getCategory_SlistApi(conInfo.mc_no);

    if (res.status === "success") {
      setScategory_list(res.data);
    }
  };

  useEffect(() => {
    conInfo.mc_no && getCategory_Slist();
  }, [conInfo.mc_no]);

  //상품 이미지 업로드

  const [logoImg, setLogoImage] = useState(null);
  const [selectFile, setSelectFile] = useState("");
  const [imgList, setImgList] = useState();
  const handleFileChange = async (e) => {
    setSelectFile(e.target.files[0].name);
    setLogoImage(e.target.files[0]);
  };
  const handleLogoIgForm = async () => {
    let form = new FormData();
    form.append("file", logoImg);
    const response = await imgUpApi(form);

    if (response.status === "success") {
      setConInfo({ ...conInfo, p_f_img: response.data.f_no });
      setImgList(response.data);
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };
  useEffect(() => {
    if (logoImg) {
      handleLogoIgForm();
    }
  }, [logoImg]);

  // const [goodsDesc, setGoodsDesc] = useState(null);
  // const [selectFile2, setSelectFile2] = useState('');
  // // const [imgList, setImgList] = useState();
  // const handleGoodsFileChange = (e) => {
  //   setSelectFile2(e.target.files[0].name);
  //   setGoodsDesc(e.target.files[0]);
  // };
  // const handleGoodsFileForm = async () => {
  //   let form = new FormData();
  //   form.append('file', goodsDesc);
  //   const response = await imgUpApi(form);
  //
  //   if (response.status === 'success') {
  //     setConInfo({ ...conInfo, p_f_file1: response.data.f_no });
  //     // setGoodsDesc(response.data);
  //   } else {
  //     swal('파일이 업로드가 되지 않았습니다.');
  //   }
  // };
  // useEffect(() => {
  //   if (goodsDesc) {
  //     handleGoodsFileForm();
  //   }
  // }, [goodsDesc]);

  //물품등록요청

  const formData = {
    c_no: userData.c_no,
    p_sc_no: conInfo.p_sc_no,
    p_name: conInfo.p_name,
    p_brand: conInfo.p_brand,
    p_f_img: conInfo.p_f_img,
    p_comment: conInfo.p_comment,
    p_standard: conInfo.p_standard,
    p_unit: conInfo.p_unit,
    p_price: conInfo.p_price,
    p_etc: conInfo.p_etc,
    p_f_attach: conInfo.p_f_attach,
  };

  //

  const onClick_postCompany_Product = async () => {
    // formData?.p_f_attach.filter((elem)=>elem.f_no);
    //
    // let copy = formData.p_f_attach.map((file)=>{
    //   // let returnObj = {};
    //   // returnObj["f_no"] = file.f_no;
    //   // return returnObj;
    //   let concatFile = file.concat(concatFile, ',');
    //

    //   //
    // });

    let copy = formData.p_f_attach.map((a) => a.f_no).join(",");

    //
    formData.p_f_attach = copy;
    if (formData.c_no) {
      if (!formData.p_sc_no || formData.p_sc_no === "") {
        swal("카테고리를 선택해주세요.", "", "warning");
        return;
      }
      if (!formData.p_name || formData.p_name === "") {
        swal("품목명을 입력해주세요.", "", "warning");
        return;
      }
      if (!formData.p_brand || formData.p_brand === "") {
        swal("브랜드명을 입력해주세요.", "", "warning");
        return;
      }
      if (!formData.p_f_img || formData.p_f_img === "") {
        swal("이미지를 입력해주세요.", "", "warning");
        return;
      }
      if (!formData.p_comment || formData.p_comment === "") {
        swal("설명을 입력해주세요.", "", "warning");
        return;
      }
      if (!formData.p_standard || formData.p_standard === "") {
        swal("규격을 입력해주세요.", "", "warning");
        return;
      }
      if (!formData.p_unit || formData.p_unit === "") {
        swal("단위를 입력해주세요.", "", "warning");
        return;
      }

      if (!formData.p_price || formData.p_price === "") {
        swal("납품가격을 입력해주세요.", "", "warning");
        return;
      }

      const res = await postCompany_ProductApi(formData);

      if (res.status === "success") {
        navigate("/my_page/registration/completed");
      } else {
        swal(
          "물품등록요청이 실패하였습니다. \n 빠짐없이 입력해주세요.",
          "",
          "error"
        );
      }
    }
  };

  //파일 추가
  const onCreate = (i) => {
    const file = {
      file: "",
      id: i,
    };
    setUploadFile(uploadFile.concat(file));
  };

  //파일 삭제
  const onRemove = (id) => {
    setUploadFile(uploadFile.filter((file) => file.id !== id));
    setFList(fList.filter((elem) => elem.f_idx !== id));
  };

  const [uploadFile, setUploadFile] = useState([{ file: "", id: 0 }]);
  const [fList, setFList] = useState([]);
  const [uploadImg, setUploadImg] = useState([]);

  useEffect(() => {
    setConInfo({ ...conInfo, p_f_attach: fList });
  }, [fList.length]);

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    //
    const response = await imgUpApi(formData);
    if (response.status === "success") {
      let copy = fList;
      copy.push({ f_no: response.data.f_no, f_idx: fList.length });
      await setFList(copy);
      setUploadImg([...uploadImg, e.target.files[0]]);

      setConInfo({ ...conInfo, p_f_attach: fList });
    } else if (response.status === "NoData" || "Error") {
      swal("파일이 업로드가 되지 않았습니다.", "", "warning");
    }
  };

  return (
    <div className="registration_input_wrap">
      <Mheader title="물품등록 요청" />
      <div className="sub">
        <div className="sub_title">
          {myInfoMenu.map((data, index) => (
            <Link
              to={data.to}
              onClick={() => setNowUrl(data.to)}
              className={nowUrl.includes(data.to) ? "sub_on" : "sub_off"}
            >
              {data.title}
            </Link>
          ))}
        </div>
      </div>
      <div
        className="registration_input_container wd"
        style={{ paddingTop: "100px" }}
      >
        <div className="top_pagenation">
          <h2
            onClick={() => {
              // let a = formData?.p_f_attach.map((file) => file.f_no );
              //
              // let copy = formData.p_f_attach.map((file)=>{
              //   // let returnObj = {};
              //   // returnObj["f_no"] = file.f_no;
              //   // return returnObj;
              //   let result = ' '.concat(file.f_no,',');
              //
              //   return result
              //   //
              //   //
              //   //
              // });
              // let copy = formData.p_f_attach.map(a=>a.f_no).join(',');
              //
              // const aaa = formData.p_f_attach.f_no.join(',');
              // const aaa = ['30000','12313','12312'].join(',');
              //
            }}
          >
            물품등록 요청
          </h2>
          <div className="top_pagenation_num">
            <p className="active">1</p>
            <p>2</p>
          </div>
        </div>
        <div className="register_category">
          <ul className="register_category_list">
            <li>
              <p>대표공정</p>
              <select
                onChange={(e) => {
                  setConInfo({ ...conInfo, pro_no: e.target.value });
                }}
              >
                <option selected>대표공정을 선택해주세요.</option>
                {/* <option disabled hidden>
                  대표공정
                </option> */}
                {ProcessList.map((item, index) => {
                  if (item.pro_no === "7" || item.pro_no === "8") {
                    //현재 공통공사, 이노빌트만 보여주고 나머진 안보여줌
                    return (
                      <option value={item.pro_no} key={index}>
                        {item.pro_name}
                      </option>
                    );
                  }
                })}
              </select>
            </li>
            <li>
              <p>대분류</p>
              <select
                onChange={(e) => {
                  setConInfo({ ...conInfo, lc_no: e.target.value });
                }}
              >
                <option selected disabled hidden>
                  대분류를 선택해주세요.
                </option>
                {category_list.map((item, index) => {
                  return (
                    <option value={item.lc_no} key={index}>
                      {item.lc_name}
                    </option>
                  );
                })}
              </select>
            </li>
            <li>
              <p>중분류</p>
              <select
                onChange={(e) => {
                  setConInfo({ ...conInfo, mc_no: e.target.value });
                }}
              >
                <option selected disabled hidden>
                  중분류를 선택해주세요.
                </option>
                {bigCategory_list.map((item, index) => {
                  return (
                    <option value={item.mc_no} key={index}>
                      {item.mc_name}
                    </option>
                  );
                })}
              </select>
            </li>
            <li>
              <p>소분류</p>
              <select
                onChange={(e) => {
                  setConInfo({ ...conInfo, p_sc_no: e.target.value });
                }}
              >
                <option value="" selected disabled hidden>
                  소분류를 선택해주세요.
                </option>
                {sCategory_list.map((item, index) => {
                  return (
                    <option value={item.sc_no} key={index}>
                      {item.sc_name}
                    </option>
                  );
                })}
              </select>
            </li>
          </ul>
        </div>
        <div className="register_input_box">
          <ul className="register_input_list">
            <li>
              <p>이미지</p>
              <div className="register_input_area">
                <div className="register_input_img">
                  {imgList && (
                    <img
                      className="register_input_img"
                      src={imgList.f_path}
                      alt=""
                    />
                  )}
                </div>
                <div className="img_upload">
                  <div>
                    <input
                      type="file"
                      id="upload"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                    <input type="text" value={selectFile} readOnly />
                    {/* {selectFile !== '' && (
                      <span className='file_remove'>
                        <img src={i_remove} alt='' />
                      </span>
                    )} */}
                  </div>
                  <label htmlFor="upload">찾아보기</label>
                </div>
              </div>
            </li>
            {/* <li>
              <p>제품 설명서</p>
              <div className='img_upload'>
                  <div>
                    <input
                      type='file'
                      id='uploadGoods1'
                      onChange={handleGoodsFileChange}
                    />
                    <input type='text' value={selectFile2} readOnly />
                  </div>
                  <label htmlFor='uploadGoods1'>찾아보기</label>
                </div>
            </li> */}

            <li>
              <p>제품 설명 파일</p>
              <div>
                {uploadFile.map((data, i) => (
                  <div className="img_add" key={data.id}>
                    <input
                      type="file"
                      id={data.id}
                      // accept='image/*'
                      onChange={(e) => {
                        const arr = uploadFile.map((f_name, index) =>
                          f_name.id === data.id
                            ? { ...f_name, file: e.target.files[0].name }
                            : { ...f_name }
                        );
                        setUploadFile(arr);
                        handleFileUpload(e);
                      }}
                    />
                    <p className="img_add_p">{data.file}</p>
                    <label
                      //  { uploadFile[i-1].file !== '' &&
                      //   htmlFor={data.id}}
                      htmlFor={uploadFile[i - 1]?.file !== "" ? data.id : null}
                      className={
                        uploadFile[i - 1]?.file === "" &&
                        "register_unable_label"
                      }
                    >
                      찾아보기
                    </label>
                    {data.id === 0 ? (
                      <div
                        className="btn_add"
                        onClick={() => {
                          if (uploadFile.length < 3) {
                            onCreate(uploadFile.length);
                          }
                        }}
                      >
                        추가　+
                      </div>
                    ) : (
                      <div
                        className="btn_add"
                        onClick={() => {
                          if (!uploadFile.length < 2) {
                            onRemove(uploadFile[i].id);
                          }
                        }}
                      >
                        삭제　-
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </li>

            <li>
              <p>품목명</p>
              <div className="register_input_area">
                <input
                  type="text"
                  placeholder="품목명을 입력해주세요."
                  onChange={(e) => {
                    setConInfo({ ...conInfo, p_name: e.target.value });
                  }}
                />
              </div>
            </li>
            <li>
              <p>브랜드명</p>
              <div className="register_input_area">
                <input
                  type="text"
                  placeholder="브랜드 명을 입력해주세요."
                  onChange={(e) => {
                    setConInfo({ ...conInfo, p_brand: e.target.value });
                  }}
                />
              </div>
            </li>
            <li>
              <p>설명</p>
              <div className="register_input_area">
                <textarea
                  placeholder="설명을 입력해주세요."
                  onChange={(e) => {
                    setConInfo({ ...conInfo, p_comment: e.target.value });
                  }}
                ></textarea>
              </div>
            </li>
            <li>
              <p>규격</p>
              <div className="register_input_area">
                <input
                  type="text"
                  placeholder="규격을 입력해주세요."
                  onChange={(e) => {
                    setConInfo({ ...conInfo, p_standard: e.target.value });
                  }}
                />
              </div>
            </li>
            <li>
              <p>단위</p>
              <div className="register_input_area">
                <input
                  type="text"
                  placeholder="단위를 입력해주세요."
                  onChange={(e) => {
                    setConInfo({ ...conInfo, p_unit: e.target.value });
                  }}
                />
              </div>
            </li>
            <li>
              <p>납품가격</p>

              <div className="register_input_area register_input_right">
                <div className="register_input_area register_input_right">
                  <input
                    type="number"
                    placeholder="납품가격을 입력해주세요."
                    onChange={(e) => {
                      setConInfo({ ...conInfo, p_price: e.target.value });
                    }}
                  />
                  <span>원</span>
                </div>
                <p
                  style={{
                    fontWeight: "initial",
                    fontSize: "13px",
                    width: "100%",
                  }}
                >
                  * 해당 가격은 시세파악을 위한 것으로, 최근 3개월 내 납품했던
                  가격을 입력해주세요.
                </p>
              </div>
            </li>
            <li>
              <p>비고</p>
              <div className="register_input_area">
                <input
                  type="text"
                  placeholder="비고"
                  onChange={(e) => {
                    setConInfo({ ...conInfo, p_etc: e.target.value });
                  }}
                />
              </div>
            </li>
          </ul>
          <div className="register_input_btn_box btn_box">
            <div className="cancel">
              <p onClick={goBack}>취소</p>
            </div>
            <div className="next" onClick={onClick_postCompany_Product}>
              요청
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
